import * as React from "react";
import { Route } from "react-router";
import DashboardPage from "../screens/DashboardPage";
import * as adminContext from "../../administration/stores/context";

const dashboardRoute = () => {
    return (
        <Route
            path={"/dashboard"}
            element={
                <adminContext.StoreProvider>
                    <DashboardPage />
                </adminContext.StoreProvider>
            }
        />
    );
};

export default dashboardRoute;

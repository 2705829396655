import * as React from "react";
const RADIAN = Math.PI / 180;

export const getPieChartLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    hasMultipleValues,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const percentLabel = (percent * 100).toFixed(0);
    return (
        <text
            x={x}
            y={y}
            fill="white"
            textAnchor={"middle"}
            dominantBaseline="central"
        >
            {percentLabel === "100" && hasMultipleValues
                ? "99%>"
                : percentLabel === "0" && hasMultipleValues
                  ? "> 1%"
                  : `${percentLabel}%`}
        </text>
    );
};

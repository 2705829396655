export const PieChartColors = [
    "#59A26D",
    "#FFCE67",
    "#4183D7",
    "#A5476D",
    "#67B5A7",
    "#FF8C61",
    "#4E88B4",
    "#EFA55A",
    "#87B7A3",
    "#FF5733",
    "#D16587",
    "#87CEEB",
    "#151B54",
    "#2B65EC",
    "#CCCCFF",
    "#16F529",
    "#EB5406",
    "#808000",
    "#6F4E37",
    "#4863A0",
];

import * as React from "react";
import { observer } from "mobx-react-lite";
import DashboardStore from "../stores/DashboardStore";
import WidgetEditor from "./WidgetEditor";
import NumericWidget from "../models/NumericWidget";
import { ALL_PROJECTS } from "../../tasks/screens/TasksPage";

type Props = {
    dashboardStore: DashboardStore;
    widget: NumericWidget;
    openEditWidgetModal: (id: string) => void;
};

const NumericWidgetCard: React.FC<Props> = ({
    widget,
    dashboardStore,
    openEditWidgetModal,
}) => {
    const handleWidgetClick = () => {
        const url = `${process.env.PUBLIC_URL}/tasks/project/${widget.projectId || ALL_PROJECTS}/widget/${widget.id}`;
        window.open(url, "_blank")!.focus();
    };

    return (
        <div className="card">
            <div className="section-title">{widget.name}</div>
            {!widget.isDefaultWidget && (
                <WidgetEditor
                    dashboardStore={dashboardStore}
                    widget={widget}
                    openEditWidgetModal={openEditWidgetModal}
                />
            )}
            <div className="list-widget-card-container numeric">
                <h1 onClick={handleWidgetClick}>{widget.totalTasksCount}</h1>
            </div>
        </div>
    );
};

export default observer(NumericWidgetCard);

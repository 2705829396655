import { Route } from "react-router";
import * as React from "react";
import * as taskStatusesContext from "../stores/context";
import TaskStatusesPage from "../screens/TaskStatusesPage";
import * as taskTypesContext from "../../task_types/stores/context";

const taskStatusesRoute = () => {
    return (
        <>
            <Route
                path={"/task/statuses"}
                element={
                    <taskTypesContext.StoreProvider>
                        <taskStatusesContext.StoreProvider>
                            <TaskStatusesPage />
                        </taskStatusesContext.StoreProvider>
                    </taskTypesContext.StoreProvider>
                }
            />
        </>
    );
};

export default taskStatusesRoute;

import security from "./SecurityService";
import AppolloClient, {
    ApolloQueryResult,
    QueryOptions,
    OperationVariables,
} from "apollo-boost";
import {
    InMemoryCache,
    IntrospectionFragmentMatcher,
} from "apollo-cache-inmemory";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import introspectionQueryResultData from "./fragmentTypes.json";

const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData,
});

const maxRetryAmount = 5;

let client: AppolloClient<object>;

function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export async function execQuery<T, TVariables = OperationVariables>(
    options: QueryOptions<TVariables>,
    retryCount = 0,
): Promise<ApolloQueryResult<T>> {
    const { token, refreshed } = await security.getOrRefreshToken();

    if (refreshed || !client) {
        const cache = new InMemoryCache({ fragmentMatcher });
        client = new AppolloClient({
            cache: cache,
            uri: process.env.REACT_APP_DATA_URL,
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    }

    try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return await client.query<T>(options as any);
    } catch (err) {
        const queryName = options.query.definitions[0].name.value;
        console.error(err);

        if (retryCount < maxRetryAmount) {
            const retryTime = Math.pow(2, retryCount);
            console.log(`Retrying ${queryName} in ${retryTime} seconds...`);
            await sleep(1000 * retryTime);
            retryCount++;
            return await execQuery(options, retryCount);
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const emptyData: any = [];
        return {
            data: emptyData,
            errors: [],
            loading: false,
            networkStatus: 7,
            stale: false,
        };
    }
}

import { TaskStatus } from "../types";
import appClient from "../../common/services/AppClient";
import { UserModel } from "../types";

export default class TaskStatusesService {
    uriPrefix = process.env.REACT_APP_TASKS_URL + "taskstatuses";

    async getTaskStatuses(typeId: string) {
        const url = this.uriPrefix + `/type/${typeId}`;
        const resp = await appClient.get<TaskStatus[]>(url);
        return resp.mapErr((err) => new Error(err.data?.title));
    }

    async createTaskStatus(
        name: string,
        code: string,
        isSubtaskStatus: boolean,
        typeId: string,
        assignedUserIds: string[] | undefined = undefined,
    ) {
        const request = {
            name,
            code,
            typeId,
            assignedUserIds,
            isSubtaskStatus,
        };
        const url = process.env.REACT_APP_TASKS_URL + "taskstatuses/new";
        const resp = await appClient.post<string>(url, request);
        return resp.mapErr((err) => new Error(err.data?.title));
    }

    async editTaskStatus(statusId: string, name: string, code: string) {
        const request = {
            statusId,
            name,
            code,
        };
        const url = process.env.REACT_APP_TASKS_URL + "taskstatuses/edit";
        const resp = await appClient.update(url, request);
        return resp.mapErr((err) => new Error(err.data?.title));
    }

    async toggleTaskStatus(statusId: string) {
        const url =
            process.env.REACT_APP_TASKS_URL + `taskstatuses/toggle/${statusId}`;
        const resp = await appClient.update(url);
        return resp.mapErr((err) => new Error(err.data?.title));
    }
    async deleteTaskStatus(statusId: string) {
        const url =
            process.env.REACT_APP_TASKS_URL + `taskstatuses/delete/${statusId}`;
        const resp = await appClient.post(url);
        return resp.mapErr((err) => new Error(err.data?.title));
    }

    async updateStatusDependencies(dependencies: object) {
        const url =
            process.env.REACT_APP_TASKS_URL + "taskstatuses/updatedependencies";
        const resp = await appClient.post(url, dependencies);
        return resp.mapErr((err) => new Error(err.data?.title));
    }

    async updateStatusAssignedUsers(taskStatusId: string, userIds: string[]) {
        const request = {
            taskStatusId,
            userIds,
        };
        const url =
            process.env.REACT_APP_TASKS_URL + "taskstatuses/assignusers";
        const resp = await appClient.post(url, request);
        return resp.mapErr((err) => new Error(err.data?.title));
    }

    async getUsersInProject(projectId: string) {
        const url =
            process.env.REACT_APP_TASKS_URL + `users/project/${projectId}`;
        const resp = await appClient.get<UserModel[]>(url);
        return resp.mapErr((err) => new Error(err.data?.title));
    }
}

import appClient, { ResultApi } from "../../common/services/AppClient";
import {
    ActionConstraint,
    ActionDefinition,
    ActionExecutionPermissions,
    ActionField,
    ActionType,
} from "../types/Actions";
import security from "../../common/services/SecurityService";
import { Utils } from "src/modules/common/misc/Utils";

export class ActionService {
    uriPrefix = process.env.REACT_APP_TASKS_URL + "actions";

    getActionTypes() {
        const url = this.uriPrefix + "/types";
        return appClient.get<ActionType[]>(url);
    }

    async getActionDefinitions(projectId?: string) {
        let url = this.uriPrefix;
        if (projectId) {
            url += `/project/${projectId}`;
        }

        const resp = await appClient.get<ActionDefinition[]>(url);
        return resp.mapErr((err) => new Error(err.data?.title));
    }

    createActionDefinition(formData: FormData) {
        return appClient.post<string>(this.uriPrefix, formData);
    }

    deleteActionDefinition(actionDefinitionId: string) {
        const url = this.uriPrefix + `/${actionDefinitionId}`;
        return appClient.delete(url);
    }

    updateActionDefinition(
        actionDefinitionId: string,
        actionType: string,
        title: string,
        fields: ActionField[] | [],
        constraints: ActionConstraint[] | [],
        permissions?: ActionExecutionPermissions,
    ) {
        const request = {
            actionType,
            title,
            fields,
            constraints,
            permissions: permissions ?? { admin: false, roles: [] },
        };
        const url = this.uriPrefix + `/${actionDefinitionId}`;
        return appClient.patch(url, request);
    }

    importActionDefinition(
        formData: FormData,
        projectId: string,
    ): Promise<ResultApi<string>> {
        const url = `${this.uriPrefix}/project/${projectId}/import`;
        return appClient.post(url, formData);
    }

    async exportActionDefinition(id: string) {
        const mapForm = document.createElement("form");
        mapForm.setAttribute("id", "actionDefExportPostForm");
        mapForm.target = "_blank";
        mapForm.method = "POST";

        await security.invoke((token) => {
            const t = "?access_token=" + encodeURIComponent(token);
            const url = `${this.uriPrefix}/export/${id}${t}`;
            Utils.downloadFile(url, mapForm, "actionDefExportPostForm");
            return Promise.resolve();
        });
    }

    updateActionsListOrder(
        id: string,
        dragIndex: number,
        dropIndex: number,
        lastModified: string | null,
    ) {
        const request = {
            dragIndex,
            dropIndex,
            lastModified,
        };
        const url = `${this.uriPrefix}/position/update/${id}`;
        return appClient.patch(url, request);
    }
}

import * as React from "react";
import { observer } from "mobx-react";
import {
    Modal,
    Input,
    Button,
    Col,
    Row,
    Form,
    Select,
    InputNumber,
    Checkbox,
} from "antd";
import TaskTemplateVisualStore from "../stores/TaskTemplatesVisualStore";
import { Utils } from "../../common/misc/Utils";
import {
    EditOutlined,
    MinusCircleOutlined,
    PlusOutlined,
} from "@ant-design/icons";
import SubtaskTemplateDialog from "./SubtaskTemplateDialog";
import { TaskTypeDueDate } from "../../task_types/types";
import { periodOptions } from "../../task_types/components/TaskTypeCreateDialog";
import { Editor } from "@progress/kendo-react-editor";
import { AdvancedToolbar } from "../../common/components/EditorToolbar";
type Props = {
    store: TaskTemplateVisualStore;
};
const FormItem = Form.Item;

const TaskTemplateEditDialog: React.FC<Props> = ({ store }) => {
    const [form] = Form.useForm();
    const [duePeriod, setDuePeriod] = React.useState<
        TaskTypeDueDate | undefined
    >(undefined);
    const [hasReminder, setHasReminder] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (store.selectedTaskTemplateId) {
            // Telerik editor breaks if value is null
            store.selectedTemplate!.description =
                store.selectedTemplate!.description || "";
            form.setFieldsValue(store.selectedTemplate);
            setHasReminder(store.selectedTemplate?.reminderDays !== undefined);
        } else if (store.selectedTaskTemplateId === null) {
            form.setFieldsValue({ projectId: store.selectedProjectId });
        }

        if (store.currentProjectId !== undefined && store.selectedProjectId) {
            store.loadTaskTypesForProject(store.selectedProjectId).then(() => {
                if (store.selectedTemplate) {
                    store.setSelectedTaskTypeId(
                        store.selectedTemplate.taskType,
                    );
                }
            });
        }
    }, [store, form, store.selectedTaskTemplateId]);

    React.useEffect(() => {
        if (store.selectedTaskTypeId) {
            const ttype = store.taskTypes.find(
                (x) => x.id === store.selectedTaskTypeId,
            );

            if (ttype?.dueDate) {
                setDuePeriod(ttype.dueDate);
            } else {
                setDuePeriod(undefined);
                toggleReminder(false);
            }
        } else {
            setDuePeriod(undefined);
            toggleReminder(false);
        }
    }, [store, form, store.selectedTaskTypeId]);

    const handleSubmit = () => {
        form.validateFields()
            .then((values) => {
                if (store.selectedTaskTemplateId) {
                    store.updateTaskTemplate(values);
                } else {
                    store.createTaskTemplate(values);
                }

                handleClose();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleClose = () => {
        form.resetFields();
        setDuePeriod(undefined);
        setHasReminder(false);
        store.selectTemplate(undefined);
    };

    const toggleReminder = (enable: boolean) => {
        setHasReminder(enable);
        form.setFieldValue("reminderDays", enable ? 3 : null);
    };

    const getSubtaskTemplate = (index: number) =>
        form.getFieldValue(["subTasks", index]);

    const taskTemplateNameValidator = (
        rule: unknown,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        value: any,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        callback: any,
    ) => {
        const taskTemplates = store!.taskTemplates.map((p) =>
            p.name.toLowerCase(),
        );
        if (
            value &&
            taskTemplates.includes(value.toLowerCase()) &&
            store.selectedTemplate?.name !== value
        ) {
            callback("Template with the same name already exists");
        } else {
            callback();
        }
    };

    const taskTemplateCodeValidator = (
        rule: unknown,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        value: any,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        callback: any,
    ) => {
        const taskTemplates = store!.taskTemplates.map((p) =>
            p.templateCode.toLowerCase(),
        );
        if (
            value &&
            taskTemplates.includes(value.toLowerCase()) &&
            store.selectedTemplate?.templateCode !== value
        ) {
            callback("Template with the same code already exists");
        } else {
            callback();
        }
    };

    const reminderSelect = (
        <Checkbox
            checked={hasReminder}
            onChange={(e) => toggleReminder(e.target.checked)}
            disabled={duePeriod === undefined}
        />
    );

    return (
        <Modal
            title={
                store.selectedTaskTemplateId ? "Edit template" : "New template"
            }
            open={store.selectedTaskTemplateId !== undefined}
            className="alpha-modal"
            closable={false}
            width={574}
            centered
            footer={[
                <Button
                    data-id="button-edit-task-template-cancel"
                    key="task-template-cancel"
                    className="light"
                    onClick={handleClose}
                >
                    Cancel
                </Button>,
                <Button
                    data-id="button-edit-task-template-Save"
                    key="task-template-update"
                    type="primary"
                    htmlType="submit"
                    form="task-template-form"
                >
                    {store.selectedTaskTemplateId ? "Update" : "Save"}
                </Button>,
            ]}
        >
            <div data-id="update-task-template-dialog-form">
                <Form
                    form={form}
                    onFinish={handleSubmit}
                    className="alpha-form"
                    id="task-template-form"
                    layout="vertical"
                >
                    <FormItem name="projectId" noStyle>
                        <Input type="hidden" />
                    </FormItem>
                    <Row gutter={8}>
                        <Col span={18}>
                            <FormItem
                                name="name"
                                colon={false}
                                className="dialog-field"
                                label={
                                    <span className="dialog-field-label">
                                        Template name
                                    </span>
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: "Template name is required",
                                    },
                                    { validator: taskTemplateNameValidator },
                                ]}
                            >
                                <Input data-id="input-Name" />
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem
                                name="templateCode"
                                colon={false}
                                className="dialog-field"
                                label={
                                    <span className="dialog-field-label">
                                        Template code
                                    </span>
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: "Template code is required",
                                    },
                                    { validator: taskTemplateCodeValidator },
                                ]}
                            >
                                <Input
                                    data-id="input-template-code"
                                    disabled={!!store.selectedTaskTemplateId}
                                />
                            </FormItem>
                        </Col>
                    </Row>
                    <FormItem
                        name="title"
                        colon={false}
                        className="dialog-field"
                        label={
                            <span className="dialog-field-label">
                                Task title
                            </span>
                        }
                        rules={[
                            {
                                required: true,
                                message: "Task title is required",
                            },
                        ]}
                    >
                        <Input data-id="input-title" />
                    </FormItem>
                    <Row gutter={24} style={{ marginTop: 5, paddingTop: 5 }}>
                        <Col span={12}>
                            <FormItem
                                name="taskType"
                                colon={false}
                                className="dialog-field"
                                label={
                                    <span className="dialog-field-label">
                                        Task type
                                    </span>
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: "Task Type is required",
                                    },
                                ]}
                            >
                                <Select
                                    options={
                                        store.taskTypes?.map((p) => ({
                                            label: p.name,
                                            value: p.id,
                                        })) || []
                                    }
                                    placeholder="Select task type"
                                    onChange={store.setSelectedTaskTypeId}
                                />
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem
                                name="defaultPriority"
                                colon={false}
                                className="dialog-field"
                                label={
                                    <span className="dialog-field-label">
                                        Task priority
                                    </span>
                                }
                            >
                                <Select
                                    options={Utils.getTaskPrioritiesForDropdown()}
                                    placeholder="Select task priority"
                                    onChange={store.setSelectedTaskTypeId}
                                />
                            </FormItem>
                        </Col>
                    </Row>
                    <Row gutter={24} style={{ marginTop: 5, paddingTop: 5 }}>
                        <Col span={12}>
                            <FormItem
                                colon={false}
                                className="dialog-field"
                                label={
                                    <span className="dialog-field-label">
                                        Due period
                                    </span>
                                }
                            >
                                {duePeriod
                                    ? `${duePeriod?.count} ${periodOptions.find((x) => x[0] === duePeriod?.period)}`
                                    : "none"}
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem
                                name="reminderDays"
                                colon={false}
                                className="dialog-field"
                                label={
                                    <span className="dialog-field-label">
                                        Reminder days before due date
                                    </span>
                                }
                            >
                                <InputNumber
                                    min={1}
                                    max={
                                        duePeriod
                                            ? Utils.getDuePeriodDays(
                                                  duePeriod.period,
                                              ) *
                                                  duePeriod.count -
                                              1
                                            : 30
                                    }
                                    disabled={duePeriod === undefined}
                                    addonBefore={reminderSelect}
                                    addonAfter="day(s)"
                                />
                            </FormItem>
                        </Col>
                    </Row>
                    <FormItem
                        name="description"
                        colon={false}
                        className="dialog-field"
                        style={{ marginBottom: 5 }}
                        label={
                            <span className="dialog-field-label">
                                Task description
                            </span>
                        }
                    >
                        <Editor
                            tools={AdvancedToolbar}
                            defaultEditMode="div"
                            onChange={(e) =>
                                form.setFieldValue("description", e.html)
                            }
                        />
                    </FormItem>
                    <FormItem
                        className="dialog-field"
                        label={<span className="dialog-field-label"> </span>}
                        style={{ marginBottom: 0 }}
                    >
                        <Form.List name="subTasks">
                            {(subTasks, { add, remove }) => (
                                <>
                                    {subTasks.map((_, index) => (
                                        <div
                                            key={index}
                                            style={{ display: "flex" }}
                                        >
                                            <Form.Item
                                                name={[index, "title"]}
                                                className="dialog-field"
                                                style={{
                                                    flex: 1,
                                                    marginRight: "8px",
                                                }}
                                            >
                                                <Input disabled />
                                            </Form.Item>
                                            <Form.Item
                                                name={[index, "description"]}
                                                noStyle
                                            >
                                                <Input type="hidden" />
                                            </Form.Item>
                                            <Form.Item>
                                                <EditOutlined
                                                    className="alpha-antd-icon"
                                                    onClick={() =>
                                                        store.setSelectedSubtaskTemplate(
                                                            getSubtaskTemplate(
                                                                index,
                                                            ),
                                                            index,
                                                        )
                                                    }
                                                />
                                            </Form.Item>
                                            <Form.Item>
                                                <MinusCircleOutlined
                                                    className="alpha-antd-icon no-mragin"
                                                    onClick={() =>
                                                        remove(index)
                                                    }
                                                />
                                            </Form.Item>
                                        </div>
                                    ))}
                                    <Button
                                        type="dashed"
                                        size="small"
                                        onClick={() =>
                                            store.setSelectedSubtaskTemplate({
                                                title: "",
                                                description: undefined,
                                            })
                                        }
                                        block
                                        icon={<PlusOutlined />}
                                    >
                                        Add subtask
                                    </Button>
                                    <SubtaskTemplateDialog
                                        store={store}
                                        addCallback={add}
                                        parentForm={form}
                                    />
                                </>
                            )}
                        </Form.List>
                    </FormItem>
                </Form>
            </div>
        </Modal>
    );
};

export default observer(TaskTemplateEditDialog);

import * as React from "react";
import { observer } from "mobx-react-lite";
import { Modal, Button, Select } from "antd";
import { SelectValue } from "antd/lib/select";
import TasksGridVisualStore from "../stores/TasksGridVisualStore";

type Props = {
    store: TasksGridVisualStore;
};

const TasksAssignDialog: React.FC<Props> = ({ store }) => {
    const [user, setUser] = React.useState<string | undefined>(undefined);

    React.useEffect(() => {
        setUser(undefined);
    }, [store.commonUsersInSelectedTasks]);

    const handleSubmit = () => {
        if (user) {
            store.assignTasksToUser(store.selectedRows, user);
        }
    };

    return (
        <Modal
            title="Assign tasks to user"
            destroyOnClose
            open={store.taskAssignDialogVisible}
            onCancel={() => store.setTaskAssignDialogVisible(false)}
            className="alpha-modal"
            footer={[
                <Button
                    key="task-assign-cancel"
                    onClick={() => store.setTaskAssignDialogVisible(false)}
                >
                    Cancel
                </Button>,
                <Button
                    key="task-assign-submit"
                    type="primary"
                    onClick={handleSubmit}
                    disabled={!user || store.submittingTaskBatchAssignment}
                    loading={store.submittingTaskBatchAssignment}
                >
                    Assign
                </Button>,
            ]}
        >
            <Select
                value={user}
                onChange={(val: SelectValue) => {
                    setUser(val ? val.toString() : undefined);
                }}
                style={{ width: "100%" }}
            >
                {store.commonUsersInSelectedTasks &&
                    store.commonUsersInSelectedTasks.map((u) => (
                        <Select.Option key={u.id} value={u.id}>
                            {u.userName}
                        </Select.Option>
                    ))}
            </Select>
        </Modal>
    );
};

export default observer(TasksAssignDialog);

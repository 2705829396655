import * as React from "react";
import { TaskCreateVisualStore } from "../stores";
import { observer } from "mobx-react-lite";
import { Modal, Button, Select, Space, Form } from "antd";
import { TaskCreateForm } from ".";
import TaskTemplateVisualStore from "../../task_templates/stores/TaskTemplatesVisualStore";
import NewTaskParametersDialog from "./NewTaskParametersForm";

type Props = {
    store: TaskCreateVisualStore;
    templateStore: TaskTemplateVisualStore;
};

const TaskCreateDialog: React.FC<Props> = ({ store, templateStore }) => {
    const [form] = Form.useForm();
    const [currentStep, setCurrentStep] = React.useState(0);
    React.useEffect(() => {
        if (!store.projects || !store.projects.length) {
            store.loadProjects();
            return;
        }
        if (store.newTaskDialogVisible) {
            templateStore.loadTaskTemplates(store.currentProjectId);
        }
    }, [store.projects, store.newTaskDialogVisible]);

    const handleOptionClick = (id: string) => {
        templateStore.selectTemplate(id);
        if (templateStore.hasParameters) {
            setCurrentStep(1);
        }
        store.setParamsSavedInUi(false);
    };

    if (!store.projects || !store.projects.length || !store.currentProjectId) {
        return null;
    }

    const handleCancel = () => {
        if (currentStep === 0) {
            store.setCurrentTaskType(null);
            store.setNewTaskDialogVisible(false);
            store.setParamsSavedInUi(false);
            store.clearCurrentMetadata();
        } else {
            if (!store.paramsSavedInUi) {
                templateStore.selectTemplate(null);
                form.resetFields();
            }
            setCurrentStep(0);
        }
    };

    const handleTemplateClear = () => {
        templateStore.selectTemplate(null);
        store.setParamsSavedInUi(false);
        form.resetFields();
    };

    return (
        <Modal
            title={
                currentStep === 0
                    ? [
                          // eslint-disable-next-line react/jsx-key
                          <Space
                              direction="horizontal"
                              style={{
                                  width: "100%",
                                  justifyContent: "space-between",
                              }}
                          >
                              <div>New task</div>
                              <div>
                                  {templateStore.hasParameters && (
                                      <Button
                                          size="small"
                                          style={{
                                              marginRight: 10,
                                              height: 32,
                                          }}
                                          onClick={() => setCurrentStep(1)}
                                      >
                                          Edit parameters
                                      </Button>
                                  )}
                                  <Select
                                      allowClear
                                      style={{ width: 200 }}
                                      optionLabelProp="label"
                                      suffixIcon={
                                          <i
                                              className="alpha-icon xxs arrow-down-icon"
                                              style={{ margin: 0, width: 8 }}
                                          />
                                      }
                                      placeholder="Templates"
                                      onChange={handleOptionClick}
                                      onClear={handleTemplateClear}
                                      loading={
                                          templateStore.taskTemplatesLoading
                                      }
                                      value={
                                          templateStore.selectedTaskTemplateId
                                      }
                                      options={
                                          templateStore.taskTemplates?.map(
                                              (t) => {
                                                  return {
                                                      label: t.name,
                                                      value: t.id,
                                                  };
                                              },
                                          ) || []
                                      }
                                  />
                              </div>
                          </Space>,
                      ]
                    : "Task template parameters"
            }
            maskClosable={false}
            closable={false}
            centered
            className="task-properties-modal create-dialog alpha-modal"
            open={store.newTaskDialogVisible}
            onCancel={handleCancel}
            footer={[
                <Button
                    key="cancel-button"
                    className="light"
                    onClick={handleCancel}
                >
                    Cancel
                </Button>,
                <Button
                    key="submit-button"
                    type="primary"
                    htmlType="submit"
                    form="new-task-modal-form"
                    loading={store.taskCreatingInProgress}
                >
                    {currentStep === 0 ? "Create task" : "Save"}
                </Button>,
            ]}
            width={740}
            destroyOnClose
        >
            {currentStep === 0 ? (
                <TaskCreateForm
                    store={store}
                    templateStore={templateStore}
                    form={form}
                />
            ) : (
                <NewTaskParametersDialog
                    store={store}
                    templateStore={templateStore}
                    form={form}
                    setCurrentStep={setCurrentStep}
                />
            )}
        </Modal>
    );
};
const WrappedTaskCreateDialog = observer(TaskCreateDialog);
export default WrappedTaskCreateDialog;

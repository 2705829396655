import { Button, Col, Collapse, Row, Spin, Upload, UploadProps } from "antd";
import { RcFile } from "antd/lib/upload";
import { observer } from "mobx-react-lite";
import * as React from "react";
import {
    TaskApplicationsMenu,
    TaskAttachment,
} from "../../../tasks/components";
import TaskViewVisualStore from "../../../tasks/stores/TaskViewVisualStore";
import { Utils } from "../../misc/Utils";
import { LoadingOutlined } from "@ant-design/icons";
import DocumentPreviewDialog from "src/modules/documents/components/DocumentPreviewDialog";

type TaskAttachmentsSectionProps = {
    store: TaskViewVisualStore;
    taskId: string;
    isFullView: boolean;
};
const { Dragger } = Upload;

const TaskAttachmentsSection: React.FC<TaskAttachmentsSectionProps> = ({
    store,
    taskId,
    isFullView,
}) => {
    const handleUpload = (file: RcFile, fileList: RcFile[]) => {
        // Workaround to trigger only once if multiple files were uploaded
        if (file.name === fileList[0].name) {
            store.appendFilesToPackageSet(
                store.taskPreview!.packageSetId,
                fileList,
                taskId,
            );
        }
        return false;
    };
    const uploadProps: UploadProps = {
        multiple: true,
        accept: Utils.uploadAcceptExtensions,
        disabled: store.taskFormLocked,
        beforeUpload: handleUpload,
        showUploadList: false,
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getExpandIcon = (panelProps: any) => {
        return (
            <i
                style={
                    !panelProps.isActive
                        ? {
                              transform: "rotate(-90deg)",
                              transition: "transform 0.24s",
                          }
                        : { transition: "transform 0.24s" }
                }
                className="alpha-icon xxxs arrow-down-icon"
            />
        );
    };

    const collapseItems = [
        {
            key: "attachments",
            label: (
                <>
                    <span className="title">Attachments&nbsp;</span>
                    <span className="items-amount">
                        ({store.taskAttachments.length})
                    </span>
                </>
            ),
            extra: (
                <div onClick={(e) => e.stopPropagation()}>
                    <Upload
                        {...uploadProps}
                        style={{
                            display: store.taskFormLocked ? "none" : "block",
                        }}
                    >
                        <Button
                            style={{ position: "relative", zIndex: 1000 }}
                            type="link"
                            size="small"
                            title="Add attachments"
                        >
                            <i
                                style={{ verticalAlign: "middle" }}
                                className="alpha-icon xs plus"
                            />
                        </Button>
                    </Upload>
                </div>
            ),
            children: (
                <div className="dialog-section">
                    {(store.attachmentsLoading ||
                        store.actionEventsLoading) && (
                        <div
                            className="attachments-loading-spin"
                            style={{ textAlign: "center", height: 48 }}
                        >
                            <Spin />
                        </div>
                    )}
                    <Dragger {...uploadProps} openFileDialogOnClick={false}>
                        <Row>
                            {!store.attachmentsLoading &&
                                !store.actionEventsLoading &&
                                store.taskAttachments &&
                                store.taskAttachments.map((a) => (
                                    <Col
                                        key={a.id}
                                        xl={isFullView ? 12 : 24}
                                        md={24}
                                        className={
                                            store.selectedAttachments.find(
                                                (id) => id === a.id,
                                            )
                                                ? "selected"
                                                : ""
                                        }
                                    >
                                        <div
                                            key={a.id}
                                            className={
                                                "attachment-container" +
                                                ` ${a.state === "Ready" || a.state === "Broken" ? "" : "loading"}`
                                            }
                                        >
                                            <TaskAttachment
                                                className={
                                                    store.selectedAttachments.find(
                                                        (id) => id === a.id,
                                                    )
                                                        ? "selected"
                                                        : ""
                                                }
                                                attachmentId={a.id}
                                                fileName={a.fileName}
                                                fileSizeBytes={a.fileSizeBytes}
                                                uploadDate={a.uploadedTime}
                                                onClick={() =>
                                                    store.toggleAttachmentSelection(
                                                        a.id,
                                                    )
                                                }
                                                onPreview={
                                                    store.setPreviewAttachment
                                                }
                                            />
                                            <div className="menu-container">
                                                {a.state === "Ready" ||
                                                a.state === "Broken" ? (
                                                    <TaskApplicationsMenu
                                                        taskId={taskId}
                                                        disabled={
                                                            store.taskFormLocked
                                                        }
                                                        tasksStore={store}
                                                        type="ghost"
                                                        label={
                                                            <i className="alpha-icon xs action-more-dots no-margin" />
                                                        }
                                                        size="small"
                                                        btnStyle={{
                                                            border: "none",
                                                        }}
                                                        pkg={a}
                                                        onDownload={
                                                            store.handleAttachmentDownload
                                                        }
                                                        onPreview={
                                                            store.setPreviewAttachment
                                                        }
                                                        onRemoveAttachements={() =>
                                                            store.removeSelectedAttachments(
                                                                a.id,
                                                            )
                                                        }
                                                        isTaskMenu={false}
                                                    />
                                                ) : (
                                                    <LoadingOutlined
                                                        style={{ fontSize: 16 }}
                                                        spin
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </Col>
                                ))}
                        </Row>
                        {!store.attachmentsLoading &&
                            !store.actionEventsLoading &&
                            (!store.taskAttachments ||
                                !store.taskAttachments.length) && (
                                <div className="empty-message">
                                    There are no attachments yet.
                                </div>
                            )}
                    </Dragger>
                    <DocumentPreviewDialog
                        isVisible={!!store.previewAttachment?.id}
                        packageId={store.previewAttachment?.id}
                        onCloseDialog={() =>
                            store.setPreviewAttachment(undefined)
                        }
                        packageName={store.previewAttachment?.fileName}
                        onDownload={store.handleAttachmentDownload}
                    />
                </div>
            ),
        },
    ];

    return (
        <Collapse
            onChange={(keys: string[]) =>
                store.setSectionActiveKey("attachments", !!keys[0])
            }
            activeKey={
                store.activeSectionKeys.includes("attachments")
                    ? "attachments"
                    : undefined
            }
            expandIcon={getExpandIcon}
            bordered={false}
            className="alpha-task-section-collapse"
            items={collapseItems}
        />
    );
};

export default observer(TaskAttachmentsSection);

import * as React from "react";
import { AppPermissions } from "../Permissions";
import { ReactElement } from "react";
import { AuthConsumer } from "../AuthContext";
import { useStore } from "src/modules/common/stores";
import { Observer, observer } from "mobx-react-lite";
import { ProjectRole } from "src/modules/administration/types/UserModel";
import { ALL_PROJECTS } from "src/modules/tasks/screens/TasksPage";

export const hasPermission = (
    permissions: string[] = [],
    action: AppPermissions,
    projectRoles: ProjectRole[] = [],
    projectId: string = "",
) => {
    if (
        permissions.includes(action) ||
        projectRoles.some(
            (p) =>
                (p.projectId === projectId && p.isAdmin) ||
                (projectId === ALL_PROJECTS && p.isAdmin),
        )
    ) {
        return true;
    }
    return false;
};

export const hasObjectPermission = (
    objectPermission: { [id: string]: string[] },
    id: string | undefined,
    action: AppPermissions,
) => {
    if (!objectPermission || !id || !objectPermission[id]) {
        return false;
    }
    return objectPermission[id]?.includes(action) ?? false;
};

type Props = {
    permissionClaim: AppPermissions;
    yes?: () => ReactElement;
    no?: () => ReactElement;
    entityId?: string;
    projectIds?: string[];
};

export const HasPermission: React.FC<Props> = observer((props) => {
    const { projectsStore } = useStore();
    return (
        <AuthConsumer>
            {({ permissions, objectPermissions }) => (
                <Observer>
                    {() => (
                        <>
                            {hasPermission(
                                permissions,
                                props.permissionClaim,
                            ) ||
                            (props.permissionClaim ===
                                AppPermissions.CanAccessAdministration &&
                                projectsStore.projects.length) ||
                            hasObjectPermission(
                                objectPermissions,
                                props.entityId,
                                props.permissionClaim,
                            ) ||
                            process.env.NODE_ENV === "test" ? (
                                (props.yes && props.yes()) || props.children
                            ) : props.no ? (
                                props.no()
                            ) : (
                                <></>
                            )}
                        </>
                    )}
                </Observer>
            )}
        </AuthConsumer>
    );
});

type PermissionForProjectProps = {
    yes?: () => ReactElement;
    no?: () => ReactElement;
    entityId?: string;
    projectIds: string[];
};
export const HasAdminPermissionForProjects: React.FC<PermissionForProjectProps> =
    observer((props) => {
        const { projectsStore } = useStore();
        const hasPermissionForProjects = props.projectIds.every((p) =>
            projectsStore.administrableProjects.map((a) => a.id).includes(p),
        );
        return (
            <AuthConsumer>
                {() => (
                    <Observer>
                        {() => (
                            <>
                                {hasPermissionForProjects ||
                                process.env.NODE_ENV === "test" ? (
                                    (props.yes && props.yes()) || props.children
                                ) : props.no ? (
                                    props.no()
                                ) : (
                                    <></>
                                )}
                            </>
                        )}
                    </Observer>
                )}
            </AuthConsumer>
        );
    });

import { observer } from "mobx-react-lite";
import React from "react";
import { useStore } from "../../common/stores";
import ActionDefinitionsList from "../components/ActionDefinitionsList";
import { useAdminStore } from "../stores/context";

const ActionDefinitionPage: React.FC = () => {
    const context = useAdminStore();
    const rootContext = useStore();

    return (
        <ActionDefinitionsList
            store={context.actionDefinitionStore}
            projectsStore={rootContext.projectsStore}
        />
    );
};

export default observer(ActionDefinitionPage);

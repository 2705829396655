import { Button, Input, Table, Tag, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { safeStringLocaleCompare, Utils } from "../../common/misc/Utils";
import { AppRoles } from "../../authorization/Permissions";
import UsersManagerStore from "../stores/UsersManagerStore";
import { ProjectRole, RoleModel, UserModel } from "../types/UserModel";
import { UsersColumnsFilters } from "./UsersColumnsFilters";
import EditProjectAssignmensDialog from "./EditProjectAssignmensDialog";

type Props = {
    store: UsersManagerStore;
};

const UsersList: React.FC<Props> = ({ store }) => {
    React.useEffect(() => {
        store.init();
        store.getProjects();
        return () => {
            store.setUserSearchString("");
        };
    }, [store]);

    const calcRoleWeights = (roles: RoleModel[]) => {
        const userRoleWeight = 2;
        const adminRoleWeight = 8;

        let cumulativeWeight = 0;

        const roleNames = roles.map((r) => r.name);
        if (roleNames.includes(AppRoles.admin)) {
            cumulativeWeight += adminRoleWeight;
        }

        if (roleNames.includes(AppRoles.user)) {
            cumulativeWeight += userRoleWeight;
        }

        return cumulativeWeight;
    };

    const sortByRoles = (a: RoleModel[], b: RoleModel[]) => {
        const rolesWeight1 = calcRoleWeights(a);
        const rolesWeight2 = calcRoleWeights(b);

        return rolesWeight1 > rolesWeight2
            ? 1
            : rolesWeight1 < rolesWeight2
              ? -1
              : 0;
    };

    const editUser = (user: UserModel) => {
        store!.handleNodeSelection(user.id);
        store.setIsEditUserDialogVisible(true);
    };

    const getHeaderClassname = (name: string) => {
        return store.storedFilters[name] ? "isFiltered" : "";
    };

    const handleProjectsClick = (userId: string) => {
        store.setEditableProjectsForUserId(userId);
        store.setIsProjectRolesDialogVisible(true);
    };

    const columns: ColumnsType<UserModel> = [
        {
            key: "firstName",
            dataIndex: "firstName",
            title: "First name",
            defaultSortOrder: "ascend",
            sorter: (a: UserModel, b: UserModel) =>
                safeStringLocaleCompare(b.firstName, a.firstName),
            render: (firstName: string) => firstName,
            onCell: (record: UserModel) =>
                ({
                    "data-id-value": record.firstName,
                    "data-id-type": "user-list",
                    "data-id-cells": "First name",
                }) as object,
        },
        {
            key: "lastName",
            dataIndex: "lastName",
            title: "Last name",
            sorter: (a: UserModel, b: UserModel) =>
                safeStringLocaleCompare(b.lastName, a.lastName),
            render: (lastName: string) => lastName,
            onCell: (record: UserModel) =>
                ({
                    "data-id-value": record.lastName,
                    "data-id-type": "user-list",
                    "data-id-cells": "Last name",
                }) as object,
        },
        {
            key: "userName",
            dataIndex: "userName",
            title: "Username",
            sorter: (a: UserModel, b: UserModel) =>
                safeStringLocaleCompare(b.userName, a.userName),
            render: (userName: string) => userName,
            onCell: (record: UserModel) =>
                ({
                    "data-id-value": record.userName,
                    "data-id-type": "user-list",
                    "data-id-cells": "Username",
                }) as object,
        },
        {
            key: "email",
            dataIndex: "email",
            title: "Email",
            sorter: (a: UserModel, b: UserModel) =>
                safeStringLocaleCompare(b.email, a.email),
            render: (email: string) => email,
            onCell: (record: UserModel) =>
                ({
                    "data-id-value": record.email,
                    "data-id-type": "user-list",
                    "data-id-cells": "Email",
                }) as object,
        },
        {
            key: "projects",
            dataIndex: "projects",
            title: "Projects",
            ...UsersColumnsFilters("projects"),
            className: getHeaderClassname("projects"),
            render: (projects: ProjectRole[]) => {
                const projectNames =
                    projects?.map((p) =>
                        store.getProjectNameById(p.projectId),
                    ) ?? [];
                const label = projectNames.join(", ") || "Not assigned";
                return (
                    <Tooltip title={label}>
                        <div>{label}</div>
                    </Tooltip>
                );
            },
            onCell: () =>
                ({
                    "data-id-type": "user-list",
                    "data-id-cells": "Group",
                    style: { maxWidth: 250 },
                }) as object,
        },
        {
            key: "roles",
            dataIndex: "roles",
            title: "Roles",
            ...UsersColumnsFilters("roles"),
            className: getHeaderClassname("roles"),
            sorter: (a: UserModel, b: UserModel) =>
                sortByRoles(b.roles, a.roles),
            render: (roles: RoleModel[]) => (
                <div>
                    {roles &&
                        roles.map((r) => (
                            <Tag
                                className="alpha-tag"
                                color={
                                    r.name === AppRoles.admin
                                        ? "#273C75"
                                        : "#9BA0AA"
                                }
                                key={r.id}
                            >
                                {Utils.getAppRoleDisplayName(r.name)}
                            </Tag>
                        ))}
                </div>
            ),
            onCell: (record: UserModel) =>
                ({
                    "data-id-value": record.roles.map((r) => r.name).join(","),
                    "data-id-type": "user-list",
                    "data-id-cells": "Roles",
                }) as object,
        },
        {
            key: "actions",
            title: null,
            render: (val: unknown, record: UserModel) => (
                <div className="row-actions-wrapper">
                    <Tooltip title="Edit user">
                        <Button
                            type="link"
                            onClick={() => editUser(record)}
                            size="small"
                            data-id-cells="Edit user"
                            data-id-name={record.userName}
                        >
                            <i className="alpha-icon md table-action-edit" />
                        </Button>
                    </Tooltip>
                    {!record.roles.map((r) => r.name).includes("admin") ? (
                        <Tooltip title="Edit project assignments">
                            <Button
                                type="link"
                                onClick={() => handleProjectsClick(record.id)}
                                size="small"
                                data-id-cells="Edit project assignments"
                                data-id-name={record.userName}
                            >
                                <i className="alpha-icon md project-assignments" />
                            </Button>
                        </Tooltip>
                    ) : null}
                </div>
            ),
        },
    ];

    const handleEnterPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        store.setUserSearchString(event.currentTarget.value);
    };

    return (
        <>
            <EditProjectAssignmensDialog store={store} />
            <div className="table-search-wrapper">
                <Input
                    data-id="input-search"
                    style={{ width: 200, marginBottom: 20 }}
                    allowClear
                    placeholder="Search..."
                    onPressEnter={handleEnterPress}
                    prefix={
                        <i
                            className="alpha-icon xs table-search"
                            style={{ marginRight: 10 }}
                        />
                    }
                    onClear={() => store.setUserSearchString("")}
                />
            </div>
            <Table
                rowKey={(r) => r.id}
                tableLayout="fixed"
                className="alpha-table users"
                columns={columns}
                dataSource={store.filteredUsers}
                pagination={{
                    showSizeChanger: false,
                    pageSize: 16,
                    hideOnSinglePage: true,
                }}
                loading={store.tableIsLoading}
                scroll={{ y: "calc(100vh - 300px)" }}
            />
        </>
    );
};

export default observer(UsersList);

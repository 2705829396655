import { KeycloakConfig } from "keycloak-js";
import Keycloak from "keycloak-js";

class SecurityService {
    inst: Keycloak;
    cachedUserInfo: Promise<object>;

    constructor() {
        const keyConfig: KeycloakConfig = {
            url: process.env.REACT_APP_AUTH_URL,
            realm: process.env.REACT_APP_KEYCLOAK_REALM || "master",
            clientId: process.env.REACT_APP_KEYCLOAK_CLIENTID || "alpha-portal",
        };

        console.log(keyConfig);
        this.inst = new Keycloak(keyConfig);
    }

    get token() {
        return this.inst.token!;
    }

    init() {
        // const prod = process.env.NODE_ENV === 'production';
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const cfg: any = { onLoad: "login-required", checkLoginIframe: false };

        // if (prod && isElectron()) {
        //     cfg = { onLoad: 'login-required', redirectUri: 'http://alpha.client/index.html', checkLoginIframe: false };
        // }

        return new Promise((res, rej) => {
            const promise = this.inst.init(cfg);
            promise
                .then((auth) => {
                    res(auth);
                })
                .catch((err) => rej(err));
        });
    }

    loadUserInfo() {
        if (!this.cachedUserInfo) {
            this.cachedUserInfo = this.inst.loadUserInfo();
        }

        return this.cachedUserInfo;
    }

    getOrRefreshToken(): Promise<{ token: string; refreshed: boolean }> {
        if (!this.inst.isTokenExpired) {
            return Promise.resolve({
                token: this.inst.token!,
                refreshed: false,
            });
        }

        return new Promise<{ token: string; refreshed: boolean }>(
            (res, rej) => {
                this.inst
                    .updateToken(30)
                    .then((refreshed) => {
                        if (refreshed) {
                            console.log("Token was updated");
                        }

                        res({ token: this.inst.token!, refreshed: true });
                    })
                    .catch((err) => {
                        console.error("Error during token refresh");
                        rej(err);
                    });
            },
        );
    }

    invoke<T>(func: (token: string) => Promise<T>): Promise<T> {
        return new Promise<T>((res, rej) => {
            if (!this.inst.isTokenExpired(30)) {
                func(this.inst.token!)
                    .then((s) => res(s))
                    .catch((err) => rej(err));
                return;
            }

            console.log("Token is expired");
            this.inst
                .updateToken(30)
                .then((refreshed) => {
                    if (refreshed) {
                        console.log("Token was updated");
                    }

                    func(this.inst.token!)
                        .then((s) => res(s))
                        .catch((err) => rej(err));
                })
                .catch((err) => {
                    console.error("Error during token refresh");
                    rej(err);
                });
        });
    }
}

const service = new SecurityService();

export default service;

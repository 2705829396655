import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import ActionDefinitionStore from "../stores/ActionDefinitionStore";
import {
    DatePicker,
    Form,
    FormInstance,
    Input,
    Radio,
    Select,
    Space,
} from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";
import { ConstraintFieldType, ConstraintOperator } from "../types/Actions";
import { PackageBase, TaskBase } from "../../tasks/types";
import { Utils } from "../../common/misc/Utils";
import {
    CURRENT_USER,
    EXCLUDED_TASK_FILTER_FIELDS,
} from "./CreateWidgetDialog";
import { useTasksStore } from "../../tasks/stores/context";

type Props = {
    store: ActionDefinitionStore;
    index: number;
    form: FormInstance;
    remove: (index: number | number[]) => void;
    isAttachment: boolean;
};
const { RangePicker } = DatePicker;

const ConstraintEditor: React.FC<Props> = ({
    store,
    index,
    form,
    remove,
    isAttachment,
}) => {
    const [fieldType, setFieldType] = useState<ConstraintFieldType | undefined>(
        store.selectedDefinition?.constraints[index]?.fieldType,
    );
    const [fieldName, setFieldName] = useState<string | undefined>(
        store.selectedDefinition?.constraints[index]?.name,
    );
    const [operator, setOperator] = useState<ConstraintOperator | undefined>(
        store.selectedDefinition?.constraints[index]?.operator,
    );
    const tasksRootStore = useTasksStore().tasksRootStore;
    const fieldTypes = [
        { value: "TaskProperty", label: "Task property", pkg: false },
        { value: "Metadata", label: "Metadata field", pkg: false },
        { value: "AttachmentProperty", label: "Attachment field", pkg: true },
    ];

    const handleFieldTypeChange = (x: ConstraintFieldType) => {
        setFieldType(x);
        setFieldName(undefined);
        form.setFieldValue(["constraints", index, "name"], undefined);
    };

    const handleOperatorChange = (x: ConstraintOperator) => {
        setOperator(x);
        if (x === "IsNull" || x === "NotNull") {
            form.setFieldValue(["constraints", index, "value"], []);
        }
    };

    const taskProperties = Object.keys(new TaskBase())
        .filter((f) => !EXCLUDED_TASK_FILTER_FIELDS.includes(f))
        .map((key) => Utils.capitalizeWord(key))
        .map((key) => ({ label: key, value: key }))
        .sort((a, b) => a.label.localeCompare(b.label));

    const metadataFields = store.metadata.map((m) => ({
        label: m.title,
        value: m.name,
    }));

    const attachmentProperties = Object.keys(new PackageBase())
        .map((key) => Utils.capitalizeWord(key))
        .map((key) => ({ label: key, value: key }))
        .sort((a, b) => a.label.localeCompare(b.label));

    const fieldItems: {
        [k in ConstraintFieldType]: { label: string; value: string }[];
    } = {
        TaskProperty: taskProperties,
        Metadata: metadataFields,
        AttachmentProperty: attachmentProperties,
    };

    const operators = [
        { value: "Equal", label: "=" },
        { value: "NotEqual", label: "\u2260" },
        { value: "IsNull", label: "is null" },
        { value: "NotNull", label: "not null" },
        { value: "AnyOf", label: "any of" },
        { value: "NoneOf", label: "none of" },
        { value: "GreaterThan", label: ">" },
        { value: "LessThan", label: "<" },
    ];

    const getValueInputControl = () => {
        const isMultiValue = operator === "AnyOf" || operator === "NoneOf";

        if (operator === "IsNull" || operator === "NotNull") {
            return <Input hidden />;
        }

        const mode = isMultiValue ? "multiple" : undefined;
        switch (fieldName) {
            case "DueDate":
            case "CreateDate":
            case "UpdateDate":
                return <RangePicker format={Utils.getDateFormat()} />;
            case "CreatedBy":
            case "UpdatedBy":
            case "AssignedTo":
            case "Followers":
                return (
                    <Select
                        mode={mode}
                        options={[
                            { label: CURRENT_USER, value: CURRENT_USER },
                            ...Object.entries(
                                tasksRootStore.allUsersFullNameResolver,
                            ).map((u) => ({ label: u[1], value: u[0] })),
                        ]}
                    />
                );
            case "TaskType":
                return (
                    <Select
                        mode={mode}
                        options={tasksRootStore.taskTypes
                            .filter(
                                (t) => t.projectId === store.selectedProjectId,
                            )
                            .map((x) => ({ value: x.id, label: x.name }))}
                    />
                );
            case "Status":
                return (
                    <Select
                        mode={mode}
                        options={tasksRootStore
                            .getTaskSatusesByProjectId(store.selectedProjectId)
                            .map((t) => ({
                                value: t.id,
                                label: `${t.name} (${tasksRootStore.taskTypes.find((type) => type.id === t.typeId)!.name})`,
                            }))}
                    />
                );
            case "Priority":
                return (
                    <Select
                        mode={mode}
                        options={[
                            { value: "LOW", label: "Low" },
                            { value: "MEDIUM", label: "Medium" },
                            { value: "HIGH", label: "High" },
                        ]}
                    />
                );
            case "IsViewed":
            case "IsManuallyCreated ":
            case "IsDone":
                return (
                    <Select
                        options={[
                            { value: "true", label: "true" },
                            { value: "false", label: "false" },
                        ]}
                    />
                );
            default:
                return isMultiValue ? (
                    <Select placeholder="Select values" mode="tags" />
                ) : (
                    <Input placeholder="Enter value" />
                );
        }
    };

    return (
        <Space style={{ display: "flex" }} className="at-space-stretch-last-1">
            <Form.Item name={[index, "kind"]}>
                <Radio.Group buttonStyle="solid">
                    <Radio.Button value="Requirement">Requirement</Radio.Button>
                    <Radio.Button value="Restriction">Restriction</Radio.Button>
                </Radio.Group>
            </Form.Item>
            <Form.Item
                name={[index, "fieldType"]}
                rules={[{ required: true, message: "Type is required" }]}
            >
                <Select
                    options={fieldTypes.filter(
                        (t) => !t.pkg || t.pkg === isAttachment,
                    )}
                    placeholder="Field type"
                    style={{ width: 145 }}
                    onChange={handleFieldTypeChange}
                />
            </Form.Item>
            <Form.Item
                name={[index, "name"]}
                rules={[{ required: true, message: "Name is required" }]}
            >
                <Select
                    options={fieldType ? fieldItems[fieldType] : []}
                    placeholder="Field name"
                    style={{ width: 160 }}
                    onChange={setFieldName}
                />
            </Form.Item>
            <Form.Item
                name={[index, "operator"]}
                rules={[{ required: true, message: "Operator is required" }]}
            >
                <Select
                    options={operators}
                    placeholder="Operator"
                    style={{ width: 100 }}
                    onChange={handleOperatorChange}
                />
            </Form.Item>
            <Form.Item name={[index, "value"]} style={{ flex: 1 }}>
                {getValueInputControl()}
            </Form.Item>
            <Form.Item>
                <MinusCircleOutlined
                    className="alpha-antd-icon no-margin"
                    onClick={() => remove(index)}
                />
            </Form.Item>
        </Space>
    );
};

export default observer(ConstraintEditor);

import * as React from "react";
import { TaskViewVisualStore } from "../stores";
import { observer } from "mobx-react-lite";
import { Spin } from "antd";

type Props = {
    attachmentId: string | undefined;
    store: TaskViewVisualStore;
};

const TaskTextAttachmentPreview: React.FC<Props> = ({
    attachmentId,
    store,
}) => {
    const [content, setContent] = React.useState<string>("");

    React.useEffect(() => {
        let hasCancelled = false;
        if (attachmentId != null) {
            store
                .getAttachmentText(attachmentId)
                .then((r) => {
                    if (!hasCancelled && r) {
                        let text = r;
                        if (r != null && typeof r === "object") {
                            text = JSON.stringify(r);
                        }
                        setContent(text);
                    }
                })
                .catch((err) => {
                    if (!hasCancelled) {
                        console.log(err);
                    }
                });
        }

        return () => {
            hasCancelled = true;
        };
    }, [attachmentId, store]);

    if (!attachmentId) {
        return null;
    }

    return (
        <div style={{ whiteSpace: "pre-line" }}>
            {!content || !content.length ? <Spin /> : content}
        </div>
    );
};

export default observer(TaskTextAttachmentPreview);

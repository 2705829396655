import {
    TaskModel,
    PackageSetPackagesResult,
    PackagePreviewModel,
    TaskCommentsResult,
    TaskActivity,
    TaskActivitiesResult,
    TaskPrefix,
    TaskPrefixForProjectResult,
    GetUserProfileByIdResult,
    ColumnModelBase,
    QueryRequestData,
    TasksData,
    TaskLink,
    TaskLookupItem,
    ActionEvent,
} from "../types";
import { gql } from "apollo-boost";
import security from "../../common/services/SecurityService";
import {
    ColumnsFilter,
    GetTaskListLayoutForUserResult,
    TaskListLayoutResult,
    UserProfile,
} from "../../common/services/types";
import appClient, { ResultApi } from "../../common/services/AppClient";
import { UserModel } from "../../common/types/UserModel";
import { execQuery } from "../../common/services/DataService";
import {
    ActionDefinition,
    ClientActionData,
} from "../../administration/types/Actions";
import { TaskStatus } from "../../task_statuses/types";

export default class TaskService {
    async getTasks(
        project: string | null,
        page: number,
        pageSize: number,
        searchTerm: string | null = null,
        isMainTasksViewEnabled: boolean,
        isDoneTasksViewEnabled: boolean,
        excludeFilters: boolean,
        sortIndex: 1 | -1 = -1,
        sortField: string = "createDate",
    ) {
        const url = process.env.REACT_APP_TASKS_URL + "tasks";
        const request = {
            project,
            page,
            pageSize,
            searchTerm,
            sortIndex,
            sortField,
            mainTasksOnly: isMainTasksViewEnabled,
            includeDoneTasks: isDoneTasksViewEnabled,
            excludeFilters,
        };
        return appClient.post<TasksData>(url, request);
    }

    async getTaskById(
        taskId: string | null,
        alphaId: string | null,
    ): Promise<ResultApi<TaskModel>> {
        const queryParams = taskId
            ? `?taskId=${taskId}`
            : `?alphaId=${alphaId}`;
        const url = `${process.env.REACT_APP_TASKS_URL}tasks${queryParams}`;
        return appClient.get<TaskModel>(url);
    }

    async getTasksByGroupedWidgetId(
        widgetId: string,
        projectId: string,
        page: number,
        mainTasksOnly: boolean,
        includeDoneTasks: boolean,
        sortField: string,
        sortIndex: number,
    ) {
        const url = `${process.env.REACT_APP_TASKS_URL}tasks/widget/${widgetId}?page=${page}&projectId=${projectId}&mainTasksOnly=${mainTasksOnly}&includeDoneTasks=${includeDoneTasks}&sortField=${encodeURIComponent(sortField)}&sortIndex=${sortIndex}`;
        return appClient.get<TasksData>(url);
    }

    async getTasksByPieChartSection(
        widgetId: string,
        projectId: string,
        value: string,
        page: number,
        mainTasksOnly: boolean,
        includeDoneTasks: boolean,
        sortField: string,
        sortIndex: number,
    ) {
        const url = `${process.env.REACT_APP_TASKS_URL}tasks/widget/${widgetId}/value/${encodeURIComponent(value)}?page=${page}&projectId=${projectId}&mainTasksOnly=${mainTasksOnly}&includeDoneTasks=${includeDoneTasks}&sortField=${encodeURIComponent(sortField)}&sortIndex=${sortIndex}`;
        return appClient.get<TasksData>(url);
    }

    async getTasksByActivityType(
        activityType: string,
        projectId: string,
        period: string,
        page: number,
        mainTasksOnly: boolean,
        includeDoneTasks: boolean,
        sortField: string,
        sortIndex: number,
    ) {
        const url = `${process.env.REACT_APP_TASKS_URL}tasks/activity/${activityType}/period/${period}?page=${page}&projectId=${projectId}&mainTasksOnly=${mainTasksOnly}&includeDoneTasks=${includeDoneTasks}&sortField=${encodeURIComponent(sortField)}&sortIndex=${sortIndex}`;
        return appClient.get<TasksData>(url);
    }

    async getTaskPrefixForProject(
        projectId: string,
    ): Promise<TaskPrefix | null> {
        const result = await execQuery<TaskPrefixForProjectResult>({
            query: gql`
                query getTaskPrefixByProjectId($projectId: String!) {
                    getTaskPrefixByProjectId(projectId: $projectId) {
                        id
                        name
                        projectId
                        counter
                    }
                }
            `,
            variables: {
                projectId,
            },
            fetchPolicy: "network-only",
        });

        if (result.errors) {
            return null;
        }

        return result.data.getTaskPrefixByProjectId;
    }

    getUsersInProject(projectId: string) {
        const url =
            process.env.REACT_APP_TASKS_URL +
            `users/project/${projectId}?includeRoles=false`;
        return appClient.get<UserProfile[]>(url);
    }

    getCommonUsersInProjects(projectIds: string[]) {
        const request = {
            projectIds,
        };
        const url =
            process.env.REACT_APP_TASKS_URL +
            "users/project/users?includeRoles=false";
        return appClient.post<UserModel[]>(url, request);
    }

    getAllUsers() {
        const url = process.env.REACT_APP_TASKS_URL + "userprofile";
        return appClient.get<UserProfile[]>(url);
    }

    async getPackageSet(packageSetId: string): Promise<PackagePreviewModel[]> {
        const result = await execQuery<PackageSetPackagesResult>({
            query: gql`
                query getTasksData($packageSetId: String) {
                    getPackagesFromSet(packageSetId: $packageSetId) {
                        id
                        fileName
                        fileSizeBytes
                        contentType
                        indexDate
                        uploadedTime
                        state
                        filePath
                        extension
                        alphaPackageId
                        sessionId
                    }
                }
            `,
            variables: {
                packageSetId,
            },
            fetchPolicy: "network-only",
        });

        if (result.errors) {
            return [];
        }

        return result.data.getPackagesFromSet;
    }

    updateFields(fields: { [field: string]: unknown }, taskId: string) {
        const request = {
            fields,
        };
        const url = `${process.env.REACT_APP_TASKS_URL}tasks/${taskId}/fields`;
        return appClient.post(url, request);
    }

    updateFieldsBulk(fields: { [field: string]: unknown }, taskIds: string[]) {
        const request = {
            fields,
            taskIds,
        };
        const url = `${process.env.REACT_APP_TASKS_URL}tasks/fields`;
        return appClient.post(url, request);
    }

    async analyzeAttachments(taskId: string, packageId: string) {
        const request = {
            taskId,
            packageId,
        };
        const url = process.env.REACT_APP_TASKS_URL + "tasks/analyze";
        return appClient.post(url, request);
    }

    async getTaskComments(taskId?: string, createDateAfter?: Date) {
        const result = await execQuery<TaskCommentsResult>({
            query: gql`
                query getTaskComments($taskId: String, $createDateAfter: Date) {
                    getTaskComments(
                        taskId: $taskId
                        createDateAfter: $createDateAfter
                    ) {
                        id
                        taskId
                        body
                        createdDate
                        updatedDate
                        userId
                        userName
                    }
                }
            `,

            variables: {
                taskId,
                createDateAfter,
            },
            fetchPolicy: "network-only",
        });

        if (result.errors) {
            return [];
        }

        return result.data.getTaskComments || [];
    }

    createNewTask(formData: FormData) {
        const url = process.env.REACT_APP_TASKS_URL + "tasks/new";
        return appClient.post<string>(url, formData);
    }

    appendAttachments(formData: FormData) {
        const url = process.env.REACT_APP_TASKS_URL + "packagesets/attachment";
        return appClient.post(url, formData);
    }

    removeAttachment(taskId: string, packageId: string) {
        const url =
            process.env.REACT_APP_TASKS_URL +
            `packagesets/attachment/${taskId}/${packageId}`;
        return appClient.update(url);
    }

    addComment(taskId: string, body: string) {
        const request = {
            taskId,
            body,
        };
        const url = process.env.REACT_APP_TASKS_URL + "taskcomments/new";
        return appClient.post(url, request);
    }

    updateComment(commentId: string, body: string) {
        const request = {
            commentId,
            body,
        };
        const url = process.env.REACT_APP_TASKS_URL + "taskcomments";
        return appClient.update(url, request);
    }

    deleteComment(commentId: string) {
        const url =
            process.env.REACT_APP_TASKS_URL +
            `taskcomments/delete/${commentId}`;
        return appClient.post(url);
    }

    async getTaskStatuses(typeId?: string) {
        let url = `${process.env.REACT_APP_TASKS_URL}taskstatuses`;
        if (typeId) {
            url += `/type/${typeId}`;
        }
        const resp = await appClient.get<TaskStatus[]>(url);
        return resp.mapErr((err) => new Error(err.data?.title));
    }

    handleDownload(ids: string[]) {
        return security.invoke((token) => {
            for (const id of ids) {
                const path = encodeURIComponent(id);
                const t = "?access_token=" + encodeURIComponent(token);
                let url = "";
                url = `${process.env.REACT_APP_TASKS_URL}document/${path}${t}`;

                const downloadWindow = window.open(url);

                if (!downloadWindow) {
                    alert(
                        "You must allow pop-ups for download to work properly.",
                    );
                }
            }
            return Promise.resolve();
        });
    }

    async getTaskActivities(taskId: string): Promise<TaskActivity[]> {
        const result = await execQuery<TaskActivitiesResult>({
            query: gql`
                query getTaskActivities($taskId: String!) {
                    getTaskActivities(taskId: $taskId) {
                        id
                        taskId
                        activityType
                        doneBy
                        message
                        createdTime
                    }
                }
            `,
            variables: {
                taskId,
            },
            fetchPolicy: "network-only",
        });

        if (result.errors) {
            return [];
        }

        return result.data.getTaskActivities;
    }

    async getUserProfileById(userId: string): Promise<UserProfile> {
        const result = await execQuery<GetUserProfileByIdResult>({
            query: gql`
                query getUserProfileById($userId: String!) {
                    getUserProfileById(userId: $userId) {
                        userName
                        name
                        timezone
                        avatarIconFileId
                    }
                }
            `,
            variables: {
                userId,
            },
            fetchPolicy: "network-only",
        });
        if (result.errors) {
            console.log(result.errors);
            throw result.errors[0];
        }

        return result.data.getUserProfileById;
    }

    getUserProfilePicture(fileId: string) {
        const url = `${process.env.REACT_APP_TASKS_URL}userProfile/icon/${fileId}`;
        return appClient.get<Blob>(url, "blob");
    }

    toggleTaskFollowing(taskId: string) {
        const url =
            process.env.REACT_APP_TASKS_URL + `tasks/togglefollow/${taskId}`;
        return appClient.update<string[]>(url);
    }

    readTextTaskAttachment(attachmentId: string) {
        const url =
            process.env.REACT_APP_TASKS_URL + `tasks/readtext/${attachmentId}`;
        return appClient.get<string>(url, "text");
    }

    assignTasksToUser(taskIds: string[], userId: string) {
        const request = {
            taskIds,
            userId,
        };
        const url = process.env.REACT_APP_TASKS_URL + "tasks/assignmultiple";
        return appClient.post(url, request);
    }

    emailTaskToUsers(taskId: string, emailAddresses: string[]) {
        const request = {
            taskId,
            emailAddresses,
        };
        const url =
            process.env.REACT_APP_TASKS_URL +
            "notifications/send-task-to-users";
        return appClient.post<string[]>(url, request);
    }

    saveLayoutConfig(
        projectId: string,
        isMetaView: boolean,
        columns: ColumnModelBase[],
    ) {
        const request = {
            columns,
            projectId,
            isMetaView,
        };
        const url = process.env.REACT_APP_TASKS_URL + "tasks/layout";
        return appClient.post<string>(url, request);
    }

    pinTask(taskId: string) {
        const url = `${process.env.REACT_APP_TASKS_URL}tasks/pin/task/${taskId}`;
        return appClient.post<string>(url);
    }

    unpinTask(taskId: string) {
        const url = `${process.env.REACT_APP_TASKS_URL}tasks/unpin/task/${taskId}`;
        return appClient.post<string>(url);
    }

    deleteLayoutConfig() {
        const url = process.env.REACT_APP_TASKS_URL + "tasks/layout/reset";
        return appClient.post(url);
    }

    async getTaskListLayoutForUser(): Promise<TaskListLayoutResult> {
        try {
            const result = await execQuery<GetTaskListLayoutForUserResult>({
                query: gql`
                    query getTaskListLayoutByUser {
                        getTaskListLayoutByUser {
                            id
                            userId
                            columnsConfig
                            selectedProjectId
                            pinnedTasks
                        }
                    }
                `,
                fetchPolicy: "network-only",
            });

            if (result.errors) {
                console.error(result.errors);
                throw result.errors[0];
            }

            return result.data.getTaskListLayoutByUser;
        } catch (err) {
            throw new Error(err);
        }
    }

    async saveSubTask(name: string, taskId: string) {
        const request = {
            name,
        };
        const url = `${process.env.REACT_APP_TASKS_URL}task/${taskId}/subtasks`;
        return await appClient.post<string>(url, request);
    }

    queryMessage(taskId: string, requestData: QueryRequestData) {
        const url = `${process.env.REACT_APP_TASKS_URL}emessage/query/task-id/${taskId}`;
        return appClient.post(url, requestData);
    }

    acceptMessage(taskId: string) {
        const url = `${process.env.REACT_APP_TASKS_URL}emessage/accept/task-id/${taskId}`;
        return appClient.post(url);
    }

    rejectMessage(taskId: string) {
        const url = `${process.env.REACT_APP_TASKS_URL}emessage/reject/task-id/${taskId}`;
        return appClient.post(url);
    }

    getAvailableActions(taskId: string) {
        const url = `${process.env.REACT_APP_TASKS_URL}tasks/${taskId}/actions`;
        return appClient.get<ActionDefinition[]>(url);
    }

    executeAction(
        taskId: string,
        actionId: string,
        payload: unknown,
        packageId?: string,
    ) {
        const url = `${process.env.REACT_APP_TASKS_URL}tasks/${taskId}/action/${actionId}`;
        return appClient.post(url, { fields: payload, packageId });
    }

    prepareClientAction(
        taskId: string,
        actionId: string,
        payload: unknown,
        packageId?: string,
    ) {
        const url = `${process.env.REACT_APP_TASKS_URL}tasks/${taskId}/action/client/${actionId}`;
        return appClient.post<ClientActionData>(url, {
            fields: payload,
            packageId,
        });
    }

    getActionEvents(taskId: string) {
        const url = `${process.env.REACT_APP_TASKS_URL}tasks/${taskId}/actions/events`;
        return appClient.get<ActionEvent[]>(url);
    }

    updateMetadataField(taskId: string, fieldName: string, value: unknown) {
        const url = `${process.env.REACT_APP_TASKS_URL}tasks/${taskId}/metadata/${fieldName}`;
        return appClient.post(url, value);
    }

    updateColumnFilters(
        projectId: string | null,
        pageSize: number,
        page: number,
        filters: ColumnsFilter[],
        searchTerm: string | undefined,
        isMainTasksViewEnabled: boolean,
        includeDoneTasks: boolean,
        sortField: string = "createDate",
        sortIndex: 1 | -1 = -1,
        widgetId: string | undefined,
        widgetValue: string | undefined,
    ) {
        const url = `${process.env.REACT_APP_TASKS_URL}tasks/change/filter`;
        const requestFilters = filters.map((f) => ({
            entityReference: f.entityReference,
            fieldType: f.fieldType,
            value: f.value,
            operator: f.operator,
        }));

        const request = {
            projectId,
            pageSize,
            page,
            searchTerm,
            mainTasksOnly: isMainTasksViewEnabled,
            includeDoneTasks,
            sortField,
            sortIndex,
            widgetId,
            widgetValue,
            filters: requestFilters,
        };
        return appClient.post<TasksData>(url, request);
    }

    resetAllColumnFilters(
        projectId: string | null,
        pageSize: number,
        page: number,
        searchTerm: string | undefined,
        isMainTasksViewEnabled: boolean,
        includeDoneTasks: boolean,
        sortField: string = "createDate",
        sortIndex: 1 | -1 = -1,
    ) {
        const url = `${process.env.REACT_APP_TASKS_URL}tasks/reset/filters`;
        const request = {
            projectId,
            pageSize,
            page,
            searchTerm,
            mainTasksOnly: isMainTasksViewEnabled,
            sortField,
            sortIndex,
            includeDoneTasks,
        };
        return appClient.post<TasksData>(url, request);
    }

    getTaskLinks(taskId: string) {
        const url = `${process.env.REACT_APP_TASKS_URL}tasklinks/task/${taskId}`;
        return appClient.get<TaskLink[]>(url);
    }

    createTaskLink(formData: FormData) {
        const url = `${process.env.REACT_APP_TASKS_URL}tasklinks`;
        return appClient.post<string>(url, formData);
    }

    deleteTaskLink(linkId: string) {
        const url = `${process.env.REACT_APP_TASKS_URL}tasklinks/${linkId}`;
        return appClient.delete<string>(url);
    }

    lookupTasks(projectId: string, searchTerm: string, currentTaskId?: string) {
        let url = `${process.env.REACT_APP_TASKS_URL}tasks/lookup?projectId=${projectId}&query=${searchTerm}`;

        if (currentTaskId) {
            url += `&currentTaskId=${currentTaskId}`;
        }

        return appClient.get<TaskLookupItem[]>(url);
    }
}

import { observer } from "mobx-react-lite";
import * as React from "react";
import { TabModel } from "../types/TabModel";
import { TaskViewVisualStore } from "../../tasks/stores";
import { TabsStore } from "../stores";
import { useNavigate } from "react-router";
import { Tooltip } from "antd";

type Props = {
    tab: TabModel;
    tasksStore: TaskViewVisualStore;
    tabsStore: TabsStore;
    isCollapsed: boolean;
};

const TaskTabHeader: React.FC<Props> = ({
    tab,
    tasksStore,
    tabsStore,
    isCollapsed,
}) => {
    const navigateTo = useNavigate();

    const navigate = (path: string) => {
        navigateTo(path);
    };

    const handleClick = async () => {
        tabsStore.addTab(tab);
        tasksStore.setPageIsLoading(true);
        navigate(`tasks/${tab.id}`);
    };

    const handleClose = (e: React.MouseEvent) => {
        e.stopPropagation();
        tasksStore.unpinTask(tab.id);
        navigate("/tasks");
    };

    const nameSection = (
        <div className="titles-frame">
            {tab.parentTitle && <p className="parent">{tab.parentTitle}</p>}
            <p className="title">
                {tab.isSubtask && (
                    <i
                        className="alpha-icon xs subtask no-margin"
                        style={{ marginBottom: "-2px" }}
                    />
                )}
                {tab.title}
            </p>
        </div>
    );

    return (
        <div
            className={`frame ${tab.id === tabsStore.selectedTab?.id ? "selected" : ""}`}
            key={tab.id}
            onClick={handleClick}
        >
            <Tooltip
                title={nameSection}
                placement="right"
                trigger={isCollapsed ? "hover" : undefined}
            >
                <div className="status-wrapper">
                    {tab.type === "task" ? (
                        // TODO: Represent proper status color.
                        // TODO: Do this after implementation of configurable task status colors.
                        <div className="status" />
                    ) : (
                        <i className="alpha-icon xs package-icon no-margin" />
                    )}
                </div>
            </Tooltip>

            {nameSection}

            <div className="icon-wrapper" onClick={handleClose}>
                <i className="alpha-icon xs cross no-margin" />
            </div>
        </div>
    );
};

export default observer(TaskTabHeader);

import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Button, Layout, Select } from "antd";
import "@progress/telerik-react-report-viewer/dist/dependencies/telerikReportViewer.kendo.min.js";
import "@progress/kendo-ui/css/web/kendo.common.min.css";
import "@progress/kendo-ui/css/web/kendo.default-v2.min.css";
import { TelerikReportViewer } from "@progress/telerik-react-report-viewer";
import { useReportsStore } from "../stores/context";
import { Content } from "antd/lib/layout/layout";

const ReportPage: React.FC = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let viewer: any;
    const [selectedReport, setSelectedReport] = useState<string | undefined>(
        undefined,
    );
    const [selectedProject, setSelectedProject] = useState<string | undefined>(
        undefined,
    );
    const store = useReportsStore().store;

    const handleRunReport = () => {
        if (selectedReport && selectedProject && viewer) {
            viewer.viewerObject.reportSource({
                report: store.getReport(selectedReport)?.source,
                parameters: { ProjectId: selectedProject },
            });
        }
    };

    return (
        <Layout className="screen-size layout-with-table">
            <div className="header-wrapper">
                <div className="title-wrapper">
                    <div className="header-title">Reports</div>
                </div>
            </div>
            <Layout>
                <Content>
                    <div
                        className="report-viewer-container"
                        style={{ height: "100vh", width: "100%" }}
                    >
                        <div
                            className="projects-list-container"
                            style={{ marginBottom: 10 }}
                        >
                            <Select
                                options={store.enabledReports.map((r) => ({
                                    label: r.title,
                                    value: r.id,
                                }))}
                                placeholder="Choose report"
                                style={{ width: 300, marginRight: 10 }}
                                loading={store.reportsLoading}
                                onChange={(r) => {
                                    setSelectedReport(r);
                                    store.refreshAvailableProjects(r);
                                }}
                            />
                            <Select
                                options={store.availableProjects.map((p) => ({
                                    label: p.name,
                                    value: p.id,
                                }))}
                                placeholder="Choose project"
                                style={{ width: 300, marginRight: 10 }}
                                onChange={(p) => {
                                    setSelectedProject(p);
                                }}
                            />
                            <Button
                                data-id="run-report"
                                size="middle"
                                key="submit"
                                type="primary"
                                onClick={handleRunReport}
                                disabled={!selectedReport || !selectedProject}
                            >
                                View report
                            </Button>
                        </div>
                        <TelerikReportViewer
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            ref={(el: any) => (viewer = el)}
                            serviceUrl={process.env.REACT_APP_REPORTS_URL}
                            // reportSource={selectedSource}
                            viewerContainerStyle={{
                                position: "relative",
                                height: "calc(100vh - 180px)",
                                width: "100%",
                                overflow: "hidden",
                                clear: "both",
                                fontFamily: "ms sans serif",
                            }}
                            viewMode="INTERACTIVE"
                            scaleMode="SPECIFIC"
                            scale={1.0}
                            enableAccessibility={false}
                        />
                    </div>
                </Content>
            </Layout>
        </Layout>
    );
};

export default observer(ReportPage);

import * as React from "react";
import {
    DragElementWrapper,
    DragSourceOptions,
    DropTarget,
    DragSource,
    DropTargetMonitor,
} from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";

let dragingIndex = -1;
let draggableRecord = {};

type BeginDragProps = {
    index: number;
    record: object;
};

type RowTargetProps = {
    moveRow: (
        dragingIndex: number,
        hoverIndex: number,
        dragRecord: object,
    ) => void;
    handleFileDrop: (files: File[], hoverIndex: number) => void;
    index: number;
};

const rowSource = {
    beginDrag(props: BeginDragProps) {
        dragingIndex = props.index;
        draggableRecord = props.record;
        return {
            index: props.index,
            record: props.record,
        };
    },
};

const rowTarget = {
    drop(props: RowTargetProps, monitor: DropTargetMonitor) {
        const files = monitor.getItem().files;
        const dragIndex = monitor.getItem().index;
        const dragRecord = monitor.getItem().record;
        const hoverIndex = props.index;

        if (files) {
            props.handleFileDrop(files, hoverIndex);
            return;
        }

        if (!dragRecord) {
            return;
        }

        props.moveRow(dragIndex, hoverIndex, dragRecord);

        monitor.getItem().index = hoverIndex;
        draggableRecord = {};
    },
};

type Props = {
    isOver: boolean;
    connectDragSource: DragElementWrapper<DragSourceOptions>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    connectDropTarget: DragElementWrapper<any>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    style: any;
    className: string;
    index: number;
    record: object;
    moveRow: () => void;
    moveTagRow: () => void;
    handleFileDrop: () => void;
};

export class ItemDraggableRow extends React.Component<Props> {
    render() {
        const { isOver, connectDragSource, connectDropTarget, ...restProps } =
            this.props;
        const style = { ...restProps.style };
        let { className } = restProps;
        if (isOver && restProps.record && draggableRecord) {
            if (restProps.index > dragingIndex) {
                className += " drop-over-downward";
            }
            if (restProps.index < dragingIndex) {
                className += " drop-over-upward";
            }
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { moveRow, moveTagRow, handleFileDrop, ...props } = restProps;
        return connectDragSource(
            connectDropTarget(
                <tr {...props} className={className} style={style} />,
            ),
        );
    }
}

export const DragableBodyRow = DropTarget(
    ["row", NativeTypes.FILE],
    rowTarget,
    (connect, monitor) => ({
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
    }),
)(
    DragSource("row", rowSource, (connect) => ({
        connectDragSource: connect.dragSource(),
    }))(ItemDraggableRow),
);

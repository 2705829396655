import { Observer, Observable, Subject } from "rxjs";
import * as signalR from "@microsoft/signalr";
import {
    TaskModel,
    TaskCommentModel,
    ActionCompletedResult,
    ActionTriggeredResult,
    TaskError,
    MetadataPushMessage,
} from "../types";
import { Notification } from "../../common/types/Notification";
import { PackageChanges } from "../../common/services/types";
import {
    AssignmentMsg,
    UpdatedActivitiesPushMsg,
} from "../../home/types/Activity";
const url =
    process.env.NODE_ENV === "test" ? "test" : process.env.REACT_APP_PUSH_URL;

export class TaskPushClient {
    static RECONNECT_INTERVAL: number = 5000;
    static ConnectionAttempted: boolean = false;
    static ConnectionStatusSubject: Subject<boolean> = new Subject<boolean>();
    static Connection = new signalR.HubConnectionBuilder()
        .withUrl(url!)
        .build();

    constructor() {
        TaskPushClient.Connection.onclose(() => {
            TaskPushClient.connect();
        });
    }

    static connect() {
        TaskPushClient.ConnectionAttempted = true;

        TaskPushClient.Connection.start()
            .then(() => {
                TaskPushClient.ConnectionStatusSubject.next(true);
            })
            .catch(() => {
                TaskPushClient.ConnectionStatusSubject.next(false);
                console.info("Wasn't able to connect to SignalR service");
                setTimeout(() => {
                    this.connect();
                }, TaskPushClient.RECONNECT_INTERVAL);
            });
    }

    createTaskListener(): Observable<TaskModel> {
        return Observable.create((obs: Observer<TaskModel>) => {
            if (process.env.NODE_ENV === "test") {
                return;
            }

            TaskPushClient.Connection.on("TaskMessage", (data: string) => {
                console.info("Received data from push service on tasks...");
                obs.next(JSON.parse(data));
            });
        });
    }

    updateTaskListener(): Observable<TaskModel> {
        return Observable.create((obs: Observer<TaskModel>) => {
            if (process.env.NODE_ENV === "test") {
                return;
            }

            TaskPushClient.Connection.on(
                "TaskFieldUpdateMessage",
                (data: string) => {
                    console.info("Received data from push service on tasks...");
                    obs.next(JSON.parse(data));
                },
            );
        });
    }

    createTaskNotificationsListener(): Observable<Notification> {
        return Observable.create((obs: Observer<Notification>) => {
            if (process.env.NODE_ENV === "test") {
                return;
            }
            TaskPushClient.Connection.on(
                "NotificationMessage",
                (data: string) => {
                    console.info(
                        "Received data from push service on task notifications...",
                    );
                    obs.next(JSON.parse(data));
                },
            );
        });
    }

    createTaskNewCommentListener(): Observable<
        TaskCommentModel & { projectId: string }
    > {
        return Observable.create((obs: Observer<TaskCommentModel>) => {
            if (process.env.NODE_ENV === "test") {
                return;
            }
            TaskPushClient.Connection.on(
                "TaskCommentMessage",
                (data: string) => {
                    console.info(
                        "Received data from push service on task comments...",
                    );
                    obs.next(JSON.parse(data));
                },
            );
        });
    }

    createTaskDeleteCommentListener(): Observable<string> {
        return Observable.create((obs: Observer<string>) => {
            if (process.env.NODE_ENV === "test") {
                return;
            }
            TaskPushClient.Connection.on(
                "TaskCommentDeleteMessage",
                (data: string) => {
                    console.info(
                        "Received data from push service on task comments...",
                    );
                    obs.next(JSON.parse(data));
                },
            );
        });
    }

    createTaskCommentDeleteListener(): Observable<string> {
        return Observable.create((obs: Observer<string>) => {
            if (process.env.NODE_ENV === "test") {
                return;
            }
            TaskPushClient.Connection.on(
                "TaskCommentDeleteMessage",
                (data: string) => {
                    console.info(
                        "Received data from push service on task comments deletion...",
                    );
                    obs.next(JSON.parse(data));
                },
            );
        });
    }

    createPackageChangeListener(): Observable<PackageChanges> {
        return Observable.create((obs: Observer<PackageChanges>) => {
            if (process.env.NODE_ENV === "test") {
                return;
            }
            TaskPushClient.Connection.on(
                "PackageStatusChange",
                (data: string) => {
                    console.info(
                        "Received data from push service on package status change...",
                    );
                    obs.next(JSON.parse(data));
                },
            );
        });
    }

    createActionCompletedListener(): Observable<ActionCompletedResult> {
        return Observable.create((obs: Observer<ActionCompletedResult>) => {
            if (process.env.NODE_ENV === "test") {
                return;
            }
            TaskPushClient.Connection.on(
                "ActionCompletedMessage",
                (data: string) => {
                    console.info(
                        "Received data from push service on action completion...",
                    );
                    obs.next(JSON.parse(data));
                },
            );
        });
    }

    createActionTriggeredListener(): Observable<ActionTriggeredResult> {
        return Observable.create((obs: Observer<ActionTriggeredResult>) => {
            if (process.env.NODE_ENV === "test") {
                return;
            }
            TaskPushClient.Connection.on(
                "ActionTriggeredMessage",
                (data: string) => {
                    console.info(
                        "Received data from push service on action triggering...",
                    );
                    obs.next(JSON.parse(data));
                },
            );
        });
    }

    createDashboardActivitiesListener(): Observable<UpdatedActivitiesPushMsg> {
        return Observable.create((obs: Observer<UpdatedActivitiesPushMsg>) => {
            if (process.env.NODE_ENV === "test") {
                return;
            }
            TaskPushClient.Connection.on(
                "DashboardActivitiesUpdate",
                (data: string) => {
                    console.info(
                        "Received data from push service on task comments...",
                    );
                    obs.next(JSON.parse(data));
                },
            );
        });
    }

    createTaskAssignmentListener(): Observable<AssignmentMsg> {
        return Observable.create((obs: Observer<AssignmentMsg>) => {
            if (process.env.NODE_ENV === "test") {
                return;
            }
            TaskPushClient.Connection.on("TaskAssignment", (data: string) => {
                console.info(
                    "Received data from push service on task comments...",
                );
                obs.next(JSON.parse(data));
            });
        });
    }

    createNewTaskErrorListener(): Observable<TaskError> {
        return Observable.create((obs: Observer<TaskError>) => {
            if (process.env.NODE_ENV === "test") {
                return;
            }
            TaskPushClient.Connection.on("TaskErrorMessage", (data: string) => {
                console.info(
                    "Received data from push service on task error...",
                );
                obs.next(JSON.parse(data));
            });
        });
    }

    createMedataUpdateListener(): Observable<MetadataPushMessage> {
        return Observable.create((obs: Observer<MetadataPushMessage>) => {
            if (process.env.NODE_ENV === "test") {
                return;
            }
            TaskPushClient.Connection.on(
                "MetadataUpdateMessage",
                (data: string) => {
                    console.info(
                        "Received data from push service on task metadata...",
                    );
                    obs.next(JSON.parse(data));
                },
            );
        });
    }
}

if (process.env.NODE_ENV !== "test") {
    if (!TaskPushClient.ConnectionAttempted) {
        TaskPushClient.connect();
    }
}

const tasksPushClient = new TaskPushClient();

export default { pushClient: tasksPushClient, PushClient: TaskPushClient };

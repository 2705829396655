import { Form, Select } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import EditorRegistry from "./input_configuration/EditorRegistry";
import ActionDefinitionStore from "../stores/ActionDefinitionStore";

type Props = {
    store: ActionDefinitionStore;
};

const ActionFieldInputSetupForm: React.FC<Props> = ({ store }) => {
    const [selectedType, setSelectedType] = React.useState<string>(
        store.selectedEditorConfig?.$type ?? "TextEditorConfig",
    );
    const [form] = Form.useForm();

    React.useEffect(() => {
        if (!store.editInputDialogVisible) {
            form.resetFields();
        } else {
            form.setFieldsValue(store.selectedEditorConfig);
            setSelectedType(
                store.selectedEditorConfig?.$type ?? "TextEditorConfig",
            );
        }
    }, [store.editInputDialogVisible, store.selectedEditorConfig, form]);

    const inputTypes = [
        { label: "Text", value: "TextEditorConfig" },
        { label: "Select", value: "SelectEditorConfig" },
        { label: "File", value: "FileEditorConfig" },
        { label: "Date", value: "DateEditorConfig" },
    ];

    const formLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const EditorConfigControl = EditorRegistry[selectedType];

    return (
        <Form
            form={form}
            id="edit-field-input-form"
            {...formLayout}
            onFinish={(values) => {
                if (store.editorConfigChangeCallback) {
                    store.editorConfigChangeCallback(values);
                }
                store.setEditInputDialogVisible(false);
            }}
            initialValues={store.selectedEditorConfig}
        >
            <Form.Item
                name="$type"
                label="Input type"
                rules={[{ required: true, message: "Input type is required" }]}
            >
                <Select
                    options={inputTypes}
                    onChange={(x) => setSelectedType(x)}
                />
            </Form.Item>
            {EditorConfigControl ? <EditorConfigControl /> : null}
        </Form>
    );
};

export default observer(ActionFieldInputSetupForm);

import * as React from "react";
import { Alert } from "antd";
import { useStore } from "../stores";
import { observer } from "mobx-react-lite";

const ErrorNotification: React.FC = () => {
    const rootContext = useStore();
    const store = rootContext.errorStore;

    const onClose = (err: Error) => {
        store.removeError(err);
    };

    return (
        <div>
            {store.errors.map((error, index) => (
                <Alert
                    message={error.message}
                    style={{ zIndex: 1000 }}
                    key={index}
                    type="error"
                    closable
                    onClose={() => onClose(error)}
                />
            ))}
        </div>
    );
};

export default observer(ErrorNotification);

import * as React from "react";
// import * as ReactDOM from 'react-dom';
import "@progress/kendo-ui/js/kendo.spreadsheet";
import * as $ from "jquery";
import "@progress/kendo-ui/css/web/kendo.common.min.css";
import "@progress/kendo-ui/css/web/kendo.default-v2.min.css";
import appClient from "../../common/services/AppClient";

export type Props = {
    documentId: string;
    documentName: string;
};

export default class DocumentExcel extends React.Component<Props> {
    spreadsheet?: kendo.ui.Spreadsheet;
    initialSelectionIgnored = false;
    disableSelectionTrigger = false;
    visualTableOffset: number = 0;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    wrapper: React.RefObject<any>;

    constructor(props: Props) {
        super(props);
        this.wrapper = React.createRef();
    }

    async componentDidMount() {
        const url =
            process.env.REACT_APP_TASKS_URL +
            `document/${this.props.documentId}`;
        const resp = await appClient.get<Blob>(url, "blob");

        resp.mapErr(() => {
            console.error(
                `Can't load file ${this.props.documentId}:${this.props.documentName} for preivew`,
            );
        }).map((blob) => {
            this.spreadsheet = $(this.wrapper.current)
                .kendoSpreadsheet({
                    sheetsbar: false,
                    toolbar: false,
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    excelImport: function (e: any) {
                        e.promise
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            .progress(function (ev: any) {
                                /* The result can be observed in the DevTools(F12) console of the browser. */
                                console.log(
                                    kendo.format("{0:P} complete", ev.progress),
                                );
                            })
                            .done(function () {
                                console.log("Export completed!");
                            });
                    },
                })
                .data("kendoSpreadsheet");

            if (this.spreadsheet) {
                this.spreadsheet.fromFile(blob);
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                this.spreadsheet.one("render", function (e: any) {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    e.sender.sheets().forEach(function (sheet: any) {
                        sheet
                            .range(
                                0,
                                0,
                                sheet._rows._count,
                                sheet._columns._count,
                            )
                            .enable(false);
                    });
                });
            }
        });
    }

    render = () => (
        <div
            id={"spreadsheet"}
            ref={this.wrapper}
            style={{ width: "100%", height: "calc(90vh - 80px)" }}
        />
    );
}

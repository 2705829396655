import axios, { ResponseType } from "axios";
import security from "./SecurityService";
import { Result, ok, err } from "neverthrow";
import { ErrorMsg } from "../types/ErrorMsg";
import { v1 as uuid } from "uuid";
import axiosRetry from "axios-retry";

const MaxRequest = 5;
const Interval = 10;
let PendingRequest = 0;

export interface ResponseData {
    status: number;
    statusText: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;
}

export interface ResponseErr {
    data: ErrorMsg | undefined;
    status: number;
    text: string;
}

export type ResultApi<T> = Result<T, ResponseErr>;
class AppClient {
    constructor() {
        axios.interceptors.request.use((config) =>
            security.invoke((token) => {
                config.headers = {
                    Authorization: "Bearer " + token,
                    "Content-Type": "application/json",
                    "X-Request-Id": uuid(),
                };
                return new Promise((resolve) => {
                    const interval = setInterval(() => {
                        if (PendingRequest < MaxRequest) {
                            PendingRequest++;
                            clearInterval(interval);
                            resolve(config);
                        }
                    }, Interval);
                });
            }),
        );

        axios.interceptors.response.use(
            function (response) {
                PendingRequest = Math.max(0, PendingRequest - 1);
                return Promise.resolve(response);
            },
            function (error) {
                PendingRequest = Math.max(0, PendingRequest - 1);
                return Promise.reject(error);
            },
        );

        axiosRetry(axios, {
            retries: 5,
            retryDelay: axiosRetry.exponentialDelay,
        });
    }

    async get<T>(
        url: string,
        responsetype: ResponseType = "json",
    ): Promise<ResultApi<T>> {
        try {
            const resp = await axios.get<T>(url, {
                responseType: responsetype,
            });
            return ok(resp.data);
        } catch (ex) {
            return this.ProcessError(ex);
        }
    }

    async post<T>(
        url: string,
        data?: unknown,
        responseType: ResponseType = "json",
    ): Promise<ResultApi<T>> {
        try {
            const resp = await axios.post<T>(url, data, {
                responseType: responseType,
            });
            return ok(resp.data);
        } catch (ex) {
            return this.ProcessError(ex);
        }
    }

    async postRaw(url: string, data?: unknown): Promise<ResponseData> {
        try {
            const resp = await axios.post(url, data);
            return {
                data: resp.data,
                status: resp.status,
                statusText: resp.statusText,
            };
        } catch (ex) {
            return this.ProcessErrorRaw(ex);
        }
    }

    async update<T>(url: string, data?: unknown): Promise<ResultApi<T>> {
        try {
            const resp = await axios.put<T>(url, data);
            return ok(resp.data);
        } catch (ex) {
            return this.ProcessError(ex);
        }
    }

    async updateRaw(url: string, data?: unknown): Promise<ResponseData> {
        try {
            const resp = await axios.put(url, data);
            return {
                data: resp.data,
                status: resp.status,
                statusText: resp.statusText,
            };
        } catch (ex) {
            return this.ProcessErrorRaw(ex);
        }
    }

    async patch<T>(url: string, data?: unknown): Promise<ResultApi<T>> {
        try {
            const resp = await axios.patch<T>(url, data);
            return ok(resp.data);
        } catch (ex) {
            return this.ProcessError(ex);
        }
    }

    async patchRaw(url: string, data?: unknown): Promise<ResponseData> {
        try {
            const resp = await axios.patch(url, data);
            return {
                data: resp.data,
                status: resp.status,
                statusText: resp.statusText,
            };
        } catch (ex) {
            return this.ProcessErrorRaw(ex);
        }
    }

    async delete<T>(url: string): Promise<ResultApi<T>> {
        try {
            const resp = await axios.delete<T>(url);
            return ok(resp.data);
        } catch (ex) {
            return this.ProcessError(ex);
        }
    }

    async deleteRaw(url: string): Promise<ResponseData> {
        try {
            const resp = await axios.delete(url);
            return {
                data: resp.data,
                status: resp.status,
                statusText: resp.statusText,
            };
        } catch (ex) {
            return this.ProcessErrorRaw(ex);
        }
    }

    private ProcessErrorRaw(ex: unknown): ResponseData {
        if (axios.isAxiosError(ex)) {
            if (ex.response?.headers["content-type"] === "application/json") {
                return {
                    statusText: ex.response!.statusText,
                    status: ex.response!.status,
                    data: ex.response?.data,
                };
            }

            if (ex.response) {
                return {
                    statusText: ex.response!.statusText,
                    status: ex.response!.status,
                    data: {
                        title: ex.response?.data ?? ex.response?.statusText,
                        details: "",
                        status: -1,
                        type: "unknown",
                        stackTrace: "",
                    },
                };
            }

            return {
                statusText: ex.message,
                status: -1,
                data: {
                    title: ex.message,
                    details: ex.stack ?? "",
                    status: -1,
                    type: "NETWORK_ERROR",
                    stackTrace: "",
                },
            };
        }

        return {
            statusText: "unknown",
            status: -1,
            data: undefined,
        };
    }

    private ProcessError<T>(ex: unknown): ResultApi<T> {
        if (axios.isAxiosError(ex)) {
            if (ex.response?.headers["content-type"] === "application/json") {
                return err({
                    text: ex.response!.statusText,
                    status: ex.response!.status,
                    data: ex.response?.data,
                });
            }

            if (ex.response) {
                return err({
                    text: ex.response!.statusText,
                    status: ex.response!.status,
                    data: {
                        title: ex.response?.data ?? ex.response?.statusText,
                        details: "",
                        status: -1,
                        type: "unknown",
                        stackTrace: "",
                    },
                });
            }

            return err({
                text: ex.message,
                status: -1,
                data: {
                    title: ex.message,
                    details: ex.stack ?? "",
                    status: -1,
                    type: "NETWORK_ERROR",
                    stackTrace: "",
                },
            });
        }

        return err({
            text: "unknown",
            status: -1,
            data: {
                title: "unknown",
                details: "",
                status: -1,
                type: "unknown",
                stackTrace: "",
            },
        });
    }
}

const appClient = new AppClient();

export default appClient;

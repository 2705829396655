import { Form, Select } from "antd";
import React from "react";
import { FULL_DATE, ONLY_DATE, ONLY_TIME } from "../../../common/misc/Utils";

export const DateEditorConfigForm: React.FC = () => {
    return (
        <>
            <Form.Item
                name="dateType"
                label="Date type"
                rules={[{ required: true, message: "Date type is required" }]}
            >
                <Select
                    options={[
                        { label: "Full date", value: FULL_DATE },
                        { label: "Short date", value: ONLY_DATE },
                        { label: "Only time", value: ONLY_TIME },
                    ]}
                />
            </Form.Item>
        </>
    );
};

import * as React from "react";
import { NotificationStore } from "../stores";
import { CloseOutlined, EyeOutlined } from "@ant-design/icons";
import { Button, Col, Dropdown, List, MenuProps, Row } from "antd";
import { Utils } from "../misc/Utils";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router";
import { Notification } from "../types/Notification";

type Props = {
    store: NotificationStore;
    fullScreen?: boolean;
    showNavLink?: boolean;
    hideMenu?: () => void;
};

const NotificationList: React.FC<Props> = ({
    store,
    hideMenu,
    fullScreen = false,
    showNavLink = false,
}) => {
    const navigate = useNavigate();

    React.useEffect(() => {
        store.loadNotificationsForUser();
    }, [store]);

    const notificationActions = (notificationId: string, isRead: boolean) => {
        const actions = [
            <Button
                title="Delete notification"
                key="delete-not"
                type="link"
                onClick={() => store.deleteNotification(notificationId)}
            >
                <CloseOutlined />
            </Button>,
        ];
        if (!isRead) {
            actions.unshift(
                <Button
                    title="Mark as read"
                    type="link"
                    onClick={() => store.markNotificationAsRead(notificationId)}
                >
                    <span className="notification-mark-as-read" />
                </Button>,
            );
        }

        return actions;
    };

    const notificationMenu: MenuProps = {
        items: [
            {
                key: 1,
                label: (
                    <div onClick={store.markAllNotificationsAsRead}>
                        Mark all as read
                    </div>
                ),
            },
        ],
    };

    const getNotificationIcon = (
        type:
            | "Info"
            | "Warning"
            | "NewTask"
            | "FollowedTaskUpdate"
            | "FailedTask",
        isRead: boolean,
    ) => {
        switch (type) {
            case "NewTask":
                return (
                    <i
                        className={`alpha-icon xs notification-new-task ${isRead ? "seen" : ""}`}
                    />
                );
            case "Warning":
                return (
                    <i
                        className={`alpha-icon xs notification-new-priority ${isRead ? "seen" : ""}`}
                    />
                );
            case "Info":
                return (
                    <i
                        className={`alpha-icon xs notification-new-comment ${isRead ? "seen" : ""}`}
                    />
                );
            case "FollowedTaskUpdate":
                return <EyeOutlined className="alpha-icon xs" />;
            default:
                return null;
        }
    };

    const handleAllNotificationsClick = () => {
        if (hideMenu) {
            hideMenu();
        }
        navigate("/notifications");
    };

    const handleNotificationsClick = async (notification: Notification) => {
        store.markNotificationAsRead(notification.id);
        const url = `${process.env.PUBLIC_URL}/tasks/${notification.relatedEntityId}`;
        window.open(url, "_blank")!.focus();
    };

    return (
        <div
            className={`notifications-container ${fullScreen ? "full-screen" : ""}`}
        >
            {fullScreen ? (
                <div className="header-wrapper">
                    <div className="title-wrapper">
                        <div className="header-title">Notifications</div>
                    </div>
                    <Button
                        data-id="button-add-type"
                        className="notifications-read-button"
                        type="text"
                        size="large"
                        onClick={store.markAllNotificationsAsRead}
                    >
                        Mark all as read
                    </Button>
                </div>
            ) : (
                <Row className="notifications-container-header">
                    <Col span={20} className="notifications-header-title">
                        Notifications
                    </Col>
                    <Col span={4} style={{ textAlign: "right" }}>
                        <Dropdown trigger={["click"]} menu={notificationMenu}>
                            <Button
                                style={{ border: "none" }}
                                size="small"
                                ghost
                                className="comment-dropdown-btn"
                            >
                                <i className="alpha-icon xs action-more-dots no-margin" />
                            </Button>
                        </Dropdown>
                    </Col>
                </Row>
            )}
            <List
                className="notifications-list"
                itemLayout="horizontal"
                dataSource={
                    fullScreen
                        ? store.notifications
                        : store.notifications.slice(0, 5)
                }
                renderItem={(item) => (
                    <List.Item
                        className={item.isRead ? "" : "unread"}
                        actions={notificationActions(item.id, item.isRead)}
                    >
                        <List.Item.Meta
                            avatar={getNotificationIcon(
                                item.notificationType,
                                item.isRead,
                            )}
                            title={
                                <span
                                    style={{ wordBreak: "break-word" }}
                                    onClick={() =>
                                        handleNotificationsClick(item)
                                    }
                                >
                                    {item.text}
                                </span>
                            }
                            description={
                                item.createdDate
                                    ? Utils.formatDateStringLong(
                                          item.createdDate,
                                      )
                                    : ""
                            }
                        />
                    </List.Item>
                )}
            />
            {showNavLink && (
                <div
                    className="notifications-container-footer"
                    onClick={handleAllNotificationsClick}
                >
                    All notifications
                    <i className="alpha-icon arrow-right xxs" />
                </div>
            )}
        </div>
    );
};

export default observer(NotificationList);

import { DashboardWidgetType } from "src/modules/administration/types/DashboardWidget";
import appClient from "../../common/services/AppClient";
import GroupedWidget from "../models/GroupedWidget";
import ListWidget from "../models/ListWidget";
import PieChartWidget from "../models/PieChartWidget";
import WidgetBase from "../models/WidgetBase";
import { ActivitiesPeriodEnum, ActivityByProjects } from "../types/Activity";
import NumericWidget from "../models/NumericWidget";
import { UserDashboardSettings } from "../types/DashboardSettings";
import { ErrorStore } from "src/modules/common/stores";

export default class DashboardService {
    constructor(private errorStore: ErrorStore) {
        this.errorStore = errorStore;
    }
    async getActivities(
        period: ActivitiesPeriodEnum,
        projectId: string | null,
    ) {
        let url = `${process.env.REACT_APP_TASKS_URL}activity?period=${period}`;
        if (projectId != null) {
            url += `&projectId=${projectId}`;
        }
        return appClient.get<ActivityByProjects>(url);
    }

    async activateWidget(id: string) {
        const url = `${process.env.REACT_APP_TASKS_URL}dashboard/widget/${id}/activate`;
        const resp = await appClient.get<WidgetBase>(url);
        const widget = resp.unwrapOr(null);
        if (widget == null) {
            throw new Error("Not implemented");
        }
        if (this.isList(widget)) {
            return new ListWidget(
                widget.id,
                widget.name,
                widget.widgetType,
                widget.tasks,
                widget.projectId,
                widget.isDefaultWidget,
                widget.totalTasksCount,
            );
        }
        if (this.isPieChart(widget)) {
            return new PieChartWidget(
                widget.id,
                widget.name,
                widget.widgetType,
                widget.chartData,
                widget.projectId,
                widget.isDefaultWidget,
            );
        }
        if (this.isGroupedChart(widget)) {
            return new GroupedWidget(
                widget.id,
                widget.name,
                widget.widgetType,
                widget.widgets,
                widget.projectId,
                widget.isDefaultWidget,
            );
        }
        if (this.isNumeric(widget)) {
            return new NumericWidget(
                widget.id,
                widget.name,
                widget.widgetType,
                widget.projectId,
                widget.isDefaultWidget,
                widget.totalTasksCount,
            );
        }
        throw new Error("Not implemented");
    }

    async deactivateWidget(id: string) {
        const url = `${process.env.REACT_APP_TASKS_URL}dashboard/widget/${id}/deactivate`;
        return appClient.post(url);
    }

    async getWidgets() {
        const url = `${process.env.REACT_APP_TASKS_URL}dashboard/widgets`;
        const resp = await appClient.get<WidgetBase[]>(url);
        const widgets = resp.unwrapOr(Array<WidgetBase>());
        return widgets.map((w) => {
            if (this.isList(w)) {
                return new ListWidget(
                    w.id,
                    w.name,
                    w.widgetType,
                    w.tasks,
                    w.projectId,
                    w.isDefaultWidget || false,
                    w.totalTasksCount,
                );
            }
            if (this.isPieChart(w)) {
                return new PieChartWidget(
                    w.id,
                    w.name,
                    w.widgetType,
                    w.chartData,
                    w.projectId,
                    w.isDefaultWidget || false,
                );
            }
            if (this.isGroupedChart(w)) {
                return new GroupedWidget(
                    w.id,
                    w.name,
                    w.widgetType,
                    w.widgets,
                    w.projectId,
                    w.isDefaultWidget || false,
                );
            }
            if (this.isNumeric(w)) {
                return new NumericWidget(
                    w.id,
                    w.name,
                    w.widgetType,
                    w.projectId,
                    w.isDefaultWidget,
                    w.totalTasksCount,
                );
            }

            throw new Error("Not implemented");
        });
    }

    updateUserSettings(
        widgetIds: string,
        selectedProject: string,
        selectedPeriod: ActivitiesPeriodEnum,
    ) {
        const url = `${process.env.REACT_APP_TASKS_URL}settings/dashboard`;
        const request = {
            widgetIds,
            selectedProject,
            selectedPeriod,
        };
        return appClient.post(url, request);
    }

    async getUserSettings() {
        const url = `${process.env.REACT_APP_TASKS_URL}settings/dashboard`;
        const resp = await appClient.get<UserDashboardSettings>(url);
        let settings = null as UserDashboardSettings | null;
        resp.map((r) => {
            settings = r;
        }).mapErr((err) => this.errorStore.addError(err.data));
        return settings;
    }

    isList(w: WidgetBase): w is ListWidget {
        return w.widgetType === DashboardWidgetType.List;
    }

    isPieChart(w: WidgetBase): w is PieChartWidget {
        return w.widgetType === DashboardWidgetType.PieChart;
    }

    isGroupedChart(w: WidgetBase): w is GroupedWidget {
        return w.widgetType === DashboardWidgetType.Grouped;
    }

    isNumeric(w: WidgetBase): w is NumericWidget {
        return w.widgetType === DashboardWidgetType.Numeric;
    }
}

import { Input, Form, Select } from "antd";
import * as React from "react";
import { useForm } from "antd/lib/form/Form";
import { ProjectsStore } from "../../common/stores";
import { UserProfile } from "../../common/services/types";

const FormItem = Form.Item;

type AddProjectFormProps = {
    onAddProject: (name: string, owner: string, projectRoles: string[]) => void;
    onSubmitCallback?: () => void;
    store: ProjectsStore;
};

interface Hash {
    [details: string]: string & string[];
}

export const AddProjectForm: React.FC<AddProjectFormProps> = ({
    store,
    onAddProject,
    onSubmitCallback,
}) => {
    const [form] = useForm();

    const handleSubmit = async (values: Hash) => {
        await onAddProject(
            values.name,
            values.projectOwner,
            values.projectRoles,
        );

        if (onSubmitCallback) {
            onSubmitCallback();
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const projectsValidator = (rule: any, value: any, callback: any) => {
        if (!value) {
            callback();
        }
        const existingProjectNames = store!.projects.map((p) =>
            p.name.toLowerCase(),
        );
        if (
            existingProjectNames.includes(value.toLowerCase()) &&
            store.editableProjectName !== value
        ) {
            callback("Project with the same name already exists");
        } else if (value?.length < 3) {
            callback("Project name must be at least 3 characters");
        } else {
            callback();
        }
    };

    const getUserLabel = (user: UserProfile) => {
        return (
            <>
                <span>{`${user.firstName} ${user.lastName}`}</span>
                <span style={{ color: "red" }}>
                    {user.isDeleted ? " (Inactive)" : ""}
                </span>
            </>
        );
    };

    return (
        <Form
            id="add-project-form"
            data-id="add-project-form"
            onFinish={handleSubmit}
            form={form}
            layout="vertical"
        >
            <FormItem
                label={<span className="dialog-field-label">Project name</span>}
                name="name"
                rules={[
                    {
                        required: true,
                        message: "Project name is required",
                        whitespace: true,
                    },
                    { validator: projectsValidator },
                ]}
                initialValue={store.editableProjectName}
            >
                <Input />
            </FormItem>
            <FormItem
                label={
                    <span className="dialog-field-label">Project owner</span>
                }
                name="projectOwner"
                rules={[
                    {
                        required: true,
                        message: "Project owner is required",
                        whitespace: true,
                    },
                ]}
                initialValue={store.editableProjectOwner}
            >
                <Select
                    options={store.filteredUsers.map((u) => ({
                        value: u.userId,
                        label: getUserLabel(u),
                    }))}
                />
            </FormItem>
            <FormItem
                label={
                    <span className="dialog-field-label">Project roles</span>
                }
                name="projectRoles"
                initialValue={store.editableProjectRoles}
            >
                <Select mode="tags" />
            </FormItem>
        </Form>
    );
};

export default AddProjectForm;

import { RcFile } from "antd/lib/upload/interface";

export default class UserProfileModel {
    constructor(
        public firstName: string,
        public lastName: string,
        public email: string,
        public timezone: string,
        public avatarIcon?: RcFile,
    ) {}
}

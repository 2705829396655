import * as React from "react";
import { observer } from "mobx-react-lite";
import { MetadataDefinition } from "../../administration/types/Metadata";
import { TaskModel } from "../types";
import { DatePicker, Input, InputNumber, Select, TimePicker } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { TaskViewVisualStore } from "../stores";
import { AuthConsumer } from "../../authorization/AuthContext";
import { AppPermissions } from "../../authorization/Permissions";
import { hasPermission } from "../../authorization/components/HasPermission";
import {
    FULL_DATE,
    ONLY_DATE,
    ONLY_TIME,
    Utils,
} from "../../common/misc/Utils";
import { Dayjs } from "dayjs";
import { useEffect, useRef } from "react";

type Props = {
    store: TaskViewVisualStore;
    definition: MetadataDefinition;
    task: TaskModel;
};

const MetadataEditor: React.FC<Props> = ({ store, definition, task }) => {
    const [isEditing, setIsEditing] = React.useState<boolean>(false);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const inputRef = useRef<any>(null);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const datePickerRef = useRef<any>(null);
    useEffect(() => {
        if (inputRef.current && isEditing) {
            inputRef.current.focus();
        }
    }, [inputRef, isEditing]);

    const handleEscapeClick = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Escape") {
            setIsEditing(false);
        }
    };

    useEffect(() => {
        const inputElement = datePickerRef.current?.nativeElement;

        if (inputElement && isEditing) {
            datePickerRef.current.focus();
            inputElement.addEventListener("keyup", handleEscapeClick);
        }

        // Clean up the event listener when the component is unmounted
        return () => {
            if (inputElement) {
                inputElement.removeEventListener("keyup", handleEscapeClick);
            }
        };
    }, [datePickerRef, isEditing]);

    const handleTextInputUpdate = () => {
        store
            .updateMetadataField(
                task.id,
                definition.name,
                inputRef.current?.input?.value || null,
            )
            .then(() => setIsEditing(false));
    };

    const handleInputUpdate = (val: unknown) => {
        store
            .updateMetadataField(
                task.id,
                definition.name,
                val === undefined ? null : val,
            )
            .then(() => setIsEditing(false));
    };

    const handleDateUpdate = (date: Dayjs | null) => {
        let ignoredTimezoneDate = null;
        if (date) {
            if (definition.format === ONLY_DATE) {
                ignoredTimezoneDate = Utils.removeLocalTimeZone(date);
            } else {
                ignoredTimezoneDate = Utils.ignoreLocalTimeZone(date);
            }
        }
        store
            .updateMetadataField(task.id, definition.name, ignoredTimezoneDate)
            .then(() => setIsEditing(false));
    };

    const getMetadataParseValue = () => {
        // TODO: Created shared handler as this logics is duplicated in TasksList component
        let nativeVal = task.metadata![definition.name];
        const format = definition.format;
        switch (definition.fieldType) {
            case "Text":
                if (nativeVal && (definition.listValues?.length ?? 0) > 0) {
                    const selectedValue = definition.listValues?.find(
                        (item) => item.value === nativeVal,
                    );
                    if (selectedValue) {
                        nativeVal = selectedValue?.label;
                    }
                }

                if (format && nativeVal) {
                    const { prefix, suffix } = JSON.parse(format);
                    return `${prefix ?? ""}${nativeVal}${suffix ?? ""}`;
                }

                return nativeVal;

            case "Boolean":
                if (format && nativeVal !== undefined) {
                    const { trueVal, falseVal } = JSON.parse(format);
                    if (nativeVal && trueVal) {
                        return trueVal;
                    }
                    if (nativeVal === false && falseVal) {
                        return falseVal;
                    }
                }
                return nativeVal?.toString() ?? "";
            case "Number":
                if (format && nativeVal) {
                    const { precision, separator } = JSON.parse(format);
                    if (separator) {
                        const fractionDigits =
                            precision ??
                            nativeVal.toString().split(".")[1]?.length ??
                            0;
                        return (nativeVal as number).toLocaleString(undefined, {
                            maximumFractionDigits: fractionDigits,
                            minimumFractionDigits: fractionDigits,
                        });
                    } else if (precision) {
                        return (nativeVal as number).toFixed(precision);
                    } else {
                        return nativeVal;
                    }
                }
                return nativeVal;
            case "DateTime":
                switch (format) {
                    case ONLY_DATE:
                        return Utils.formatDateStringShort(
                            nativeVal,
                            true,
                            true,
                            false,
                            true,
                        );
                    case ONLY_TIME:
                        return Utils.formatDateStringShort(
                            nativeVal,
                            false,
                            false,
                            true,
                            true,
                        );
                    default:
                        return Utils.formatDateStringShort(
                            nativeVal,
                            false,
                            false,
                            false,
                            true,
                        );
                }
            default:
                return nativeVal;
        }
    };

    const widget = () => {
        switch (definition.fieldType) {
            case "Number": {
                const { precision, separator } = definition.format
                    ? JSON.parse(definition.format)
                    : { precision: 2, separator: false };
                const stringifiedValue =
                    task.metadata![definition.name]?.toString();
                const decimalCount = stringifiedValue?.includes(".")
                    ? (stringifiedValue.split(".")[1].length ?? 0)
                    : 0;
                const step = `0.${"0".repeat((decimalCount || precision || 2) - 1)}1`;

                const handleNumberSave = () => {
                    handleInputUpdate(
                        inputRef.current.value === ""
                            ? null
                            : Number(
                                  inputRef.current.value.replace(/(,*)/g, ""),
                              ),
                    );
                };

                return (
                    <InputNumber
                        ref={inputRef}
                        step={step}
                        formatter={
                            separator
                                ? (value) =>
                                      value.replace(
                                          /\B(?=(\d{3})+(?!\d))/g,
                                          ",",
                                      )
                                : undefined
                        }
                        parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
                        defaultValue={task.metadata![definition.name]}
                        style={{ width: "100%" }}
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        onKeyUp={(e: any) => {
                            if (e.key === "Escape") {
                                setIsEditing(false);
                            }
                            if (e.key === "Enter") {
                                handleInputUpdate(
                                    e.target.value === ""
                                        ? null
                                        : Number(
                                              e.target.value.replace(
                                                  /(,*)/g,
                                                  "",
                                              ),
                                          ),
                                );
                            }
                        }}
                        addonAfter={
                            <>
                                <CheckOutlined onClick={handleNumberSave} />
                                <CloseOutlined
                                    onClick={() => setIsEditing(false)}
                                    style={{ marginLeft: 10 }}
                                />
                            </>
                        }
                    />
                );
            }
            case "Text":
                return (definition.listValues?.length ?? 0) === 0 ? (
                    <Input
                        ref={inputRef}
                        onKeyUp={(e) => {
                            if (e.key === "Escape") {
                                setIsEditing(false);
                            }
                            if (e.key === "Enter") {
                                handleTextInputUpdate();
                            }
                        }}
                        defaultValue={
                            task.metadata ? task.metadata[definition.name] : ""
                        }
                        addonAfter={
                            <>
                                <CheckOutlined
                                    onClick={handleTextInputUpdate}
                                />
                                <CloseOutlined
                                    onClick={() => setIsEditing(false)}
                                    style={{ marginLeft: 10 }}
                                />
                            </>
                        }
                    />
                ) : (
                    <Select
                        allowClear
                        value={getMetadataParseValue()}
                        onChange={handleInputUpdate}
                        options={definition.listValues!}
                        onKeyUp={handleEscapeClick}
                    />
                );
            case "Boolean": {
                const { trueVal, falseVal } = definition.format
                    ? JSON.parse(definition.format)
                    : { trueVal: "true", falseVal: "false" };
                return (
                    <Select
                        allowClear
                        value={getMetadataParseValue()}
                        onChange={handleInputUpdate}
                        options={[
                            { label: trueVal || "true", value: true },
                            { label: falseVal || "false", value: false },
                        ]}
                        onKeyUp={handleEscapeClick}
                    />
                );
            }
            case "DateTime":
                switch (definition.format) {
                    case ONLY_DATE:
                    case FULL_DATE:
                        return (
                            <DatePicker
                                ref={datePickerRef}
                                style={{ width: "100%" }}
                                suffixIcon={
                                    <i
                                        className="alpha-icon xs calendar-icon"
                                        style={{ margin: 0 }}
                                    />
                                }
                                format={Utils.getDateFormat(
                                    definition.format === FULL_DATE,
                                )}
                                showTime={definition.format === FULL_DATE}
                                onChange={handleDateUpdate}
                                value={Utils.formatDatePickerValue(
                                    task.metadata![definition.name],
                                    undefined,
                                    true,
                                )}
                            />
                        );
                    default:
                        return (
                            <TimePicker
                                ref={datePickerRef}
                                style={{ width: "100%" }}
                                onChange={handleDateUpdate}
                            />
                        );
                }
            default:
                return <></>;
        }
    };

    return (
        <div className="meta-value">
            <AuthConsumer>
                {({ permissions, projectRoles }) => {
                    return isEditing ? (
                        widget()
                    ) : (
                        <div
                            className="meta-display"
                            onClick={() => {
                                if (
                                    definition.isEditable ||
                                    !permissions ||
                                    hasPermission(
                                        permissions,
                                        AppPermissions.CanAccessAdministration,
                                        projectRoles,
                                        store.taskPreview!.projectId,
                                    )
                                ) {
                                    setIsEditing(true);
                                }
                            }}
                        >
                            {task.metadata ? getMetadataParseValue() : ""}
                        </div>
                    );
                }}
            </AuthConsumer>
        </div>
    );
};

export default observer(MetadataEditor);

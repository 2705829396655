import { observer, Observer } from "mobx-react-lite";
import {
    Input,
    Col,
    Row,
    Select,
    DatePicker,
    Upload,
    Form,
    UploadProps,
    Skeleton,
    Button,
    notification,
    InputNumber,
    TimePicker,
} from "antd";
import { TaskCreateVisualStore } from "../stores";
import { useStore } from "../../common/stores";
import Dragger from "antd/lib/upload/Dragger";
import React from "react";
import { RcFile } from "antd/lib/upload";
import { AccessDropdownItems } from "./AccessDropdownItems";
import { TaskAttachment } from ".";
import { FULL_DATE, ONLY_DATE, Utils } from "../../common/misc/Utils";
import TaskTemplateVisualStore from "../../task_templates/stores/TaskTemplatesVisualStore";
import { TaskTemplate } from "../../task_templates/types";
import { AccessType } from "../stores/TaskViewVisualStore";
import { TaskTypeDueDate } from "../../task_types/types";
import { FormInstance } from "antd/es/form";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { UserProfile } from "../../common/services/types";
import dayjs, { Dayjs } from "dayjs";
import { TaskModel } from "../types";
import {
    Editor,
    EditorMountEvent,
    ProseMirror,
} from "@progress/kendo-react-editor";
import { AdvancedToolbar } from "src/modules/common/components/EditorToolbar";
const FormItem = Form.Item;

type Props = {
    store: TaskCreateVisualStore;
    templateStore: TaskTemplateVisualStore;
    form: FormInstance;
};

const { EditorState, EditorView, Plugin, PluginKey } = ProseMirror;
const TaskCreateForm: React.FC<Props> = ({ store, templateStore, form }) => {
    const [isSharedWithVisible, setIsSharedWithVisible] = React.useState(false);
    const [isTemplateApplied, setIsTemplateApplied] = React.useState(false);
    const [isNameOverriden, setIsNameOverriden] = React.useState(false);
    const userProfileStore = useStore().userProfileStore;
    const editableRef = React.useRef<boolean>(true);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const view = React.useRef<any>(null);

    React.useEffect(() => {
        if (view.current && !isTemplateApplied) {
            view.current.updateState(view.current.state);
        }
    }, [isTemplateApplied]);

    const handleTaskClick = (taskId: string) => {
        const url = `${process.env.PUBLIC_URL}/tasks/${taskId}`;
        window.open(url, "_blank")!.focus();
    };

    const openNotification = (task: TaskModel) => {
        notification.success({
            message: (
                <div>
                    The task {task.alphaId} has been created.{" "}
                    <span
                        className="view-task"
                        onClick={() => handleTaskClick(task.id)}
                    >
                        View task
                    </span>
                </div>
            ),
            placement: "top",
            className: "sucess-notification",
            icon: <></>,
            style: { width: 480 },
        });
    };

    React.useEffect(() => {
        form.setFieldsValue({ projectId: store.currentProjectId });
        loadProjectData(store.currentProjectId!);
    }, []);

    React.useEffect(() => {
        if (!store.newTaskDialogVisible) {
            form.resetFields();
            setIsSharedWithVisible(false);
            templateStore.selectTemplate(undefined);
        }
    }, [store.newTaskDialogVisible, templateStore, form]);

    const loadProjectData = (projectId: string) => {
        return Promise.all([
            store.loadUsersForProject(projectId),
            store.loadTaskTypesForProject(projectId),
            store.loadMetadata(projectId),
        ]);
    };

    const setValuesFromTemplate = (template: TaskTemplate) => {
        form.setFieldsValue({
            projectId: template.projectId,
            taskType: template.taskType,
            templateCode: template.templateCode,
            priority: template.defaultPriority ?? "LOW",
        });
        if (!templateStore.hasParameters) {
            form.setFieldsValue({
                name: template.title,
                description: template.description,
            });
        }

        handleTaskTypeChange(template.taskType);
        const { reminderDays } = template;
        const dueDate = form.getFieldValue("dueDate");
        if (reminderDays && dueDate) {
            const reminder = dayjs(
                new Date(dueDate).setDate(
                    new Date(dueDate).getDate() - reminderDays,
                ),
            );
            form.setFieldsValue({ reminder });
        }
    };

    React.useEffect(() => {
        if (!templateStore.selectedTaskTemplateId) {
            setIsTemplateApplied(false);
            return;
        }
        setIsTemplateApplied(!!templateStore.selectedTaskTemplateId);
        if (templateStore.selectedTemplate) {
            editableRef.current = false;
        }
        setIsNameOverriden(false);

        form.setFieldsValue({
            templateCode: undefined,
            sharedWith: [],
            projectId: undefined,
            taskType: undefined,
            priority: "LOW",
        });

        if (
            form.getFieldValue("projectId") !==
            templateStore.selectedTemplate?.projectId
        ) {
            const newProjectId =
                templateStore.selectedTemplate?.projectId ||
                store.currentProjectId;

            if (newProjectId) {
                loadProjectData(newProjectId).then(() => {
                    if (templateStore.selectedTaskTemplateId) {
                        setValuesFromTemplate(templateStore.selectedTemplate!);
                    }
                });
            }
        } else if (templateStore.selectedTaskTemplateId) {
            setValuesFromTemplate(templateStore.selectedTemplate!);
        }
    }, [templateStore.selectedTaskTemplateId]);

    const handleSubmit = () => {
        form.validateFields()
            .then(async () => {
                const vals = form.getFieldsValue();
                const titleParams = vals.name.match(/\${[^}]+}/g) as
                    | string[]
                    | null;
                if (titleParams) {
                    for (const param of titleParams) {
                        const paramKey = param.match(/[^${}]+/g)![0];
                        const paramVal = vals[`title-${paramKey}`];
                        vals.name = (vals.name as string).replace(
                            param,
                            paramVal,
                        );
                        delete vals[paramKey];
                    }
                }
                const descriptionParams = vals.description?.match(
                    /\${[^}]+}/g,
                ) as string[] | null;
                if (descriptionParams) {
                    for (const param of descriptionParams) {
                        const paramKey = param.match(/[^${}]+/g)![0];
                        const paramVal = vals[`description-${paramKey}`];
                        vals.description = (vals.description as string).replace(
                            param,
                            paramVal,
                        );
                        delete vals[paramKey];
                    }
                }

                if (vals.metadata) {
                    for (const metadata of vals.metadata) {
                        const format = store.metadata.find(
                            (m) => m.name === metadata.metadataKey,
                        )!.format;
                        // Date in metadata represents absoulte value and is not bound to any timezone;
                        if (
                            dayjs(
                                metadata.metadataValue,
                                "YYYY-MM-DD",
                                true,
                            ).isValid()
                        ) {
                            if (format === ONLY_DATE) {
                                metadata.metadataValue =
                                    Utils.removeLocalTimeZone(
                                        metadata.metadataValue,
                                    );
                            } else if (format === FULL_DATE) {
                                metadata.metadataValue =
                                    Utils.ignoreLocalTimeZone(
                                        metadata.metadataValue,
                                    );
                            }
                        }
                    }
                }
                const task = await store.saveTask(vals);
                if (task) {
                    openNotification(task);
                }
                store.setCurrentTaskType(null);
                store.setParamsSavedInUi(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleProjectChange = (projectId: string) => {
        form.setFieldsValue({
            assignedTo: undefined,
            sharedWith: [],
        });
        templateStore.setSelectedProjectId(projectId);
        loadProjectData(projectId).then(() => {
            form.setFieldsValue({ taskType: undefined });
        });
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const metadataValidator = (rule: any, value: any, callback: any) => {
        if (!value) {
            callback();
        }

        if (
            Object.values(store.currentMetadataKey).filter(
                (m) => m.id === value,
            ).length > 1
        ) {
            callback("Duplicated keys are not allowed");
        } else {
            callback();
        }
    };
    const getDueDate = (dueDate: TaskTypeDueDate) => {
        const { period, count } = dueDate;
        let daysCount = 0;
        switch (period) {
            case "D":
                daysCount = count;
                break;
            case "W":
                daysCount = count * 7;
                break;
            case "M":
                daysCount = count * 30;
                break;
            case "Y":
                daysCount = count * 365;
                break;
            default:
                break;
        }

        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + daysCount);
        return dayjs(currentDate);
    };

    const disabledDate = (current: Dayjs) => {
        return current && current < dayjs().endOf("day");
    };

    const attachmentUploadProps: UploadProps = {
        multiple: true,
        accept: Utils.uploadAcceptExtensions,
        showUploadList: false,
        beforeUpload: (file) => {
            store.setTaskAttachments([...store.taskAttachments, file]);
            return false;
        },
        fileList: store.taskAttachments,
    };

    const removeAttachment = (file: RcFile) => {
        const index = store.taskAttachments.indexOf(file);
        const newFileList = store.taskAttachments.slice();
        newFileList.splice(index, 1);
        store.setTaskAttachments(newFileList);
    };

    const FormItemSkeleton = (
        <div style={{ marginBottom: -12 }}>
            <Skeleton
                title={false}
                avatar={false}
                paragraph={{ rows: 1, width: "100%" }}
                active
            />
        </div>
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleAccessChange = (value: string, option: any) => {
        if (isSharedWithVisible) {
            setIsSharedWithVisible(false);
            form.setFieldsValue({ sharedWith: [] });
        }
        if (option.label === AccessType.OnlyMe) {
            store.setFilteredAssignToUsers([
                userProfileStore.userProfile.userId!,
            ]);
            form.setFieldsValue({ assignedTo: "" });
        } else {
            if (option.label === AccessType.LimitedAccess) {
                setIsSharedWithVisible(true);
                store.setFilteredAssignToUsers([
                    userProfileStore.userProfile.userId!,
                ]);
            } else {
                const ids = store.usersInProject.map((x) => x.userId);
                store.setFilteredAssignToUsers(ids);
            }
        }
    };

    if (!store.projects || !store.projects.length || !store.currentProjectId) {
        return null;
    }

    const handleSharedWithChange = () => {
        const vals = form.getFieldValue("sharedWith");
        store.setFilteredAssignToUsers([
            userProfileStore.userProfile.userId!,
            ...vals,
        ]);
    };

    const handleTaskTypeChange = (id: string) => {
        const taskType = store.taskTypes.find((t) => t.id === id)!;
        store.setCurrentTaskType(id);
        if (taskType.assignee) {
            form.setFieldsValue({ assignedTo: taskType.assignee });
        }
        if (taskType.dueDate) {
            const dueDate = getDueDate(taskType.dueDate);
            form.setFieldsValue({ dueDate });
        }
    };

    const getUserLabel = (user: UserProfile) => {
        return (
            <>
                <span>{`${user?.firstName} ${user?.lastName}`}</span>
                <span style={{ color: "red" }}>
                    {user?.isDeleted ? " (Inactive)" : ""}
                </span>
            </>
        );
    };

    const getWidget = (key: number) => {
        const metadata = store.currentMetadataKey[key];
        switch (metadata?.fieldType) {
            case "Text":
                return (metadata.listValues?.length ?? 0) === 0 ? (
                    <Input />
                ) : (
                    <Select allowClear options={metadata.listValues!} />
                );
            case "Boolean": {
                const { trueVal, falseVal } = metadata.format
                    ? JSON.parse(metadata.format)
                    : { trueVal: "true", falseVal: "false" };
                return (
                    <Select
                        allowClear
                        options={[
                            { label: trueVal || "true", value: true },
                            { label: falseVal || "false", value: false },
                        ]}
                    />
                );
            }
            case "DateTime":
                switch (metadata.format) {
                    case ONLY_DATE:
                    case FULL_DATE:
                        return (
                            <DatePicker
                                style={{ width: "100%" }}
                                suffixIcon={
                                    <i
                                        className="alpha-icon xs calendar-icon"
                                        style={{ margin: 0 }}
                                    />
                                }
                                format={Utils.getDateFormat(
                                    metadata.format === FULL_DATE,
                                )}
                                showTime={metadata.format === FULL_DATE}
                            />
                        );
                    default:
                        return <TimePicker style={{ width: "100%" }} />;
                }
            case "Number": {
                const { precision, separator } = metadata.format
                    ? JSON.parse(metadata.format)
                    : { precision: 2, separator: false };
                const step = `0.${"0".repeat((precision || 2) - 1)}1`;
                return (
                    <InputNumber
                        step={step}
                        formatter={
                            separator
                                ? (value) =>
                                      value!
                                          .toString()
                                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                : undefined
                        }
                        parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
                    />
                );
            }
            default:
                return <Input />;
        }
    };

    const onMount = (event: EditorMountEvent) => {
        const state = event.viewProps.state;
        const plugins = [
            ...state.plugins,
            new Plugin({
                key: new PluginKey("readonly"),
                props: { editable: () => editableRef.current },
                filterTransaction: (tr) =>
                    editableRef.current || !tr.docChanged,
            }),
        ];
        view.current = new EditorView(
            { mount: event.dom },
            {
                ...event.viewProps,
                state: EditorState.create({ doc: state.doc, plugins }),
            },
        );

        return view.current;
    };

    return (
        <div className="dialog-section">
            <Form
                form={form}
                onFinish={handleSubmit}
                className="inputs-container"
                id="new-task-modal-form"
                layout="vertical"
            >
                <FormItem name="templateCode" noStyle>
                    <Input type="hidden" />
                </FormItem>
                <Row gutter={24}>
                    <Col span={12}>
                        <Observer>
                            {() => (
                                <FormItem
                                    colon={false}
                                    className="dialog-field "
                                    label={
                                        <span className="dialog-field-label">
                                            Access
                                        </span>
                                    }
                                    name="accessType"
                                    initialValue={AccessType.Everyone}
                                >
                                    <Select
                                        onChange={handleAccessChange}
                                        optionLabelProp="label"
                                        suffixIcon={
                                            <i
                                                className="alpha-icon xxs arrow-down-icon"
                                                style={{ margin: 0, width: 8 }}
                                            />
                                        }
                                        dropdownStyle={{ minWidth: "355px" }}
                                    >
                                        {Object.keys(AccessType).map((p) => (
                                            <Select.Option
                                                key={p}
                                                value={p}
                                                label={
                                                    AccessType[
                                                        p as keyof typeof AccessType
                                                    ]
                                                }
                                            >
                                                {
                                                    AccessDropdownItems[
                                                        p as keyof typeof AccessDropdownItems
                                                    ]
                                                }
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </FormItem>
                            )}
                        </Observer>
                    </Col>
                    {isSharedWithVisible ? (
                        <Col span={12}>
                            <Observer>
                                {() => (
                                    <FormItem
                                        name="sharedWith"
                                        colon={false}
                                        className="dialog-field"
                                        label={
                                            <span className="dialog-field-label">
                                                Share with
                                            </span>
                                        }
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    "Share with is required",
                                            },
                                        ]}
                                    >
                                        {!store.isUsersLoading ? (
                                            <Select
                                                mode="multiple"
                                                optionLabelProp="label"
                                                suffixIcon={
                                                    <i
                                                        className="alpha-icon xxs arrow-down-icon"
                                                        style={{
                                                            margin: 0,
                                                            width: 8,
                                                        }}
                                                    />
                                                }
                                                onChange={
                                                    handleSharedWithChange
                                                }
                                            >
                                                {store.usersInProject &&
                                                    store.usersInProject
                                                        .filter(
                                                            (x) =>
                                                                x.userId !==
                                                                    userProfileStore
                                                                        .userProfile
                                                                        ?.userId &&
                                                                !x.isDeleted,
                                                        )
                                                        .map((u) => (
                                                            <Select.Option
                                                                key={u.userId}
                                                                value={u.userId}
                                                                label={
                                                                    u.userName
                                                                }
                                                            >
                                                                <div
                                                                    style={{
                                                                        marginLeft: 7,
                                                                        display:
                                                                            "inline-block",
                                                                    }}
                                                                >
                                                                    {getUserLabel(
                                                                        u,
                                                                    )}
                                                                </div>
                                                            </Select.Option>
                                                        ))}
                                            </Select>
                                        ) : (
                                            FormItemSkeleton
                                        )}
                                    </FormItem>
                                )}
                            </Observer>
                        </Col>
                    ) : (
                        <></>
                    )}
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Observer>
                            {() => (
                                <FormItem
                                    colon={false}
                                    className="dialog-field"
                                    label={
                                        <span className="dialog-field-label">
                                            Project
                                        </span>
                                    }
                                    name="projectId"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Task project is required",
                                        },
                                    ]}
                                    initialValue={store.currentProjectId}
                                >
                                    <Select
                                        showSearch
                                        onChange={(val: string) => {
                                            handleProjectChange(val);
                                        }}
                                        options={
                                            store.projects &&
                                            store.projects.map((p) => ({
                                                label: p.name,
                                                value: p.id,
                                            }))
                                        }
                                        suffixIcon={
                                            <i
                                                className="alpha-icon xxs arrow-down-icon"
                                                style={{ margin: 0, width: 8 }}
                                            />
                                        }
                                        filterOption={Utils.filterOption}
                                        disabled={isTemplateApplied}
                                    />
                                </FormItem>
                            )}
                        </Observer>
                    </Col>
                    <Col span={12}>
                        <Observer>
                            {() => (
                                <FormItem
                                    name="taskType"
                                    colon={false}
                                    className="dialog-field"
                                    label={
                                        <span className="dialog-field-label">
                                            Type
                                        </span>
                                    }
                                    rules={[
                                        {
                                            required: true,
                                            message: "Task type is required",
                                        },
                                    ]}
                                >
                                    <Select
                                        loading={store.taskTypesLoading}
                                        suffixIcon={
                                            <i
                                                className="alpha-icon xxs arrow-down-icon"
                                                style={{ margin: 0, width: 8 }}
                                            />
                                        }
                                        options={
                                            store.taskTypes &&
                                            store.taskTypes
                                                .filter((t) => t.isEnabled)
                                                .map((p) => ({
                                                    label: p.name,
                                                    value: p.id,
                                                }))
                                        }
                                        filterOption={Utils.filterOption}
                                        showSearch
                                        disabled={isTemplateApplied}
                                        onChange={handleTaskTypeChange}
                                    />
                                </FormItem>
                            )}
                        </Observer>
                    </Col>
                </Row>
                <FormItem
                    name="name"
                    className="dialog-field"
                    label={<span className="dialog-field-label">Title</span>}
                    rules={[
                        { required: true, message: "Task title is required" },
                    ]}
                >
                    <Input
                        data-id="input-Name"
                        disabled={isTemplateApplied && !isNameOverriden}
                    />
                </FormItem>
                {isTemplateApplied &&
                    !isNameOverriden &&
                    !templateStore.templatTitleParameters?.length && (
                        <Button
                            className="override-title-button"
                            style={{ height: "1em" }}
                            size="small"
                            onClick={() => setIsNameOverriden(true)}
                            type="link"
                        >
                            Override task title
                        </Button>
                    )}
                <FormItem
                    name="description"
                    colon={false}
                    className="dialog-field"
                    label={
                        <span className="dialog-field-label">Description</span>
                    }
                    getValueProps={(value) => ({ value: value ?? "" })}
                >
                    {
                        <Editor
                            className={`${isTemplateApplied ? "disabled" : ""}`}
                            defaultEditMode="div"
                            tools={
                                isTemplateApplied ? undefined : AdvancedToolbar
                            }
                            contentStyle={
                                isTemplateApplied
                                    ? { height: 150, opacity: 0.4 }
                                    : { height: 150 }
                            }
                            onChange={(e) =>
                                form.setFieldValue("description", e.html)
                            }
                            onMount={onMount}
                        />
                    }
                </FormItem>
                <Row gutter={24}>
                    <Col span={12}>
                        <Observer>
                            {() => (
                                <FormItem
                                    name="assignedTo"
                                    colon={false}
                                    className="dialog-field"
                                    label={
                                        <span className="dialog-field-label">
                                            Assign to
                                        </span>
                                    }
                                    rules={[
                                        {
                                            required: true,
                                            message: "Assigned to is required",
                                        },
                                    ]}
                                >
                                    {!store.isUsersLoading ? (
                                        <Select
                                            suffixIcon={
                                                <i
                                                    className="alpha-icon xxs arrow-down-icon"
                                                    style={{
                                                        margin: 0,
                                                        width: 8,
                                                    }}
                                                />
                                            }
                                            dropdownRender={(menu) => (
                                                <div className="assign-to-dropdown">
                                                    <div
                                                        className={
                                                            "limited-access-description" +
                                                            (isSharedWithVisible
                                                                ? " visible"
                                                                : "")
                                                        }
                                                    >
                                                        This task has a limited
                                                        access. You can assign
                                                        the task only to user
                                                        who has the access.
                                                    </div>
                                                    {menu}
                                                </div>
                                            )}
                                            options={store.filteredAssignToUsers.map(
                                                (p) => ({
                                                    label: getUserLabel(
                                                        p,
                                                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                                    ) as any,
                                                    value: p.userId,
                                                }),
                                            )}
                                            filterOption={Utils.filterOption}
                                            showSearch
                                        />
                                    ) : (
                                        FormItemSkeleton
                                    )}
                                </FormItem>
                            )}
                        </Observer>
                    </Col>
                    <Col span={12}>
                        <FormItem
                            name="dueDate"
                            colon={false}
                            className="dialog-field"
                            label={
                                <span className="dialog-field-label">
                                    Due date
                                </span>
                            }
                        >
                            <DatePicker
                                suffixIcon={
                                    <i
                                        className="alpha-icon xs calendar-icon"
                                        style={{ margin: 0 }}
                                    />
                                }
                                style={{ width: "100%" }}
                                placeholder=""
                                disabledDate={disabledDate}
                                format={Utils.getDateFormat()}
                            />
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem
                            name="priority"
                            initialValue="LOW"
                            colon={false}
                            className="dialog-field"
                            label={
                                <span className="dialog-field-label">
                                    Priority
                                </span>
                            }
                            rules={[
                                {
                                    required: true,
                                    message: "Task priority is required",
                                },
                            ]}
                        >
                            <Select
                                suffixIcon={
                                    <i
                                        className="alpha-icon xxs arrow-down-icon"
                                        style={{ margin: 0, width: 8 }}
                                    />
                                }
                                options={Utils.getTaskPrioritiesForDropdown()}
                            />
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem
                            name="reminder"
                            colon={false}
                            className="dialog-field"
                            label={
                                <span className="dialog-field-label">
                                    Reminder
                                </span>
                            }
                        >
                            <DatePicker
                                suffixIcon={
                                    <i
                                        className="alpha-icon xs calendar-icon"
                                        style={{ margin: 0 }}
                                    />
                                }
                                style={{ width: "100%" }}
                                placeholder=""
                                disabledDate={disabledDate}
                                format={Utils.getDateFormat()}
                            />
                        </FormItem>
                    </Col>
                </Row>

                <Form.List name="metadata">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name }) => (
                                <Row gutter={24} key={key}>
                                    <Col span={12}>
                                        <FormItem
                                            name={[name, "metadataKey"]}
                                            colon={false}
                                            className="dialog-field"
                                            label={
                                                <span className="dialog-field-label">
                                                    Metadata key
                                                </span>
                                            }
                                            rules={[
                                                {
                                                    validator:
                                                        metadataValidator,
                                                },
                                                {
                                                    required: true,
                                                    message:
                                                        "Task metadata key is required",
                                                },
                                            ]}
                                        >
                                            <Select
                                                suffixIcon={
                                                    <i
                                                        className="alpha-icon xxs arrow-down-icon"
                                                        style={{
                                                            margin: 0,
                                                            width: 8,
                                                        }}
                                                    />
                                                }
                                                options={store.filteredMetadata.map(
                                                    (m) => ({
                                                        label: m.title,
                                                        value: m.name,
                                                    }),
                                                )}
                                                onChange={(val: string) =>
                                                    store.setCurrentMetadataKey(
                                                        key,
                                                        val,
                                                    )
                                                }
                                                showSearch
                                                filterOption={
                                                    Utils.filterOption
                                                }
                                                allowClear
                                            />
                                        </FormItem>
                                    </Col>
                                    <Col span={11} key={key}>
                                        <Observer>
                                            {() => (
                                                <FormItem
                                                    name={[
                                                        name,
                                                        "metadataValue",
                                                    ]}
                                                    colon={false}
                                                    className="dialog-field"
                                                    label={
                                                        <span className="dialog-field-label">
                                                            Metadata value
                                                        </span>
                                                    }
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                "Task metadata value is required",
                                                        },
                                                    ]}
                                                >
                                                    {getWidget(key)}
                                                </FormItem>
                                            )}
                                        </Observer>
                                    </Col>
                                    <div
                                        style={{
                                            margin: "auto",
                                            marginRight: 12,
                                        }}
                                    >
                                        <MinusCircleOutlined
                                            onClick={() => {
                                                remove(name);
                                                store.removeMetadataKey(name);
                                            }}
                                        />
                                    </div>
                                </Row>
                            ))}
                            <Form.Item style={{ marginTop: 5 }}>
                                <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    block
                                    icon={<PlusOutlined />}
                                >
                                    Add metadata
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            </Form>
            <div className="attachments">
                <div className="attachments-container">
                    <span className="title">Attachments</span>
                    {store.hasFiles ? (
                        <>
                            <span className="items-amount">
                                &nbsp;({store.taskAttachments.length})
                            </span>
                            <span
                                onClick={(e: React.MouseEvent) => {
                                    e.stopPropagation();
                                }}
                                style={{ float: "right", marginRight: 10 }}
                            >
                                <Upload {...attachmentUploadProps}>
                                    <i className="alpha-icon xs add-attachment" />
                                </Upload>
                            </span>
                        </>
                    ) : null}
                </div>

                <div style={{ marginBottom: "12px" }}>
                    <Dragger
                        {...attachmentUploadProps}
                        openFileDialogOnClick={!store.hasFiles}
                    >
                        {store.hasFiles ? null : (
                            <span>
                                Drop files to attach or{" "}
                                <span
                                    style={{
                                        fontSize: "14px",
                                        color: "#273C75",
                                    }}
                                >
                                    browse
                                </span>
                            </span>
                        )}
                        {store.taskAttachments &&
                            store.taskAttachments.map((a) => (
                                <TaskAttachment
                                    fileName={a.name || ""}
                                    fileSizeBytes={a.size}
                                    uploadDate={new Date().toISOString()}
                                    key={a.uid}
                                    onRemove={() => {
                                        removeAttachment(a);
                                    }}
                                />
                            ))}
                    </Dragger>
                </div>
            </div>
        </div>
    );
};

export default observer(TaskCreateForm);

import * as React from "react";
import { useLocalObservable } from "mobx-react-lite";
import { ErrorStore, ProjectsStore, useStore } from "../../common/stores";
import TaskStatusesService from "../services/TaskStatusesService";
import TaskStatusesVisualStore from "./TaskStatusesVisualStore";
import TaskTypesVisualStore from "../../task_types/stores/TaskTypesVisualStore";
import { useTaskTypesStore } from "../../task_types/stores/context";
import { ActionService } from "../../administration/services/ActionService";
import TasksRootStore from "../../tasks/stores/TasksRootStore";
import { useTasksStore } from "src/modules/tasks/stores/context";

function createStore(
    errorStore: ErrorStore,
    taskTypesStore: TaskTypesVisualStore,
    projectStore: ProjectsStore,
    tasksRootStore: TasksRootStore,
) {
    const service = new TaskStatusesService();
    const actionService = new ActionService();
    const store = new TaskStatusesVisualStore(
        projectStore,
        service,
        taskTypesStore,
        tasksRootStore,
        actionService,
        errorStore,
    );
    return {
        store,
    };
}

const storeContext = React.createContext<ReturnType<typeof createStore> | null>(
    null,
);
type Props = {
    children: React.ReactNode;
};

export const StoreProvider: React.FC<Props> = ({ children }) => {
    const root = useStore();
    const taskTypes = useTaskTypesStore();
    const tasksRootStore = useTasksStore().tasksRootStore;
    const store = useLocalObservable(() =>
        createStore(
            root.errorStore,
            taskTypes.store,
            root.projectsStore,
            tasksRootStore,
        ),
    );
    return (
        <storeContext.Provider value={store}>{children}</storeContext.Provider>
    );
};

export const useTaskStatusesStore = () => {
    const store = React.useContext(storeContext);
    if (!store) {
        // this is especially useful in TypeScript so you don't need to be checking for null all the time
        throw new Error("You have forgot to use StoreProvider, shame on you.");
    }

    return store;
};

import { Input, Modal, Button, Upload, Form } from "antd";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { RcFile } from "antd/lib/upload";
import UserProfileModel from "../models/UserProfileModel";
import { PlusOutlined } from "@ant-design/icons";
import { UserProfileStore } from "../../common/stores";

type Props = {
    store: UserProfileStore;
};

const UserProfileEditForm: React.FC<Props> = ({ store }) => {
    const [imageUrl, setimageUrl] = React.useState("");
    const [form] = Form.useForm();
    const handleSubmit = () => {
        form.validateFields()
            .then((values) => {
                const model = new UserProfileModel(
                    values.firstName,
                    values.lastName,
                    values.email,
                    values.timezone,
                    values.avatarIcon ? values.avatarIcon.file : null,
                );
                store!.updateUserProfile(model);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleCancel = () => {
        store!.setEditUserProfileVisible(false);
        setimageUrl("");
    };

    const beforeUpload = (file: RcFile) => {
        const reader = new FileReader();

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        reader.onload = function (e: any) {
            setimageUrl(e.target.result);
        };

        reader.readAsDataURL(file);
        return false;
    };

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div className="ant-upload-text">Upload</div>
        </div>
    );

    const isFieldDisabled = store.userProfile && store.userProfile.isDomainUser;

    return (
        <Modal
            title="My Profile"
            open={store!.editUserProfileVisible}
            onCancel={handleCancel}
            maskClosable={false}
            destroyOnClose
            closable={false}
            className="alpha-modal"
            footer={[
                <Button
                    className="light alpha-dialog-button"
                    size="large"
                    data-id="iota-add-connection-dialog-cancel"
                    key="back"
                    onClick={handleCancel}
                >
                    Cancel
                </Button>,
                <Button
                    className="alpha-dialog-button"
                    size="large"
                    data-id="iota-add-connection-dialog-submit"
                    key="submit"
                    type="primary"
                    onClick={handleSubmit}
                >
                    Submit
                </Button>,
            ]}
            zIndex={10000}
        >
            <Form
                form={form}
                className="alpha-form"
                layout="vertical"
                initialValues={{
                    timezone: store!.userProfile?.timezone,
                    firstName: store!.userProfile?.firstName,
                    lastName: store!.userProfile?.lastName,
                    email: store!.userProfile?.email,
                }}
            >
                <Form.Item label={<span>Avatar</span>} name="avatarIcon">
                    <Upload
                        name="application-icon"
                        accept=".jpeg,.jpg,.png"
                        listType="picture-card"
                        className="icon-uploader"
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                    >
                        {imageUrl || store.imageUrl ? (
                            <img
                                src={imageUrl || store.imageUrl}
                                alt="application-icon"
                            />
                        ) : (
                            uploadButton
                        )}
                    </Upload>
                </Form.Item>
                <Form.Item
                    label={
                        <span className="dialog-field-label">First Name</span>
                    }
                    name="firstName"
                    rules={[
                        {
                            required: true,
                            message: "Input First Name please.",
                        },
                    ]}
                >
                    <Input disabled={isFieldDisabled} />
                </Form.Item>
                <Form.Item
                    label={
                        <span className="dialog-field-label">Last Name</span>
                    }
                    name="lastName"
                    rules={[
                        {
                            required: true,
                            message: "Input Last Name please.",
                        },
                    ]}
                >
                    <Input disabled={isFieldDisabled} />
                </Form.Item>
                <Form.Item
                    label={<span className="dialog-field-label">Email</span>}
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: "Input Email please.",
                        },
                        {
                            pattern:
                                /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/g,
                            message: "Input valid email address please.",
                        },
                    ]}
                >
                    <Input disabled={isFieldDisabled} />
                </Form.Item>
                {/* <Form.Item
                {...formItemLayout}
                label="Timezone"
            >
            {getFieldDecorator('timezone', {
                rules: [{
                required: false, message: 'Please input timezone!',
                }],
            })(
                <Input />
            )}
            </Form.Item> */}
            </Form>
        </Modal>
    );
};

export default observer(UserProfileEditForm);

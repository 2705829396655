import { ColumnsFilter, UserProfile } from "../../common/services/types";

export type TaskPrivacyType = "LimitedAccess" | "OnlyMe" | "Everyone";
export type LinkType = "Relation" | "Duplicate" | "Block" | "Priority";
export type ActionStatus = "Started" | "Completed" | "Failed";

export class TaskBase {
    id: string;
    name: string;
    createDate: string;
    updateDate: string;
    createdBy: string;
    updatedBy: string;
    assignedTo: string;
    priority: string;
    packageSetId: string;
    projectId: string;
    status: string;
    dueDate: string;
    reminder: string;
    taskType: string;
    alphaId: AlphaId;
    description?: string;
    isViewed?: boolean;
    taskId: string;
    isManuallyCreated?: boolean;
    isDone: boolean;
    errors: TaskError[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    metadata?: { [key: string]: any };
}

export class Task extends TaskBase {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    statistics: { [key: string]: any };
    sessionIds: string[];
    followers: string[];
    statusName: string;
    attachmentsCount: number;
    sharedWith: string[] | null;
    accessType: TaskPrivacyType;
    parentTask?: ParentTaskMeta;
    subTasksMeta?: SubtaskMeta[];
}

export interface SubtaskMeta {
    subtaskId: string;
    name: string;
    statusId: string;
    statusName: string;
    alphaId: AlphaId;
    assignedTo: string | null;
}

export interface ParentTaskMeta {
    taskId: string;
    alphaId: AlphaId;
    name: string;
}

export type TaskModel = Task;

export interface TaskListModel extends TaskModel {
    isSelected?: boolean;
}

export interface TaskCommentModel {
    id: string;
    userId: string;
    userName: string;
    createdDate: string;
    updatedDate: string;
    body: string;
    taskId: string;
}

export interface TaskActivity {
    id: string;
    taskId: string;
    activityType: string;
    doneBy: string;
    message: string;
    createdTime: string;
}

export interface TaskActivitiesResult {
    getTaskActivities: TaskActivity[];
}

export interface SortModel {
    field: string;
    order: "ascend" | "descend";
}

export interface PackageSetPreviewModel {
    id: string;
    name: string;
    packages: PackagePreviewModel[];
}

export class PackageBase {
    fileName: string;
    filePath: string;
    contentType: string;
    fileSizeBytes: number;
    uploadedTime: string;
    state: string;
    extension: string;
    alphaPackageId?: string;
    sessionId?: string;
}

export class PackagePreviewModel extends PackageBase {
    id: string;
    indexDate: string;
}

export interface AttachmentUpdateModel {
    packageSetId: string;
    attachmentId: string | undefined;
    action: "add" | "remove";
}

export interface FollowersChangeModel {
    taskId: string;
    followers: string[];
}

export interface AssignedToChangesModel {
    taskId: string;
    assignedTo: string;
}

export interface EmailMetadata {
    from: string | undefined;
    to: string[] | undefined;
    body: string | undefined;
    subject: string | undefined;
    date: string | undefined;
}

export interface UserModel {
    userName: string;
    id: string;
}

export interface TasksData {
    tasks: TaskModel[];
    tasksCount: number;
    userColumnsFilter: {
        filters: ColumnsFilter[];
    } | null;
    widgetFilters: ColumnsFilter[];
    widgetName?: string;
    includeDoneTasks: boolean | null;
}

export interface TasksDataResult {
    getTasksData: TasksData;
}

export interface PackageSetPackagesResult {
    getPackagesFromSet: PackagePreviewModel[];
}

export interface TaskCommentsResult {
    getTaskComments: TaskCommentModel[];
}

export interface TaskPrefix {
    id: string;
    name: string;
    projectId: string;
    counter: number;
}

export interface TaskPrefixForProjectResult {
    getTaskPrefixByProjectId: TaskPrefix | null;
}

export interface GetUserProfileByIdResult {
    getUserProfileById: UserProfile;
}
export interface ColumnModel {
    name: string;
    isVisible: boolean;
    isMetaData: boolean;
    width: number;
}

export class ColumnModelBase {
    isVisible: boolean;
    isMetaData: boolean;
    width: number;
}

export class MetadataColumn extends ColumnModelBase {
    metaDataId: string;
}

export class DefaultColumn extends ColumnModelBase {
    name: string;
}

export type TaskError = {
    id: string;
    source: string;
    taskId: string;
    description: string;
    details: ErrorDetails;
    createdAt: string;
};

export type MetadataPushMessage = {
    taskId: string;
    fields: { [key: string]: string };
};

export type ErrorDetails = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any[];
};

export type QueryRequestData = {
    name: string;
    details: string;
    email: string;
    phone: string;
    phoneType: string;
    fileData: { packageId: string; name: string; contentType: string }[];
};

export interface TaskLookupItem {
    id: string;
    name: string;
    alphaId?: AlphaId;
    isSubtask: boolean;
}

export interface AlphaId {
    prefix: string;
    index: number;
}

export interface TaskLink {
    id: string;
    taskId: string;
    taskIdentifier?: AlphaId;
    taskTitle: string;
    linkType: LinkType;
    linkedTaskId: string;
    linkedTaskIdentifier?: AlphaId;
    linkedTaskTitle: string;
    comments?: string;
    isSubtask: boolean;
}

export type TaskRelashionshipOption = {
    value: string;
    label: string;
    type: LinkType;
    directional: boolean;
    inverse?: boolean;
};

export type SubtaskFromTemplates = {
    name?: string;
    description?: string;
};

export class TaskRelationships {
    static options: TaskRelashionshipOption[] = [
        {
            value: "relation",
            label: "Relates to",
            type: "Relation",
            directional: false,
        },
        {
            value: "duplicate",
            label: "Duplicates",
            type: "Duplicate",
            directional: false,
        },
        {
            value: "block-1",
            label: "Blocks",
            type: "Block",
            directional: true,
            inverse: false,
        },
        {
            value: "block-2",
            label: "Blocked by",
            type: "Block",
            directional: true,
            inverse: true,
        },
        {
            value: "priority-1",
            label: "Precedes",
            type: "Priority",
            directional: true,
            inverse: false,
        },
        {
            value: "priority-2",
            label: "Follows",
            type: "Priority",
            directional: true,
            inverse: true,
        },
    ];

    static getRelationInfo(link: TaskLink, currentTaskId: string) {
        return this.options.find(
            (x) =>
                x.type === link.linkType &&
                (!x.directional ||
                    x.inverse === (currentTaskId !== link.taskId)),
        );
    }

    static getOption(value: string) {
        return this.options.find((x) => x.value === value);
    }
}

export interface ActionEvent {
    id: string;
    userId: string;
    actionDefinitionId: string;
    actionType: string;
    taskId: string;
    packageId?: string;
    status: ActionStatus;
    message?: string;
    result?: unknown;
    triggeredOn: string;
    completedOn?: string;
    fields: unknown;
}

export interface ActionCompletedResult {
    message: string;
    event: ActionEvent;
}

export interface ActionTriggeredResult {
    event: ActionEvent;
}

import {
    action,
    computed,
    makeObservable,
    observable,
    runInAction,
} from "mobx";
import {
    AssignedToChangesModel,
    ColumnModel,
    DefaultColumn,
    MetadataColumn,
    SortModel,
    TaskListModel,
    TaskModel,
    TaskPrivacyType,
    UserModel,
} from "../types";
import TasksRootStore from "./TasksRootStore";
import {
    ColumnResult,
    TaskListLayoutResult,
} from "../../common/services/types";
import { ALL_PROJECTS } from "../screens/TasksPage";
import { MetadataDefinition } from "../../administration/types/Metadata";
import { ErrorStore } from "../../common/stores";
import _, { DebouncedFunc } from "lodash";
import { Observable, Subject } from "rxjs";
import { TaskService } from "../services";
import { message, TableColumnsType } from "antd";
import tasksPushClient from "../services/TaskPushClient";

const defaultColumnsConfig = [
    {
        isVisible: true,
        name: "Identifier",
        id: "alphaId",
        isMetaData: false,
        width: 100,
    },
    {
        isVisible: true,
        name: "Name",
        id: "name",
        isMetaData: false,
        width: 200,
    },
    {
        isVisible: false,
        name: "Project",
        id: "project",
        isMetaData: false,
        width: 100,
    },
    {
        isVisible: true,
        name: "Type",
        id: "type",
        isMetaData: false,
        width: 100,
    },
    {
        isVisible: true,
        name: "Status",
        id: "statusName",
        isMetaData: false,
        width: 100,
    },
    {
        isVisible: true,
        name: "Due Date",
        id: "dueDate",
        isMetaData: false,
        width: 100,
    },
    {
        isVisible: true,
        name: "Attachments",
        id: "attachments",
        isMetaData: false,
        width: 100,
    },
    {
        isVisible: true,
        name: "Owner",
        id: "owner",
        isMetaData: false,
        width: 200,
    },
    {
        isVisible: true,
        name: "Date Created",
        id: "dateCreated",
        isMetaData: false,
        width: 100,
    },
    {
        isVisible: true,
        name: "Assigned To",
        id: "assignedTo",
        isMetaData: false,
        width: 200,
    },
    {
        isVisible: true,
        name: "Priority",
        id: "priority",
        isMetaData: false,
        width: 100,
    },
];

export default class TasksGridVisualStore {
    selectedProject: string;

    defaultLayoutProject: string;

    selectedRows: string[] = [];

    currentPage: number = 1;

    pageSize: number = 50;

    isMetadataViewEnabled: boolean = false;

    isMainTasksViewEnabled: boolean = false;

    isDoneTasksViewEnabled: boolean = false;

    taskColumns: ColumnModel[] = defaultColumnsConfig;

    userColumnsConfig: { [key: string]: ColumnResult[] } = {};

    tableMetaData: MetadataDefinition[] = [];

    isInitialized: boolean = false;

    commonUsersLoading: boolean = false;

    searchTerm: string | undefined = undefined;

    selectedRowModels: TaskModel[] = [];

    commonUsersInSelectedTasks: UserModel[] = [];

    taskAssignDialogVisible: boolean = false;

    submittingTaskBatchAssignment: boolean = false;

    isDoneTasksToggleDisabled: boolean = false;

    currentWidgetName: string | null;

    currentWidgetId: string | undefined;

    currentWidgetValue: string | undefined;

    assignedToChangesSubject = new Subject<AssignedToChangesModel>();

    sortOrder: SortModel = {
        field: "createDate",
        order: "descend",
    };

    tableColumns: TableColumnsType<TaskListModel> = [];

    isBulkUpdateDialogOpen: boolean = false;

    private debouncedSetLayoutDefault: DebouncedFunc<() => Promise<void>>;

    private taskChanges: Observable<TaskModel>;

    constructor(
        private tasksRootStore: TasksRootStore,
        private errorStore: ErrorStore,
        private tasksService: TaskService,
    ) {
        makeObservable<
            TasksGridVisualStore,
            "setColumns" | "handleCommonUsersLoading"
        >(this, {
            selectedProject: observable,
            selectedRowModels: observable,
            isMetadataViewEnabled: observable,
            isMainTasksViewEnabled: observable,
            isDoneTasksViewEnabled: observable,
            userColumnsConfig: observable,
            taskColumns: observable,
            tableMetaData: observable,
            searchTerm: observable,
            selectedRows: observable,
            commonUsersInSelectedTasks: observable,
            sortOrder: observable,
            commonUsersLoading: observable,
            taskAssignDialogVisible: observable,
            submittingTaskBatchAssignment: observable,
            currentWidgetName: observable,
            tableColumns: observable,
            isBulkUpdateDialogOpen: observable,
            setIsBulkUpdateDialogOpen: action,
            setTableColumns: action,
            setTaskAssignDialogVisible: action.bound,
            setTaskListLayoutAsDefault: action.bound,
            handleColumnsReorder: action.bound,
            handleVisibiltyOnChange: action.bound,
            removeDefaultLayout: action.bound,
            handleProjectChange: action.bound,
            setIsMetadataViewEnabled: action.bound,
            setIsMainTasksViewEnabled: action.bound,
            setIsDoneTasksViewEnabled: action,
            handleCommonUsersLoading: action.bound,
            setSortOrder: action.bound,
            setSelectedRows: action.bound,
            setTableMetaData: action,
            setColumns: action.bound,
            setSelectedProject: action,
            setSearchTerm: action.bound,
            setSelectedRowModels: action.bound,
            deleteSelectedTasks: action.bound,
            loadCommonUsersInProjects: action.bound,
            assignTasksToUser: action.bound,
            removeSearchTerm: action,
            setLayoutDefaultProject: action,
            resetAllFilters: action.bound,
            setIzInitialized: action,
            filteredTaskColumns: computed,
            allowBatchAssign: computed,
            userProfilePictures: computed,
            tasks: computed,
            isGridView: computed,
            layoutIsLoading: computed,
        });

        this.debouncedSetLayoutDefault = _.debounce(
            () => this.setTaskListLayoutAsDefault(),
            200,
        );

        const { pushClient } = tasksPushClient;

        const tasksObs = pushClient.createTaskListener().publish();
        this.taskChanges = tasksObs.map((r) => r);
        tasksObs.connect();
        this.taskChanges.subscribe(() => this.tasksRootStore.loadTasks());
    }

    get filteredTaskColumns() {
        return this.isMetadataViewEnabled
            ? this.taskColumns.filter((x) => x.isMetaData)
            : this.taskColumns;
    }

    get currentColsConfigKey() {
        return `${this.selectedProject}-${this.isMetadataViewEnabled ? "MetaView" : "RegularView"}`;
    }

    get currentProjectCols() {
        return this.userColumnsConfig[this.currentColsConfigKey];
    }

    get allowBatchAssign() {
        return (
            this.commonUsersInSelectedTasks &&
            this.commonUsersInSelectedTasks.length &&
            !this.commonUsersLoading
        );
    }

    get allUsersFullNameResolver() {
        return this.tasksRootStore.allUsersFullNameResolver;
    }

    get userProfiles() {
        return this.tasksRootStore.userProfiles;
    }

    get usersLoaded() {
        return this.tasksRootStore.usersLoaded;
    }

    get userProfilePictures() {
        return this.tasksRootStore.userProfilePictures;
    }

    get currentUserId() {
        return this.tasksRootStore.currentUserId;
    }

    get tasks() {
        return this.tasksRootStore.tasks;
    }

    get tasksLoading() {
        return this.tasksRootStore.tasksLoading;
    }

    get layoutIsLoading() {
        return this.tasksRootStore.layoutIsLoading;
    }

    get tasksCount() {
        return this.tasksRootStore.tasksCount;
    }

    get usersInProject() {
        return this.tasksRootStore.usersInProject;
    }

    get loadingUsers() {
        return this.tasksRootStore.loadingUsers;
    }

    get isGridView() {
        return !!this.currentWidgetName;
    }

    get taskStatuses() {
        return this.tasksRootStore.taskStatuses;
    }

    get selectedTasksShareSameStatus() {
        const selectedTasks = this.tasks.filter((t) =>
            this.selectedRows.includes(t.id),
        );
        return selectedTasks.every((s) => s.status === selectedTasks[0].status);
    }

    get selectedTasksShareSameOwner() {
        const selectedTasks = this.tasks.filter((t) =>
            this.selectedRows.includes(t.id),
        );
        return selectedTasks.every((s) => s.createdBy === this.currentUserId);
    }

    get projectsOfSelectedTasks() {
        return this.selectedRows.map(
            (id) => this.tasks.find((t) => t.id === id)!.projectId,
        );
    }

    get taskStatusesForBulkUpdate() {
        if (this.selectedTasksShareSameStatus) {
            const selectedTask = this.tasks.find((t) =>
                this.selectedRows.includes(t.id),
            )!;

            const currentStatus = this.taskStatuses.find(
                (t) => t.id === selectedTask.status,
            )!;
            const taskType = currentStatus?.typeId;
            let taskStatusesFiltered = this.taskStatuses
                .filter((t) => t.typeId === taskType)
                .slice();

            if (!currentStatus?.transitions?.length) {
                return [];
            }

            // If there are task transitions, show only allowed target statuses
            if (currentStatus?.transitions?.length) {
                taskStatusesFiltered = taskStatusesFiltered.filter((s) =>
                    currentStatus.transitions!.find(
                        (st) => st.targetStatusId === s.id,
                    ),
                );
            }

            if (this.currentUserId) {
                taskStatusesFiltered = taskStatusesFiltered.filter(
                    (s) =>
                        !s.assignedUserIds ||
                        !s.assignedUserIds.length ||
                        s.assignedUserIds.indexOf(this.currentUserId) !== -1,
                );
            }

            return taskStatusesFiltered;
        }

        return [];
    }

    async init(
        widgetId: string | undefined,
        activityType: string | undefined,
        period: string | null,
        projectId: string | undefined,
        value: string | undefined,
    ) {
        this.currentWidgetId = widgetId;
        this.currentWidgetValue = value;
        if (!this.isInitialized) {
            if (widgetId && !value) {
                this.setIsDoneTasksViewEnabled(true);
                this.getTasksByGroupedWidgetId(widgetId, projectId!);
            } else if (value) {
                this.setIsDoneTasksViewEnabled(true);
                this.getTasksByPieChartSection(widgetId!, projectId!, value);
            } else if (activityType) {
                this.setIsDoneTasksViewEnabled(true);
                this.getTasksByActivityType(activityType, projectId!, period!);
            } else {
                this.loadTasks();
            }
            if (projectId) {
                this.setSelectedProject(projectId);
            }
            this.setIzInitialized(true);
        }
        // Sync metadata
        if (this.selectedProject !== ALL_PROJECTS && this.isInitialized) {
            await this.tasksRootStore.loadMetadata(this.selectedProject);
            this.syncMetadata();
            runInAction(() => {
                this.reconfigureColumns();
                this.setTableMetaData(this.tasksRootStore.metadataDefinitions);
            });
        }
    }

    setIzInitialized(val: boolean) {
        this.isInitialized = val;
    }

    setCurrentPage(page: number) {
        this.currentPage = page;
    }

    setPageSize(size: number) {
        this.pageSize = size;
    }

    setSelectedProject(project: string) {
        this.selectedProject = project;
    }

    resetToDefaultProject() {
        this.selectedProject = this.defaultLayoutProject;
    }

    setLayoutDefaultProject(project: string) {
        this.defaultLayoutProject = project;
    }

    setTableMetaData(tableMetaData: MetadataDefinition[]) {
        this.tableMetaData = tableMetaData;
    }

    setSearchTerm(term: string | undefined) {
        this.searchTerm = term;
    }

    setSortOrder(sortOrder: SortModel) {
        this.sortOrder = sortOrder;
    }

    setTaskAssignDialogVisible(visible: boolean) {
        this.taskAssignDialogVisible = visible;
    }

    setTableColumns(cols: TableColumnsType<TaskListModel>) {
        this.tableColumns = cols;
    }

    setIsBulkUpdateDialogOpen(val: boolean) {
        this.isBulkUpdateDialogOpen = val;
    }

    async handlePrivacyChange(
        task: TaskListModel,
        value: TaskPrivacyType,
        sharedWith: string[],
    ) {
        this.tasksRootStore.handlePrivacyChange(task, value, sharedWith);
    }

    removeSearchTerm() {
        this.searchTerm = "";
    }

    async loadTasks(excludeFilters?: boolean) {
        this.tasksRootStore.loadTasks(excludeFilters);
    }

    async updateField(
        taskId: string,
        fieldName: string,
        value: unknown,
        callback: (() => void) | undefined = undefined,
    ) {
        await this.tasksRootStore.updateField(
            taskId,
            fieldName,
            value,
            callback,
        );
        if (
            this.tasksRootStore.taskPreview &&
            this.tasksRootStore.taskPreview.id === taskId
        ) {
            const localFieldName =
                fieldName.charAt(0).toLowerCase() + fieldName.slice(1);
            this.tasksRootStore.taskPreview[localFieldName] = value;
        }
    }

    loadUsersForProject(id: string) {
        this.tasksRootStore.loadUsersForProject(id);
    }

    unSelectRow(taskId: string) {
        this.selectedRows = this.selectedRows.filter((s) => s !== taskId);
    }

    async resetAllFilters() {
        await this.tasksRootStore.resetAllFilters();
        this.setCurrentWidgetName(null);
    }

    async handleProjectChange(
        projectId: string,
        widgetId: string | undefined,
        widgetValue: string | undefined,
        activityType: string | undefined,
        period: string | undefined,
    ) {
        this.setSelectedProject(projectId);
        if (widgetId && !widgetValue) {
            await this.getTasksByGroupedWidgetId(widgetId, projectId);
        } else if (widgetValue) {
            await this.getTasksByPieChartSection(
                widgetId!,
                projectId,
                widgetValue,
            );
        } else if (activityType) {
            await this.getTasksByActivityType(activityType, projectId, period!);
        } else {
            await this.resetAllFilters();
        }
        if (projectId == ALL_PROJECTS) {
            runInAction(() => {
                this.setIsMetadataViewEnabled(false);
                this.setTableMetaData([]);
            });
        } else {
            this.tasksRootStore.setTasksIsLoading(true);
            await this.tasksRootStore.loadMetadata(projectId);
            runInAction(() => {
                this.syncMetadata();
                this.reconfigureColumns();
                this.setTableMetaData(this.tasksRootStore.metadataDefinitions);
            });
            this.tasksRootStore.setTasksIsLoading(false);
        }
        this.setTaskListLayoutAsDefault();
    }

    setIsMetadataViewEnabled(isEnabled: boolean) {
        this.isMetadataViewEnabled = isEnabled;
        this.syncMetadata();
        this.reconfigureColumns();
    }

    setIsMainTasksViewEnabled(isEnabled: boolean) {
        this.isMainTasksViewEnabled = isEnabled;
    }

    setIsDoneTasksViewEnabled(isEnabled: boolean | null) {
        this.isDoneTasksViewEnabled = !!isEnabled;
    }

    forceDoneTasksViewEnabled(isEnabled: boolean | null) {
        if (isEnabled !== null) {
            this.setIsDoneTasksToggleDisabled(true);
            this.setIsDoneTasksViewEnabled(isEnabled);
        }
    }

    setIsDoneTasksToggleDisabled(isDisabled: boolean) {
        this.isDoneTasksToggleDisabled = isDisabled;
    }

    setSelectedRows(rows: string[]) {
        this.selectedRows = rows;
    }

    setCurrentWidgetName(name: string | null) {
        this.currentWidgetName = name;
    }

    getProjectName = (id: string) => {
        return this.tasksRootStore.getProjectName(id);
    };

    getTaskName = (id: string) => {
        return this.tasksRootStore.getTaskName(id);
    };

    reconfigureColumns() {
        if (this.currentProjectCols) {
            let cols = this.currentProjectCols;
            if (this.selectedProject === ALL_PROJECTS) {
                cols = this.currentProjectCols.filter((c) => !c.isMetaData);
            }
            const columnsToSet = cols.map((u) => {
                if (u.isMetaData) {
                    return {
                        isMetaData: true,
                        isVisible: u.isVisible,
                        name:
                            this.tasksRootStore.metadataDefinitions.find(
                                (m) =>
                                    m.id === (u as MetadataColumn).metaDataId,
                            )?.title || "",
                        width: u.width,
                    };
                }
                return u as DefaultColumn;
            });
            this.setColumns(columnsToSet.filter((c) => !!c.name));
        } else {
            const metaColumns =
                this.selectedProject === ALL_PROJECTS
                    ? []
                    : this.tasksRootStore.metadataDefinitions.map((m) => ({
                          isMetaData: true,
                          name: m.title,
                          isVisible: this.isMetadataViewEnabled
                              ? m.isVisible
                              : false,
                          width: 100,
                      }));
            if (this.isMetadataViewEnabled) {
                this.setColumns([
                    defaultColumnsConfig[0],
                    defaultColumnsConfig[1],
                    ...metaColumns,
                ]);
            } else {
                this.setColumns([...defaultColumnsConfig, ...metaColumns]);
            }
        }
    }

    getUserProfilePicture(userId: string) {
        this.tasksRootStore.getUserProfilePicture(userId);
    }

    handleColumnsReorder(dragIndex: number, dropIndex: number) {
        const draggable = this.isMetadataViewEnabled
            ? this.taskColumns[dragIndex + 2]
            : this.taskColumns[dragIndex];
        const columns = this.taskColumns.slice();
        columns.splice(
            this.isMetadataViewEnabled ? dragIndex + 2 : dragIndex,
            1,
        );
        columns.splice(
            this.isMetadataViewEnabled ? dropIndex + 2 : dropIndex,
            0,
            draggable,
        );
        this.taskColumns = columns;
        const transformedCols = this.transformTableColumns(columns);
        this.userColumnsConfig[this.currentColsConfigKey] = transformedCols;
        this.setTaskListLayoutAsDefault();
    }

    handleVisibiltyOnChange(name: string, isChecked: boolean) {
        const index = this.taskColumns.findIndex((x) => x.name === name);
        const cols = this.taskColumns.slice();
        cols[index].isVisible = isChecked;
        this.taskColumns = cols;
        const transformedCols = this.transformTableColumns(cols);
        this.userColumnsConfig[this.currentColsConfigKey] = transformedCols;
        this.setTaskListLayoutAsDefault();
    }

    handleColumnWidthChange(name: string, width: number) {
        const index = this.taskColumns.findIndex((x) => x.name === name);
        this.taskColumns[index].width = width;
        this.debouncedSetLayoutDefault();
    }

    syncMetadata() {
        if (this.currentProjectCols) {
            const metaColumns = this.currentProjectCols.filter(
                (c) => c.isMetaData,
            ) as MetadataColumn[];
            const currentMetadataIds = metaColumns.map((m) => m.metaDataId);
            // Adding new metadata columns;
            const newMetas = this.tasksRootStore.metadataDefinitions.filter(
                (m) => !currentMetadataIds.includes(m.id),
            );
            if (newMetas.length) {
                this.userColumnsConfig[this.currentColsConfigKey].push(
                    ...newMetas.map((n) => ({
                        isMetaData: true,
                        isVisible: this.isMetadataViewEnabled
                            ? n.isVisible
                            : false,
                        metaDataId: n.id,
                        width: 100,
                    })),
                );
            }
            // Deleteting old metadata columns;
            const oldeMetaIndeces = metaColumns
                .map((c, i) =>
                    this.tasksRootStore.metadataDefinitions
                        .map((m) => m.id)
                        .includes(c.metaDataId)
                        ? -1
                        : i,
                )
                .filter((c) => c > -1)
                .sort((a, b) => a - b);
            if (oldeMetaIndeces.length) {
                for (const i of oldeMetaIndeces) {
                    const indexToDelete = this.userColumnsConfig[
                        this.currentColsConfigKey
                    ].findIndex(
                        (c) =>
                            (c as MetadataColumn).metaDataId ===
                            metaColumns[i].metaDataId,
                    );
                    this.userColumnsConfig[this.currentColsConfigKey].splice(
                        indexToDelete,
                        1,
                    );
                }
            }
        }
    }

    async setTaskListLayout(layout: TaskListLayoutResult) {
        try {
            if (layout && layout.selectedProjectId) {
                runInAction(() => {
                    if (this.tasks.length) {
                        this.setSelectedProject(ALL_PROJECTS);
                        this.setLayoutDefaultProject(ALL_PROJECTS);
                    } else {
                        this.setSelectedProject(layout.selectedProjectId!);
                        this.setLayoutDefaultProject(layout.selectedProjectId!);
                    }
                    this.userColumnsConfig = layout.columnsConfig;
                });
            } else {
                this.setSelectedProject(ALL_PROJECTS);
            }
            if (
                layout?.columnsConfig &&
                layout.columnsConfig[this.currentColsConfigKey] &&
                this.selectedProject === ALL_PROJECTS
            ) {
                const columns = layout.columnsConfig[
                    this.currentColsConfigKey
                ].filter((c) => !c.isMetaData) as DefaultColumn[];
                this.setColumns(columns);
            } else if (!this.taskColumns.length) {
                this.setColumns(defaultColumnsConfig);
            }
            if (layout?.pinnedTasks) {
                this.tasksRootStore.setPinnedTasks(layout.pinnedTasks);
            }
        } catch (err) {
            console.error(err);
        }
    }

    async setTaskListLayoutAsDefault() {
        try {
            const transformedCols = this.transformTableColumns(
                this.taskColumns,
            );
            const resp = await this.tasksService.saveLayoutConfig(
                this.selectedProject,
                this.isMetadataViewEnabled,
                transformedCols,
            );
            if (resp.isOk()) {
                this.userColumnsConfig[this.currentColsConfigKey] =
                    transformedCols;
            } else {
                console.error(resp.error);
            }
        } catch (err) {
            console.error(err);
        }
    }

    async removeDefaultLayout() {
        const resp = await this.tasksService.deleteLayoutConfig();
        if (resp.isOk()) {
            this.userColumnsConfig = {};
            if (!this.isMetadataViewEnabled) {
                if (this.selectedProject === ALL_PROJECTS) {
                    this.setColumns(defaultColumnsConfig);
                } else {
                    const metadataCols = this.taskColumns
                        .slice()
                        .filter((f) => f.isMetaData);
                    metadataCols.forEach((c) => (c.isVisible = false));
                    this.setColumns([...defaultColumnsConfig, ...metadataCols]);
                }
            } else {
                const cols = this.taskColumns.slice();
                cols.forEach((c) => (c.isVisible = true));
                this.setColumns(cols);
            }
        } else {
            console.error(resp.error);
        }
    }

    async getTasksByActivityType(
        activityType: string,
        projectId: string,
        period: string,
    ) {
        this.tasksRootStore.getTasksByActivityType(
            activityType,
            projectId,
            period,
            this.currentPage,
            this.isMainTasksViewEnabled,
            this.isDoneTasksViewEnabled,
            this.sortOrder.field,
            this.sortOrder.order,
        );
    }

    async getTasksByGroupedWidgetId(widgetId: string, projectId: string) {
        this.tasksRootStore.getTasksByGroupedWidgetId(
            widgetId,
            projectId,
            this.currentPage,
            this.isMainTasksViewEnabled,
            this.isDoneTasksViewEnabled,
            this.sortOrder.field,
            this.sortOrder.order,
        );
    }

    async getTasksByPieChartSection(
        widgetId: string,
        projectId: string,
        value: string,
    ) {
        this.tasksRootStore.getTasksByPieChartSection(
            widgetId,
            projectId,
            value,
            this.currentPage,
            this.isMainTasksViewEnabled,
            this.isDoneTasksViewEnabled,
            this.sortOrder.field,
            this.sortOrder.order,
        );
    }

    async deleteSelectedTasks() {
        if (this.selectedRows && this.selectedRows.length) {
            try {
                const tasksRemoved: string[] = [];
                for (const row of this.selectedRows) {
                    await this.tasksRootStore.updateField(
                        row,
                        "Deleted",
                        true,
                        () => {
                            runInAction(() => {
                                tasksRemoved.push(row);
                                this.tasksRootStore.togglePreview(undefined);
                            });
                        },
                    );
                }
                runInAction(() => {
                    this.tasksRootStore.setTasksList(
                        this.tasks.filter(
                            (t) => tasksRemoved.indexOf(t.id) === -1,
                        ),
                    );
                    this.selectedRows = this.selectedRows.filter(
                        (r) => tasksRemoved.indexOf(r) === -1,
                    );
                });
            } catch (err) {
                this.errorStore.addBasicError(err);
            }
        }
    }

    setSelectedRowModels(rows: TaskModel[]) {
        const currentTaskProjects = [
            ...new Set(this.selectedRowModels.map((r) => r.projectId)),
        ];
        const newTaskProjects = [...new Set(rows.map((r) => r.projectId))];
        this.selectedRowModels = rows;
        this.handleCommonUsersLoading(currentTaskProjects, newTaskProjects);
    }

    async loadCommonUsersInProjects(projectIds: string[]) {
        runInAction(() => {
            this.commonUsersLoading = true;
        });
        const response =
            await this.tasksService.getCommonUsersInProjects(projectIds);
        response
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .map((commonUsers: any) => {
                runInAction(() => {
                    this.commonUsersInSelectedTasks = commonUsers.map(
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        (u: any) => {
                            return {
                                id: u.id,
                                userName: u.firstName
                                    ? `${u.firstName} ${u.lastName}`
                                    : u.userName,
                            };
                        },
                    );
                });
            })
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .mapErr((err: any) => this.errorStore.addError(err.data));

        runInAction(() => {
            this.commonUsersLoading = false;
        });
    }

    async assignTasksToUser(taskIds: string[], userId: string) {
        runInAction(() => {
            this.submittingTaskBatchAssignment = true;
        });
        const resp = await this.tasksService.assignTasksToUser(taskIds, userId);
        resp.map(() => {
            runInAction(() => {
                const newTasks = this.tasks.slice();
                for (const taskId of taskIds) {
                    const task = newTasks.find((t) => t.id === taskId);

                    if (task) {
                        const index = newTasks.indexOf(task);
                        newTasks[index].assignedTo = userId;
                    }

                    this.assignedToChangesSubject.next({
                        taskId,
                        assignedTo: userId,
                    });
                }

                this.tasksRootStore.setTasksList(newTasks);
                this.tasksRootStore.setAssignedUserInPreview(userId, taskIds);
            });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }).mapErr((err: any) => this.errorStore.addError(err.data));

        runInAction(() => {
            this.submittingTaskBatchAssignment = false;
            this.taskAssignDialogVisible = false;
        });

        return resp.unwrapOr([]);
    }

    async updateFieldsBulk(fields: { [field: string]: unknown }) {
        const resp = await this.tasksService.updateFieldsBulk(
            fields,
            this.selectedRows,
        );
        resp.map(() => {
            message.success("Fields have been successfully updated");
            const { taskPreview } = this.tasksRootStore;
            if (taskPreview) {
                this.tasksRootStore.togglePreview(undefined);
            }
        }).mapErr((err) => this.errorStore.addError(err.data));
        this.loadTasks();
    }

    private transformTableColumns(columns: ColumnModel[]) {
        return columns.map((t) => {
            if (t.isMetaData) {
                return {
                    metaDataId: this.tasksRootStore.metadataDefinitions.find(
                        (d) => d.title === t.name,
                    )!.id,
                    isVisible: t.isVisible,
                    isMetaData: t.isMetaData,
                    width: t.width,
                };
            }
            return t;
        });
    }

    private setColumns(columns: ColumnModel[]) {
        this.taskColumns = columns;
    }

    private handleCommonUsersLoading(
        currentTaskProjects: string[],
        newSelectedTaskProjects: string[],
    ) {
        if (
            !_.isEqual(
                currentTaskProjects.sort(),
                newSelectedTaskProjects.sort(),
            )
        ) {
            this.loadCommonUsersInProjects(newSelectedTaskProjects);
        }
    }
}

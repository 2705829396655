import * as React from "react";

export const AccessDropdownItems = {
    Everyone: (
        <div className="task-access-dropdown-item">
            <i className="alpha-icon md everyone-access" />
            <div>
                Everyone
                <div className="description">
                    All people will have the access to this task.
                </div>
            </div>
        </div>
    ),
    LimitedAccess: (
        <div className="task-access-dropdown-item">
            <i className="alpha-icon md limited-access" />
            <div>
                Limited Access
                <div className="description">
                    Only selected people will have the access to this task.
                </div>
            </div>
        </div>
    ),
    OnlyMe: (
        <div className="task-access-dropdown-item">
            <i className="alpha-icon md lock" />
            <div>
                Only me
                <div className="description">
                    Only you will have the access to this task.
                </div>
            </div>
        </div>
    ),
};

import {
    action,
    runInAction,
    observable,
    computed,
    makeObservable,
} from "mobx";
import { TabModel } from "../types/TabModel";
import { TaskViewVisualStore, TaskCommentsStore } from "../../tasks/stores";
import { Subject } from "rxjs";
import DocumentVisualStore from "../../documents/stores/DocumentsVisualStore";
import ImageService from "../services/ImageService";
import { DocumentService } from "../services";
import ErrorStore from "./ErrorStore";

export default class TabsStore {
    tabs: TabModel[] = [];

    documentStores: { [id: string]: DocumentVisualStore } = {};

    taskStores: { [id: string]: TaskViewVisualStore } = {};

    taskCommentStores: { [id: string]: TaskCommentsStore } = {};

    dynamicUiLoadRetries: number = 0;

    tabUiLoadingSubject: Subject<string> = new Subject();

    sessionTabReloading:
        | { sessionId: string; packageName: string; packageId: string }
        | undefined = undefined;

    get selectedTab() {
        if (this.tabs) {
            return this.tabs.find((t) => t.isSelected);
        }
        return undefined;
    }

    get taskTabs() {
        if (this.tabs) {
            return this.tabs.filter((t) => t.type === "task");
        }
        return [];
    }

    get documentTabs() {
        if (this.tabs) {
            return this.tabs.filter((t) => t.type === "document");
        }
        return [];
    }

    get multidocumentTabs() {
        if (this.tabs) {
            return this.tabs.filter((t) => t.type === "multidocument");
        }
        return null;
    }

    constructor(private errorStore: ErrorStore) {
        makeObservable(this, {
            tabs: observable,
            sessionTabReloading: observable,
            selectedTab: computed,
            taskTabs: computed,
            documentTabs: computed,
            multidocumentTabs: computed,
            setSessionTabReloading: action.bound,
            addTab: action.bound,
            removeTab: action.bound,
            resetSelection: action.bound,
            selectTab: action.bound,
            setTabFullPreview: action.bound,
        });
    }

    addTaskStore(store: TaskViewVisualStore, taskId: string) {
        this.taskStores[taskId] = store;
    }

    removeTaskStore(taskId: string) {
        this.taskStores[taskId].removeSubscriptions();
        delete this.taskStores[taskId];
        delete this.taskCommentStores[taskId];
    }

    setSessionTabReloading(
        tab:
            | { sessionId: string; packageName: string; packageId: string }
            | undefined,
    ) {
        this.sessionTabReloading = tab;
    }

    async addTab(tab: TabModel) {
        if (!this.tabs.find((t) => t.id === tab.id && t.type === tab.type)) {
            if (tab.isSelected) {
                this.tabs.forEach((t) => (t.isSelected = false));
            }

            this.tabs.push(tab);
        } else {
            const existingTab = this.tabs.find(
                (t) => t.id === tab.id && t.type === tab.type,
            );
            existingTab!.isFullPreview = true;
            this.selectTab(this.tabs.indexOf(existingTab!));
        }
    }

    async addTabForPinnedTask(tab: TabModel) {
        const index = this.tabs.findIndex(
            (t) => t.id === tab.id && t.type === tab.type,
        );
        if (index == -1) {
            this.tabs.push(tab);
        } else {
            this.tabs.splice(index, 1);
        }
    }

    removeTab(index: number, softRemove: boolean = false) {
        const tab = this.tabs[index];
        if (softRemove && tab) {
            this.tabs.splice(index, 1);
            return;
        }
        this.tabs.splice(index, 1);
        if (tab) {
            if (this.taskStores[tab.id]) {
                delete this.taskStores[tab.id];
            }
            if (this.taskCommentStores[tab.id]) {
                delete this.taskCommentStores[tab.id];
            }
            if (this.documentStores[tab.id]) {
                delete this.documentStores[tab.id];
            }
        }
    }

    removeTabForTask(id: string, softRemove: boolean) {
        const index = this.tabs.findIndex(
            (t) => t.id === id && t.type === "task",
        );
        if (index > -1) {
            this.removeTab(index, softRemove);
        }
    }

    resetSelection() {
        this.tabs.forEach((t) => (t.isSelected = false));
    }

    selectTab(index: number) {
        if (this.tabs && this.tabs[index]) {
            runInAction(() => {
                this.tabs.forEach((t) => (t.isSelected = false));
                this.tabs[index].isSelected = true;
            });
        }
    }

    setTabFullPreview(id: string, value: boolean) {
        const index = this.tabs.findIndex((t) => t.id === id);
        this.tabs[index].isFullPreview = value;
    }

    addDocumentStore(packageId: string) {
        const imageService = new ImageService();
        const documentService = new DocumentService();
        this.documentStores[packageId] = new DocumentVisualStore(
            imageService,
            this.errorStore,
            documentService,
        );
    }
}

import { DashboardWidgetType } from "src/modules/administration/types/DashboardWidget";
import WidgetBase from "./WidgetBase";

type PieChartData = { [key: string]: { count: number; title: string } };
export default class PieChartWidget extends WidgetBase {
    chartData: PieChartData;

    constructor(
        id: string,
        name: string,
        type: DashboardWidgetType,
        chartData: PieChartData,
        projectId: string,
        isDefaultWidget: boolean,
    ) {
        super(id, type, name, projectId, isDefaultWidget);
        this.chartData = chartData;
    }
}

import { Row, Col, Button } from "antd";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { TaskApplicationsMenu } from "../../tasks/components";
import TaskViewVisualStore from "../../tasks/stores/TaskViewVisualStore";
import TaskActionControl from "../../tasks/components/TaskActionControl";
import TaskProgressControl from "../../tasks/components/TaskProgressControl";
import { useLocation, useNavigate } from "react-router";

type TaskPreviewHeaderProps = {
    store: TaskViewVisualStore;
    handleCollapseExpandTask: () => void;
    isFullView: boolean;
};

const TaskPreviewHeader: React.FC<TaskPreviewHeaderProps> = ({
    store,
    handleCollapseExpandTask,
    isFullView,
}) => {
    const navigate = useNavigate();
    const location = useLocation();

    const handleTaskRemove = async () => {
        await store.deleteTask();
        navigate("/tasks");
    };

    const taskStatusControl = (
        <div
            className="task-status-control"
            data-id="task-status-container-right-pandel"
            style={{ overflow: "hidden", textOverflow: "ellipsis" }}
        >
            <TaskProgressControl store={store} taskId={store.taskPreview!.id} />
        </div>
    );

    return (
        <>
            <Row
                gutter={8}
                style={{ marginLeft: isFullView ? 35 : undefined }}
                align={"middle"}
                className="task-preview-header"
            >
                {!isFullView && (
                    <Col>
                        {
                            <i
                                data-id="button-close"
                                className="alpha-icon xxs cross"
                                onClick={() => store.togglePreview(undefined)}
                            />
                        }
                    </Col>
                )}
                <Col>{taskStatusControl}</Col>
                <TaskActionControl store={store} />
                {isFullView && <Col flex="auto" />}
                <Col>
                    <Button
                        ghost
                        size="small"
                        style={{ border: "none" }}
                        title={isFullView ? "Collapse task" : "Expand task"}
                        onClick={handleCollapseExpandTask}
                        data-id="button-shrink-task-expand-panel"
                    >
                        <i
                            className={`alpha-icon xs ${isFullView ? "shrink" : "task-full-screen"} no-margin`}
                        />
                    </Button>
                </Col>
                <Col>
                    <Button
                        ghost
                        size="small"
                        style={{ border: "none" }}
                        title={store.isPinned ? "Unpin" : "Pin"}
                        onClick={() =>
                            store.handlePinClick(
                                location.pathname.endsWith(
                                    store.taskPreview?.id ?? "",
                                ),
                            )
                        }
                        data-id="button-pin-task"
                    >
                        <i
                            className={`alpha-icon xs no-margin 
                            ${store.isPinned ? "unpin-icon" : "pin-icon"}`}
                        />
                    </Button>
                </Col>
                <Col>
                    <Button
                        ghost
                        size="small"
                        style={{ border: "none" }}
                        title={
                            store.isFollowingTask
                                ? "Unfollow task"
                                : "Follow task"
                        }
                        onClick={() =>
                            store.toggleTaskFollowing(store.taskPreview!.id)
                        }
                        data-id="button-follow-task-expand-panel"
                    >
                        {store.isFollowingTask ? (
                            <i className="alpha-icon xs eye-invisible no-margin" />
                        ) : (
                            <i className="alpha-icon xs eye-visible no-margin" />
                        )}
                    </Button>
                </Col>
                <Col>
                    <Button
                        ghost
                        size="small"
                        style={{ border: "none" }}
                        title="Copy task URL"
                        onClick={store.copyTaskUrl}
                        data-id="button-copy-task-expand-panel"
                    >
                        <i className="alpha-icon xs task-link no-margin" />
                    </Button>
                </Col>
                <TaskApplicationsMenu
                    disabled={!store.canRunApplications || store.taskFormLocked}
                    taskId={store.taskPreview!.id}
                    tasksStore={store}
                    type="ghost"
                    label={
                        <i className="alpha-icon xs action-more-dots no-margin" />
                    }
                    size="small"
                    btnStyle={{ border: "none" }}
                    onPreview={store.setPreviewAttachment}
                    onDownload={store.handleAttachmentDownload}
                    onSendTaskToUsers={() =>
                        store.setEmailTaskToUserDialogVisible(true)
                    }
                    selectedPackages={store.selectedAttachmentObjects}
                    onRemoveAttachements={() =>
                        store.removeSelectedAttachments()
                    }
                    onRemoveTask={handleTaskRemove}
                />
            </Row>
        </>
    );
};

export default observer(TaskPreviewHeader);

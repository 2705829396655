import * as React from "react";
import { observer } from "mobx-react-lite";
import { TaskViewVisualStore } from "../stores";
import { Button, Col, Dropdown, MenuProps, Skeleton, Tooltip } from "antd";
import ActionInputDialog from "./ActionInputDialog";

type Props = {
    store: TaskViewVisualStore;
};

const TaskActionControl: React.FC<Props> = ({ store }) => {
    const handleExecuteActionClick = (actionId: string) => {
        const taskAction = store.filteredAvailableTaskActions.find(
            (x) => x.action.id === actionId,
        );

        if (!taskAction || !taskAction.canExecute) {
            return;
        }

        const hasUserInputFields =
            taskAction.action.fields.some((x) => x.fieldType === "UserInput") ||
            false;

        if (hasUserInputFields) {
            // Display form
            store.setSelectedAction(actionId, undefined);
        } else {
            // Execute action
            store
                .executeAction(
                    store.taskPreview!.id,
                    actionId,
                    {},
                    taskAction.action!.isClientSide,
                    undefined,
                )
                .catch((err) => console.log(err));
        }
    };

    const actionsMenu: MenuProps = {
        items: store.filteredAvailableTaskActions
            .slice(2, store.filteredAvailableTaskActions.length)
            .map((x) => ({
                key: x.action.id,
                label: (
                    <Tooltip title={x.reason}>
                        <div
                            style={{ padding: 15 }}
                            onClick={() =>
                                handleExecuteActionClick(x.action.id)
                            }
                        >
                            {x.action.title ?? x.action.name}
                        </div>
                    </Tooltip>
                ),
                disabled: !x.canExecute,
            })),
    };

    return (
        <>
            {store.selectedAction && (
                <ActionInputDialog
                    taskId={store.taskPreview!.id}
                    actionId={store.selectedAction}
                    store={store}
                />
            )}
            {store.availableActionsLoading && (
                <Skeleton.Button active size="default" shape="square" />
            )}
            {store.filteredAvailableTaskActions.slice(0, 2).map((a) => (
                <Col key={a.action.id}>
                    <Tooltip title={a.reason}>
                        <Button
                            onClick={() =>
                                handleExecuteActionClick(a.action.id)
                            }
                            disabled={!a.canExecute}
                        >
                            {a.action.title ?? a.action.name}
                        </Button>
                    </Tooltip>
                </Col>
            ))}
            {store.filteredAvailableTaskActions.length > 2 && (
                <Col>
                    <Dropdown
                        menu={actionsMenu}
                        trigger={["click"]}
                        overlayClassName="alpha-dropdown-overlay action-menu"
                    >
                        <Button>...</Button>
                    </Dropdown>
                </Col>
            )}
        </>
    );
};

export default observer(TaskActionControl);

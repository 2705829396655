import { createContext } from "react";
import { ProjectRole } from "../administration/types/UserModel";
const authContext = createContext({
    permissions: [] as string[],
    objectPermissions: {} as { [id: string]: string[] },
    projectRoles: [] as ProjectRole[],
});

export const AuthProvider = authContext.Provider;
export const AuthConsumer = authContext.Consumer;

import * as React from "react";
import { useLocalObservable } from "mobx-react-lite";
import { ErrorStore, ProjectsStore, useStore } from "../../common/stores";
import TaskTemplateService from "../services/TaskTemplateService";
import TaskTemplateVisualStore from "./TaskTemplatesVisualStore";
import TaskTypesService from "src/modules/task_types/services/TaskTypesService";

function createStore(projectStore: ProjectsStore, errorStore: ErrorStore) {
    const service = new TaskTemplateService();
    const taskTypesService = new TaskTypesService();
    const store = new TaskTemplateVisualStore(
        projectStore,
        service,
        taskTypesService,
        errorStore,
    );
    return {
        store,
    };
}

const storeContext = React.createContext<ReturnType<typeof createStore> | null>(
    null,
);
type Props = {
    children: React.ReactNode;
};

export const StoreProvider: React.FC<Props> = ({ children }) => {
    const root = useStore();
    const store = useLocalObservable(() =>
        createStore(root.projectsStore, root.errorStore),
    );
    return (
        <storeContext.Provider value={store}>{children}</storeContext.Provider>
    );
};

export const useTaskTemplateStore = () => {
    const store = React.useContext(storeContext);
    if (!store) {
        // this is especially useful in TypeScript so you don't need to be checking for null all the time
        throw new Error("You have forgot to use StoreProvider, shame on you.");
    }

    return store;
};

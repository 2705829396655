import * as React from "react";
import { observer } from "mobx-react-lite";
import PdfViewer from "../components/PdfViewer";
import DocumentViewVisualStore from "../stores/DocumentViewVisualStore";
import { LoadingIndicator } from "src/modules/common/components/LoadingIndicator";
import ExcelViewer from "./ExcelViewer";
import TextViewer from "./TextViewer";
import DocxViewer from "./DocxViewer";
import XMLViewer from "react-xml-viewer";
import ImageViewer from "./ImageViewer";

type Props = {
    store: DocumentViewVisualStore;
    path: string;
};

const DocumentViewer: React.FC<Props> = ({ store, path }) => {
    React.useEffect(() => {
        store.getDocument(path);
    }, [path]);

    if (store.isLoading) {
        return (
            <div style={{ width: 300, height: 300 }}>
                <LoadingIndicator />
            </div>
        );
    }

    switch (store.fileType) {
        case "pdf":
            return <PdfViewer store={store} />;
        case "text":
            return <TextViewer store={store} />;
        case "xml":
            return <XMLViewer xml={store.xmlString} />;
        case "excel":
            return <ExcelViewer store={store} />;
        case "docx":
            return <DocxViewer store={store} />;
        case "image":
            return <ImageViewer store={store} />;
        default:
            return null;
    }
};

export default observer(DocumentViewer);

import * as React from "react";
import { useLocalObservable } from "mobx-react-lite";

import { ErrorStore, ProjectsStore, useStore } from "../../common/stores";
import { GlobalAdministrationService } from "../services/GlobalAdministrationService";
import { ProjectsService } from "../../common/services";
import UsersManagerStore from "./UsersManagerStore";
import MetadataDefinitionStore from "./MetadataDefinitionStore";
import WidgetConstructorStore from "./WidgetConstructorStore";
import { ActionService } from "../services/ActionService";
import ActionDefinitionStore from "./ActionDefinitionStore";
import { TaskService } from "../../tasks/services";
import TasksRootStore from "src/modules/tasks/stores/TasksRootStore";
import { useTasksStore } from "src/modules/tasks/stores/context";
import TaskTypesService from "src/modules/task_types/services/TaskTypesService";
function createStore(
    errorStore: ErrorStore,
    projectsStore: ProjectsStore,
    tasksRootStore: TasksRootStore,
) {
    const adminService = new GlobalAdministrationService();
    const projectService = new ProjectsService();
    const actionService = new ActionService();
    const taskService = new TaskService();
    const taskTypesService = new TaskTypesService();

    const usersManagerStore = new UsersManagerStore(
        adminService,
        projectsStore,
        projectService,
        errorStore,
    );
    const metadataDefinitionStore = new MetadataDefinitionStore(
        adminService,
        errorStore,
        taskTypesService,
    );
    const widgetConstructorStore = new WidgetConstructorStore(
        projectsStore,
        adminService,
        taskService,
        taskTypesService,
        errorStore,
    );
    const actionDefinitionStore = new ActionDefinitionStore(
        adminService,
        actionService,
        tasksRootStore,
        errorStore,
    );

    return {
        usersManagerStore,
        metadataDefinitionStore,
        widgetConstructorStore,
        actionDefinitionStore,
    };
}

const storeContext = React.createContext<ReturnType<typeof createStore> | null>(
    null,
);
type Props = {
    children: React.ReactNode;
};

export const StoreProvider: React.FC<Props> = ({ children }) => {
    const root = useStore();
    const tasksRootStore = useTasksStore().tasksRootStore;
    const store = useLocalObservable(() =>
        createStore(root.errorStore, root.projectsStore, tasksRootStore),
    );
    return (
        <storeContext.Provider value={store}>{children}</storeContext.Provider>
    );
};

export const useAdminStore = () => {
    const store = React.useContext(storeContext);
    if (!store) {
        // this is especially useful in TypeScript so you don't need to be checking for null all the time
        throw new Error("You have forgot to use StoreProvider, shame on you.");
    }

    return store;
};

import {
    Button,
    Checkbox,
    Input,
    Layout,
    Popconfirm,
    Select,
    Tooltip,
} from "antd";
import Table, { ColumnProps } from "antd/lib/table";
import { observer } from "mobx-react-lite";
import React from "react";
import { safeStringLocaleCompare, Utils } from "../../common/misc/Utils";
import { ProjectsStore } from "../../common/stores";
import ActionDefinitionStore from "../stores/ActionDefinitionStore";
import { ActionDefinition } from "../types/Actions";
import ActionDefinitionCreateEditDialog from "./ActionDefinitionCreateEditDialog";
import ImportActionDefDialog from "./ImportActionDefDialog";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import { DragableBodyRow } from "../../common/components/TableRowDragAndDropRenderer";
const { Content } = Layout;

type Props = {
    store: ActionDefinitionStore;
    projectsStore: ProjectsStore;
};

const ActionDefinitionsList: React.FC<Props> = ({ store, projectsStore }) => {
    const [projectSelected, setProjectSelected] =
        React.useState<boolean>(false);
    const [isImportDefinitionVisible, setIsImportDefinitionVisible] =
        React.useState(false);
    const [sortingApplied, setSortingApplied] = React.useState<boolean>(false);

    React.useEffect(() => {
        store.loadActionTypes();
    }, []);
    const columns: ColumnProps<ActionDefinition>[] = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            render: (text: string, record: ActionDefinition) => (
                <span data-id-cells="name">{record.name}</span>
            ),
            sorter: (a: ActionDefinition, b: ActionDefinition) =>
                safeStringLocaleCompare(b.name, a.name),
        },
        {
            title: "Title",
            dataIndex: "title",
            key: "title",
            render: (text: string, record: ActionDefinition) => (
                <span data-id-cells="name">{record.title}</span>
            ),
            sorter: (a: ActionDefinition, b: ActionDefinition) =>
                safeStringLocaleCompare(b.title, a.title),
        },
        {
            title: "Type",
            dataIndex: "actionType",
            key: "actionType",
            render: (text: string, record: ActionDefinition) => (
                <span data-id-cells="name">
                    {
                        store.actionTypes.find(
                            (x) => x.name === record.actionType,
                        )?.title
                    }
                </span>
            ),
            sorter: (a: ActionDefinition, b: ActionDefinition) => {
                const titleA = store.actionTypes.find(
                    (x) => x.name === a.actionType,
                )?.title;
                const titleB = store.actionTypes.find(
                    (x) => x.name === b.actionType,
                )?.title;
                return safeStringLocaleCompare(titleB, titleA);
            },
        },
        {
            title: "Attachment action",
            dataIndex: "isAttachment",
            key: "isAttachment",
            render: (_, record: ActionDefinition) => (
                <Checkbox disabled checked={record.isAttachment} />
            ),
            sorter: (a: ActionDefinition, b: ActionDefinition) => {
                if (a.isAttachment === b.isAttachment) return 0;
                return a.isAttachment ? -1 : 1;
            },
        },
        {
            title: "Actions",
            width: 200,
            render: (text: string, record: ActionDefinition) => (
                <div className="row-actions-wrapper">
                    <Button
                        data-id-cells="Edit action definition"
                        title="Rename"
                        size="small"
                        type="link"
                        onClick={() => {
                            store.selectActionDefinition(record);
                        }}
                    >
                        <Tooltip
                            title="Edit action definition"
                            placement="bottom"
                        >
                            <i className="alpha-icon md table-action-edit" />
                        </Tooltip>
                    </Button>
                    <Button
                        data-id-cells="Export action"
                        title="Export"
                        size="small"
                        type="link"
                        onClick={() => {
                            store.exportActionDefinition(record.id);
                        }}
                    >
                        <Tooltip
                            title="Export action definition"
                            placement="bottom"
                        >
                            <i className="alpha-icon md export" />
                        </Tooltip>
                    </Button>
                    <Popconfirm
                        id="data-id-popconfirm-box"
                        title="Are you sure you want to delete this action definition?"
                        onConfirm={() => {
                            store.deleteActionDefinition(record.id);
                        }}
                        placement="topRight"
                    >
                        <Button
                            data-id-cells="Delete action definition"
                            title="Delete"
                            size="small"
                            type="link"
                        >
                            <Tooltip
                                title="Delete action definition"
                                placement="bottom"
                            >
                                <i className="alpha-icon md table-action-delete" />
                            </Tooltip>
                        </Button>
                    </Popconfirm>
                </div>
            ),
        },
    ];

    const dragAndDropBody = {
        body: {
            row: DragableBodyRow,
        },
    };

    const moveRow = (
        dragIndex: number,
        dropIndex: number,
        draggedRecord: ActionDefinition,
    ) => {
        store.updateListOrder(draggedRecord.id, dragIndex, dropIndex);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleTableChange = (pag: any, filt: any, sorter: any) => {
        setSortingApplied(!!sorter.column);
    };

    const handleEnterPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        store.setSearchString(event.currentTarget.value);
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <Layout className="screen-size tasks-types-layout layout-with-table">
                <div className="header-wrapper">
                    <div className="title-wrapper">
                        <div className="header-title">Action Definitions</div>
                    </div>
                    <Button
                        key={1}
                        disabled={!store.selectedProjectId}
                        data-id="button-add-type"
                        style={{ marginRight: 8 }}
                        type="primary"
                        size="large"
                        onClick={() => {
                            setIsImportDefinitionVisible(true);
                        }}
                    >
                        Import definition
                    </Button>
                    <Button
                        key={2}
                        disabled={!store.selectedProjectId}
                        data-id="button-add-definition"
                        type="primary"
                        size="large"
                        onClick={() => {
                            store.setNewDefinitionDialogVisible(true);
                        }}
                    >
                        Add definition
                    </Button>
                </div>
                <ActionDefinitionCreateEditDialog
                    store={store}
                    projectsStore={projectsStore}
                />
                <ImportActionDefDialog
                    store={store}
                    isDialogVisible={isImportDefinitionVisible}
                    closeDialog={() => setIsImportDefinitionVisible(false)}
                />
                <Layout>
                    <Content>
                        <div className="projects-list-container">
                            <Select
                                options={
                                    projectsStore.administrableProjects?.map(
                                        (p) => ({ label: p.name, value: p.id }),
                                    ) || []
                                }
                                style={{ width: 220 }}
                                placeholder="Select project"
                                onChange={(v) => {
                                    setProjectSelected(true);
                                    store.setSelectedProjectId(v);
                                }}
                                showSearch
                                filterOption={Utils.filterOption}
                            />
                            <Input
                                data-id="search area"
                                placeholder="Search..."
                                onPressEnter={handleEnterPress}
                                onClear={() => store.setSearchString("")}
                                maxLength={150}
                                style={{ width: 200, marginLeft: "auto" }}
                                prefix={
                                    <i
                                        className="alpha-icon xs table-search"
                                        style={{ marginRight: 10 }}
                                    />
                                }
                                allowClear
                            />
                        </div>
                        <Table
                            data-id="action-definitions-list"
                            className="alpha-table action-definitions"
                            columns={columns}
                            loading={store.isLoading}
                            dataSource={
                                projectSelected
                                    ? store.filteredActionDefinitions
                                          .slice()
                                          .sort(
                                              (a, b) => a.position - b.position,
                                          )
                                    : []
                            }
                            rowKey={(r) => r.id}
                            pagination={false}
                            components={
                                store.searchString || sortingApplied
                                    ? undefined
                                    : dragAndDropBody
                            }
                            onRow={(record, index) =>
                                ({
                                    index,
                                    record,
                                    moveRow: moveRow,
                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                }) as any
                            }
                            onChange={handleTableChange}
                            rowSelection={{}}
                        />
                    </Content>
                </Layout>
            </Layout>
        </DndProvider>
    );
};

export default observer(ActionDefinitionsList);

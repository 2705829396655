import * as React from "react";
import { TaskCreateVisualStore } from "../stores";
import { Observer, observer } from "mobx-react-lite";
import { LoadingOutlined } from "@ant-design/icons";
import {
    Row,
    Col,
    Button,
    Input,
    Popconfirm,
    Dropdown,
    Switch,
    Tooltip,
    Select,
} from "antd";
import DraggableItemWrapper from "../../common/components/DraggableItemWrapper";
import { OnHitEventArgs } from "react-drag-drop-container";
import { ALL_PROJECTS } from "../screens/TasksPage";
import TasksGridVisualStore from "../stores/TasksGridVisualStore";
import TasksFiltersVisualStore from "../stores/TasksFiltersVisualStore";
import { useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { AuthConsumer } from "../../authorization/AuthContext";
import {
    HasAdminPermissionForProjects,
    hasPermission,
} from "../../authorization/components/HasPermission";
import { AppPermissions } from "../../authorization/Permissions";
import { ColumnModel } from "../types";
import { Utils } from "src/modules/common/misc/Utils";
import { ProjectsStore } from "src/modules/common/stores";

type Props = {
    store: TasksGridVisualStore;
    filterStore: TasksFiltersVisualStore;
    createStore: TaskCreateVisualStore;
    projectsStore: ProjectsStore;
};

const TaskListHeaderControls: React.FC<Props> = ({
    store,
    createStore,
    filterStore,
    projectsStore,
}) => {
    const { widgetId, value, activityType, period, projectId } = useParams();
    const [searchParams] = useSearchParams();
    const activityPeriod = searchParams.get("period");

    let dragIndex = 0;
    const navigateTo = useNavigate();
    const handleBatchAssignDialogButtonClick = () => {
        if (store.allowBatchAssign) {
            store.setTaskAssignDialogVisible(true);
        }
    };

    const dynamicControls = () => {
        return (
            <div className="tasks-header-controls">
                <span className="selected-items">
                    {store.selectedRows.length} selected tasks:{" "}
                </span>
                {store.selectedProject !== ALL_PROJECTS ? (
                    <span
                        className="header-control"
                        onClick={() => store.setIsBulkUpdateDialogOpen(true)}
                    >
                        <i className={"alpha-icon xs edit-native-blue"} />
                        Update
                    </span>
                ) : null}
                {store.selectedProject === ALL_PROJECTS ? (
                    <span
                        className={`header-control ${store.allowBatchAssign ? "" : "disabled"}`}
                        onClick={handleBatchAssignDialogButtonClick}
                    >
                        {store.commonUsersLoading ? (
                            <LoadingOutlined style={{ marginRight: 10 }} />
                        ) : (
                            <i
                                className={`alpha-icon xs filter-my-tasks${store.allowBatchAssign ? "-active" : ""}`}
                            />
                        )}
                        Assign
                    </span>
                ) : null}
                <HasAdminPermissionForProjects
                    projectIds={store.projectsOfSelectedTasks}
                >
                    <Popconfirm
                        title={`Delete ${store.selectedRows.length} task(-s)?`}
                        onConfirm={store.deleteSelectedTasks}
                        okText="Delete"
                        cancelText="Cancel"
                    >
                        <span className="header-control">
                            <i className={"alpha-icon xs action-remove"} />
                            Delete
                        </span>
                    </Popconfirm>
                </HasAdminPermissionForProjects>
            </div>
        );
    };

    const handleOnDrop = (index: number) => {
        dragIndex = index;
    };

    const handleOnHit = (args: OnHitEventArgs, index: number) => {
        store.handleColumnsReorder(dragIndex, index);
    };

    const isVisibleColumn = (col: ColumnModel, permissions: string[]) => {
        if (!col.isMetaData) {
            return true;
        }
        const metadata = store.tableMetaData.find((t) => t.title === col.name);
        return (
            metadata!.isVisible ||
            hasPermission(permissions, AppPermissions.CanAccessAdministration)
        );
    };

    const columnsMenu = (permissions: string[]) => {
        return {
            items: store.filteredTaskColumns
                .filter((col) => isVisibleColumn(col, permissions))
                .map((x, i) => ({
                    key: i,
                    label: (
                        <div
                            style={{ padding: "8px 0px" }}
                            key={x.name}
                            data-id-type={x.name}
                        >
                            <DraggableItemWrapper
                                key={x.name}
                                index={i}
                                handleOnDrop={handleOnDrop}
                                handleOnHit={handleOnHit}
                            >
                                <div>
                                    <div className="draggable-item">
                                        <i
                                            style={{ verticalAlign: "middle" }}
                                            className="alpha-icon md dragger"
                                        />
                                        <span>{x.name}</span>
                                    </div>
                                    <Observer>
                                        {() => (
                                            <Switch
                                                onClick={(e, ev) =>
                                                    ev.stopPropagation()
                                                }
                                                checked={x.isVisible}
                                                onChange={(e) =>
                                                    store.handleVisibiltyOnChange(
                                                        x.name,
                                                        e,
                                                    )
                                                }
                                            />
                                        )}
                                    </Observer>
                                </div>
                            </DraggableItemWrapper>
                        </div>
                    ),
                })),
        };
    };

    const handleFiltersReset = () => {
        navigateTo("/tasks");
        store.resetToDefaultProject();
        store.resetAllFilters();
    };

    const loadTasks = (excludeFilters?: boolean) => {
        if (widgetId && !value) {
            store.getTasksByGroupedWidgetId(widgetId, store.selectedProject);
        } else if (value) {
            store.getTasksByPieChartSection(
                widgetId!,
                store.selectedProject,
                value,
            );
        } else if (activityType) {
            store.getTasksByActivityType(
                activityType,
                store.selectedProject,
                activityPeriod!,
            );
        } else {
            store.loadTasks(!!excludeFilters);
        }
    };

    const handleMainTasksSwitchChange = (isEnabled: boolean) => {
        store.setIsMainTasksViewEnabled(isEnabled);
        loadTasks();
    };

    const handleDoneTasksSwitchChange = (isEnabled: boolean) => {
        store.setIsDoneTasksViewEnabled(isEnabled);
        loadTasks();
    };

    const handleProjectChange = (id: string) => {
        store.handleProjectChange(id, widgetId, value, activityType, period);
    };

    const handleSearch = () => {
        store.loadTasks(true);
    };

    const handleSearchClear = () => {
        loadTasks(false);
    };

    const secondRowRenderer = () => {
        return store.selectedRows && !!store.selectedRows.length ? (
            <Row style={{ alignItems: "center", marginBottom: 33 }}>
                <Col>{dynamicControls()}</Col>
            </Row>
        ) : (
            <Row style={{ alignItems: "center", marginBottom: 20 }}>
                <Col>
                    <div className="tasks-filter-switch">
                        <Switch
                            data-id="main-tasks"
                            onChange={handleMainTasksSwitchChange}
                            checked={store.isMainTasksViewEnabled}
                        />
                        <span className="title">Main tasks only</span>
                    </div>
                </Col>
                <Col
                    flex={
                        (store.selectedProject !== ALL_PROJECTS &&
                            store.selectedProject) ||
                        (store.currentWidgetName &&
                            store.selectedProject === ALL_PROJECTS)
                            ? undefined
                            : 2
                    }
                >
                    <div className="tasks-filter-switch">
                        <Switch
                            data-id="done-tasks"
                            onChange={handleDoneTasksSwitchChange}
                            checked={store.isDoneTasksViewEnabled}
                            disabled={
                                !!store.currentWidgetName &&
                                store.isDoneTasksToggleDisabled
                            }
                        />
                        <span className="title">Include done tasks</span>
                    </div>
                </Col>
                {store.selectedProject !== ALL_PROJECTS &&
                store.selectedProject ? (
                    <Col flex={store.currentWidgetName ? undefined : 2}>
                        <div className="tasks-filter-switch">
                            <Switch
                                data-id="metadata-view"
                                onChange={store.setIsMetadataViewEnabled}
                                checked={store.isMetadataViewEnabled}
                            />
                            <span className="title">Metadata view</span>
                        </div>
                    </Col>
                ) : null}
                {store.currentWidgetName ? (
                    <Col flex={2}>
                        <Button
                            className="widget-name-button"
                            type="primary"
                            onClick={handleFiltersReset}
                            data-id="button-current-widget"
                        >
                            <div className="widget-label">
                                <Tooltip title={store.currentWidgetName}>
                                    {store.currentWidgetName}
                                </Tooltip>
                            </div>
                            <Tooltip title="Reset widget">
                                <i className={"alpha-icon xs filter-reset"} />
                            </Tooltip>
                        </Button>
                    </Col>
                ) : null}
                <Col>
                    <Input
                        data-id="search area"
                        placeholder="Search..."
                        value={store.searchTerm}
                        onChange={(e) => store.setSearchTerm(e.target.value)}
                        onPressEnter={handleSearch}
                        onClear={handleSearchClear}
                        maxLength={150}
                        prefix={
                            <i
                                className="alpha-icon xs table-search"
                                style={{ marginRight: 10 }}
                            />
                        }
                        allowClear
                    />
                </Col>
            </Row>
        );
    };

    return (
        <>
            <div className="tasks-content-header">
                <div className="tasks-header-controls-container">
                    <Row style={{ alignItems: "center", marginBottom: 20 }}>
                        <Col flex={2} className="title-wrapper">
                            <div className="header-title">Tasks</div>
                        </Col>
                        <Col>
                            <div className="projects-select">
                                <Select
                                    loading={
                                        !store.selectedProject ||
                                        !projectsStore.projects
                                    }
                                    prefix={
                                        <i className="alpha-icon xs breafcase-icon grey" />
                                    }
                                    showSearch
                                    disabled={
                                        !!store.currentWidgetName &&
                                        projectId !== ALL_PROJECTS
                                    }
                                    optionFilterProp="children"
                                    dropdownStyle={{ width: 200 }}
                                    filterOption={Utils.filterOption}
                                    value={store.selectedProject}
                                    options={[
                                        {
                                            value: ALL_PROJECTS,
                                            label: "All projects",
                                        },
                                        ...(projectsStore.projects?.map(
                                            (p) => ({
                                                value: p.id,
                                                label: p.name,
                                            }),
                                        ) || []),
                                    ]}
                                    onChange={handleProjectChange}
                                    className={
                                        store.selectedProject === ALL_PROJECTS
                                            ? "all-projects"
                                            : ""
                                    }
                                />
                            </div>
                        </Col>
                        <Col>
                            <AuthConsumer>
                                {({ permissions }) => (
                                    <Observer>
                                        {() => (
                                            <Dropdown
                                                trigger={["click"]}
                                                menu={columnsMenu(permissions)}
                                                overlayClassName="alpha-dropdown-overlay task-columns-menu"
                                            >
                                                <Button
                                                    data-id="button-columns"
                                                    className="dropdown-btn"
                                                    style={{
                                                        border: "none",
                                                        marginRight: 10,
                                                    }}
                                                    ghost
                                                >
                                                    <i className="alpha-icon xs columns" />
                                                    Columns
                                                </Button>
                                            </Dropdown>
                                        )}
                                    </Observer>
                                )}
                            </AuthConsumer>
                        </Col>
                        <Col>
                            <Button
                                className="reset-button"
                                type="text"
                                icon={<i className="alpha-icon refresh xs" />}
                                onClick={filterStore.handleAllFiltersReset}
                                disabled={
                                    !Object.keys(filterStore.columnsFilterDict)
                                        .length || filterStore.isGridView
                                }
                            >
                                Reset filters
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                className="reset-button"
                                type="text"
                                icon={<i className="alpha-icon refresh xs" />}
                                onClick={store.removeDefaultLayout}
                                disabled={
                                    !Object.keys(store.userColumnsConfig).length
                                }
                                ghost
                            >
                                Reset layout
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                className="new-task-btn"
                                style={{ flex: "0 0" }}
                                type="primary"
                                onClick={() =>
                                    createStore.setNewTaskDialogVisible(true)
                                }
                                loading={
                                    !createStore.projects ||
                                    !createStore.projects.length ||
                                    !createStore.currentProjectId
                                }
                                data-id="button-new-task"
                            >
                                New task
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>
            {secondRowRenderer()}
        </>
    );
};

export default observer(TaskListHeaderControls);

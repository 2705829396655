/* eslint-disable no-case-declarations */
import * as React from "react";
import { ApplicationSession, SessionStates } from "../../common/services/types";
import { Utils } from "../../common/misc/Utils";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin, Dropdown, Button, Tag, Tooltip, MenuProps } from "antd";
import { observer } from "mobx-react-lite";

type Props = {
    session: ApplicationSession;
    userName: string;
    onOpen: (a: ApplicationSession) => void;
};

const TaskActivitySession: React.FC<Props> = ({
    session,
    onOpen,
    userName,
}) => {
    const menu: MenuProps = {
        items: [
            {
                key: 1,
                label: <div onClick={() => onOpen(session)}>Open</div>,
            },
        ],
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getSessionTag = (state: SessionStates, error: any = undefined) => {
        switch (state) {
            case "Aborted":
                return (
                    <Tooltip title={error}>
                        <Tag color="#F5222D" className="alpha-info-tag">
                            Failed
                        </Tag>
                    </Tooltip>
                );
            case "WaitingAction":
                const color = error == null ? "#FADB14" : "#F5222D";
                const text = error == null ? "Analyzed" : "Failed";
                return (
                    <Tooltip title={error}>
                        <Tag color={color} className="alpha-info-tag">
                            {text}
                        </Tag>
                    </Tooltip>
                );
            case "Finished":
                return (
                    <Tag
                        color="rgba(28, 120, 12, .85)"
                        className="alpha-info-tag"
                    >
                        Finished
                    </Tag>
                );
            case "Archived":
                return (
                    <Tag color="#9BA0AA" className="alpha-info-tag">
                        Archived
                    </Tag>
                );
            default:
                return null;
        }
    };

    return (
        <div className="activity-entry">
            <div className="activity-info">
                <span className="activity-name">
                    &quot;{session.applicationName}&quot; was executed on &quot;
                    {session.packageName}&quot; by <strong>{userName}</strong>
                </span>
                <span className="activity-time">
                    {" "}
                    - {Utils.formatDateStringShort(session.created)}
                </span>
            </div>
            <div className="activity-state">
                <span>{getSessionTag(session.state, session.error)}</span>
            </div>
            <div className="activity-controls">
                {["WaitingAction", "Finished", "Aborted", "Archived"].includes(
                    session.state,
                ) && (
                    <Dropdown menu={menu} trigger={["click"]}>
                        <Button
                            style={{ border: "none" }}
                            size="small"
                            ghost
                            className="session-dropdown-btn"
                        >
                            <i className="alpha-icon xs action-more-dots no-margin" />
                        </Button>
                    </Dropdown>
                )}
                {(session.state === "NotStarted" ||
                    session.state === "Pending") && (
                    <Spin
                        indicator={
                            <LoadingOutlined
                                style={{ fontSize: 16, marginLeft: 7 }}
                                spin
                            />
                        }
                    />
                )}
            </div>
        </div>
    );
};

export default observer(TaskActivitySession);

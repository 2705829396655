import * as React from "react";
import { AlphaId, TaskLink, TaskRelationships } from "../types";
import { observer } from "mobx-react-lite";
import { Button, Popconfirm, Tag, Tooltip, Typography } from "antd";
import { TaskViewVisualStore } from "../stores";
import Table, { ColumnProps } from "antd/lib/table";

type Props = {
    currentTaskId: string;
    store: TaskViewVisualStore;
    isFullView: boolean;
};

type TargetTask = {
    id: string;
    identifier?: AlphaId;
    title: string;
};

const TaskLinkElements: React.FC<Props> = ({
    currentTaskId: taskId,
    store,
    isFullView,
}) => {
    const getTargetTask = (link: TaskLink): TargetTask => {
        return taskId === link.taskId
            ? {
                  id: link.linkedTaskId,
                  identifier: link.linkedTaskIdentifier,
                  title: link.linkedTaskTitle,
              }
            : {
                  id: link.taskId,
                  identifier: link.taskIdentifier,
                  title: link.taskTitle,
              };
    };

    const columns: ColumnProps<TaskLink>[] = [
        {
            width: isFullView ? 150 : undefined,
            render: (_: string, link: TaskLink) => {
                const linkDef = TaskRelationships.getRelationInfo(link, taskId);
                return {
                    children: <Tag>{linkDef?.label}</Tag>,
                };
            },
        },
        {
            width: 30,
            render: (_: string, link: TaskLink) => {
                return {
                    children: (
                        <>
                            {link.isSubtask && (
                                <i className="alpha-icon subtask-icon xs" />
                            )}
                        </>
                    ),
                };
            },
        },
        {
            width: 80,
            render: (_: string, link: TaskLink) => {
                const targetTask = getTargetTask(link);
                return {
                    children: (
                        <>
                            {targetTask.identifier && (
                                <Tag color="#273C75">{`${targetTask.identifier.prefix}-${targetTask.identifier.index}`}</Tag>
                            )}
                        </>
                    ),
                };
            },
        },
        {
            width: isFullView ? undefined : "20%",
            render: (_: string, link: TaskLink) => {
                const targetTask = getTargetTask(link);
                return {
                    children: (
                        <div className="task-link-title-container">
                            <Tooltip title={targetTask.title}>
                                <Typography.Link
                                    href={`${window.location.origin}/tasks/${targetTask.id}`}
                                    target="_blank"
                                >
                                    {targetTask.title}
                                </Typography.Link>
                            </Tooltip>
                        </div>
                    ),
                };
            },
        },
        {
            width: 50,
            render: (_: string, link: TaskLink) => {
                return {
                    children: (
                        <div className="task-link-menu">
                            <Button
                                data-id="link-actions-dropdown-button"
                                ghost
                                style={{ border: "none" }}
                            >
                                <Popconfirm
                                    title="Are you sure that you want to delete this link?"
                                    okText="Delete"
                                    onConfirm={() =>
                                        store.deleteTaskLink(link.id)
                                    }
                                    cancelText="Cancel"
                                >
                                    <Tooltip title="Delete link">
                                        <i
                                            data-id="button-close"
                                            className="alpha-icon xxs cross"
                                        />
                                    </Tooltip>
                                </Popconfirm>
                            </Button>
                        </div>
                    ),
                };
            },
        },
    ];

    return (
        <Table
            rowKey={(r) => r.id}
            className="linked-tasks-table"
            dataSource={store.taskLinks ?? []}
            columns={columns}
            pagination={false}
            showHeader={false}
            size="small"
            tableLayout="fixed"
        />
    );
};

export default observer(TaskLinkElements);

import * as React from "react";
import { useLocalObservable } from "mobx-react-lite";
import DocumentVisualStore from "./DocumentsVisualStore";
import ImageService from "../../common/services/ImageService";
import { DocumentService } from "../../common/services";
import { ErrorStore, useStore } from "src/modules/common/stores";
import DocumentViewVisualStore from "./DocumentViewVisualStore";
import DocumentViewService from "../services/DocumentViewService";

function createStore(errorStore: ErrorStore) {
    const minioService = new ImageService();
    const documentService = new DocumentService();
    const documentViewService = new DocumentViewService();

    const documentVisualStore = new DocumentVisualStore(
        minioService,
        errorStore,
        documentService,
    );
    const documentViewVisualStore = new DocumentViewVisualStore(
        documentViewService,
    );

    return {
        documentVisualStore,
        documentViewVisualStore,
    };
}

const storeContext = React.createContext<ReturnType<typeof createStore> | null>(
    null,
);
type Props = {
    children: React.ReactNode;
};

export const DocumentsStoreProvider: React.FC<Props> = ({ children }) => {
    const root = useStore();
    const store = useLocalObservable(() => createStore(root.errorStore));
    return (
        <storeContext.Provider value={store}>{children}</storeContext.Provider>
    );
};

export const useDocumentsStore = () => {
    const store = React.useContext(storeContext);
    if (!store) {
        // this is especially useful in TypeScript so you don't need to be checking for null all the time
        throw new Error("You have forgot to use StoreProvider, shame on you.");
    }

    return store;
};

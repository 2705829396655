import { Button, Modal } from "antd";
import { observer } from "mobx-react";
import React from "react";
import ActionDefinitionStore from "../stores/ActionDefinitionStore";
import ActionFieldInputSetupForm from "./ActionFieldInputSetupForm";

type Props = {
    store: ActionDefinitionStore;
};

const ActionFieldInputSetupDialog: React.FC<Props> = ({ store }) => {
    return (
        <Modal
            data-id-type="modal-window"
            data-id-name="edit-field-input"
            className="alpha-modal"
            title="Configure input appearance"
            open={store.editInputDialogVisible}
            closable={false}
            centered
            width={500}
            footer={[
                <Button
                    data-id="button-edit-field-input-cancel"
                    key="edit-field-input-cancel"
                    size="large"
                    className="light"
                    onClick={() => {
                        store.setEditInputDialogVisible(false);
                    }}
                >
                    Cancel
                </Button>,
                <Button
                    data-id="button-edit-field-input-save"
                    type="primary"
                    key="edit-field-input-save"
                    size="large"
                    className="dark"
                    htmlType="submit"
                    form="edit-field-input-form"
                >
                    Save
                </Button>,
            ]}
        >
            <ActionFieldInputSetupForm store={store} />
        </Modal>
    );
};

export default observer(ActionFieldInputSetupDialog);

import { action, observable, runInAction, makeObservable } from "mobx";
import { ErrorStore } from "../../common/stores";
import ImageService from "../../common/services/ImageService";
import { ImagePreviewSize } from "../types/types";
import { DocumentService, types } from "../../common/services";
import { Subject } from "rxjs";

type PagePreview = {
    page: number;
    url: string;
    orientation: "Portrait" | "Landscape";
    imgSize?: {
        width: number;
        height: number;
    };
};

export type HighlightBlockProps = {
    height: number;
    width: number;
    bottom: number;
    left: number;
    visibility: "hidden" | "visible";
    position: "absolute";
    page: number;
    nodeHierarchy?: string;
};

export type DocumentBlockProps = {
    id: string;
    blockProps: HighlightBlockProps;
};

export default class DocumentVisualStore {
    currentPage: number = 0;

    pageWidth: number | undefined = undefined;

    totalPages: number = 0;

    isRendered: boolean = false;

    scale: number = 1;

    scrollPosition: number = 0;

    sizeCoefficient: number;

    pageCoordinates: number[];

    previews: PagePreview[] = [];

    highlightedBlockProps: HighlightBlockProps | undefined;

    highlightedField: types.FormInputFieldData | undefined;

    highlightedInputId: string | undefined;

    largePreviews: PagePreview[] = [];

    documentBlocks: DocumentBlockProps[] = [];

    showBlocks: boolean = true;

    shouldScrollBlockIntoView: boolean = true;

    highlightedInputIdSubject = new Subject<string>();

    constructor(
        private imageService: ImageService,
        private errorStore: ErrorStore,
        private documentService: DocumentService,
    ) {
        makeObservable<DocumentVisualStore>(this, {
            currentPage: observable,
            pageWidth: observable,
            totalPages: observable,
            isRendered: observable,
            scale: observable,
            scrollPosition: observable,
            previews: observable,
            highlightedBlockProps: observable,
            highlightedInputId: observable,
            largePreviews: observable,
            documentBlocks: observable,
            showBlocks: observable,
            shouldScrollBlockIntoView: observable,
            setShowingBlocks: action.bound,
            toggleShowBlocks: action.bound,
            setHighlightedBlockProps: action.bound,
            getPackageXmlContent: action.bound,
            setScale: action.bound,
            getPreviews: action.bound,
            prepareDocumentPages: action.bound,
            setScrollPosition: action,
            setCurrentPage: action,
            setPageWidth: action,
            setPagesNumber: action,
            setIsRendered: action,
            setPageCoodinates: action.bound,
            calculateMinimapHeight: action.bound,
        });
    }

    setShowingBlocks(showBlocks: boolean) {
        this.showBlocks = showBlocks;
    }

    toggleShowBlocks() {
        this.showBlocks = !this.showBlocks;
    }

    setHighlightedBlockProps(props: HighlightBlockProps | undefined) {
        this.highlightedBlockProps = props;
    }

    async getPackageXmlContent(packageId: string) {
        try {
            const resp =
                await this.documentService.getXmlDocumentContent(packageId);
            return resp.unwrapOr("");
        } catch (err) {
            console.error(err);
            return "";
        }
    }

    setScale(scale: number) {
        this.scale = scale;
        this.pageCoordinates = this.pageCoordinates.map(
            (x, i) => 1444 * i * scale,
        );
    }

    async getPreviews(
        packageId: string,
        imageSize: ImagePreviewSize,
        indexDate: string,
        fromPage?: number,
        toPage?: number,
    ) {
        const resp = await this.imageService.getImagePathsWithSizes(
            packageId,
            imageSize,
            indexDate,
            fromPage,
            toPage,
        );
        runInAction(() => {
            this.previews = [];
        });

        resp.map((data) => {
            const arr: PagePreview[] = data.map((r, i) => {
                const url = this.imageService.getImageUrlFromPath(
                    r.path,
                    imageSize,
                    indexDate,
                );
                return {
                    page: i,
                    url,
                    orientation: "Portrait",
                    imgSize: r.size,
                };
            });

            runInAction(() => (this.previews = arr));
        }).mapErr((err) => this.errorStore.addError(err.data));
    }

    async prepareDocumentPages(
        packageId: string,
        indexDate: string,
        imgSize: ImagePreviewSize = ImagePreviewSize.DocumentSize,
    ) {
        const resp = await this.imageService.getImagePathsWithSizes(
            packageId,
            imgSize,
            indexDate,
        );
        resp.map((data) => {
            this.pageCoordinates = [];
            runInAction(() => {
                this.largePreviews = [];
                this.scale = 0;
            });

            const arr: PagePreview[] = [];

            data.forEach((r, i) => {
                const url = this.imageService.getImageUrlFromPath(
                    r.path,
                    imgSize,
                    indexDate,
                );
                let orientation: "Portrait" | "Landscape" = "Portrait";

                if (r.size.width > r.size.height) {
                    orientation = "Landscape";
                }

                arr.push({
                    page: i,
                    url,
                    orientation: orientation,
                    imgSize: r.size,
                });
            });

            runInAction(() => {
                this.largePreviews = arr;
                this.totalPages = this.largePreviews.length;
                this.isRendered = true;
                this.scale = 1;
            });
        }).mapErr((err) => this.errorStore.addError(err.data));
    }

    setScrollPosition(scrollPosition: number) {
        this.scrollPosition = scrollPosition;
    }

    setCurrentPage(currentPage: number, useScroll: boolean = true) {
        this.currentPage = currentPage;
        if (useScroll && this.pageCoordinates) {
            this.scrollPosition = this.pageCoordinates[currentPage];
        }
    }

    setPageWidth(width: number | undefined) {
        if (width === this.pageWidth) {
            return;
        }

        this.pageWidth = width;
    }

    setPagesNumber(pages: number) {
        this.totalPages = pages;
    }

    setIsRendered(isRendered: boolean) {
        this.isRendered = isRendered;
    }

    setPageCoodinates(pageCoords: number[]) {
        this.pageCoordinates = pageCoords;
    }

    calculateMinimapHeight() {
        let height = 0;
        const pageGap = 30;
        if (this.largePreviews && this.pageWidth) {
            this.largePreviews.forEach((lp) => {
                if (lp.imgSize) {
                    height +=
                        this.calculatePreviewHeight(
                            lp.imgSize.width,
                            this.pageWidth!,
                            lp.imgSize.height,
                        ) + pageGap;
                }
            });
        }
        return height;
    }

    calculatePreviewHeight(
        imgWidth: number,
        previewWidth: number,
        imgHeight: number,
    ) {
        return imgHeight * (previewWidth / imgWidth);
    }

    sortByPageNumber(prev1: PagePreview, prev2: PagePreview) {
        if (prev1.page > prev2.page) {
            return 1;
        } else if (prev1.page < prev2.page) {
            return -1;
        } else {
            return 0;
        }
    }
}

import * as React from "react";
import { observer } from "mobx-react-lite";
import { Modal, Button, Input, Select, Form, Checkbox } from "antd";
import TaskStatusesVisualStore from "../stores/TaskStatusesVisualStore";
import { useForm } from "antd/lib/form/Form";
import { Utils } from "../../common/misc/Utils";

type Props = {
    store: TaskStatusesVisualStore;
};
const FormItem = Form.Item;

const TaskStatusCreateDialog: React.FC<Props> = ({ store }) => {
    const [form] = useForm();

    if (!store.selectedTaskType) {
        return null;
    }

    const handleSubmit = async () => {
        form.validateFields()
            .then((values) => {
                store.createTaskStatus(
                    values.name,
                    values.code,
                    values.isSubtaskStatus,
                );
                form.resetFields();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const statusNameValidator = (rule: any, value: any, callback: any) => {
        const isSubtaskStatus = form.getFieldValue("isSubtaskStatus");
        const statusesToValidate = store!.taskStatuses
            .filter((s) => s.isSubtaskStatus === isSubtaskStatus)
            .map((t) => t.name.toLowerCase());
        if (value && statusesToValidate.includes(value.toLowerCase())) {
            callback("Status with the same name already exists");
        } else {
            callback();
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const statusCodeValidator = (rule: any, value: any, callback: any) => {
        const isSubtaskStatus = form.getFieldValue("isSubtaskStatus");
        const statusesToValidate = store!.taskStatuses
            .filter((s) => s.isSubtaskStatus === isSubtaskStatus)
            .map((t) => t.code?.toLowerCase());
        if (value && statusesToValidate.includes(value.toLowerCase())) {
            callback("Status with the same code already exists");
        } else {
            callback();
        }
    };

    return (
        <Modal
            className="alpha-portal-dialog"
            title="Create new task status"
            open={store.newStatusDialogVisible}
            closable={false}
            centered
            footer={[
                <Button
                    size="large"
                    key="task-status-cancel"
                    className="light"
                    data-id="new-task-status-dialog-cancel"
                    onClick={() => {
                        store.setNewStatusDialogVisible(false);
                        form.resetFields();
                    }}
                >
                    Cancel
                </Button>,
                <Button
                    type="primary"
                    size="large"
                    className="dark"
                    key="task-status-save"
                    data-id="new-task-status-dialog-save"
                    onClick={handleSubmit}
                >
                    Save
                </Button>,
            ]}
        >
            <Form
                layout="vertical"
                data-id="create-task-type-dialog-form"
                form={form}
                className="alpha-form"
            >
                <FormItem
                    label={<span className="dialog-field-label">Name</span>}
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: "Name is required",
                            whitespace: true,
                        },
                        { validator: statusNameValidator },
                    ]}
                >
                    <Input data-id="input-Name" />
                </FormItem>
                <FormItem
                    label={
                        <span className="dialog-field-label">
                            Assigned users
                        </span>
                    }
                    name="assigneUsers"
                >
                    <Select
                        mode="multiple"
                        filterOption={Utils.filterOption}
                        showSearch
                        options={
                            store.usersInProject
                                ? store.usersInProject.map((s) => ({
                                      label: s.userName,
                                      value: s.id,
                                  }))
                                : []
                        }
                    />
                </FormItem>
                <FormItem
                    label={<span className="dialog-field-label">Code</span>}
                    name="code"
                    rules={[{ validator: statusCodeValidator }]}
                >
                    <Input data-id="input-code" />
                </FormItem>
                {store.hasDistinctSubtaskStatuses && (
                    <FormItem
                        label={
                            <span className="dialog-field-label">
                                Is subtask status
                            </span>
                        }
                        name="isSubtaskStatus"
                        valuePropName="checked"
                        initialValue={false}
                    >
                        <Checkbox data-id="input-code" />
                    </FormItem>
                )}
            </Form>
        </Modal>
    );
};

export default observer(TaskStatusCreateDialog);

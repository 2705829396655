import { ErrorStore, ProjectsStore } from "../../common/stores";
import {
    computed,
    action,
    runInAction,
    reaction,
    makeObservable,
    observable,
} from "mobx";
import type { TaskStatus } from "../types";
import { UserModel } from "../types";
import { message } from "antd";
import TaskStatusesService from "../services/TaskStatusesService";
import TaskTypesVisualStore from "../../task_types/stores/TaskTypesVisualStore";
import { ActionDefinition } from "../../administration/types/Actions";
import { ActionService } from "../../administration/services/ActionService";
import TasksRootStore from "src/modules/tasks/stores/TasksRootStore";

export default class TaskStatusesVisualStore {
    taskStatuses: TaskStatus[] = [];

    taskStatusesLoading: boolean = false;

    actionDefinitions: ActionDefinition[] = [];

    actionDefinitionsLoading: boolean = false;

    newStatusDialogVisible: boolean = false;

    statusEditDialogVisible: boolean = false;

    statusName: string | undefined = undefined;

    selectedTaskStatus: TaskStatus | undefined = undefined;

    selectedTaskType: string | undefined = undefined;

    taskStatusDependencies = {};

    usersInProject: UserModel[] = [];

    currentStatusUsers: UserModel[] = [];

    selectedProjectId: string;

    searchString: string = "";

    constructor(
        private projectsStore: ProjectsStore,
        private taskStatusesService: TaskStatusesService,
        private taskTypesStore: TaskTypesVisualStore,
        private tasksRootStore: TasksRootStore,
        private actionService: ActionService,
        private errorStore: ErrorStore,
    ) {
        makeObservable<TaskStatusesVisualStore>(this, {
            taskStatuses: observable,
            taskStatusesLoading: observable,
            actionDefinitions: observable,
            actionDefinitionsLoading: observable,
            newStatusDialogVisible: observable,
            statusEditDialogVisible: observable,
            statusName: observable,
            selectedTaskStatus: observable,
            selectedTaskType: observable,
            taskStatusDependencies: observable,
            usersInProject: observable,
            currentStatusUsers: observable,
            selectedProjectId: observable,
            searchString: observable,
            loadTaskTypes: action.bound,
            loadActionDefinitions: action.bound,
            loadUsers: action.bound,
            selectUsers: action.bound,
            setStatusName: action.bound,
            setSelectedTaskType: action.bound,
            setNewStatusDialogVisible: action.bound,
            setTypeEditDialogVisible: action.bound,
            selectStatus: action.bound,
            createTaskStatus: action.bound,
            editTaskStatus: action.bound,
            updateTaskStatus: action.bound,
            toggleTaskStatus: action.bound,
            deleteTaskStatus: action.bound,
            handleTaskStatusDependencyChange: action.bound,
            submitNewStatusDependencies: action.bound,
            updateStatusAssignedUsers: action.bound,
            getStatusUserNames: action.bound,
            setSelectedProjectId: action.bound,
            setSearchString: action,
            taskTypes: computed,
            taskType: computed,
            projects: computed,
            filteredTaskStatuses: computed,
        });
        reaction(
            () => this.taskTypesStore.taskTypes,
            (types) => {
                if (
                    !types
                        .map((t) => t.id)
                        .includes(this.selectedTaskType || "")
                ) {
                    this.selectedTaskType = undefined;
                }
            },
        );
    }

    get taskTypes() {
        return this.taskTypesStore.taskTypes;
    }

    get taskType() {
        return this.taskTypesStore.taskTypes.find(
            (x) => x.id === this.selectedTaskType,
        );
    }

    get projects() {
        return this.projectsStore.administrableProjects;
    }

    get filteredTaskStatuses() {
        if (this.searchString.trim() !== "") {
            const searchStringLower = this.searchString.toLowerCase();
            return this.taskStatuses.filter(
                (m) =>
                    m.name.toLowerCase().includes(searchStringLower) ||
                    m.code?.toLowerCase().includes(searchStringLower),
            );
        }
        return this.taskStatuses;
    }

    loadTaskTypes(projectId: string) {
        this.taskTypesStore.loadTaskTypes(projectId);
    }

    async loadUsers() {
        if (this.selectedProjectId) {
            const response = await this.taskStatusesService.getUsersInProject(
                this.selectedProjectId,
            );
            response.map((users) => {
                runInAction(() => {
                    this.usersInProject = users;
                });
            });
        }
    }

    selectUsers(userIds: string[]) {
        if (this.usersInProject) {
            this.currentStatusUsers = this.usersInProject.filter((u) =>
                userIds.includes(u.id),
            );
        }
    }

    setStatusName(name: string) {
        this.statusName = name;
    }

    setSearchString(searchString: string) {
        this.searchString = searchString;
    }

    async setSelectedTaskType(typeId: string) {
        this.selectedTaskType = typeId;
        await this.loadTaskStatuses(typeId);
    }

    get hasDistinctSubtaskStatuses() {
        return !!this.taskTypes.find((t) => t.id === this.selectedTaskType)
            ?.distinctSubtaskStatuses;
    }

    setNewStatusDialogVisible(visible: boolean) {
        this.statusName = undefined;
        this.newStatusDialogVisible = visible;
    }

    setTypeEditDialogVisible(visible: boolean) {
        this.statusEditDialogVisible = visible;

        if (!visible) {
            this.statusName = undefined;
        }
    }

    selectStatus(status: TaskStatus | undefined) {
        this.selectedTaskStatus = status;
        if (!status) {
            this.statusEditDialogVisible = false;
            this.statusName = undefined;
            this.currentStatusUsers = [];
        } else {
            this.statusName = status.name;
            this.statusEditDialogVisible = true;
            this.currentStatusUsers =
                status.assignedUserIds && this.usersInProject
                    ? this.usersInProject.filter((u) =>
                          status.assignedUserIds!.includes(u.id),
                      )
                    : [];
        }
    }

    setSelectedProjectId(projectId: string) {
        this.selectedProjectId = projectId;
    }

    async loadTaskStatuses(typeId: string) {
        runInAction(() => {
            this.taskStatusesLoading = true;
            this.taskStatuses = [];
            this.taskStatusDependencies = {};
        });

        const response = await this.taskStatusesService.getTaskStatuses(typeId);

        response
            .map((taskStatuses) => {
                runInAction(() => {
                    this.taskStatuses = taskStatuses!;
                    this.taskStatuses.forEach((ts) => {
                        this.taskStatusDependencies[ts.id] = ts.transitions
                            ? ts.transitions.map((t) => t.targetStatusId)
                            : [];
                    });
                });
            })
            .mapErr((err) => this.errorStore.addBasicError(err));

        runInAction(() => {
            this.taskStatusesLoading = false;
        });
    }

    async loadActionDefinitions(projectId: string) {
        runInAction(() => {
            this.actionDefinitionsLoading = true;
            this.actionDefinitions = [];
        });

        const response =
            await this.actionService.getActionDefinitions(projectId);

        response
            .map((definitions: ActionDefinition[]) => {
                runInAction(() => {
                    this.actionDefinitions = definitions;
                    this.actionDefinitionsLoading = false;
                });
            })
            .mapErr((err) => this.errorStore.addBasicError(err));
    }

    async createTaskStatus(
        name: string,
        code: string,
        isSubtaskStatus: boolean,
    ) {
        const userIds = this.currentStatusUsers
            ? this.currentStatusUsers.map((u) => u.id)
            : undefined;
        const response = await this.taskStatusesService.createTaskStatus(
            name,
            code,
            isSubtaskStatus,
            this.selectedTaskType!,
            userIds,
        );
        response
            .map((newStatusId) => {
                runInAction(() => {
                    const newTaskStatuses = this.taskStatuses.slice();
                    newTaskStatuses.push({
                        id: newStatusId,
                        name,
                        code,
                        typeId: this.selectedTaskType!,
                        isEnabled: true,
                        assignedUserIds: userIds,
                        isDefault: false,
                        isSubtaskStatus,
                        isFinal: false,
                    });
                    this.taskStatuses = newTaskStatuses;
                    this.newStatusDialogVisible = false;
                });
            })
            .mapErr((err) => this.errorStore.addBasicError(err));
    }

    async editTaskStatus(name: string, code: string) {
        const statusId = this.selectedTaskStatus!.id;
        const response = await this.taskStatusesService.editTaskStatus(
            statusId,
            name,
            code,
        );
        response
            .map(() => {
                runInAction(() => {
                    const newTaskStatuses = this.taskStatuses.slice();
                    const taskStatusToRename = newTaskStatuses.find(
                        (s) => s.id === statusId,
                    );

                    if (taskStatusToRename) {
                        const index =
                            newTaskStatuses.indexOf(taskStatusToRename);
                        newTaskStatuses[index].name = name;
                        newTaskStatuses[index].code = code;
                    }

                    this.taskStatuses = newTaskStatuses;
                });
            })
            .mapErr((err) => this.errorStore.addBasicError(err));
    }

    async updateTaskStatus(name: string, code: string) {
        await this.editTaskStatus(name, code);
        await this.updateStatusAssignedUsers();
        this.selectStatus(undefined);
    }

    async toggleTaskStatus(statusId: string) {
        const response =
            await this.taskStatusesService.toggleTaskStatus(statusId);
        response
            .map(() => {
                runInAction(() => {
                    const newTaskStatuses = this.taskStatuses.slice();
                    const taskStatusToToggle = newTaskStatuses.find(
                        (s) => s.id === statusId,
                    );

                    if (taskStatusToToggle) {
                        const index =
                            newTaskStatuses.indexOf(taskStatusToToggle);
                        newTaskStatuses[index].isEnabled =
                            !newTaskStatuses[index].isEnabled;
                    }

                    this.taskStatuses = newTaskStatuses;
                });
            })
            .mapErr((err) => {
                this.errorStore.addBasicError(err);
            });
    }

    async deleteTaskStatus(statusId: string) {
        const response =
            await this.taskStatusesService.deleteTaskStatus(statusId);
        response
            .map(() => {
                runInAction(() => {
                    this.loadTaskStatuses(this.selectedTaskType!);
                });
            })
            .mapErr((err) => this.errorStore.addBasicError(err));
    }

    handleTaskStatusDependencyChange(
        checked: boolean,
        baseStatusId: string,
        dependentStatusId: string,
    ) {
        if (!this.taskStatusDependencies[baseStatusId]) {
            this.taskStatusDependencies[baseStatusId] = [];
        }

        if (checked) {
            this.taskStatusDependencies[baseStatusId].push(dependentStatusId);
        } else {
            this.taskStatusDependencies[baseStatusId] =
                this.taskStatusDependencies[baseStatusId].filter(
                    (s: string) => s !== dependentStatusId,
                );
        }
    }

    async submitNewStatusDependencies() {
        const payload = this.taskStatuses.map((t) => ({
            taskStatusId: t.id,
            isDefault: t.isDefault,
            transitions: t.transitions,
            design: {
                ...t.design,
                x: Math.round(t.design!.x),
                y: Math.round(t.design!.y),
            },
            allowedActions: t.allowedActions,
            isFinal: t.isFinal,
        }));

        const response =
            await this.taskStatusesService.updateStatusDependencies(payload);

        response
            .map(() => {
                message.success("Successfully updated status dependencies");
                this.loadTaskStatuses(this.selectedTaskType!);
                this.tasksRootStore.getTaskStatuses();
            })
            .mapErr((err) => this.errorStore.addBasicError(err));
    }

    async updateStatusAssignedUsers() {
        if (this.selectedTaskStatus && this.statusUsersChanged()) {
            const response =
                await this.taskStatusesService.updateStatusAssignedUsers(
                    this.selectedTaskStatus.id,
                    this.currentStatusUsers.map((u) => u.id),
                );
            response
                .map(() => {
                    runInAction(() => {
                        const newTaskStatuses = this.taskStatuses.slice();
                        const taskStatusToToggle = newTaskStatuses.find(
                            (s) => s.id === this.selectedTaskStatus!.id,
                        );

                        if (taskStatusToToggle) {
                            const index =
                                newTaskStatuses.indexOf(taskStatusToToggle);
                            newTaskStatuses[index].assignedUserIds =
                                this.currentStatusUsers.map((u) => u.id);
                        }

                        this.taskStatuses = newTaskStatuses;
                    });
                })
                .mapErr((err) => this.errorStore.addBasicError(err));
        }
    }

    getStatusUserNames(status: TaskStatus) {
        return this.usersInProject
            .filter(
                (u) =>
                    status.assignedUserIds &&
                    status.assignedUserIds.includes(u.id),
            )
            .map((u) => u.userName);
    }

    private statusUsersChanged() {
        if (this.selectedTaskStatus) {
            const lengthChanged =
                (this.selectedTaskStatus.assignedUserIds &&
                    this.selectedTaskStatus.assignedUserIds.length !==
                        this.currentStatusUsers.length) ||
                (!this.selectedTaskStatus.assignedUserIds &&
                    this.currentStatusUsers.length > 0);
            const currentUserIds = this.currentStatusUsers.map((u) => u.id);
            const usersChanged =
                this.selectedTaskStatus.assignedUserIds &&
                !this.selectedTaskStatus.assignedUserIds.every((u) =>
                    currentUserIds.includes(u),
                );
            return lengthChanged || usersChanged;
        }

        return false;
    }
}

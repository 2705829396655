export type DashboardWidget = {
    id: string;
    name: string;
    widgetType: DashboardWidgetType;
};

export enum DashboardWidgetType {
    List = "List",
    PieChart = "PieChart",
    Grouped = "Grouped",
    Numeric = "Numeric",
}

import * as React from "react";
import * as ReactDOM from "react-dom";
import App from "./App";
import "./styles/index.css";
import security from "./modules/common/services/SecurityService";
import "react-virtualized-select/node_modules/react-select/dist/react-select.css";
import "react-virtualized-select/styles.css";
import "@ant-design/flowchart/dist/index.css";
import UserInfoService from "./modules/common/services/UserInfoService";
import { AuthProvider } from "./modules/authorization/AuthContext";
import NoRolesError from "./modules/common/types/CustomErrors";

declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        require: (val: string) => any;
    }
}

const initApp = () => {
    security
        .init()
        .then(() =>
            UserInfoService.getUserInfo().then((userInfo) => {
                // eslint-disable-next-line react/no-deprecated
                ReactDOM.render(
                    <AuthProvider
                        value={{
                            permissions: userInfo!.permissions,
                            objectPermissions: userInfo!.objectPermissions,
                            projectRoles: userInfo.projectRoles,
                        }}
                    >
                        <App />
                    </AuthProvider>,
                    document.getElementById("root") as HTMLElement,
                );
            }),
        )
        .catch((err: object) => {
            if (err instanceof NoRolesError) {
                // eslint-disable-next-line react/no-deprecated
                ReactDOM.render(
                    (err as NoRolesError).errorScreen,
                    document.getElementById("root") as HTMLElement,
                );
            }
        });
};

if (module.hot) {
    module.hot.accept(["./App"], () => {
        // eslint-disable-next-line @typescript-eslint/no-require-imports
        const NextApp = require("./App").default;
        // eslint-disable-next-line react/no-deprecated
        ReactDOM.render(
            <NextApp />,
            document.getElementById("root") as HTMLElement,
        );
    });
}

initApp();

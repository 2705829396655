import * as React from "react";
import { NotificationStore } from "../stores";
import { observer } from "mobx-react-lite";
import { Badge, Popover, Empty } from "antd";
import NotificationList from "./NotificationList";

type Props = {
    store: NotificationStore;
};

const NotificationsContainer: React.FC<Props> = ({ store }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    React.useEffect(() => {
        store.loadNotificationsForUser();
    }, [store]);

    const controlClassName =
        store.unreadNotificationsCount > 0
            ? "user-control has-notifications"
            : "user-control";
    const handleOnOpenChange = (isVisible: boolean) => {
        if (!isVisible) {
            setIsOpen(false);
        }
    };
    return (
        <Popover
            content={
                store.notifications && store.notifications.length ? (
                    <NotificationList
                        hideMenu={() => setIsOpen(false)}
                        store={store}
                        showNavLink
                    />
                ) : (
                    <Empty
                        style={{ width: 400 }}
                        description="No notifications"
                    />
                )
            }
            trigger="click"
            placement="bottomLeft"
            open={isOpen}
            onOpenChange={handleOnOpenChange}
        >
            <div
                className={controlClassName}
                onClick={() => setIsOpen(!isOpen)}
                style={{ cursor: "pointer" }}
            >
                <Badge
                    count={store.unreadNotificationsCount}
                    style={{
                        fontSize: "12px",
                        minWidth: "12px",
                        lineHeight: "16px",
                        height: "16px",
                        transform: "translate(12px, -7px)",
                        boxShadow: "0 0 0 2px #273c75",
                        padding: "0 5px",
                    }}
                >
                    <i
                        className="alpha-icon xs bell-icon"
                        style={{
                            width: 16,
                            height: 16,
                            marginRight: 0,
                            verticalAlign: "middle",
                        }}
                    />
                </Badge>
            </div>
        </Popover>
    );
};

export default observer(NotificationsContainer);

import { Button, Select, Tooltip } from "antd";
import * as React from "react";
import { Utils } from "src/modules/common/misc/Utils";
import { useAdminStore } from "../stores/context";
import { FilterFilled } from "@ant-design/icons";
import { Observer } from "mobx-react-lite";

export const UsersColumnsFilters = (columnName: string) => {
    const store = useAdminStore().usersManagerStore;

    const handleFilterResetClick = (event: React.MouseEvent) => {
        event.stopPropagation();
        store.resetColumnFilter(columnName);
    };

    const isFilterApplied = store.storedFilters[columnName];

    const getFilterIconStyle = () => {
        return { color: isFilterApplied ? "#1677ff" : undefined };
    };

    return {
        filterDropdown: ({
            confirm,
        }: {
            confirm: (params: { closeDropdown: boolean }) => void;
        }) => {
            let widget: React.ReactElement;
            switch (columnName) {
                case "projects":
                    widget = (
                        <Observer>
                            {() => (
                                <Select
                                    placeholder="Select projects"
                                    filterOption={Utils.filterOption}
                                    mode="multiple"
                                    onChange={(val) =>
                                        store.setCurrentFilterValue(
                                            columnName,
                                            val,
                                        )
                                    }
                                    value={
                                        store.tempColumnsFilterDict[
                                            columnName
                                        ] || []
                                    }
                                    options={store.projects.map((p) => ({
                                        value: p.id,
                                        label: p.name,
                                    }))}
                                />
                            )}
                        </Observer>
                    );
                    break;
                case "roles":
                    widget = (
                        <Observer>
                            {() => (
                                <Select
                                    placeholder="Select roles"
                                    mode="multiple"
                                    filterOption={Utils.filterOption}
                                    value={
                                        store.tempColumnsFilterDict[
                                            columnName
                                        ] || []
                                    }
                                    onChange={(val) =>
                                        store.setCurrentFilterValue(
                                            columnName,
                                            val,
                                        )
                                    }
                                    options={[
                                        {
                                            value: "admin",
                                            label: "System admin",
                                        },
                                        { value: "user", label: "User" },
                                    ]}
                                />
                            )}
                        </Observer>
                    );
                    break;
                default:
                    widget = <></>;
            }
            return (
                <div className="table-filter-popup">
                    {widget}
                    <div className="controls-container">
                        <Button
                            onClick={() => {
                                confirm({ closeDropdown: true });
                                if (!store.storedFilters[columnName]) {
                                    store.setCurrentFilterValue(columnName, []);
                                } else {
                                    store.setCurrentFilterValue(
                                        columnName,
                                        store.storedFilters[columnName],
                                    );
                                }
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => {
                                confirm({ closeDropdown: true });
                                store.applyColumnFilter(columnName);
                            }}
                            style={{ marginLeft: 10 }}
                        >
                            Apply
                        </Button>
                    </div>
                </div>
            );
        },
        filterIcon: (
            <span style={{ marginRight: 5 }}>
                <FilterFilled style={getFilterIconStyle()} />
                <Tooltip title="Reset filter">
                    <i
                        style={{ marginLeft: 10 }}
                        className={`alpha-icon xs filter-reset ${isFilterApplied ? "visible" : ""}`}
                        onClick={handleFilterResetClick}
                    />
                </Tooltip>
            </span>
        ),
    };
};

import * as React from "react";
import { TaskViewVisualStore } from "../stores";
import { observer } from "mobx-react-lite";
import { Avatar, Tooltip } from "antd";
import TasksGridVisualStore from "../stores/TasksGridVisualStore";

type Props = {
    userId: string | null;
    userName: string | null;
    store: TaskViewVisualStore | TasksGridVisualStore;
    size?: number | "small" | "large" | "default" | undefined;
    className?: string;
    tooltipMsg?: string;
    emptyAvatarClassName?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    style?: any;
};

const TaskUserProfilePicture: React.FC<Props> = ({
    userId,
    userName,
    store,
    className,
    size,
    style = {},
    tooltipMsg,
    emptyAvatarClassName,
}) => {
    // const sizeToStyleMeapping = {16: 'xs', 24: 'md', 12: 'xxs'};

    React.useEffect(() => {
        if (userId && !(userId in store.userProfilePictures)) {
            store.getUserProfilePicture(userId);
        }
    }, [store, userId]);

    const getUserNameInitials = () => {
        const initials = userName?.match(/\b\w/g) || [];
        const initialsString = (
            (initials.shift() || "") + (initials.pop() || "")
        ).toUpperCase();
        return initialsString;
    };

    if (!userName) {
        return (
            <Tooltip title={tooltipMsg}>
                <i
                    style={{ ...style, marginRight: 0, marginBottom: 2 }}
                    className={`alpha-icon ${emptyAvatarClassName || "md"} user`}
                />
            </Tooltip>
        );
    }

    return (
        <Tooltip title={tooltipMsg}>
            {store.userProfilePictures[userId!] ? (
                <Avatar
                    style={style}
                    className={className}
                    size={size}
                    src={store.userProfilePictures[userId!]}
                />
            ) : (
                <Avatar style={style} className={className} size={size}>
                    {getUserNameInitials()}
                </Avatar>
            )}
        </Tooltip>
    );
};

export default observer(TaskUserProfilePicture);

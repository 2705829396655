import React from "react";
import { ErrorStore, ProjectsStore, useStore } from "../../common/stores";
import ReportsService from "../services/ReportsService";
import ReportsStore from "./ReportsStore";
import { useLocalObservable } from "mobx-react-lite";

function createStore(errorStore: ErrorStore, projectsStore: ProjectsStore) {
    const service = new ReportsService();
    const store = new ReportsStore(projectsStore, errorStore, service);
    return {
        store,
    };
}

const storeContext = React.createContext<ReturnType<typeof createStore> | null>(
    null,
);

type Props = {
    children: React.ReactNode;
};

export const StoreProvider: React.FC<Props> = ({ children }) => {
    const root = useStore();
    const store = useLocalObservable(() =>
        createStore(root.errorStore, root.projectsStore),
    );
    return (
        <storeContext.Provider value={store}>{children}</storeContext.Provider>
    );
};

export const useReportsStore = () => {
    const store = React.useContext(storeContext);

    if (!store) {
        // this is especially useful in TypeScript so you don't need to be checking for null all the time
        throw new Error("You have forgot to use StoreProvider, shame on you.");
    }

    return store;
};

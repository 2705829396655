import React, { useState } from "react";
import { Button, Card, Col, Row, Select, Table, Tag, Tooltip } from "antd";
import type { TableColumnsType } from "antd";
import type { ResizeCallbackData } from "react-resizable";
import { Resizable } from "react-resizable";
import {
    TasksGridVisualStore,
    TaskViewVisualStore,
    TasksFiltersVisualStore,
} from "../stores";
import { TaskListModel } from "../types";
import { Observer, observer } from "mobx-react-lite";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import ChangePrivacyDialog from "./ChangePrivacyDialog";
import { ScrollToSections } from "../stores/TaskViewVisualStore";
import { FULL_DATE, ONLY_TIME, Utils } from "src/modules/common/misc/Utils";
import TaskUserProfilePicture from "./TaskUserProfilePicture";
import { TaskColumnsFilters } from "./TaskColumnsFilters";
import { ColumnProps } from "antd/lib/table";
import { AppPermissions } from "src/modules/authorization/Permissions";
import { hasPermission } from "src/modules/authorization/components/HasPermission";

const ResizableTitle = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    props: React.HTMLAttributes<any> & {
        onResize: (
            e: React.SyntheticEvent<Element>,
            data: ResizeCallbackData,
        ) => void;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onClick: (e: any) => void;
        width: number;
    },
) => {
    const { onResize, onClick, width, ...restProps } = props;

    if (!width) {
        return <th {...restProps} />;
    }

    return (
        <Resizable
            width={width}
            height={0}
            handle={<span className="react-resizable-handle" />}
            onResize={onResize}
            draggableOpts={{ enableUserSelectHack: false }}
        >
            <th
                {...restProps}
                onClick={(e) => {
                    if (
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        !(e.target as any).className.includes("react-resizable")
                    ) {
                        onClick(e);
                    }
                }}
            />
        </Resizable>
    );
};

type Props = {
    store: TasksGridVisualStore;
    taskPreviewStore: TaskViewVisualStore;
    tasksFiltersStore: TasksFiltersVisualStore;
    userPermissions: string[];
};

const TaskList: React.FC<Props> = ({
    store,
    taskPreviewStore,
    tasksFiltersStore,
    userPermissions,
}) => {
    const [privacyChangeTaskId, setPrivacyChangeTaskId] = React.useState("");
    const [isChangePrivacyDialogVisible, setIsChangePrivacyDialogVisible] =
        React.useState(false);

    const { widgetId, activityType, projectId, value } = useParams();
    const [searchParams] = useSearchParams();
    const activityPeriod = searchParams.get("period");
    const applyButtonRef = React.useRef<Record<string, HTMLElement>>({});

    React.useEffect(() => {
        if (!store.layoutIsLoading) {
            store.init(
                widgetId,
                activityType,
                activityPeriod,
                projectId,
                value,
            );
        }
    }, [store.layoutIsLoading]);

    const allCollumns: ColumnProps<TaskListModel>[] = [
        {
            title: "Identifier",
            dataIndex: "alphaId",
            width: 100,
            sorter: true,
            sortOrder:
                store.sortOrder.field === "alphaId"
                    ? store.sortOrder.order
                    : undefined,
            render: (alphaId: string, task: TaskListModel) => (
                <Button
                    key="task-id"
                    size="small"
                    type="link"
                    title={alphaId}
                    onClick={() => taskPreviewStore.togglePreview(task)}
                    className="entry-title task-button"
                >
                    <span className={"task-name"}>{alphaId}</span>
                </Button>
            ),
            onCell: () => ({ "data-id-cells": "tasks" }) as object,
        },
        {
            title: "Name",
            dataIndex: "name",
            filterSearch: true,
            width: 300,
            sorter: true,
            sortOrder:
                store.sortOrder.field === "name"
                    ? store.sortOrder.order
                    : undefined,
            render: (name: string, task: TaskListModel) => {
                const icons = getIcons(task);
                const getWidth = () => {
                    switch (icons.length) {
                        case 0:
                            return "100%";
                        case 1:
                            return "80%";
                        case 2:
                            return "70%";
                        case 3:
                            return "50%";
                        default:
                            return "100%";
                    }
                };
                return (
                    <>
                        <Button
                            key="task-name"
                            size="small"
                            type="link"
                            title={task.name}
                            onClick={() => taskPreviewStore.togglePreview(task)}
                            className="entry-title task-button name"
                            style={{ maxWidth: getWidth() }}
                        >
                            <span className={"task-name"}>{name}</span>
                        </Button>
                        <div className="task-name-info-icons">{icons}</div>
                    </>
                );
            },
            onCell: () => ({ "data-id-cells": "name" }) as object,
        },
        {
            title: "Project",
            dataIndex: "projectId",
            width: 100,
            sorter: true,
            sortOrder:
                store.sortOrder.field === "projectId"
                    ? store.sortOrder.order
                    : undefined,
            filterSearch: true,
            render: (prjId: string) => (
                <span>{store.getProjectName(prjId)}</span>
            ),
            onCell: () => ({ "data-id-cells": "project" }) as object,
        },
        {
            title: "Type",
            dataIndex: "taskType",
            width: 100,
            sorter: true,
            sortOrder:
                store.sortOrder.field === "taskType"
                    ? store.sortOrder.order
                    : undefined,
            ...TaskColumnsFilters(
                "taskType",
                tasksFiltersStore,
                applyButtonRef,
            ),
            render: (taskType: string) => (
                <span>{store.getTaskName(taskType)}</span>
            ),
            onCell: () => ({ "data-id-cells": "type" }) as object,
        },
        {
            title: "Status",
            dataIndex: "statusName",
            width: 100,
            sorter: (a, b) =>
                Utils.safeStringLocaleCompare(a.statusName, b.statusName),
            sortOrder:
                store.sortOrder.field === "statusName"
                    ? store.sortOrder.order
                    : undefined,
            ...TaskColumnsFilters("status", tasksFiltersStore, applyButtonRef),
            render: (statusName: string) => <span>{statusName}</span>,
            onCell: () => ({ "data-id-cells": "status" }) as object,
        },
        {
            title: "Due Date",
            dataIndex: "dueDate",
            width: 100,
            sorter: true,
            sortOrder:
                store.sortOrder.field === "dueDate"
                    ? store.sortOrder.order
                    : undefined,
            ...TaskColumnsFilters("dueDate", tasksFiltersStore, applyButtonRef),
            render: (dueDate: string, task: TaskListModel) => (
                <span
                    style={{
                        color:
                            new Date(dueDate) <= new Date() && !task.isDone
                                ? "red"
                                : "",
                    }}
                >
                    {Utils.formatDateStringShort(dueDate, true, true)}
                </span>
            ),
            onCell: () => ({ "data-id-cells": "due date" }) as object,
        },
        {
            title: "Attachments",
            dataIndex: "attachmentsCount",
            width: 100,
            sorter: (a, b) => a.attachmentsCount - b.attachmentsCount,
            sortOrder:
                store.sortOrder.field === "attachmentsCount"
                    ? store.sortOrder.order
                    : undefined,
            ...TaskColumnsFilters(
                "attachmentsCount",
                tasksFiltersStore,
                applyButtonRef,
            ),
            render: (attachmentsCount: string) => (
                <span>{attachmentsCount}</span>
            ),
            onCell: () => ({ "data-id-cells": "attachments" }) as object,
        },
        {
            title: "Owner",
            dataIndex: "createdBy",
            width: 100,
            sorter: true,
            sortOrder:
                store.sortOrder.field === "createdBy"
                    ? store.sortOrder.order
                    : undefined,
            ...TaskColumnsFilters(
                "createdBy",
                tasksFiltersStore,
                applyButtonRef,
            ),
            render: (createdBy: string) => {
                const isDeletedUser = store.userProfiles.some(
                    (u) => u.userId === createdBy && u.isDeleted,
                );
                return (
                    <Observer>
                        {() => (
                            <div
                                style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                }}
                            >
                                {getAvatar(createdBy)}
                                <span style={{ verticalAlign: "middle" }}>
                                    {store.usersLoaded
                                        ? `${store.allUsersFullNameResolver[createdBy]}`
                                        : ""}
                                </span>
                                <span
                                    style={{
                                        verticalAlign: "middle",
                                        color: "red",
                                    }}
                                >
                                    {isDeletedUser ? " (Inactive)" : ""}
                                </span>
                            </div>
                        )}
                    </Observer>
                );
            },
            onCell: () => ({ "data-id-cells": "Owner" }) as object,
        },
        {
            title: "Date Created",
            dataIndex: "createDate",
            width: 100,
            sorter: true,
            sortOrder:
                store.sortOrder.field === "createDate"
                    ? store.sortOrder.order
                    : undefined,
            ...TaskColumnsFilters(
                "createDate",
                tasksFiltersStore,
                applyButtonRef,
            ),
            render: (date: string) => {
                const formattedDate = Utils.formatDateStringShort(
                    date,
                    true,
                    true,
                );
                return (
                    <Tooltip title={formattedDate}>
                        <span>{formattedDate}</span>
                    </Tooltip>
                );
            },
            onCell: (record: TaskListModel) =>
                ({
                    "data-id-cells": "Date Created",
                    "data-id-timestamp": new Date(
                        record.createDate,
                    ).toISOString(),
                }) as object,
        },
        {
            title: "Assigned To",
            dataIndex: "assignedTo",
            width: 100,
            sorter: (a, b) =>
                Utils.safeStringLocaleCompare(a.assignedTo, b.assignedTo),
            sortOrder:
                store.sortOrder.field === "assignedTo"
                    ? store.sortOrder.order
                    : undefined,
            ...TaskColumnsFilters(
                "assignedTo",
                tasksFiltersStore,
                applyButtonRef,
            ),
            render: (userId: string) => {
                const isDeletedUser = store.userProfiles.some(
                    (u) => u.userId === userId && u.isDeleted,
                );
                return (
                    userId && (
                        <Observer>
                            {() => (
                                <div className="assigned-to">
                                    {getAvatar(userId)}
                                    <span style={{ verticalAlign: "middle" }}>
                                        {store.usersLoaded
                                            ? `${store.allUsersFullNameResolver[userId]}`
                                            : ""}
                                    </span>
                                    <span
                                        style={{
                                            verticalAlign: "middle",
                                            color: "red",
                                        }}
                                    >
                                        {isDeletedUser ? " (Inactive)" : ""}
                                    </span>
                                </div>
                            )}
                        </Observer>
                    )
                );
            },
            onCell: () => ({ "data-id-cells": "assigned to" }) as object,
        },
        {
            title: "Priority",
            dataIndex: "priority",
            width: 100,
            sorter: (a, b) => Utils.priorityCompare(a.priority, b.priority),
            sortOrder:
                store.sortOrder.field === "priority"
                    ? store.sortOrder.order
                    : undefined,
            ...TaskColumnsFilters(
                "priority",
                tasksFiltersStore,
                applyButtonRef,
            ),
            render: (priority: string, task: TaskListModel) => {
                return (
                    <Select
                        className={`priority-select ${priority}`}
                        value={priority}
                        style={{ width: "100%", minWidth: 115 }}
                        onChange={(option: unknown) =>
                            store.updateField(task.id, "Priority", option)
                        }
                    >
                        <Select.Option value="LOW">
                            <Tag color="#9BA0AA" className="alpha-info-tag">
                                Low
                            </Tag>
                        </Select.Option>
                        <Select.Option value="MEDIUM">
                            <Tag color="#FADB14" className="alpha-info-tag">
                                Medium
                            </Tag>
                        </Select.Option>
                        <Select.Option value="HIGH">
                            <Tag color="#F5222D" className="alpha-info-tag">
                                High
                            </Tag>
                        </Select.Option>
                    </Select>
                );
            },
            onCell: () => ({ "data-id-cells": "priority" }) as object,
        },
    ];

    const metadataColumns = store.tableMetaData
        .filter(
            (m) =>
                m.isVisible ||
                hasPermission(
                    userPermissions,
                    AppPermissions.CanAccessAdministration,
                ),
        )
        .map((m) => ({
            key: m.name,
            title: m.title,
            dataIndex: "metadata",
            width: 200,
            sorter: true,
            ...TaskColumnsFilters(
                m.name,
                tasksFiltersStore,
                applyButtonRef,
                m.fieldType,
                m,
            ),
            sortOrder:
                store.sortOrder.field === `metadata.${m.name}`
                    ? store.sortOrder.order
                    : undefined,
            render: (metadata: { [key: string]: unknown } | null) => {
                let val = metadata ? metadata[m.name] : null;
                switch (m.fieldType) {
                    // TO-DO: May be need to use polymorphism based on field type. This way we can handle format field in nicer way
                    case "DateTime":
                        if (m.format === FULL_DATE) {
                            val = Utils.formatDateStringShort(
                                val as string,
                                false,
                                false,
                                false,
                                true,
                            );
                            break;
                        }
                        if (m.format === ONLY_TIME) {
                            val = Utils.formatDateStringShort(
                                val as string,
                                false,
                                false,
                                true,
                                true,
                            );
                            break;
                        }
                        if (m.format === ONLY_TIME) {
                            val = Utils.formatDateStringShort(
                                val as string,
                                false,
                                false,
                                true,
                                true,
                            );
                            break;
                        }
                        val = Utils.formatDateStringShort(
                            val as string,
                            true,
                            true,
                            false,
                            true,
                        );
                        break;
                    case "Text":
                        if (m.format && val) {
                            const { prefix, suffix } = JSON.parse(m.format);
                            return `${prefix ?? ""}${val}${suffix ?? ""}`;
                        }
                        return val;
                    case "Boolean":
                        if (m.format && val !== undefined) {
                            const { trueVal, falseVal } = JSON.parse(m.format);
                            if (val && trueVal) {
                                return trueVal;
                            }
                            if (val === false && falseVal) {
                                return falseVal;
                            }
                        }
                        return (val as boolean)?.toString() ?? "";
                    case "Number":
                        if (m.format && val) {
                            const { precision, separator } = JSON.parse(
                                m.format,
                            );
                            if (separator) {
                                const fractionDigits =
                                    precision ??
                                    val.toString().split(".")[1]?.length ??
                                    0;
                                val = (val as number).toLocaleString(
                                    undefined,
                                    {
                                        maximumFractionDigits: fractionDigits,
                                        minimumFractionDigits: fractionDigits,
                                    },
                                );
                            } else if (precision) {
                                val = (val as number).toFixed(precision);
                            } else {
                                return val;
                            }
                        }
                        return val;
                    default:
                        break;
                }
                return <span>{val ? val : ""}</span>;
            },
            onCell: () => ({ "data-id-cells": m.title }) as object,
        }));

    const [columns, setColumns] = useState<TableColumnsType<TaskListModel>>([
        ...allCollumns,
        ...metadataColumns,
    ]);

    React.useEffect(() => {
        let filteredCols = store.isMetadataViewEnabled
            ? [allCollumns[0], allCollumns[1], ...metadataColumns]
            : [...allCollumns, ...metadataColumns];
        filteredCols = store.taskColumns.length
            ? (store.taskColumns
                  .filter((x) => x.isVisible)
                  .map((x) => {
                      const filtered = filteredCols.find(
                          (col) => col.title === x.name,
                      );
                      filtered!.width = x.width || 100;
                      return filtered;
                  })
                  .filter((x) => x) as ColumnProps<TaskListModel>[])
            : filteredCols;
        setColumns(filteredCols);
    }, [store.taskColumns, store.isMetadataViewEnabled]);

    const getAvatar = (userId: string) => {
        return (
            <TaskUserProfilePicture
                style={{ marginRight: 10, verticalAlign: "middle" }}
                store={store}
                size={24}
                userId={userId}
                userName={store.allUsersFullNameResolver[userId]}
            />
        );
    };

    const handlePrivacyClick = (task: TaskListModel) => {
        if (task.taskId) {
            return;
        }
        store.loadUsersForProject(task.projectId);
        setPrivacyChangeTaskId(task.id);
        setIsChangePrivacyDialogVisible(true);
    };

    const handleSubtasksClick = (task: TaskListModel) => {
        taskPreviewStore.togglePreview(task, ScrollToSections.Subtasks);
    };

    const getTaskNameCss = (task: TaskListModel) => {
        const privacyCss = `access-type alpha-icon xs ${task.accessType === "OnlyMe" ? "lock" : "limited-access"}`;
        return privacyCss;
    };

    const getIcons = (task: TaskListModel) => {
        const icons = [];
        if (task.accessType !== "Everyone") {
            icons.push(
                <Tooltip
                    title={
                        task.accessType === "LimitedAccess"
                            ? "Limited access"
                            : "Only me"
                    }
                >
                    <i
                        key="accessType"
                        style={{ marginRight: 9 }}
                        onClick={() => handlePrivacyClick(task)}
                        className={getTaskNameCss(task)}
                    />
                </Tooltip>,
            );
        }

        const handleAttachmentsClick = () => {
            taskPreviewStore.setSectionActiveKey("attachments", true);
            taskPreviewStore.togglePreview(task, ScrollToSections.Attachments);
        };

        const subTasksСount = task.subTasksMeta?.length;
        if (subTasksСount) {
            icons.push(
                <div
                    key="subtasks"
                    className="info-sub-container"
                    onClick={() => handleSubtasksClick(task)}
                >
                    <Tooltip title="Subtasks">
                        <i className="alpha-icon checked-bordered xxxs" />
                    </Tooltip>
                    <span style={{ fontSize: 10, color: "#9BA0AA" }}>
                        {subTasksСount}
                    </span>
                </div>,
            );
        }

        if (task.attachmentsCount) {
            icons.push(
                <div key="attachments" className="info-sub-container">
                    <Tooltip title="Attachments">
                        <i
                            className="alpha-icon attachment xxxs"
                            onClick={handleAttachmentsClick}
                        />
                    </Tooltip>
                    <span style={{ fontSize: 10, color: "#9BA0AA" }}>
                        {task.attachmentsCount}
                    </span>
                </div>,
            );
        }

        return icons;
    };

    // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
    const handleResize: Function =
        (index: number) =>
        (_: React.SyntheticEvent<Element>, { size }: ResizeCallbackData) => {
            const newColumns = [...columns];
            newColumns[index] = {
                ...newColumns[index],
                width: size.width,
            };
            setColumns(newColumns);
            store.handleColumnWidthChange(
                columns[index].title! as string,
                size.width,
            );
        };

    const getColumnFilterKey = (
        col: TableColumnsType<TaskListModel>[number] & { dataIndex: string },
    ) => {
        switch (col.dataIndex) {
            case "statusName":
                return "status";
            case "metadata":
                return col.key! as string;
            default:
                return col.dataIndex as string;
        }
    };

    const getSortOrder = (col: ColumnProps<TaskListModel>) => {
        if (col.dataIndex === "metadata") {
            return `metadata.${col.key}` === store.sortOrder.field
                ? store.sortOrder.order
                : undefined;
        }
        return col.dataIndex === store.sortOrder.field
            ? store.sortOrder.order
            : undefined;
    };

    const mergedColumns = columns.map<TableColumnsType<TaskListModel>[number]>(
        (col, index) => ({
            ...col,
            sortOrder: getSortOrder(col as ColumnProps<TaskListModel>),
            sorter: true,
            filterIcon: TaskColumnsFilters(
                getColumnFilterKey(
                    col as TableColumnsType<TaskListModel> & {
                        dataIndex: string;
                    },
                ),
                tasksFiltersStore,
                applyButtonRef,
            ).filterIcon,
            onHeaderCell: (
                column: TableColumnsType<TaskListModel>[number] & {
                    dataIndex: string;
                },
            ) => ({
                width: column.width,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onResize: handleResize(index) as React.ReactEventHandler<any>,
                className: tasksFiltersStore.columnsFilterDict[
                    getColumnFilterKey(column)
                ]?.saved
                    ? "isFiltered"
                    : "",
                ...TaskColumnsFilters(
                    getColumnFilterKey(column),
                    tasksFiltersStore,
                    applyButtonRef,
                ),
            }),
        }),
    );

    const handleRowClick = (
        event: React.SyntheticEvent<Element, Event>,
        record: TaskListModel,
    ) => {
        const element = event.target as Element;
        if (element.matches("td")) {
            taskPreviewStore.togglePreview(record);
        }
    };

    const getRowClassName = (task: TaskListModel) => {
        let className = store.taskStatuses.find((t) => t.id === task.status)
            ?.isFinal
            ? "closed"
            : "";

        if (task.isSelected) {
            className += " selected";
        }

        return className;
    };

    const handlePageChange = (page: number, pageSize: number) => {
        store.setCurrentPage(page);
        store.setPageSize(pageSize);
        if (activityType) {
            store.getTasksByActivityType(
                activityType,
                store.selectedProject,
                activityPeriod!,
            );
        } else if (widgetId && !value) {
            store.getTasksByGroupedWidgetId(widgetId, store.selectedProject);
        } else if (widgetId && value) {
            store.getTasksByPieChartSection(
                widgetId,
                store.selectedProject,
                value,
            );
        } else {
            store.loadTasks();
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleTableChange = (pag: any, filt: any, sorter: any) => {
        if (
            store.sortOrder.field === sorter.field &&
            store.sortOrder.order === sorter.order
        ) {
            return;
        }
        const columnTitle =
            sorter.field === "metadata"
                ? store.tableMetaData.find((s) => s.name === sorter.columnKey)!
                      .name
                : sorter.columnKey;
        if (!sorter.column && store.sortOrder.field !== "createDate") {
            store.setSortOrder({ field: "createDate", order: "descend" });
        } else {
            store.setSortOrder({
                field:
                    sorter.field === "metadata"
                        ? `metadata.${columnTitle}`
                        : sorter.field,
                order: sorter.order,
            });
        }
        if (widgetId && !value) {
            store.getTasksByGroupedWidgetId(widgetId, store.selectedProject);
        } else if (value) {
            store.getTasksByPieChartSection(
                widgetId!,
                store.selectedProject,
                value,
            );
        } else if (activityType) {
            store.getTasksByActivityType(
                activityType,
                store.selectedProject,
                activityPeriod!,
            );
        } else {
            store.loadTasks();
        }
    };

    return (
        <div className="tasks-list" data-id="task-list-root">
            {privacyChangeTaskId && (
                <ChangePrivacyDialog
                    task={
                        store.tasks.find((t) => t.id === privacyChangeTaskId)!
                    }
                    store={store}
                    isDialogVisible={isChangePrivacyDialogVisible}
                    closeDialog={() => setIsChangePrivacyDialogVisible(false)}
                />
            )}
            <Card
                style={{ border: "none", minHeight: "calc(100vh - 186px)" }}
                className="content-card shadow table-content"
            >
                <Row>
                    <Col span={24}>
                        <Table
                            data-id={"table-task-list"}
                            className="nested-table alpha-table no-border-top fixed sm-rows lg-title"
                            rowSelection={{
                                selectedRowKeys: store.selectedRows,
                                onChange: (selectedRowKeys, selectedRows) => {
                                    store.setSelectedRows(
                                        selectedRowKeys as string[],
                                    );
                                    store.setSelectedRowModels(selectedRows);
                                },
                                columnWidth: 60,
                                // TODO: Looks ugly ${record.parentTask?.alphaId.prefix}. We should introduce task as a class and use dedicated methods
                                renderCell: (
                                    val: boolean,
                                    record: TaskListModel,
                                    index: number,
                                    originNode: React.ReactNode,
                                ) => {
                                    return (
                                        <div>
                                            <Tooltip
                                                title={`${record.parentTask?.alphaId.prefix}-${record.parentTask?.alphaId.index} ${record.parentTask?.name}`}
                                            >
                                                <div className="subtask-indicator">
                                                    {record.taskId ? (
                                                        <i className="alpha-icon subtask-icon xs" />
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            </Tooltip>
                                            {originNode}
                                        </div>
                                    );
                                },
                            }}
                            rowKey={(r) => r.id}
                            rowClassName={getRowClassName}
                            style={{ borderTop: "none", width: "100%" }}
                            onRow={(record) => {
                                return {
                                    onClick: (e) => handleRowClick(e, record),
                                };
                            }}
                            components={{
                                header: {
                                    cell: ResizableTitle,
                                },
                            }}
                            columns={mergedColumns}
                            loading={store.tasksLoading}
                            onChange={handleTableChange}
                            dataSource={store.tasks}
                            pagination={{
                                defaultPageSize: store.pageSize,
                                total: store.tasksCount,
                                onChange: handlePageChange,
                                current: store.currentPage,
                            }}
                            scroll={{ y: "calc(100vh - 300px)" }}
                        />
                    </Col>
                </Row>
            </Card>
        </div>
    );
};

export default observer(TaskList);

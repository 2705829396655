import { Input, Tag, Table, Tooltip, Select } from "antd";
import { Observer, observer } from "mobx-react-lite";
import * as React from "react";
import { TaskViewVisualStore } from "../stores";
import { SubtaskMeta } from "../types";

import { ColumnProps } from "antd/lib/table";
import { useNavigate } from "react-router";
import { useStore } from "../../common/stores";
import TaskUserProfilePicture from "./TaskUserProfilePicture";

type Props = {
    store: TaskViewVisualStore;
    taskId: string;
    parentTaskStatus: string;
    subTaskMeta: SubtaskMeta[] | undefined;
    isFullView: boolean;
};

const TaskPreviewSubtask: React.FC<Props> = ({
    store,
    taskId,
    parentTaskStatus,
    subTaskMeta,
    isFullView,
}) => {
    const navigate = useNavigate();
    const { errorStore, tabsStore } = useStore();

    const [editableSubtaskName, setEditableSubtaskName] = React.useState("");

    const saveSubtask = (
        event:
            | React.FocusEvent<HTMLInputElement>
            | React.KeyboardEvent<HTMLInputElement>,
    ) => {
        setEditableSubtaskName("");
        if (event.currentTarget.value) {
            store.saveSubTask(event.currentTarget.value, taskId);
        }
    };

    const handleSubtaskClick = async (subtaskId: string) => {
        if (isFullView) {
            store.setPageIsLoading(true);
            tabsStore.removeTaskStore(taskId);
            navigate(`/tasks/${subtaskId}`);
            return;
        }
        const subtask = store.tasks.find((t) => t.id === subtaskId);
        if (subtask) {
            store.togglePreview(subtask);
        } else {
            store.setTasksIsLoading(true);
            const resp = await store.getTaskById(subtaskId);
            resp.map((taskById) => {
                store.togglePreview(taskById);
                store.setTasksIsLoading(false);
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            }).mapErr((err: any) => {
                errorStore.addError(err.data);
                store.setTasksIsLoading(false);
            });
        }
    };

    const getStatuses = (statusId: string) => {
        const currentStatus = store.taskStatusesForType.find(
            (s) => s.id === statusId,
        );
        if (!currentStatus) {
            return [];
        }
        const currentStatusOption = {
            label: currentStatus.name,
            value: statusId,
        };
        if (currentStatus.transitions?.length) {
            const targetedStatuses = currentStatus.transitions.map(
                (t) => t.targetStatusId,
            );
            const enabledTargetStatuses = targetedStatuses.filter(
                (id) =>
                    store.taskStatusesForType.find((status) => status.id === id)
                        ?.isEnabled,
            );
            const targetedStatusesOptions = enabledTargetStatuses.map((t) => ({
                value: t,
                label: store.taskStatusesForType.find((s) => s.id === t)!.name,
            }));
            return [currentStatusOption, ...targetedStatusesOptions];
        }
        return [currentStatusOption];
    };

    const handleStatusChange = (subtaskId: string, statusId: string) => {
        const callback = () => {
            const subtaskMeta = store.taskPreview!.subTasksMeta!.find(
                (s) => s.subtaskId === subtaskId,
            )!;
            subtaskMeta.statusId = statusId;
        };
        store.updateField(subtaskId, "Status", statusId, callback);
    };

    const columns: ColumnProps<SubtaskMeta>[] = [
        {
            width: isFullView ? 150 : undefined,
            render: (_: string, subtask: SubtaskMeta) => {
                return {
                    children: (
                        <Select
                            style={{ width: "100%" }}
                            options={getStatuses(subtask.statusId)}
                            defaultValue={
                                store.taskStatusesLoading
                                    ? null
                                    : subtask.statusId
                            }
                            onChange={(val) =>
                                handleStatusChange(subtask.subtaskId, val)
                            }
                            loading={store.taskStatusesLoading}
                        />
                    ),
                };
            },
        },
        {
            width: 30,
            render: () => {
                return {
                    children: <i className="alpha-icon subtask-icon xs" />,
                };
            },
        },
        {
            width: 80,
            render: (_: string, subtask: SubtaskMeta) => {
                return {
                    children: (
                        <>
                            {subtask.alphaId && (
                                <Tag color="#273C75">{`${subtask.alphaId.prefix}-${subtask.alphaId.index}`}</Tag>
                            )}
                        </>
                    ),
                };
            },
        },
        {
            width: isFullView ? undefined : "20%",
            render: (_: string, subtask: SubtaskMeta) => {
                return {
                    children: (
                        <Tooltip title={subtask.name}>
                            <div
                                className="sub-task-name"
                                onClick={() =>
                                    handleSubtaskClick(subtask.subtaskId)
                                }
                            >
                                {subtask.name}
                            </div>
                        </Tooltip>
                    ),
                };
            },
        },
        {
            width: 50,
            render: (_: string, subtask: SubtaskMeta) => {
                return {
                    children: (
                        <Observer>
                            {() => (
                                <>
                                    {subtask.assignedTo && (
                                        <TaskUserProfilePicture
                                            store={store}
                                            style={{ marginLeft: 8 }}
                                            className="sender-avatar"
                                            userName={
                                                subtask.assignedTo &&
                                                store.allUsersFullNameResolver[
                                                    subtask.assignedTo
                                                ]
                                            }
                                            userId={subtask.assignedTo}
                                            size={24}
                                            tooltipMsg={
                                                subtask.assignedTo
                                                    ? store
                                                          .allUsersFullNameResolver[
                                                          subtask.assignedTo
                                                      ]
                                                    : ""
                                            }
                                        />
                                    )}
                                </>
                            )}
                        </Observer>
                    ),
                };
            },
        },
    ];

    const inputDisabled =
        store.getStatusById(parentTaskStatus)?.isFinal || store.taskFormLocked;
    return (
        <div>
            {subTaskMeta?.length ? (
                <Table
                    rowKey={(r) => r.subtaskId}
                    dataSource={subTaskMeta ?? []}
                    columns={columns}
                    pagination={false}
                    showHeader={false}
                    size="small"
                    tableLayout="fixed"
                />
            ) : (
                <></>
            )}
            <div className="sub-task-item">
                <div style={{ color: "#9BA0AA", marginLeft: 0 }}>
                    <Input
                        disabled={inputDisabled}
                        value={editableSubtaskName}
                        onChange={(e) =>
                            setEditableSubtaskName(e.currentTarget.value)
                        }
                        variant="borderless"
                        onBlur={saveSubtask}
                        onPressEnter={saveSubtask}
                        placeholder={
                            inputDisabled
                                ? "Can't create new subtask for closed tasks"
                                : "Create new subtask..."
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default observer(TaskPreviewSubtask);

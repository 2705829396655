import { message } from "antd";
import { ClientActionData, StringKeyDictionary } from "../types/Actions";

export default class ClientActionRunner {
    executeAction(args: ClientActionData) {
        switch (args.actionType) {
            case "OpenLink":
                this.handleOpenLink(args.fields);
                break;
            case "ViewAiSession":
                this.handleViewAiSession(args.fields);
                break;
            default:
                message.error(`Unsupported action type '${args.actionType}'`);
                break;
        }
    }

    private handleOpenLink(fields: StringKeyDictionary) {
        window.open(fields.url, "_blank", "noreferrer");
    }

    private handleViewAiSession(fields: StringKeyDictionary) {
        const url = `${fields.baseUrl}/sessions/${fields.sessionId}?toolbar=0&access_token=${fields.accessToken}&refresh_token=${fields.refreshToken}`;
        console.log("View session url:", url);
        window.open(url, "_blank", "noreferrer");
    }
}

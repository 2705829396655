import {
    action,
    computed,
    makeObservable,
    observable,
    runInAction,
} from "mobx";
import { ColumnsFilter } from "../../common/services/types";
import { TasksData } from "../types";
import TasksRootStore from "./TasksRootStore";
import { ALL_PROJECTS } from "../screens/TasksPage";

export default class TasksFiltersVisualStore {
    columnsFilterDict: { [key: string]: ColumnsFilter } = {};

    currentFilterOperator: string;

    constructor(private tasksRootStore: TasksRootStore) {
        makeObservable<TasksFiltersVisualStore>(this, {
            columnsFilterDict: observable,
            setCurrentFilterValue: action,
            resetColumnFilter: action,
            applyColumnFilter: action,
            taskTypesForCurrentProject: computed,
            handleAllFiltersReset: action.bound,
            isGridView: computed,
        });
    }

    get taskTypesForCurrentProject() {
        return this.tasksRootStore.taskTypesForCurrentProject;
    }

    get taskStatusesForCurrentProject() {
        return this.tasksRootStore.taskStatusesForCurrentProject;
    }

    get allUsersFullNameResolver() {
        return this.tasksRootStore.activeUsersFullNameResolver;
    }

    get isGridView() {
        return this.tasksRootStore.isGridView;
    }

    setColumnsFilter(tasks: TasksData) {
        if (tasks.userColumnsFilter) {
            for (const colFilter of tasks.userColumnsFilter.filters) {
                let columnName = colFilter.entityReference;
                if (colFilter.fieldType === "Metadata") {
                    columnName = this.tasksRootStore.metadataDefinitions.find(
                        (m) => m.id === colFilter.entityReference,
                    )!.name;
                }
                this.columnsFilterDict[columnName] = {
                    entityReference: colFilter.entityReference,
                    value: colFilter.value,
                    fieldType: colFilter.fieldType,
                    operator: colFilter.operator,
                    extraFilters: colFilter.extraFilters,
                    saved: true,
                    isWidgetFilter: false,
                };
            }
        }

        for (const colFilter of tasks.widgetFilters) {
            this.columnsFilterDict[colFilter.entityReference] = {
                entityReference: colFilter.entityReference,
                value: colFilter.value,
                fieldType: colFilter.fieldType,
                operator: colFilter.operator,
                extraFilters: colFilter.extraFilters,
                saved: true,
                isWidgetFilter: true,
            };
        }
    }

    setCurrentFilterValue(
        colName: string,
        value: unknown[],
        isMetaData: boolean,
    ) {
        if (this.columnsFilterDict[colName]) {
            const obj = Object.assign(this.columnsFilterDict[colName], {
                value: value,
            });
            runInAction(() => {
                delete this.columnsFilterDict[colName];
                this.columnsFilterDict[colName] = obj;
            });
        } else {
            this.columnsFilterDict[colName] = {
                entityReference: colName,
                operator: this.currentFilterOperator,
                value,
                extraFilters: {},
                fieldType: isMetaData ? "Metadata" : "General",
                saved: false,
                isWidgetFilter: false,
            };
        }
    }

    setCurrentFilterOperator(operator: string) {
        this.currentFilterOperator = operator;
    }

    unsetAllColumnsFilters() {
        runInAction(() => (this.columnsFilterDict = {}));
    }

    async applyColumnFilter(columnName: string) {
        this.tasksRootStore.setTasksIsLoading(true);
        const projectId =
            this.tasksRootStore.selectedProject === ALL_PROJECTS
                ? null
                : this.tasksRootStore.selectedProject;
        this.columnsFilterDict[columnName].operator =
            this.currentFilterOperator;
        await this.tasksRootStore.updateColumnFilters(
            projectId,
            this.prepareRequestFilters(),
        );
        this.setCurrentFilterOperator("Equal");
        this.markFilterAsSaved(columnName);
    }

    async resetColumnFilter(columnName: string) {
        this.tasksRootStore.setTasksIsLoading(true);
        const projectId =
            this.tasksRootStore.selectedProject === ALL_PROJECTS
                ? null
                : this.tasksRootStore.selectedProject;
        this.removeFilter(columnName);
        await this.tasksRootStore.updateColumnFilters(
            projectId,
            this.prepareRequestFilters(),
        );
        this.tasksRootStore.setTasksIsLoading(false);
    }

    async handleAllFiltersReset() {
        await this.tasksRootStore.resetAllFilters();
    }

    markFilterAsSaved(columnName: string) {
        this.columnsFilterDict[columnName].saved = true;
    }

    removeFilter(columnName: string) {
        delete this.columnsFilterDict[columnName];
    }

    private prepareRequestFilters() {
        return (
            Object.entries(this.columnsFilterDict)
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .filter(([_, x]) => !x.isWidgetFilter)
                .map(([key, f]) => {
                    if (f.fieldType === "Metadata") {
                        const metadataId =
                            this.tasksRootStore.metadataDefinitions.find(
                                (m) => m.name === key,
                            )!.id;
                        f.entityReference = metadataId;
                    }
                    return f;
                })
        );
    }
}

import { Modal, Button, Form, Input, Select } from "antd";
import React from "react";
import { ProjectsStore } from "../../../modules/common/stores";
import { InboxOutlined } from "@ant-design/icons";
import { Upload } from "antd";

type Props = {
    projectsStore: ProjectsStore;
    isDialogVisible: boolean;
    closeDialog: () => void;
};
const FormItem = Form.Item;

const ImportProjectDialog: React.FC<Props> = ({
    projectsStore,
    isDialogVisible,
    closeDialog,
}) => {
    const { Dragger } = Upload;
    const [form] = Form.useForm();
    const [isLoading, setIsloading] = React.useState(false);
    const handleSubmit = () => {
        setIsloading(true);
        form.validateFields()
            .then(async (values) => {
                const result = await projectsStore.importProject(values);

                if (result.isOk()) {
                    form.resetFields();
                    closeDialog();
                }
                setIsloading(false);
            })
            .catch((err) => {
                setIsloading(false);
                console.log(err);
            });
    };

    const handleCancel = () => {
        form.resetFields();
        closeDialog();
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const projectsValidator = (rule: any, value: any, callback: any) => {
        if (!value) {
            callback();
        }
        const projectsToValidate = projectsStore!.projects.map((p) =>
            p.name.toLowerCase(),
        );
        if (
            value &&
            projectsToValidate.includes(value.toLowerCase()) &&
            projectsStore.editableProjectName !== value
        ) {
            callback("Project with the same name already exists");
        } else if (value?.length < 3) {
            callback("Project name must be at least 3 characters");
        } else {
            callback();
        }
    };

    return (
        <Modal
            centered
            width={600}
            title="Import project"
            destroyOnClose
            closable={false}
            maskClosable={false}
            open={isDialogVisible}
            className="alpha-modal"
            footer={[
                <Button
                    data-id="import-project-cancel"
                    key="add-project-cancel"
                    onClick={handleCancel}
                    size="large"
                    className="light"
                >
                    Cancel
                </Button>,
                <Button
                    data-id="import-project-submit"
                    key="add-project-submit"
                    size="large"
                    type="primary"
                    htmlType="submit"
                    form="add-project-form"
                    onClick={handleSubmit}
                    loading={isLoading}
                >
                    Import project
                </Button>,
            ]}
        >
            <Form
                className="inputs-container"
                style={{ marginTop: 10 }}
                id="new-task-template-form"
                layout="vertical"
                form={form}
            >
                <FormItem
                    name="file"
                    valuePropName="file"
                    colon={false}
                    className="dialog-field"
                    label={
                        <span className="dialog-field-label">Project file</span>
                    }
                    rules={[
                        { required: true, message: "Project file is required" },
                    ]}
                    getValueFromEvent={(e) => {
                        if (!e.file || !e.fileList || !e.fileList.length) {
                            return;
                        }

                        return e.file;
                    }}
                >
                    <Dragger
                        name="file"
                        beforeUpload={() => false}
                        maxCount={1}
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">
                            Click or drag a file to this area to upload
                        </p>
                    </Dragger>
                </FormItem>
                <FormItem
                    name="name"
                    colon={false}
                    className="dialog-field"
                    label={
                        <span className="dialog-field-label">Project name</span>
                    }
                    rules={[
                        { required: true, message: "Project name is required" },
                        { validator: projectsValidator },
                    ]}
                >
                    <Input data-id="input-Name" />
                </FormItem>
                <FormItem
                    name="projectOwner"
                    colon={false}
                    className="dialog-field"
                    rules={[
                        {
                            required: true,
                            message: "Project owner is required",
                            whitespace: true,
                        },
                    ]}
                    label={
                        <span className="dialog-field-label">
                            Project owner
                        </span>
                    }
                >
                    <Select
                        style={{ width: "100%" }}
                        options={projectsStore.allUsers.map((u) => ({
                            value: u.userId,
                            label: `${u.firstName} ${u.lastName}`,
                        }))}
                    />
                </FormItem>
            </Form>
        </Modal>
    );
};

export default ImportProjectDialog;

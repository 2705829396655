import * as React from "react";
import { observer } from "mobx-react-lite";
import { TaskCommentsStore } from "../stores";
import { Form } from "antd";
import { Editor } from "@progress/kendo-react-editor";
import { AdvancedToolbar } from "../../common/components/EditorToolbar";

type Props = {
    commentsStore: TaskCommentsStore;
    value: string;
};
const FormItem = Form.Item;

const CommentInDialog: React.FC<Props> = ({ value, commentsStore }) => {
    return (
        <FormItem colon={false} name="comment">
            <div className="comment-input-container">
                <div
                    className="comment-input-wrapper"
                    data-id={"input-comment"}
                >
                    <Editor
                        onChange={(e) =>
                            commentsStore.setEditingCommentBody(e.html)
                        }
                        value={value}
                        defaultEditMode={"div"}
                        contentStyle={{ height: 100 }}
                        tools={AdvancedToolbar}
                    />
                </div>
            </div>
        </FormItem>
    );
};

export default observer(CommentInDialog);

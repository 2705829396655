import React from "react";
import { BrowserRouter } from "react-router-dom";
import "./styles/common.less";
import "./styles/tasks.less";
import "./styles/preview.less";
import "./styles/dashboard.less";
import "./styles/analysis.less";
import "./styles/table.less";
import "./styles/users.less";
import "./styles/widgets-constructor.less";
import "./styles/side-menu.less";
import "./styles/filters.less";
import "./styles/admin.less";
import "./styles/notifications.less";
import "./styles/reports.less";
import "./styles/telerik.less";

import { bootstrap } from "./modules/common";
import MainLayout from "./modules/common/components/MainLayout";
import { ConfigProvider } from "antd";
import "@progress/kendo-theme-bootstrap/dist/all.css";

class App extends React.Component {
    componentDidMount() {
        if (process.env.REACT_APP_DISABLE_ANIMATIONS === "true") {
            document.body.className = "no-anims";
        }
    }

    render() {
        console.log(`Version: ${process.env.REACT_APP_VERSION}`);
        return (
            <bootstrap.StoreProvider>
                <ConfigProvider
                    theme={{
                        components: {
                            Button: {
                                colorPrimary: "#273C75",
                                colorPrimaryHover: "#162242",
                                colorPrimaryActive: "#162242",
                                defaultHoverBg: "#E4E5ED",
                                colorLink: "#273C75",
                                colorLinkHover: "#162242",
                            },
                            Switch: {
                                colorPrimary: "#273C75",
                                colorPrimaryHover: "#273C75",
                            },
                            Select: {
                                colorPrimary: "#273C75",
                                colorPrimaryHover: "#273C75",
                            },
                            Checkbox: {
                                colorPrimary: "#273C75",
                                colorPrimaryHover: "#162242",
                            },
                            Radio: {
                                colorPrimary: "#273C75",
                                colorPrimaryHover: "#273C75",
                            },
                            Input: {
                                colorPrimary: "#273C75",
                                colorPrimaryHover: "#273C75",
                            },
                            InputNumber: {
                                colorPrimary: "#273C75",
                                colorPrimaryHover: "#273C75",
                            },
                            Spin: {
                                colorPrimary: "#273C75",
                            },
                            Tabs: {
                                colorPrimary: "#273C75",
                                colorPrimaryHover: "#162242",
                            },
                            Pagination: {
                                colorPrimary: "#273C75",
                                colorPrimaryHover: "#162242",
                            },
                            DatePicker: {
                                colorPrimary: "#273C75",
                                colorPrimaryHover: "#162242",
                            },
                            Form: {
                                labelColor: "#9ba0aa",
                                labelFontSize: 11,
                            },
                            Typography: {
                                colorLink: "#273C75",
                            },
                            Tree: {
                                colorPrimary: "#273C75",
                            },
                        },
                    }}
                >
                    <BrowserRouter>
                        <MainLayout />
                    </BrowserRouter>
                </ConfigProvider>
            </bootstrap.StoreProvider>
        );
    }
}

export default App;

import * as React from "react";
import TaskTemplateVisualStore from "../../task_templates/stores/TaskTemplatesVisualStore";
import { TaskCreateVisualStore } from "../stores";
import { observer } from "mobx-react-lite";
import { Divider, Form, Input } from "antd";
import { FormInstance } from "antd/es/form";
import { SubtaskFromTemplates } from "../types";
import {
    Editor,
    EditorMountEvent,
    ProseMirror,
} from "@progress/kendo-react-editor";

type Props = {
    store: TaskCreateVisualStore;
    templateStore: TaskTemplateVisualStore;
    form: FormInstance;
    setCurrentStep: (step: number) => void;
};

const FormItem = Form.Item;
const { EditorState, EditorView, Plugin, PluginKey } = ProseMirror;

const NewTaskParametersForm: React.FC<Props> = ({
    templateStore,
    form,
    setCurrentStep,
    store,
}) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const view = React.useRef<any>(null);
    const [isMounted, setIsMounted] = React.useState(false);
    React.useEffect(() => {
        form.setFieldsValue({
            nameFromTemplate: templateStore.selectedTemplate?.title,
            descriptionFromTemplate:
                templateStore.selectedTemplate?.description,
        });
        const formObj: { [key: string]: unknown } = {};
        for (const [index, subtask] of (
            templateStore.selectedTemplate?.subTasks ?? []
        ).entries()) {
            formObj[`subtask-title-${index}`] = subtask.title;
            formObj[`subtask-description-${index}`] = subtask.description;
        }
        form.setFieldsValue(formObj);
        setIsMounted(true);
    }, [templateStore.selectedTaskTemplateId]);
    const handleSubmit = () => {
        form.validateFields()
            .then(() => {
                const vals = form.getFieldsValue();
                const titleParams = vals.nameFromTemplate.match(
                    /\${[^}]+}/g,
                ) as string[] | null;
                if (titleParams) {
                    for (const param of titleParams) {
                        const paramKey = param.match(/[^${}]+/g)![0];
                        const paramVal = vals[`title-${paramKey}`];
                        vals.name = (vals.nameFromTemplate as string).replace(
                            param,
                            paramVal,
                        );
                        delete vals[paramKey];
                    }
                } else {
                    vals.name = vals.nameFromTemplate;
                }
                const descriptionParams = vals.descriptionFromTemplate?.match(
                    /\${[^}]+}/g,
                ) as string[] | null;
                if (descriptionParams) {
                    for (const param of descriptionParams) {
                        const paramKey = param.match(/[^${}]+/g)![0];
                        const paramVal = vals[`description-${paramKey}`];
                        vals.description = (
                            vals.descriptionFromTemplate as string
                        ).replace(param, paramVal);
                        vals.descriptionFromTemplate = vals.description;
                        delete vals[paramKey];
                    }
                } else {
                    vals.description = vals.descriptionFromTemplate;
                }
                const titles = Object.keys(vals).filter((v) =>
                    v.match(/subtask-title-[\d]/),
                );
                const subtasksFromTemplates = [] as SubtaskFromTemplates[];
                for (const title of titles) {
                    const subtaskTitleParams = vals[title].match(
                        /\${[^}]+}/g,
                    ) as string[] | null;
                    if (subtaskTitleParams) {
                        for (const param of subtaskTitleParams) {
                            const paramKey = param.match(/[^${}]+/g)![0];
                            const paramVal = vals[`subtask-title-${paramKey}`];
                            vals[title] = (vals[title] as string).replace(
                                param,
                                paramVal,
                            );
                            delete vals[paramKey];
                        }
                    }
                    subtasksFromTemplates.push({ name: vals[title] });
                }

                const descriptions = Object.keys(vals).filter((v) =>
                    v.match(/subtask-description-[\d]/),
                );
                for (const description of descriptions) {
                    const subtaskDescriptionParams = vals[description]?.match(
                        /\${[^}]+}/g,
                    ) as string[] | null;
                    if (subtaskDescriptionParams) {
                        for (const param of subtaskDescriptionParams) {
                            const paramKey = param.match(/[^${}]+/g)![0];
                            const paramVal =
                                vals[`subtask-description-${paramKey}`];
                            vals[description] = (
                                vals[description] as string
                            ).replace(param, paramVal);
                            delete vals[paramKey];
                        }
                    }
                    const index = Number(description.match(/([\d]$)/)![0]);
                    if (subtasksFromTemplates[index]) {
                        subtasksFromTemplates[index].description =
                            vals[description];
                    } else {
                        subtasksFromTemplates.push({
                            description: vals[description],
                        });
                    }
                }
                store.setSubtasksFromTemplate(subtasksFromTemplates);
                form.setFieldsValue(vals);
                store.setParamsSavedInUi(true);
                setCurrentStep(0);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const editorContentStyle = {
        height: 100,
        opacity: 0.4,
    } as React.CSSProperties;

    const onMount = (event: EditorMountEvent) => {
        const state = event.viewProps.state;
        const plugins = [
            ...state.plugins,
            new Plugin({
                key: new PluginKey("readonly"),
                props: { editable: () => false },
                filterTransaction: (tr) => !tr.docChanged,
            }),
        ];
        view.current = new EditorView(
            { mount: event.dom },
            {
                ...event.viewProps,
                state: EditorState.create({ doc: state.doc, plugins }),
            },
        );

        return view.current;
    };

    return (
        <Form
            form={form}
            onFinish={handleSubmit}
            className="inputs-container"
            id="new-task-modal-form"
            layout="vertical"
        >
            <div>Task</div>
            <FormItem
                name="nameFromTemplate"
                className="dialog-field"
                label={<span className="dialog-field-label">Title</span>}
                rules={[{ required: true, message: "Task title is required" }]}
            >
                <Input data-id="input-Name" disabled />
            </FormItem>
            {templateStore.templatTitleParameters?.map((t, index) => (
                <FormItem
                    key={`${t}-${index}`}
                    name={`title-${t.match(/[^${}]+/g)!}`}
                    rules={[
                        { required: true, message: "Parameter is required" },
                    ]}
                >
                    <Input placeholder={t.match(/[^${}]+/g)![0] as string} />
                </FormItem>
            ))}
            {isMounted && (
                <FormItem
                    name="descriptionFromTemplate"
                    colon={false}
                    className="dialog-field"
                    style={{ marginBottom: 24 }}
                    label={
                        <span className="dialog-field-label">Description</span>
                    }
                    getValueProps={(value) => ({ value: value ?? "" })}
                >
                    <Editor
                        defaultEditMode="div"
                        contentStyle={editorContentStyle}
                        onMount={onMount}
                    />
                </FormItem>
            )}
            {templateStore.templateDescriptionParameters?.map((t, index) => (
                <FormItem
                    key={`${t}-${index}`}
                    name={`description-${t.match(/[^${}]+/g)!}`}
                    rules={[
                        { required: true, message: "Parameter is required" },
                    ]}
                >
                    <Input placeholder={t.match(/[^${}]+/g)![0] as string} />
                </FormItem>
            ))}
            <>
                {templateStore.selectedTemplate?.subTasks?.length ? (
                    <div>Subtasks</div>
                ) : (
                    <></>
                )}
                {templateStore.selectedTemplate?.subTasks?.map((s, index) => (
                    <>
                        <FormItem
                            key={`${s.title}-${index}`}
                            name={`subtask-title-${index}`}
                            label={
                                <span className="dialog-field-label">
                                    Title
                                </span>
                            }
                        >
                            <Input disabled />
                        </FormItem>
                        {templateStore.selectedTemplate
                            ?.subTasks![index]?.title.match(/\${[^}]+}/g)
                            ?.map((t) => (
                                <FormItem
                                    key={t}
                                    name={`subtask-title-${t.match(/[^${}]+/g)!}`}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Parameter is required",
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder={
                                            t.match(/[^${}]+/g)![0] as string
                                        }
                                    />
                                </FormItem>
                            ))}
                        {isMounted && (
                            <FormItem
                                key={`${s.description}-${index}`}
                                name={`subtask-description-${index}`}
                                label={
                                    <span className="dialog-field-label">
                                        Description
                                    </span>
                                }
                                style={{ marginBottom: 0 }}
                                getValueProps={(value) => ({
                                    value: value ?? "",
                                })}
                            >
                                <Editor
                                    contentStyle={editorContentStyle}
                                    defaultEditMode="div"
                                    onMount={onMount}
                                />
                            </FormItem>
                        )}
                        <div style={{ marginTop: 24 }}>
                            {templateStore.selectedTemplate
                                ?.subTasks![
                                    index
                                ]?.description?.match(/\${[^}]+}/g)
                                ?.map((t, subIndex) => (
                                    <FormItem
                                        key={`${t}-${subIndex}`}
                                        name={`subtask-description-${t.match(/[^${}]+/g)!}`}
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    "Parameter is required",
                                            },
                                        ]}
                                    >
                                        <Input
                                            placeholder={
                                                t.match(
                                                    /[^${}]+/g,
                                                )![0] as string
                                            }
                                        />
                                    </FormItem>
                                ))}
                        </div>
                        {index !==
                            templateStore.selectedTemplate!.subTasks!.length -
                                1 && <Divider />}
                    </>
                ))}
            </>
        </Form>
    );
};

export default observer(NewTaskParametersForm);

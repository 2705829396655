import { ErrorStore, ProjectsStore } from "../../common/stores";
import {
    action,
    runInAction,
    makeObservable,
    observable,
    computed,
} from "mobx";
import { TaskType, TaskTypeDueDate } from "../types";
import { message } from "antd";
import TaskTypesService from "../services/TaskTypesService";
import TasksRootStore from "../../tasks/stores/TasksRootStore";
import { UserProfile } from "src/modules/common/services/types";

export default class TaskTypesVisualStore {
    taskTypes: TaskType[] = [];

    taskTypesLoading: boolean = false;

    newTypeDialogVisible: boolean = false;

    typeEditDialogVisible: boolean = false;

    typeName: string | undefined = undefined;

    selectedTaskType: TaskType | undefined = undefined;

    selectedProjectId: string;

    usersForProject: UserProfile[] = [];

    searchString: string = "";

    constructor(
        private taskTypesService: TaskTypesService,
        private projectsStore: ProjectsStore,
        private tasksRootStore: TasksRootStore,
        private errorStore: ErrorStore,
    ) {
        makeObservable<TaskTypesVisualStore>(this, {
            taskTypes: observable,
            taskTypesLoading: observable,
            newTypeDialogVisible: observable,
            typeEditDialogVisible: observable,
            typeName: observable,
            selectedTaskType: observable,
            selectedProjectId: observable,
            usersForProject: observable,
            searchString: observable,
            filteredUsersForProject: computed,
            filteredTaskTypes: computed,
            setSelectedProjectId: action.bound,
            setTypeName: action.bound,
            setNewTypeDialogVisible: action.bound,
            setTypeEditDialogVisible: action.bound,
            selectType: action.bound,
            setTaskTypes: action.bound,
            loadTaskTypes: action.bound,
            createTaskType: action.bound,
            editTaskType: action.bound,
            toggleTaskType: action.bound,
            deleteTaskType: action.bound,
            loadUsersForProject: action,
            setSearchString: action,
        });
    }

    get filteredTaskTypes() {
        if (this.searchString.trim() !== "") {
            const searchStringLower = this.searchString.toLowerCase();
            return this.taskTypes.filter(
                (m) =>
                    m.name.toLowerCase().includes(searchStringLower) ||
                    m.code?.toLowerCase().includes(searchStringLower),
            );
        }
        return this.taskTypes;
    }

    get filteredUsersForProject() {
        const hiddenUsers = this.usersForProject
            .filter(
                (u) =>
                    u.isDeleted && u.userId !== this.selectedTaskType?.assignee,
            )
            .map((h) => h.userId);
        return this.usersForProject.filter(
            (f) => !hiddenUsers.includes(f.userId),
        );
    }

    setTypeName(name: string) {
        this.typeName = name;
    }

    setNewTypeDialogVisible(visible: boolean) {
        this.typeName = undefined;
        this.newTypeDialogVisible = visible;
    }

    setTypeEditDialogVisible(visible: boolean) {
        this.typeEditDialogVisible = visible;

        if (!visible) {
            this.typeName = undefined;
        }
    }

    setSearchString(searchString: string) {
        this.searchString = searchString;
    }

    setTaskTypes(types: TaskType[]) {
        this.taskTypes = types;
    }

    selectType(type: TaskType | undefined) {
        this.selectedTaskType = type;
        if (!type) {
            this.typeEditDialogVisible = false;
            this.typeName = undefined;
        } else {
            this.typeName = type.name;
            this.typeEditDialogVisible = true;
        }
    }

    async setSelectedProjectId(projectId: string) {
        this.selectedProjectId = projectId;
        this.taskTypesLoading = true;
        const promises = [
            this.loadTaskTypes(projectId),
            this.loadUsersForProject(projectId),
        ];
        await Promise.all(promises);
        this.taskTypesLoading = false;
    }

    async loadUsersForProject(id: string) {
        const users = await this.projectsStore.getUsersForProject(id);
        this.usersForProject = users;
    }

    async loadTaskTypes(projectId: string) {
        if (!projectId) {
            return;
        }

        this.setTaskTypes([]);
        const resp =
            await this.taskTypesService.getTaskTypesForProject(projectId);
        resp.map((t) => {
            runInAction(() => {
                this.setTaskTypes(t);
            });
        }).mapErr((err) => this.errorStore.addError(err.data));
    }

    async createTaskType(
        name: string,
        assignee: string,
        code: string,
        canChangeStatusManually: boolean,
        distinctSubtaskStatuses: boolean,
        dueDate: TaskTypeDueDate | null,
    ) {
        const response = await this.taskTypesService.createTaskType(
            name,
            assignee,
            code,
            this.selectedProjectId,
            canChangeStatusManually,
            distinctSubtaskStatuses,
            dueDate,
        );
        response
            .map((newTypeId) => {
                runInAction(() => {
                    const newTaskTypes = this.taskTypes.slice();
                    newTaskTypes.push({
                        id: newTypeId,
                        name,
                        assignee,
                        code,
                        projectId: this.selectedProjectId,
                        isEnabled: true,
                        canChangeStatusManually,
                        distinctSubtaskStatuses,
                        dueDate,
                    });
                    this.taskTypes = newTaskTypes;
                    this.newTypeDialogVisible = false;
                });
                this.tasksRootStore.loadTaskTypes();
            })
            .mapErr((err) => this.errorStore.addError(err.data));
    }

    async editTaskType(
        name: string,
        assignee: string,
        code: string,
        canChangeStatusManually: boolean,
        distinctSubtaskStatuses: boolean,
        dueDate: TaskTypeDueDate | null,
    ) {
        const response = await this.taskTypesService.editTaskType(
            this.selectedTaskType!.id,
            name,
            assignee,
            code,
            canChangeStatusManually,
            distinctSubtaskStatuses,
            dueDate,
        );
        response
            .map(() => {
                runInAction(() => {
                    const newTaskTypes = this.taskTypes.slice();
                    const taskTypeToRename = newTaskTypes.find(
                        (t) => t.id === this.selectedTaskType!.id,
                    );

                    if (taskTypeToRename) {
                        const index = newTaskTypes.indexOf(taskTypeToRename);
                        newTaskTypes[index].name = name;
                        newTaskTypes[index].code = code;
                        newTaskTypes[index].assignee = assignee;
                        newTaskTypes[index].canChangeStatusManually =
                            canChangeStatusManually;
                        newTaskTypes[index].distinctSubtaskStatuses =
                            distinctSubtaskStatuses;
                        newTaskTypes[index].dueDate = dueDate;
                    }

                    this.taskTypes = newTaskTypes;
                    this.selectType(undefined);
                });
                this.tasksRootStore.loadTaskTypes();
            })
            .mapErr((err) => this.errorStore.addError(err.data));
    }

    async toggleTaskType(typeId: string) {
        const response = await this.taskTypesService.toggleTaskType(typeId);
        response
            .map(() => {
                runInAction(() => {
                    const newTaskTypes = this.taskTypes.slice();
                    const taskTypeToToggle = newTaskTypes.find(
                        (t) => t.id === typeId,
                    );

                    if (taskTypeToToggle) {
                        const index = newTaskTypes.indexOf(taskTypeToToggle);
                        newTaskTypes[index].isEnabled =
                            !newTaskTypes[index].isEnabled;
                    }

                    this.taskTypes = newTaskTypes;
                    this.tasksRootStore.loadTaskTypes();
                });
            })
            .mapErr((err) => this.errorStore.addError(err.data));
    }

    async deleteTaskType(typeId: string) {
        const response = await this.taskTypesService.deleteTaskType(typeId);
        response
            .map(() => {
                runInAction(() => {
                    let newTaskTypes = this.taskTypes.slice();
                    newTaskTypes = newTaskTypes.filter((t) => t.id !== typeId);
                    this.taskTypes = newTaskTypes;
                });
                this.tasksRootStore.loadTaskTypes();
            })
            .mapErr((err) => {
                if (err.status === 409) {
                    message.error(err.data!.title);
                } else {
                    this.errorStore.addError(err.data);
                }
            });
    }

    getUserNameById(id?: string) {
        const user = this.usersForProject.find((u) => u.userId === id);
        return user ? `${user?.firstName} ${user?.lastName}` : "";
    }
}

import * as React from "react";
import { observer } from "mobx-react-lite";
import { Button, Col, Layout, Row } from "antd";
import ActivitiesCard from "../components/ActivitiesCard";
import { useDashboardStore } from "../stores/context";
import { useTasksStore } from "../../tasks/stores/context";
import { useStore } from "../../common/stores";
import AddWidgetDialog from "../components/AddWidgetDialog";
import { DashboardWidgetType } from "src/modules/administration/types/DashboardWidget";
import ListWidgetCard from "../components/ListWidgetCard";
import ListWidget from "../models/ListWidget";
import PieWidgetCard from "../components/PieWidgetCard";
import PieChartWidget from "../models/PieChartWidget";
import GroupedWidget from "../models/GroupedWidget";
import GroupedWidgetsCard from "../components/GroupedWidgetsCard";
import { LoadingIndicator } from "../../common/components/LoadingIndicator";
import CreateWidgetDialog from "../../administration/components/CreateWidgetDialog";
import { useAdminStore } from "../../administration/stores/context";
import CreateGroupedWidgetDialog from "src/modules/administration/components/CreateGroupedWidgetDialog";
import NumericWidgetCard from "../components/NumericWidgetCard";
import NumericWidget from "../models/NumericWidget";

const DashboardPage: React.FC = () => {
    const [isAddWidgetDialogVisible, setIsAddWidgetDialogVisible] =
        React.useState(false);
    const [isEditModalVisible, setIsEditModalVisible] = React.useState(false);
    const [
        isEditGroupedWidgetModalVisible,
        setIsEditGroupedWidgetModalVisible,
    ] = React.useState(false);

    const context = useDashboardStore();
    const tasksContext = useTasksStore();
    const userProfile = useStore().userProfileStore.userProfile;
    const { dashboardStore } = context;
    const { taskPreviewVisualStore } = tasksContext;

    const adminContext = useAdminStore();
    const widgetConstructorStore = adminContext.widgetConstructorStore;

    React.useEffect(() => {
        widgetConstructorStore.init();
    }, []);

    React.useEffect(() => {
        dashboardStore.loadInitialData();
    }, []);

    React.useEffect(() => {
        if (dashboardStore.projects.length) {
            dashboardStore.setSelectedActivitiesProjectId(
                dashboardStore.projects[0].id,
            );
        }
    }, [dashboardStore.projects]);

    if (!userProfile) {
        return null;
    }
    if (dashboardStore.isLoading || widgetConstructorStore.isLoading) {
        return <LoadingIndicator />;
    }

    const openEditWidgetModal = (widgetId: string) => {
        widgetConstructorStore.setEditableWidget(widgetId);
        setIsEditModalVisible(true);
    };

    const openEditGroupedWidgetModal = (widgetId: string) => {
        widgetConstructorStore.setEditableWidget(widgetId);
        setIsEditGroupedWidgetModalVisible(true);
    };

    return (
        <Layout className="screen-size layout-with-table">
            <div className="dashboard-container">
                <div className="header-wrapper">
                    <div className="title-wrapper">
                        <div className="header-title">Dashboard</div>
                    </div>
                    <Button
                        data-id="button-refresh-widgets"
                        onClick={dashboardStore.refreshDashboard}
                        className="refresh-button"
                        type="text"
                        size="large"
                        icon={<i className="alpha-icon refresh" />}
                    >
                        Refresh widgets
                    </Button>
                    <Button
                        data-id="button-add-type"
                        type="primary"
                        size="large"
                        onClick={() => setIsAddWidgetDialogVisible(true)}
                    >
                        Add widget
                    </Button>
                </div>
                <AddWidgetDialog
                    store={dashboardStore}
                    widgetStore={widgetConstructorStore}
                    isVisible={isAddWidgetDialogVisible}
                    closeDialog={() => setIsAddWidgetDialogVisible(false)}
                />
                <CreateWidgetDialog
                    isVisible={isEditModalVisible}
                    closeDialog={() => setIsEditModalVisible(false)}
                    updateCallBack={dashboardStore.refreshDashboard}
                />
                <CreateGroupedWidgetDialog
                    isVisible={isEditGroupedWidgetModalVisible}
                    closeDialog={() =>
                        setIsEditGroupedWidgetModalVisible(false)
                    }
                    updateCallBack={dashboardStore.refreshDashboard}
                />
                <div className="widgets-container">
                    <Row>
                        <Col xxl={8} xl={12} lg={24} md={24}>
                            <ActivitiesCard
                                tasksStore={taskPreviewVisualStore}
                                dashboardStore={dashboardStore}
                            />
                        </Col>
                        {dashboardStore.widgets
                            .slice()
                            .sort(
                                (a, b) =>
                                    Number(b.isDefaultWidget) -
                                    Number(a.isDefaultWidget),
                            )
                            .map((w, index) => {
                                switch (w.widgetType) {
                                    case DashboardWidgetType.List:
                                        return (
                                            <Col
                                                key={index}
                                                xxl={8}
                                                xl={12}
                                                lg={24}
                                                md={24}
                                            >
                                                <ListWidgetCard
                                                    dashboardStore={
                                                        dashboardStore
                                                    }
                                                    widget={w as ListWidget}
                                                    openEditWidgetModal={
                                                        openEditWidgetModal
                                                    }
                                                />
                                            </Col>
                                        );
                                    case DashboardWidgetType.PieChart:
                                        return (
                                            <Col
                                                key={index}
                                                xxl={8}
                                                xl={12}
                                                lg={24}
                                                md={24}
                                            >
                                                <PieWidgetCard
                                                    dashboardStore={
                                                        dashboardStore
                                                    }
                                                    widget={w as PieChartWidget}
                                                    openEditWidgetModal={
                                                        openEditWidgetModal
                                                    }
                                                />
                                            </Col>
                                        );
                                    case DashboardWidgetType.Grouped:
                                        return (
                                            <Col
                                                key={index}
                                                xxl={8}
                                                xl={12}
                                                lg={24}
                                                md={24}
                                            >
                                                <GroupedWidgetsCard
                                                    dashboardStore={
                                                        dashboardStore
                                                    }
                                                    widget={w as GroupedWidget}
                                                    openEditWidgetModal={
                                                        openEditGroupedWidgetModal
                                                    }
                                                />
                                            </Col>
                                        );
                                    case DashboardWidgetType.Numeric:
                                        return (
                                            <Col
                                                key={index}
                                                xxl={8}
                                                xl={12}
                                                lg={24}
                                                md={24}
                                            >
                                                <NumericWidgetCard
                                                    dashboardStore={
                                                        dashboardStore
                                                    }
                                                    widget={w as NumericWidget}
                                                    openEditWidgetModal={
                                                        openEditWidgetModal
                                                    }
                                                />
                                            </Col>
                                        );
                                    default:
                                        return null;
                                }
                            })}
                    </Row>
                </div>
            </div>
        </Layout>
    );
};

export default observer(DashboardPage);

import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { ActionType, EditorConfig } from "../types/Actions";
import { Checkbox, Form, Input, Select, Space, Tooltip } from "antd";
import ActionDefinitionStore from "../stores/ActionDefinitionStore";
import { PackageBase, TaskBase } from "../../tasks/types";
import { Utils } from "../../common/misc/Utils";
import { FormInstance } from "antd/es/form/Form";
import { FormOutlined, MinusCircleOutlined } from "@ant-design/icons";

interface Props {
    store: ActionDefinitionStore;
    index: number;
    form: FormInstance;
    actionType?: ActionType;
    remove: (index: number | number[]) => void;
}

const ActionFieldEditor: React.FC<Props> = ({
    store,
    index,
    form,
    actionType,
    remove,
}) => {
    const [fieldType, setFieldType] = useState<string | undefined>(
        form.getFieldValue(["fields", index, "fieldType"]),
    );

    useEffect(() => {
        if (form.isFieldTouched(["fields", index, "fieldType"])) {
            form.setFieldValue(["fields", index, "defaultValue"], undefined);
        }
    }, [fieldType, form, index]);

    useEffect(() => {
        if (fieldType === "AttachmentProperty" && !actionType?.isAttachment) {
            form.setFieldValue(["fields", index, "fieldType"], undefined);
            form.setFieldValue(["fields", index, "defaultValue"], undefined);
            setFieldType(undefined);
        }
    }, [actionType, index, form]);

    const onEditorChange = (ec: EditorConfig) => {
        form.setFieldValue(["fields", index, "editor"], ec);
    };

    const getValue = (name: string) =>
        form.getFieldValue(["fields", index, name]);

    const fieldTypes = [
        { value: "FixedValue", label: "Fixed value" },
        { value: "TaskProperty", label: "Task property" },
        { value: "Metadata", label: "Metadata field" },
        {
            value: "AttachmentProperty",
            label: "Attachment field",
            disabled: !(actionType?.isAttachment ?? false),
        },
        { value: "UserInput", label: "User input" },
    ];

    const fieldTypeValues = {
        TaskProperty: "Task Property",
        Metadata: "Metadata Field",
        AttachmentProperty: "Attachment Field",
        FixedValue: "Value",
    };

    const taskProperties = Object.keys(new TaskBase())
        .map((key) => Utils.capitalizeWord(key))
        .map((key) => ({ label: key, value: key }))
        .sort((a, b) => a.label.localeCompare(b.label));

    const metadataFields = store.metadata.map((m) => ({
        label: m.title,
        value: m.name,
    }));

    const attachmentProperties = Object.keys(new PackageBase())
        .map((key) => Utils.capitalizeWord(key))
        .map((key) => ({ label: key, value: key }))
        .sort((a, b) => a.label.localeCompare(b.label));

    const getFieldOptions = () => {
        switch (fieldType) {
            case "TaskProperty":
                return taskProperties;
            case "Metadata":
                return metadataFields;
            case "AttachmentProperty":
                return attachmentProperties;
            default:
                return [];
        }
    };

    return (
        <Space>
            <Form.Item name={[index, "isPredefined"]} noStyle>
                <Input type="hidden" />
            </Form.Item>
            <Form.Item
                name={[index, "name"]}
                rules={[{ required: true, message: "Name is required" }]}
            >
                <Input
                    placeholder="Field name"
                    style={{ width: 140 }}
                    readOnly={getValue("isPredefined") || false}
                />
            </Form.Item>
            <Form.Item name={[index, "title"]}>
                <Input
                    placeholder="Title"
                    style={{ width: 140 }}
                    readOnly={getValue("isPredefined") || false}
                />
            </Form.Item>
            <Form.Item
                name={[index, "fieldType"]}
                rules={[{ required: true, message: "Type is required" }]}
            >
                <Select
                    options={fieldTypes}
                    placeholder="Field type"
                    style={{ width: 160 }}
                    onChange={setFieldType}
                />
            </Form.Item>
            <Form.Item
                name={[index, "defaultValue"]}
                rules={[
                    {
                        required:
                            fieldType !== undefined &&
                            ["TaskProperty", "Metadata", "FixedValue"].includes(
                                fieldType,
                            ),
                        message: `${fieldType ? fieldTypeValues[fieldType] : "Value"} is required`,
                    },
                ]}
            >
                {fieldType !== undefined &&
                ["Metadata", "TaskProperty", "AttachmentProperty"].includes(
                    fieldType,
                ) ? (
                    <Select
                        options={getFieldOptions()}
                        placeholder={fieldTypeValues[fieldType]}
                        style={{ width: 350 }}
                    />
                ) : (
                    <Input
                        style={{ width: 350 }}
                        placeholder={
                            getValue("fieldType") == "FixedValue"
                                ? "Value"
                                : "Default value"
                        }
                    />
                )}
            </Form.Item>
            <Form.Item name={[index, "isRequired"]} valuePropName="checked">
                <Checkbox disabled={getValue("isPredefined") || false}>
                    Required
                </Checkbox>
            </Form.Item>
            {fieldType === "UserInput" && (
                <Form.Item>
                    <Tooltip title="Configure input" placement="bottom">
                        <FormOutlined
                            className="alpha-antd-icon"
                            onClick={() => {
                                store.setEditInputDialogVisible(
                                    true,
                                    getValue("editor"),
                                    onEditorChange,
                                );
                            }}
                        />
                    </Tooltip>
                </Form.Item>
            )}
            {form.getFieldValue(["fields", index, "isPredefined"]) ? null : (
                <Form.Item>
                    <MinusCircleOutlined
                        className="alpha-antd-icon no-margin"
                        onClick={() => remove(index)}
                    />
                </Form.Item>
            )}
        </Space>
    );
};

export default observer(ActionFieldEditor);

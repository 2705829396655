import {
    action,
    computed,
    makeObservable,
    observable,
    runInAction,
} from "mobx";
import {
    ErrorStore,
    ProjectsStore,
    UserProfileStore,
} from "../../../modules/common/stores";
import type { ProjectRole, RoleModel, UserModel } from "../types/UserModel";
import { message } from "antd";
import security from "../../common/services/SecurityService";
import { ResponseData } from "../../common/services/AppClient";
import { CustomRole } from "../types/CustomRoleModel";
import { GlobalAdministrationService } from "../services/GlobalAdministrationService";
import permissions from "../../authorization/Permissions";
import { ProjectsService } from "../../common/services";
import { Project } from "../../common/services/types";
import _ from "lodash";

export enum LoginTypes {
    internalUser = "Internal User",
    domainUser = "Domain User",
}

export default class UsersManagerStore {
    isLoading: boolean = false;

    users: UserModel[] = [];

    selectedUser: UserModel | null;

    selectedProjectId: string | null;

    roles: RoleModel[];

    isAddUserDialogVisible: boolean;

    isEditUserDialogVisible: boolean;

    isEditProjectsRolesDialogVisible: boolean = false;

    resetPasswordDialogVisible: boolean;

    searchTerm: string = "";

    userProfileStore: UserProfileStore;

    currentUserId: string;

    userSearchString: string = "";

    tableIsLoading: boolean = false;

    isUserRoleEditDialogVisible: boolean = false;

    customRoles: CustomRole[] = [];

    loadingCustomRoles: boolean = false;

    customRoleDialogVisible: boolean = false;

    customRoleDialogModel: CustomRole | undefined = undefined;

    projects: Project[] = [];

    tempColumnsFilterDict: { [key: string]: string[] } = {};

    storedFilters: { [key: string]: string[] } = {};

    editableProjectsForUser: ProjectRole[] = [];

    editingAssignmentsFor: string | null;

    newUserFormValues: UserModel;

    currentEditableRole: string | null;

    private searchForAutocompleteCallServer: (s: string) => Promise<void>;

    get canDeleteUser() {
        return (
            !!this.selectedUser &&
            this.selectedUser.userName !== "admin" &&
            this.selectedUser.id !== this.currentUserId
        );
    }

    get filteredUsers() {
        const filteredProjects = this.storedFilters.projects
            ? this.storedFilters.projects
            : [];
        const filteredRoles = this.storedFilters.roles
            ? this.storedFilters.roles
            : [];

        let filterdUsers = filteredProjects.length
            ? this.users.filter(
                  (u) =>
                      _.intersection(
                          u.projects.map((p) => p.projectId),
                          filteredProjects,
                      ).length,
              )
            : this.users;
        filterdUsers = filteredRoles.length
            ? filterdUsers.filter(
                  (u) =>
                      _.intersection(
                          u.roles.map((r) => r.name),
                          filteredRoles,
                      ).length,
              )
            : filterdUsers;
        if (this.userSearchString.trim() !== "") {
            const searchStringLower = this.userSearchString.toLowerCase();
            return filterdUsers.filter(
                (u) =>
                    (u.firstName &&
                        u.firstName
                            .toLowerCase()
                            .includes(searchStringLower)) ||
                    (u.lastName &&
                        u.lastName.toLowerCase().includes(searchStringLower)) ||
                    (u.email &&
                        u.email.toLowerCase().includes(searchStringLower)) ||
                    u.userName.toLowerCase().includes(searchStringLower),
            );
        }

        return filterdUsers;
    }

    get sortedRoles() {
        const sortedRoleNames =
            this.roles &&
            Object.keys(permissions).filter(
                (y) => this!.roles.find((x) => x.name === y)!,
            );
        return sortedRoleNames
            ? sortedRoleNames.map((x) => this.roles.find((y) => y.name === x)!)
            : [];
    }

    constructor(
        private service: GlobalAdministrationService,
        private projectsStore: ProjectsStore,
        private projectService: ProjectsService,
        private errorStore: ErrorStore,
    ) {
        makeObservable<UsersManagerStore>(this, {
            isLoading: observable,
            users: observable,
            selectedUser: observable,
            selectedProjectId: observable,
            roles: observable,
            isAddUserDialogVisible: observable,
            isEditUserDialogVisible: observable,
            resetPasswordDialogVisible: observable,
            isEditProjectsRolesDialogVisible: observable,
            searchTerm: observable,
            currentUserId: observable,
            userSearchString: observable,
            tableIsLoading: observable,
            isUserRoleEditDialogVisible: observable,
            customRoles: observable,
            loadingCustomRoles: observable,
            customRoleDialogVisible: observable,
            customRoleDialogModel: observable,
            projects: observable,
            editableProjectsForUser: observable,
            currentEditableRole: observable,
            canDeleteUser: computed,
            filteredUsers: computed,
            sortedRoles: computed,
            setUserSearchString: action.bound,
            setCustomRoleDialogVisible: action.bound,
            setCustomRoleDialogModel: action.bound,
            setSelectedProjectId: action.bound,
            setIsUserRoleEditDialogVisible: action.bound,
            setLoadingCustomRoles: action.bound,
            getCustomRoles: action.bound,
            saveCustomRole: action.bound,
            deleteCustomRole: action.bound,
            getUsers: action.bound,
            getProjectNameById: action.bound,
            init: action.bound,
            getRoles: action.bound,
            setIsAddUserDialogVisible: action.bound,
            setIsEditUserDialogVisible: action.bound,
            setResetPasswordDialogVisible: action.bound,
            handleNodeSelection: action.bound,
            searchForAutocomplete: action.bound,
            handleUserSelect: action.bound,
            createUpdateUser: action.bound,
            deleteUser: action.bound,
            resetPassword: action.bound,
            changePassword: action.bound,
            updateObjectRoleAssignments: action.bound,
            tempColumnsFilterDict: observable,
            storedFilters: observable,
            setCurrentFilterValue: action,
            getProjects: action,
            setIsProjectRolesDialogVisible: action,
            setEditableProjectsForUserId: action,
            setAssignedProjectsForUser: action,
            clearEditableProjects: action.bound,
            setCurrentEditableRole: action,
            setAdminFlagForProject: action.bound,
            setUserRolesForProject: action.bound,
        });

        this.setUserId();
    }

    async setUserId() {
        const resp = await security.inst.loadUserInfo();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.currentUserId = (resp as any).sub;
    }

    setUserSearchString(searchString: string) {
        this.userSearchString = searchString;
    }

    setCustomRoleDialogVisible(visible: boolean) {
        this.customRoleDialogVisible = visible;
    }

    setCustomRoleDialogModel(model: CustomRole | undefined) {
        this.customRoleDialogModel = model;
    }

    setCurrentEditableRole(role: string | null) {
        this.currentEditableRole = role;
    }

    setAssignedProjectsForUser(projects: string[]) {
        this.editableProjectsForUser = projects.map((p) => ({
            projectId: p,
            isAdmin:
                this.editableProjectsForUser.find((e) => e.projectId === p)
                    ?.isAdmin ?? false,
            roles:
                this.editableProjectsForUser.find((e) => e.projectId === p)
                    ?.roles ?? [],
        }));
    }

    setNewUserFormValues(values: UserModel) {
        values.email = values.email.toLowerCase();
        this.newUserFormValues = values;
    }

    setAdminFlagForProject(projectId: string, isAdmin: boolean) {
        const index = this.editableProjectsForUser.findIndex(
            (p) => p.projectId === projectId,
        );
        if (index > -1) {
            this.editableProjectsForUser[index].isAdmin = isAdmin;
        } else {
            this.editableProjectsForUser.push({
                projectId: projectId,
                isAdmin: isAdmin,
                roles: [],
            });
        }
    }

    setUserRolesForProject(projectId: string, roles: string[]) {
        const index = this.editableProjectsForUser.findIndex(
            (p) => p.projectId === projectId,
        );
        if (index > -1) {
            this.editableProjectsForUser[index].roles = roles;
        } else {
            this.editableProjectsForUser.push({
                projectId: projectId,
                isAdmin: false,
                roles,
            });
        }
    }

    setSelectedProjectId(selectedProjectId: string | null) {
        this.selectedProjectId = selectedProjectId;
    }

    setIsUserRoleEditDialogVisible(isVisible: boolean) {
        this.isUserRoleEditDialogVisible = isVisible;

        if (!isVisible) {
            this.setSelectedProjectId(null);
        }
    }

    setLoadingCustomRoles(loading: boolean) {
        this.loadingCustomRoles = loading;
    }

    setCurrentFilterValue(colName: string, value: string[]) {
        this.tempColumnsFilterDict[colName] = value;
    }

    setEditableProjectsForUserId(id: string) {
        const user = this.users.find((u) => u.id === id)!;
        this.editingAssignmentsFor = user.id;
        this.editableProjectsForUser = user.projects;
    }

    setEditableProjectsForEditableUser() {
        const userId = this.selectedUser!.id;
        this.editingAssignmentsFor = userId;
        this.editableProjectsForUser = this.projects
            .filter((p) => p.owner === userId)
            .map((p) => ({ projectId: p.id, isAdmin: true, roles: [] }));
    }

    clearEditableProjects() {
        this.editableProjectsForUser = [];
        this.editingAssignmentsFor = null;
    }

    applyColumnFilter(columnName: string) {
        this.storedFilters[columnName] = this.tempColumnsFilterDict[columnName];
    }

    resetColumnFilter(columnName: string) {
        delete this.tempColumnsFilterDict[columnName];
        delete this.storedFilters[columnName];
    }

    isUserProjectOwner(projectId: string) {
        const userId = this.selectedUser?.id;
        const project = this.projects.find((p) => p.id === projectId)!;
        return project.owner === userId;
    }

    async getCustomRoles() {
        try {
            this.setLoadingCustomRoles(true);
            const roles = await this.service.getCustomRoles();
            runInAction(() => {
                this.customRoles = roles;
            });
        } catch (ex) {
            console.error(ex);
        } finally {
            this.setLoadingCustomRoles(false);
        }
    }

    async saveCustomRole(name: string, rolePermissions: string[], id?: string) {
        try {
            let resp: ResponseData;
            if (id) {
                resp = await this.service.updateCustomRole(
                    id,
                    name,
                    rolePermissions,
                );
            } else {
                resp = await this.service.createCustomRole(
                    name,
                    rolePermissions,
                );
            }

            if (resp.status === 200) {
                this.setCustomRoleDialogVisible(false);
                this.setCustomRoleDialogModel(undefined);
                const msg = `Role ${id ? "updated" : "created"} successfully`;
                message.success(msg);
                await this.getCustomRoles();
            } else {
                console.log(resp.status, resp.statusText, resp.data);
                const msg = `Error during role ${id ? "update" : "creation"}`;
                message.error(msg);
            }
        } catch (ex) {
            console.error(ex);
        }
    }

    async deleteCustomRole(roleId: string) {
        try {
            const resp = await this.service.deleteCustomRole(roleId);

            if (resp.status === 200) {
                runInAction(() => {
                    this.customRoles = this.customRoles.filter(
                        (r) => r.id !== roleId,
                    );
                });
                const msg = "Role deleted successfully";
                message.success(msg);
            } else {
                console.log(resp.status, resp.statusText, resp.data);
                const msg = "Error during role deletion";
                message.error(msg);
            }
        } catch (ex) {
            console.error(ex);
        }
    }

    async getUsers() {
        this.tableIsLoading = true;
        const users = await this.service.getAppUsers();
        runInAction(() => (this.users = users));
        if (users.length > 0) {
            runInAction(() => (this.selectedUser = users[0]));
        }

        runInAction(() => (this.tableIsLoading = false));
    }

    async getProjects() {
        const resp = await this.projectService.getAllProjects();
        resp.map((projects) => {
            runInAction(() => (this.projects = projects));
        }).mapErr((err) => this.errorStore.addError(err.data));
    }

    getProjectNameById(id: string) {
        return this.projects.find((p) => p.id === id)?.name || "";
    }

    async init() {
        this.isLoading = true;
        this.getRoles();
        this.isLoading = false;
    }

    async getRoles() {
        const roles = await this.service.getRoles();
        runInAction(() => (this.roles = roles));
    }

    setIsAddUserDialogVisible(isVisible: boolean) {
        this.isAddUserDialogVisible = isVisible;
    }

    setIsEditUserDialogVisible(isVisible: boolean) {
        this.isEditUserDialogVisible = isVisible;
    }

    setResetPasswordDialogVisible(isVisible: boolean) {
        this.resetPasswordDialogVisible = isVisible;
    }

    setIsProjectRolesDialogVisible(isVisible: boolean) {
        this.isEditProjectsRolesDialogVisible = isVisible;
    }

    async handleNodeSelection(selectedKey: string | null) {
        if (!selectedKey) {
            return;
        }
        this.selectedUser = this.users.find((u) => u.id === selectedKey)!;
    }

    searchForAutocomplete(term: string) {
        this.searchTerm = term;
        if (this.searchTerm) {
            return this.searchForAutocompleteCallServer(this.searchTerm);
        }
        return;
    }

    async handleUserSelect(userId: string) {
        console.log(userId);
    }

    loadProjects() {
        this.projectsStore.loadProjects();
    }

    async createUpdateUser(userData?: UserModel, isUpdate?: boolean) {
        this.tableIsLoading = true;
        userData = userData ?? this.newUserFormValues;
        if (isUpdate) {
            userData.id = this.selectedUser!.id!;
        }
        let resp = null;
        userData.projects = this.editableProjectsForUser;
        if (userData.isDomainUser) {
            resp = await this.service.updateDomainUser(
                userData.userName,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                userData.roles as any,
                userData.group,
            );
        } else {
            resp = await this.service.createUpdateUser(userData);
        }
        if (resp.status === 409) {
            const res = resp.data;
            message.error(res.title);
            if (isUpdate) {
                this.setIsEditUserDialogVisible(true);
            }
            this.tableIsLoading = false;
            return Promise.reject();
        } else {
            const isActionSuccessfull = resp.data;
            if (resp.status !== 200 || !isActionSuccessfull) {
                message.error(
                    "There were problems with your request, please report to administrator",
                );
                this.tableIsLoading = false;
                return Promise.reject();
            }
            if (isUpdate) {
                const users = await this.service.getAppUsers();
                runInAction(() => (this.users = users));
                message.success("User has been updated");
            } else {
                await this.getUsers();
                message.success("New user has been created");
            }
            this.selectedUser = userData.isDomainUser
                ? this.users.find((u) => u.id === userData!.userName)!
                : this.users.find((u) => u.userName === userData!.userName)!;
        }
        this.setIsAddUserDialogVisible(false);
        this.tableIsLoading = false;
        return Promise.resolve();
    }

    async updateUserAssignments() {
        this.tableIsLoading = true;
        const resp = await this.service.updateProjectAssignments(
            this.editingAssignmentsFor!,
            this.editableProjectsForUser,
        );
        if (resp.status !== 200) {
            message.error(
                "There were problems with your request, please report to administrator",
            );
            return Promise.reject();
        } else {
            message.success("Project assignments have been updated");
            this.setIsProjectRolesDialogVisible(false);
            await this.getUsers();
        }
        return Promise.resolve();
    }

    async deleteUser() {
        this.tableIsLoading = true;
        const resp = await this.service.deleteUser(
            this.selectedUser!.id!,
            this.selectedUser!.isDomainUser,
        );

        resp.map(async (isSuccessfull: boolean) => {
            if (!isSuccessfull) {
                message.error(
                    "There were problems deleting user, please report to administrator",
                );
                return;
            }
            this.setIsEditUserDialogVisible(false);
            this.users = await this.service.getAppUsers();
            this.selectedUser = null;
            message.success("User has been deleted");
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        resp.mapErr((err: any) => this.errorStore.addError(err.data));
        this.tableIsLoading = false;
    }

    async resetPassword(password: string) {
        const resp = await this.service.resetPassword(
            password,
            this.selectedUser!.id!,
        );
        if (resp.status === 200) {
            const isPasswordReset = await resp.data;
            if (isPasswordReset) {
                message.success("Password has been successfully reset");
            } else {
                message.error(
                    "There were problems resetting your password, please report to administrator",
                );
            }
        }
        this.setResetPasswordDialogVisible(false);
    }

    async changePassword(
        newPassword: string,
        oldPassword: string,
        userId: string | null,
    ) {
        try {
            const resp = await this.service.changePassword(
                newPassword,
                oldPassword,
                userId,
            );
            if (resp.status === 200) {
                this.setResetPasswordDialogVisible(false);
                message.success("Password has been successfully reset.");
            } else if (resp.status === 401) {
                message.error("Old password is incorrect.");
            } else {
                message.error(
                    "There were problems resetting your password, please report to administrator",
                );
            }
        } catch (err) {
            this.errorStore.addBasicError(err);
        }
    }

    async updateObjectRoleAssignments(
        userId: string,
        objectId: string,
        objectType: string,
        roles: string[],
    ) {
        try {
            const resp = await this.service.changeObjectRoleAssignments(
                userId,
                objectId,
                objectType,
                roles,
            );
            if (resp.status === 200) {
                this.setIsUserRoleEditDialogVisible(false);
                message.success("Roles have been successfully updated.");
            } else {
                message.error(
                    "There were problems during role update, please report to administrator",
                );
            }
        } catch (err) {
            this.errorStore.addBasicError(err);
        }
    }
}

import { Button, Dropdown, Layout, MenuProps } from "antd";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { ProjectsStore } from "src/modules/common/stores";
import WidgetConstructorStore from "../stores/WidgetConstructorStore";
import { GroupedWidgetsTable } from "./GroupedWidgetsTable";
import WidgetsTable from "./WidgetsTable";

type Props = {
    store: WidgetConstructorStore;
    projectsStore: ProjectsStore;
};

export const WidgetConstructor: React.FC<Props> = ({
    store,
    projectsStore,
}) => {
    const [isCreateModalVisible, setIsCreateModalVisible] =
        React.useState(false);
    const [currentTableMode, setCurrentTableMode] = React.useState(
        "Regular" as "Regular" | "Grouped",
    );

    React.useEffect(() => {
        store.init();
        store.getTaskStatuses();
    }, []);

    const showSelectionCheckMark = (
        selectedItem: string | number,
        selectOption: string | number,
    ) => {
        if (selectedItem !== selectOption) {
            return null;
        }

        return (
            <i
                style={{ marginLeft: 10, verticalAlign: "middle" }}
                className="alpha-icon xs action-check-active"
            />
        );
    };

    const handleGroupedClick = () => {
        setCurrentTableMode("Grouped");
        store.setSearchString("");
    };

    const handleRegularClick = () => {
        setCurrentTableMode("Regular");
        store.setSearchString("");
    };

    const groupMenu: MenuProps = {
        items: [
            {
                key: 2,
                label: (
                    <div
                        key="regular"
                        data-id-type="regular"
                        onClick={handleRegularClick}
                    >
                        <span style={{ width: 160, display: "inline-block" }}>
                            Regular widgets
                        </span>{" "}
                        {showSelectionCheckMark(currentTableMode, "Regular")}
                    </div>
                ),
            },
            {
                key: "grouped",
                label: (
                    <div
                        key="grouped"
                        data-id-type="grouped"
                        onClick={handleGroupedClick}
                    >
                        <span style={{ width: 160, display: "inline-block" }}>
                            Grouped widgets
                        </span>{" "}
                        {showSelectionCheckMark(currentTableMode, "Grouped")}
                    </div>
                ),
            },
        ],
    };

    return (
        <Layout className="screen-size widgets-constructor-layout layout-with-table">
            <div className="header-wrapper">
                <div className="title-wrapper">
                    <div className="header-title">Widgets Management</div>
                </div>
                <Dropdown
                    trigger={["click"]}
                    menu={groupMenu}
                    overlayClassName="alpha-dropdown-overlay"
                >
                    <Button
                        data-id="button-group"
                        className="dropdown-btn"
                        style={{ border: "none", marginRight: 10 }}
                        ghost
                    >
                        <i className="alpha-icon xs table-group" />
                        {currentTableMode}
                    </Button>
                </Dropdown>
                <Button
                    key={1}
                    data-id="button-save-widget"
                    type="primary"
                    size="large"
                    onClick={() => {
                        setIsCreateModalVisible(true);
                    }}
                >
                    Create widget
                </Button>
            </div>
            <Layout>
                {currentTableMode === "Regular" ? (
                    <WidgetsTable
                        store={store}
                        projectsStore={projectsStore}
                        isCreateModalVisible={isCreateModalVisible}
                        setIsCreateModalVisible={setIsCreateModalVisible}
                    />
                ) : (
                    <GroupedWidgetsTable
                        store={store}
                        projectsStore={projectsStore}
                        isCreateModalVisible={isCreateModalVisible}
                        setIsCreateModalVisible={setIsCreateModalVisible}
                    />
                )}
            </Layout>
        </Layout>
    );
};
export default observer(WidgetConstructor);

import * as React from "react";
import { observer } from "mobx-react-lite";
import { Button, Table, Layout, Tooltip, Popconfirm, Tag, Input } from "antd";
import { ColumnProps } from "antd/lib/table";
import { ProjectsStore } from "../../common/stores";
import { ProjectForCurrentUser } from "../../common/services/types";
import AddProjectDialog from "./AddProjectDialog";
import ImportProjectDialog from "./ImportProjectDialog";
import { LoadingIndicator } from "../../common/components/LoadingIndicator";
import { safeStringLocaleCompare } from "../../common/misc/Utils";

const { Content } = Layout;
type Props = {
    projectsStore: ProjectsStore;
};

const ProjectsList: React.FC<Props> = ({ projectsStore }) => {
    const [isProjectDialogVisible, setIsProjectDialogVisible] =
        React.useState(false);
    const [isImportProjectVisible, setIsImportProjectVisible] =
        React.useState(false);

    const columns: ColumnProps<ProjectForCurrentUser>[] = [
        {
            title: "Project name",
            dataIndex: "name",
            key: "name",
            render: (text: string, record: ProjectForCurrentUser) => (
                <span data-id-cells="name">{record.name}</span>
            ),
            sorter: (a: ProjectForCurrentUser, b: ProjectForCurrentUser) =>
                safeStringLocaleCompare(b.name, a.name),
        },
        {
            title: "Owner",
            dataIndex: "owner",
            key: "owner",
            render: (text: string, record: ProjectForCurrentUser) => (
                <>
                    <span data-id-cells="owner">
                        {projectsStore.getUserNameById(record.owner)}
                    </span>
                    <span data-id-cells="is-active" style={{ color: "red" }}>
                        {projectsStore.isUserDeleted(record.owner)
                            ? " (Inactive)"
                            : ""}
                    </span>
                </>
            ),
            sorter: (a: ProjectForCurrentUser, b: ProjectForCurrentUser) => {
                const ownerA = projectsStore.getUserNameById(a.owner);
                const onwerB = projectsStore.getUserNameById(b.owner);
                return safeStringLocaleCompare(onwerB, ownerA);
            },
        },
        {
            title: "Project roles",
            dataIndex: "projectRoles",
            key: "projectRoles",
            render: (rolesList: string[], record: ProjectForCurrentUser) => {
                record.projectRoles.map((role, index) => (
                    <Tag key={index}>{role}</Tag>
                ));
            },
        },
        {
            title: "Actions",
            width: 200,
            render: (record: ProjectForCurrentUser) => (
                <div className="row-actions-wrapper">
                    <Button
                        data-id-cells="Edit project"
                        title="Edit"
                        size="small"
                        type="link"
                        onClick={() => {
                            projectsStore.setEditableProject(record.id);
                            setIsProjectDialogVisible(true);
                        }}
                    >
                        <Tooltip title="Edit project" placement="bottom">
                            <i className="alpha-icon md table-action-edit" />
                        </Tooltip>
                    </Button>
                    <Button
                        data-id-cells="Export project"
                        title="Export"
                        size="small"
                        type="link"
                        onClick={() => {
                            projectsStore.exportProject(record.id);
                        }}
                    >
                        <Tooltip title="Export project" placement="bottom">
                            <i className="alpha-icon md export" />
                        </Tooltip>
                    </Button>
                    <Popconfirm
                        id="data-id-popconfirm-box"
                        title="Are you sure you want to delete this project?"
                        onConfirm={() => {
                            projectsStore.deleteProject(record.id);
                        }}
                        placement="topRight"
                    >
                        <Button
                            data-id-cells="Delete project"
                            title="Delete"
                            size="small"
                            type="link"
                        >
                            <Tooltip title="Delete project" placement="bottom">
                                <i className="alpha-icon md table-action-delete" />
                            </Tooltip>
                        </Button>
                    </Popconfirm>
                </div>
            ),
        },
    ];

    const handleEnterPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        projectsStore.setSearchString(event.currentTarget.value);
    };

    if (projectsStore.isLoading) {
        return <LoadingIndicator />;
    }
    return (
        <Layout className="screen-size tasks-types-layout layout-with-table">
            <div className="header-wrapper">
                <div className="title-wrapper">
                    <div className="header-title">Projects</div>
                </div>
                <Button
                    data-id="button-add-type"
                    style={{ marginRight: 8 }}
                    type="primary"
                    size="large"
                    onClick={() => {
                        setIsImportProjectVisible(true);
                    }}
                >
                    Import project
                </Button>
                <Button
                    data-id="button-add-type"
                    type="primary"
                    size="large"
                    onClick={() => {
                        setIsProjectDialogVisible(true);
                    }}
                >
                    Add project
                </Button>
            </div>
            <AddProjectDialog
                projectsStore={projectsStore}
                isDialogVisible={isProjectDialogVisible}
                closeDialog={() => setIsProjectDialogVisible(false)}
            />
            <ImportProjectDialog
                projectsStore={projectsStore}
                isDialogVisible={isImportProjectVisible}
                closeDialog={() => setIsImportProjectVisible(false)}
            />
            <Layout>
                <Content>
                    <Input
                        data-id="input-search"
                        style={{ width: 200, marginBottom: 20 }}
                        allowClear
                        placeholder="Search..."
                        onPressEnter={handleEnterPress}
                        prefix={
                            <i
                                className="alpha-icon xs table-search"
                                style={{ marginRight: 10 }}
                            />
                        }
                        onClear={() => projectsStore.setSearchString("")}
                    />
                    <Table
                        data-id="table-tasks-templates-list"
                        className="alpha-table with-scroll"
                        columns={columns}
                        dataSource={projectsStore.filteredProjects}
                        rowKey={(r) => r.id}
                        pagination={false}
                        scroll={{ y: "calc(100vh - 240px)" }}
                    />
                </Content>
            </Layout>
        </Layout>
    );
};

export default observer(ProjectsList);

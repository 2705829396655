import * as React from "react";
import { TaskCommentsStore, TaskViewVisualStore } from "../stores";
import { TaskCommentModel } from "../types";
import { Utils } from "../../common/misc/Utils";
import { Dropdown, Button, Modal } from "antd";
import { observer } from "mobx-react-lite";
import { TaskUserProfilePicture, TaskAlphaIdHyperlink } from ".";
import parse, { DomElement } from "html-react-parser";
import CommentInDialog from "./CommentInDialog";

type Props = {
    currentUserId: string;
    comment: TaskCommentModel;
    store: TaskCommentsStore;
    taskStore: TaskViewVisualStore;
    disabled?: boolean;
    taskPrefix?: string;
};

const TaskComment: React.FC<Props> = ({
    store,
    taskStore,
    comment,
    currentUserId,
    disabled = false,
    taskPrefix = undefined,
}) => {
    const commentMenu = (commentId: string) => {
        return {
            items: [
                {
                    key: 1,
                    label: (
                        <div onClick={() => store.toggleCommentEdit(commentId)}>
                            Edit
                        </div>
                    ),
                },
                {
                    key: 2,
                    label: (
                        <div
                            style={{ color: "red" }}
                            onClick={() => store.removeComment(commentId)}
                        >
                            Delete
                        </div>
                    ),
                },
            ],
        };
    };

    const renderComment = () => {
        if (!taskPrefix) {
            return <span dangerouslySetInnerHTML={{ __html: comment.body }} />;
        }
        taskPrefix = taskPrefix.replace(/(\W)/g, "\\$1"); // Escape all non-word characters
        const regex = new RegExp(`${taskPrefix} [0-9]+`, "gm");
        let alphaIds: string[] = comment.body.match(regex)!;
        if (alphaIds) {
            let processedComment = comment.body;
            alphaIds = [...new Set(alphaIds)];
            for (const id of alphaIds) {
                processedComment = processedComment
                    .split(id)
                    .join(`<span data-id='${id}'></span>`);
            }

            const item = parse(processedComment, {
                replace: (node: DomElement) => {
                    const dataId = node.attribs
                        ? node.attribs["data-id"]
                        : undefined;
                    if (dataId) {
                        return (
                            <TaskAlphaIdHyperlink
                                store={store}
                                alphaId={dataId}
                            />
                        );
                    }
                    return node;
                },
            });

            if (!(item instanceof Array)) {
                return item;
            } else {
                return <React.Fragment>{item.map((i) => i)}</React.Fragment>;
            }
        }
        return <span dangerouslySetInnerHTML={{ __html: comment.body }} />;
    };

    const isEditing = store.editingCommentId === comment.id;

    return (
        <div className="task-comment">
            {isEditing && (
                <Modal
                    title="Change comment"
                    open
                    width={572}
                    className="alpha-modal comment-in-modal"
                    closable={false}
                    footer={[
                        <Button
                            data-id="status-cancel-button"
                            key="status-cancel-button"
                            className="light"
                            onClick={() => store.toggleCommentEdit(undefined)}
                        >
                            Cancel
                        </Button>,
                        <Button
                            data-id="status-save-button"
                            key="status-save-button"
                            type="primary"
                            loading={store.postingComment}
                            onClick={() =>
                                store.updateComment(taskStore.taskPreview!.id)
                            }
                            disabled={!store.canEditComment}
                        >
                            Save changes
                        </Button>,
                    ]}
                >
                    <CommentInDialog
                        commentsStore={store}
                        value={store.editingCommentBody}
                    />
                </Modal>
            )}
            <div className="hover-layer" />
            <div className="task-comment-header">
                <TaskUserProfilePicture
                    store={taskStore}
                    userId={comment.userId}
                    userName={comment.userName}
                    className="author-avatar"
                    size={24}
                />
                <div>
                    <span className="author-username">
                        {currentUserId === comment.userId
                            ? "You"
                            : comment.userName}
                    </span>
                    <span className="comment-date">
                        &nbsp;posted comment -{" "}
                        {Utils.formatDateStringLong(comment.createdDate)}
                    </span>
                </div>
                {store.currentUserId === comment.userId && (
                    <Dropdown
                        trigger={["click"]}
                        menu={commentMenu(comment.id)}
                        disabled={disabled}
                    >
                        <Button
                            style={{ border: "none" }}
                            size="small"
                            ghost
                            className="comment-dropdown-btn"
                        >
                            <i className="alpha-icon xs action-more-dots no-margin" />
                        </Button>
                    </Dropdown>
                )}
            </div>
            <div className="task-comment-body">{renderComment()}</div>
        </div>
    );
};

export default observer(TaskComment);

import * as React from "react";
import { observer } from "mobx-react-lite";
import DashboardStore from "../stores/DashboardStore";
import ListWidget from "../models/ListWidget";
import { ALL_PROJECTS } from "src/modules/tasks/screens/TasksPage";
import WidgetEditor from "./WidgetEditor";

type Props = {
    dashboardStore: DashboardStore;
    widget: ListWidget;
    openEditWidgetModal: (id: string) => void;
};

const ListWidgetCard: React.FC<Props> = ({
    widget,
    dashboardStore,
    openEditWidgetModal,
}) => {
    const handleWidgetClick = () => {
        const url = `${process.env.PUBLIC_URL}/tasks/project/${widget.projectId || ALL_PROJECTS}/widget/${widget.id}`;
        window.open(url, "_blank")!.focus();
    };

    const handleTaskClick = (taskId: string) => {
        const url = `${process.env.PUBLIC_URL}/tasks/${taskId}`;
        window.open(url, "_blank")!.focus();
    };

    return (
        <div className="card">
            <div className="section-title">{widget.name}</div>
            {!widget.isDefaultWidget && (
                <WidgetEditor
                    dashboardStore={dashboardStore}
                    widget={widget}
                    openEditWidgetModal={openEditWidgetModal}
                />
            )}
            {widget.tasks.length ? (
                <>
                    <div className="list-widget-card-container ">
                        {widget.tasks.map((t) => (
                            <div
                                className="task-item-container"
                                key={t.taskId}
                                onClick={() => handleTaskClick(t.taskId)}
                            >
                                <div className="indicator" />
                                <span>
                                    {t.isSubtask ? (
                                        <i
                                            style={{
                                                verticalAlign: "text-top",
                                            }}
                                            className="alpha-icon subtask-icon xs"
                                        />
                                    ) : (
                                        <></>
                                    )}
                                    <span>{t.taskTitle}</span>
                                </span>
                            </div>
                        ))}
                    </div>
                    <div className="view-all" onClick={handleWidgetClick}>
                        View all ({widget.totalTasksCount})
                    </div>
                </>
            ) : (
                <div className="empty-card-title">There are no tasks yet</div>
            )}
        </div>
    );
};

export default observer(ListWidgetCard);

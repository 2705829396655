import React from "react";
import { observer } from "mobx-react-lite";
import MetadataDefinitionsList from "../components/MetadataDefinitionsList";
import { useAdminStore } from "../stores/context";
import { useStore } from "../../common/stores";

const MetadataPage: React.FC = () => {
    const context = useAdminStore();
    const rootContext = useStore();

    return (
        <MetadataDefinitionsList
            store={context.metadataDefinitionStore}
            projectsStore={rootContext.projectsStore}
        />
    );
};

export default observer(MetadataPage);

export type ActivityByProjects = {
    [key in "AllProjects" | "SelectedProject"]: Activity[];
};

export type Activity = {
    activityType: "FailedMessages" | "AssignedTasks" | "NewComments";
    ids: string[];
};

export type UpdatedActivitiesPushMsg = {
    deletedComments: string[];
    deletedTaskErrors: string[];
    deletedTask: string;
};

export type AssignmentMsg = {
    taskId: string;
    userId: string;
};

export enum ActivitiesPeriodEnum {
    Today = "Today",
    SevenDays = "SevenDays",
    ThirtyDays = "ThirtyDays",
}

import * as React from "react";
import { observer } from "mobx-react-lite";
import UsersManagerStore from "../stores/UsersManagerStore";
import { Checkbox, Select, Table } from "antd";
import { ColumnProps } from "antd/lib/table";

type Props = {
    store: UsersManagerStore;
};

type ProjectRoleModel = {
    id: string;
    projectName: string;
    isAdmin: boolean;
    roles: string[];
};

export const ProjectsAssignmentTable: React.FC<Props> = observer(
    ({ store }) => {
        const isEditingDisabled = (projectId: string) => {
            const editableUser = store.editingAssignmentsFor;
            return editableUser
                ? store.projects.some(
                      (p) => p.id === projectId && editableUser === p.owner,
                  )
                : false;
        };
        const columns: ColumnProps<ProjectRoleModel>[] = [
            {
                title: "Project name",
                dataIndex: "projectName",
            },
            {
                title: "Is Admin",
                dataIndex: "isAdmin",
                width: 100,
                render: (isAdmin: boolean, record: ProjectRoleModel) => {
                    return (
                        <Checkbox
                            checked={isAdmin}
                            onChange={(e) =>
                                store.setAdminFlagForProject(
                                    record.id,
                                    e.target.checked,
                                )
                            }
                            disabled={isEditingDisabled(record.id)}
                        />
                    );
                },
            },
            {
                title: "Project roles",
                dataIndex: "roles",
                width: 300,
                render: (roles: string[], record: ProjectRoleModel) => (
                    <Select
                        style={{ width: "100%" }}
                        mode="multiple"
                        defaultValue={roles}
                        onChange={(values) =>
                            store.setUserRolesForProject(record.id, values)
                        }
                        options={
                            store.projects
                                .find((p) => p.id === record.id)
                                ?.projectRoles?.map((r) => ({
                                    value: r,
                                    label: r,
                                })) ?? []
                        }
                        disabled={isEditingDisabled(record.id)}
                    />
                ),
            },
        ];

        return (
            <Table
                className="alpha-table"
                columns={columns}
                dataSource={store.projects
                    .map((s) => ({
                        id: s.id,
                        projectName: s.name,
                        isAdmin:
                            store.editableProjectsForUser.find(
                                (e) => e.projectId === s.id,
                            )?.isAdmin ?? false,
                        roles:
                            store.editableProjectsForUser.find(
                                (e) => e.projectId === s.id,
                            )?.roles ?? [],
                    }))
                    .sort((a, b) => a.projectName.localeCompare(b.projectName))}
                scroll={{ y: "calc(100vh - 250px)" }}
                rowKey={(s) => s.id}
                pagination={false}
                rowSelection={{
                    selectedRowKeys: store.editableProjectsForUser.map(
                        (p) => p.projectId,
                    ),
                    onChange: (selectedRowKeys) => {
                        store.setAssignedProjectsForUser(
                            selectedRowKeys as string[],
                        );
                    },
                    getCheckboxProps: (record: ProjectRoleModel) => {
                        return {
                            disabled: isEditingDisabled(record.id),
                        };
                    },
                }}
            />
        );
    },
);

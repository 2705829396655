import { observer } from "mobx-react-lite";
import * as React from "react";
import { TaskCommentsStore, TaskViewVisualStore } from "../stores";
import {
    Button,
    Col,
    Collapse,
    DatePicker,
    Input,
    Row,
    Select,
    Skeleton,
    Spin,
    Tooltip,
} from "antd";
import TaskUserProfilePicture from "./TaskUserProfilePicture";
import { Utils } from "../../common/misc/Utils";
import TaskDescriptionSection from "../../common/components/TaskFullViewSections/TaskDescriptionSection";
import MetadataEditor from "./MetadataEditor";
import TaskPreviewSubtask from "./TaskPreviewSubtask";
import TaskLinkElements from "./TaskLinkElements";
import TaskAttachmentsSection from "../../common/components/TaskFullViewSections/TaskAttachmentsSection";
import { ErrorRenderer } from "./ErrorRenderer";
import TaskLinkDialog from "./TaskLinkDialog";
import DetailsSection from "./DetailsSection";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
import { AuthConsumer } from "../../authorization/AuthContext";
import { hasPermission } from "../../authorization/components/HasPermission";
import { AppPermissions } from "../../authorization/Permissions";
import { ScrollToSections } from "../stores/TaskViewVisualStore";
import { UserProfile } from "src/modules/common/services/types";
import dayjs, { Dayjs } from "dayjs";

type Props = {
    store: TaskViewVisualStore;
    commentsStore: TaskCommentsStore;
    isFullView: boolean;
    previewRef?: React.MutableRefObject<null>;
};
const TaskPreview: React.FC<Props> = ({
    store,
    commentsStore,
    isFullView,
    previewRef,
}) => {
    const subtasksRef = React.useRef(null);
    const attachmentsRef = React.useRef(null);

    React.useEffect(() => {
        if (store.scrollToSections === ScrollToSections.Subtasks) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (subtasksRef!.current as any).scrollIntoView();
        } else if (store.scrollToSections === ScrollToSections.Attachments) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (attachmentsRef!.current as any).scrollIntoView();
        } else if (previewRef) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (previewRef!.current as any).scrollTo(0, 0);
        }
    }, [store.scrollToSections]);
    const navigate = useNavigate();
    const { taskPreview } = store;

    const disabledDate = (current: Dayjs) => {
        return current && current < dayjs().endOf("day");
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getExpandIcon = (panelProps: any) => {
        return (
            <i
                style={
                    !panelProps.isActive
                        ? {
                              transform: "rotate(-90deg)",
                              transition: "transform 0.24s",
                          }
                        : { transition: "transform 0.24s" }
                }
                className="alpha-icon xxxs arrow-down-icon"
            />
        );
    };

    const isTaskClosed = !!store.getStatusById(taskPreview!.status)?.isFinal;

    const handleParentTaskClick = () => {
        if (isFullView) {
            store.setPageIsLoading(true);
            navigate(`/tasks/${taskPreview!.parentTask!.taskId}`);
        } else {
            store.openParentTask();
        }
    };

    const getParentTaskNavigation = () => {
        const title = store.getParentTaskTitle(taskPreview!.parentTask!);
        return (
            <div
                className="parent-task-navigation"
                onClick={handleParentTaskClick}
            >
                {title}
            </div>
        );
    };

    const getUserLabel = (user: UserProfile) => {
        return (
            <>
                <span>{`${user?.firstName} ${user?.lastName}`}</span>
                <span style={{ color: "red" }}>
                    {user?.isDeleted ? " (Inactive)" : ""}
                </span>
            </>
        );
    };

    const metadataCollapsePanel = (permissions: string[]) => {
        return [
            {
                key: "metadata",
                label: "Metadata",
                children: store.metadataDefinitionsLoading ? (
                    <Skeleton />
                ) : (
                    <div className="dialog-section">
                        {store.metadataDefinitions
                            .filter(
                                (m) =>
                                    (m.isVisible ||
                                        hasPermission(
                                            permissions,
                                            AppPermissions.CanAccessAdministration,
                                        )) &&
                                    store.hasAccessToMetadata(m.taskTypes),
                            )
                            .slice()
                            .sort((a, b) => a.position - b.position)
                            .map((x) => (
                                <Row
                                    className="inputs-container input-row"
                                    key={x.name}
                                >
                                    <Col span={10}>
                                        <span className="input-label">
                                            {x.title ?? x.name}
                                        </span>
                                    </Col>
                                    <Col span={14}>
                                        <MetadataEditor
                                            store={store}
                                            definition={x}
                                            task={store.taskPreview!}
                                        />
                                    </Col>
                                </Row>
                            ))}
                        {!store.metadataDefinitions.length && (
                            <div className="empty-message">
                                There are no metadata yet.
                            </div>
                        )}
                    </div>
                ),
            },
        ];
    };

    const subtasksCollapsePanel = [
        {
            key: "subtasks",
            label: (
                <span className="title">
                    {`Subtasks (${taskPreview!.subTasksMeta?.length})`}
                </span>
            ),
            children:
                store.taskStatusesLoading ||
                store.isSubtaskLoadingInProgress ? (
                    <div
                        className="attachments-loading-spin"
                        style={{ textAlign: "center", height: 48 }}
                    >
                        <Spin />
                    </div>
                ) : (
                    <TaskPreviewSubtask
                        subTaskMeta={taskPreview!.subTasksMeta}
                        store={store}
                        taskId={taskPreview!.id}
                        parentTaskStatus={taskPreview!.status}
                        isFullView={isFullView}
                    />
                ),
        },
    ];

    const collapseLinkedTaskItems = [
        {
            key: "linked-tasks",
            label: `Linked tasks (${store.taskLinks.length})`,
            extra: (
                <Button
                    style={{ position: "relative", zIndex: 1000 }}
                    type="link"
                    size="small"
                    title="Link task"
                    onClick={(e) => {
                        e.stopPropagation();
                        store.setSelectedLink(null);
                    }}
                >
                    <i
                        style={{ verticalAlign: "middle" }}
                        className="alpha-icon xs plus"
                    />
                </Button>
            ),
            children: (
                <div className="dialog-section">
                    {store.taskLinksLoading && (
                        <div
                            className="attachments-loading-spin"
                            style={{ textAlign: "center", height: 48 }}
                        >
                            <Spin />
                        </div>
                    )}
                    {!store.taskLinksLoading && !!store.taskLinks.length && (
                        <TaskLinkElements
                            isFullView={isFullView}
                            currentTaskId={taskPreview!.id}
                            store={store}
                        />
                    )}
                    {!store.taskLinksLoading && !store.taskLinks.length && (
                        <div className="empty-message">
                            There are no linked tasks yet.
                        </div>
                    )}
                </div>
            ),
        },
    ];

    const collapseErrorItems = [
        {
            key: "error",
            label: "Error",
            className: "alpha-task-section-collapse-panel",
            children: (
                <>
                    <div className="dialog-section">
                        <ErrorRenderer errors={taskPreview!.errors} />
                    </div>
                    {!taskPreview!.errors.length && (
                        <div className="empty-message">
                            There are no errors.
                        </div>
                    )}
                </>
            ),
            extra: null,
        },
    ];

    return (
        <>
            <TaskLinkDialog taskId={store.taskPreview!.id} store={store} />
            <div
                className="dialog-header"
                data-id-type="collapse-container"
                data-id-name="Task-Head"
            >
                {taskPreview!.isSubtask && getParentTaskNavigation()}
                <div
                    style={{ display: store.editingTitle ? "none" : "block" }}
                    className={`title ${isTaskClosed ? "closed" : ""}`}
                >
                    <Tooltip title={store.taskPreviewDisplayName}>
                        {Utils.trimTitleString(
                            store.taskPreviewDisplayName || "",
                        )}
                    </Tooltip>
                    {store.taskFormLocked ? null : (
                        <Button
                            type="link"
                            size="small"
                            disabled={isTaskClosed}
                            onClick={() => {
                                store.setEditingTitle(true, taskPreview!.name);
                            }}
                            style={{ verticalAlign: "middle" }}
                        >
                            <i className="alpha-icon xs edit-pen" />
                        </Button>
                    )}
                </div>
                <div
                    style={{ display: !store.editingTitle ? "none" : "block" }}
                >
                    <Input.TextArea
                        className="title"
                        value={store.newTitle}
                        onChange={(e) => {
                            store.setNewTitle(e.target.value);
                        }}
                    />
                    <Button
                        type="link"
                        size="small"
                        disabled={
                            isTaskClosed ||
                            !store.newTitle ||
                            !store.newTitle.length
                        }
                        onClick={() => {
                            store.updateField(
                                taskPreview!.id,
                                "Name",
                                store.newTitle,
                            );
                            store.setEditingTitle(false, store.newTitle);
                        }}
                    >
                        <CheckOutlined />
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            store.setEditingTitle(false, taskPreview!.name);
                        }}
                    >
                        <CloseOutlined />
                    </Button>
                </div>
            </div>
            <div
                style={
                    isFullView ? { overflow: "auto", overflowX: "hidden" } : {}
                }
            >
                <div
                    className="dialog-section"
                    data-id-type="collapse-container"
                    data-id-name="Task-Assign"
                    style={{ marginLeft: isFullView ? 0 : 24 }}
                >
                    <Row>
                        <Col
                            span={isFullView ? 10 : 15}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                            }}
                            // onClick={() => store.taskFormLocked ? null : setIsAssignModalVisible(true)}
                        >
                            <TaskUserProfilePicture
                                store={store}
                                size={24}
                                userId={taskPreview!.assignedTo}
                                userName={
                                    store.allUsersFullNameResolver[
                                        taskPreview!.assignedTo
                                    ]
                                }
                                tooltipMsg="Assign user"
                                emptyAvatarClassName="xs"
                            />
                            {!store.usersInProject ||
                            !store.usersInProject.length ? (
                                <div
                                    style={{
                                        display: "inline-block",
                                        marginLeft: 12,
                                        height: "16px",
                                        width: "calc(100% - 36px)",
                                        verticalAlign: "middle",
                                    }}
                                >
                                    <Skeleton
                                        title={false}
                                        avatar={false}
                                        paragraph={{ rows: 1 }}
                                        active
                                    />
                                </div>
                            ) : (
                                <Select
                                    placeholder="Assign user"
                                    disabled={store.taskFormLocked}
                                    style={{
                                        maxWidth: "calc(100% - 62px)",
                                        width: "100%",
                                        marginLeft: 4,
                                    }}
                                    className="borderless-select"
                                    value={taskPreview!.assignedTo || undefined}
                                    onChange={(option: unknown) =>
                                        store.hanldeAssignToChange(option)
                                    }
                                >
                                    {store.filteredAssignToUsers.map((u) => (
                                        <Select.Option
                                            key={u.userId}
                                            value={u.userId}
                                        >
                                            {getUserLabel(u)}
                                        </Select.Option>
                                    ))}
                                </Select>
                            )}
                        </Col>
                        <Col span={isFullView ? 14 : 9}>
                            <DatePicker
                                disabled={store.taskFormLocked}
                                className="borderless-picker"
                                placeholder="Set due date"
                                disabledDate={disabledDate}
                                // suffixIcon={<i className="alpha-icon xs calendar-icon" />}
                                value={Utils.formatDatePickerValue(
                                    taskPreview!.dueDate,
                                )}
                                onChange={(m) =>
                                    store.updateField(
                                        taskPreview!.id,
                                        "DueDate",
                                        m?.toISOString(),
                                    )
                                }
                                format={Utils.getDateFormat()}
                            />
                        </Col>
                    </Row>
                </div>
                <TaskDescriptionSection store={store} isFullView={isFullView} />
                <DetailsSection
                    store={store}
                    commentStore={commentsStore}
                    getExpandIcon={getExpandIcon}
                    disabledDate={disabledDate}
                    isTaskClosed={isTaskClosed}
                />
                <AuthConsumer>
                    {({ permissions }) => (
                        <div
                            data-id-type="collapse-container"
                            data-id-name="Metadata"
                        >
                            <Collapse
                                bordered={false}
                                expandIcon={getExpandIcon}
                                activeKey={
                                    store.activeSectionKeys.includes("metadata")
                                        ? "metadata"
                                        : undefined
                                }
                                onChange={(keys: string[]) =>
                                    store.setSectionActiveKey(
                                        "metadata",
                                        !!keys[0],
                                    )
                                }
                                className="alpha-task-section-collapse"
                                items={metadataCollapsePanel(permissions)}
                            />
                        </div>
                    )}
                </AuthConsumer>
                {!taskPreview!.isSubtask ? (
                    <div
                        ref={subtasksRef}
                        data-id-type="collapse-container"
                        data-id-name="Subtasks"
                    >
                        <Collapse
                            bordered={false}
                            className="alpha-task-section-collapse"
                            expandIcon={getExpandIcon}
                            defaultActiveKey="subtasks"
                            items={subtasksCollapsePanel}
                        />
                    </div>
                ) : (
                    <></>
                )}
                <div
                    data-id-type="collapse-container"
                    data-id-name="Linked-Tasks"
                >
                    <Collapse
                        bordered={false}
                        expandIcon={getExpandIcon}
                        className="alpha-task-section-collapse"
                        activeKey={
                            store.activeSectionKeys.includes("linked-tasks")
                                ? "linked-tasks"
                                : undefined
                        }
                        onChange={(keys: string[]) =>
                            store.setSectionActiveKey("linked-tasks", !!keys[0])
                        }
                        items={collapseLinkedTaskItems}
                    />
                </div>
                <div
                    ref={attachmentsRef}
                    data-id-type="collapse-container"
                    data-id-name="Attachments"
                >
                    <TaskAttachmentsSection
                        store={store}
                        taskId={taskPreview!.id}
                        isFullView={isFullView}
                    />
                </div>
                <div data-id-type="collapse-container" data-id-name="Error">
                    <Collapse
                        expandIcon={getExpandIcon}
                        bordered={false}
                        className="alpha-task-section-collapse"
                        activeKey={
                            store.activeSectionKeys.includes("error")
                                ? "error"
                                : undefined
                        }
                        onChange={(keys: string[]) =>
                            store.setSectionActiveKey("error", !!keys[0])
                        }
                        items={collapseErrorItems}
                    />
                </div>
            </div>
        </>
    );
};
export default observer(TaskPreview);

import * as React from "react";
import { observer } from "mobx-react-lite";
import TaskStatusesVisualStore from "../stores/TaskStatusesVisualStore";
import { Form, Select } from "antd";
import { FlowMode } from "./TaskStatusFlowEditor";

type Props = {
    store: TaskStatusesVisualStore;
    flowMode: FlowMode;
    onChangeCallback: () => void;
};

const FlowCanvasEditor: React.FC<Props> = ({
    store,
    flowMode,
    onChangeCallback,
}) => {
    const isSubtaskFlow = flowMode === "child";

    return (
        <>
            <Form.Item
                label={
                    <span className="dialog-field-label">Initial status</span>
                }
                name="initialStatus"
                rules={[
                    { required: true, message: "Initial status is required" },
                ]}
            >
                <Select
                    data-id="input-initial-status"
                    options={store.taskStatuses
                        .filter((x) => x.isSubtaskStatus === isSubtaskFlow)
                        .map((x) => ({ label: x.name, value: x.id }))}
                    onChange={(v: string) => {
                        store.taskStatuses
                            .filter((x) => x.isSubtaskStatus === isSubtaskFlow)
                            .forEach((ts) => {
                                ts.isDefault = ts.id === v;
                            });

                        onChangeCallback();
                    }}
                />
            </Form.Item>
            <Form.Item
                label={<span className="dialog-field-label">Final status</span>}
                name="finalStatus"
                rules={[
                    { required: true, message: "Final status is required" },
                ]}
            >
                <Select
                    data-id="input-final-status"
                    options={store.taskStatuses
                        .filter((x) => x.isSubtaskStatus === isSubtaskFlow)
                        .map((x) => ({ label: x.name, value: x.id }))}
                    onChange={(v: string) => {
                        store.taskStatuses
                            .filter((x) => x.isSubtaskStatus === isSubtaskFlow)
                            .forEach((ts) => {
                                ts.isFinal = ts.id === v;
                            });

                        onChangeCallback();
                    }}
                />
            </Form.Item>
        </>
    );
};

export default observer(FlowCanvasEditor);

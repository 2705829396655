import * as React from "react";
import { observer } from "mobx-react-lite";
import {
    Button,
    Table,
    Switch,
    Popconfirm,
    Layout,
    Tooltip,
    Select,
    Checkbox,
    Input,
} from "antd";
import { ColumnType } from "antd/lib/table";
import { TaskType, TaskTypeDueDate } from "../types";
import TaskTypesVisualStore from "../stores/TaskTypesVisualStore";
import TaskTypeCreateDialog from "./TaskTypeCreateDialog";
import TaskTypeEditDialog from "./TaskTypeEditDialog";
import { ProjectsStore } from "../../common/stores";
import { safeStringLocaleCompare, Utils } from "../../common/misc/Utils";

const { Content } = Layout;
type Props = {
    store: TaskTypesVisualStore;
    projectsStore: ProjectsStore;
};

const TaskTypesList: React.FC<Props> = ({ store, projectsStore }) => {
    React.useEffect(() => {
        return () => {
            store.setTaskTypes([]);
        };
    }, []);

    const getUserInacitivityLabel = (userId: string) => {
        const isDeleted = store.usersForProject.some(
            (u) => u.userId === userId && u.isDeleted,
        );
        return (
            <span data-id-cells="is-active" style={{ color: "red" }}>
                {isDeleted ? " (Inactive)" : ""}
            </span>
        );
    };

    const periodResolver = (dueDate: TaskTypeDueDate) => {
        switch (dueDate.period) {
            case "D":
                return "days";
            case "W":
                return "weeks";
            case "M":
                return "months";
            case "Y":
                return "years";
            default:
                return "";
        }
    };

    const columns: ColumnType<TaskType | null>[] = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            render: (_, record: TaskType) => (
                <span data-id-cells="name">{record.name}</span>
            ),
            sorter: (a: TaskType, b: TaskType) =>
                safeStringLocaleCompare(b.name, a.name),
        },
        {
            title: "Assignee",
            dataIndex: "assignee",
            key: "assignee",
            render: (_, record: TaskType) => (
                <>
                    <span data-id-cells="assignee">
                        {store.getUserNameById(record.assignee)}
                    </span>
                    {getUserInacitivityLabel(record.assignee)}
                </>
            ),
            sorter: (a: TaskType, b: TaskType) => {
                store.getUserNameById(a.assignee);
                return safeStringLocaleCompare(
                    store.getUserNameById(b.assignee),
                    store.getUserNameById(a.assignee),
                );
            },
        },
        {
            title: "Code",
            dataIndex: "code",
            key: "code",
            render: (_, record: TaskType) => (
                <span data-id-cells="code">{record.code}</span>
            ),
            sorter: (a: TaskType, b: TaskType) =>
                safeStringLocaleCompare(b.code, a.code),
        },
        {
            title: "Due period",
            dataIndex: "dueDate",
            key: "duePeriod",
            render: (_, record: TaskType) => (
                <span data-id-cells="code">
                    {record.dueDate?.count
                        ? `${record.dueDate?.count} ${periodResolver(record.dueDate)}`
                        : ""}
                </span>
            ),
        },
        {
            title: "Manual status change",
            dataIndex: "canChangeStatusManually",
            key: "canChangeStatusManually",
            render: (_, record: TaskType) => (
                <Checkbox disabled checked={record.canChangeStatusManually} />
            ),
            sorter: (a: TaskType, b: TaskType) => {
                if (a.canChangeStatusManually === b.canChangeStatusManually)
                    return 0;
                return a.canChangeStatusManually ? -1 : 1;
            },
        },
        {
            title: "Distinct subtask statuses",
            dataIndex: "distinctSubtaskStatuses",
            key: "distinctSubtaskStatuses",
            render: (_, record: TaskType) => (
                <Checkbox disabled checked={record.distinctSubtaskStatuses} />
            ),
            sorter: (a: TaskType, b: TaskType) => {
                if (a.distinctSubtaskStatuses === b.distinctSubtaskStatuses)
                    return 0;
                return a.distinctSubtaskStatuses ? -1 : 1;
            },
        },
        {
            title: "Actions",
            width: 200,
            render: (_, record: TaskType) => (
                <div className="row-actions-wrapper">
                    <Button
                        data-id-cells="Edit type"
                        title="Rename"
                        size="small"
                        type="link"
                        onClick={() => {
                            store.selectType(record);
                        }}
                    >
                        <Tooltip title="Edit type" placement="bottom">
                            <i className="alpha-icon md table-action-edit" />
                        </Tooltip>
                    </Button>
                    <Popconfirm
                        id="data-id-popconfirm-box"
                        title="Are you sure you want to delete this type?"
                        onConfirm={() => {
                            store.deleteTaskType(record.id);
                        }}
                        placement="topRight"
                    >
                        <Button
                            data-id-cells="Delete type"
                            title="Delete"
                            size="small"
                            type="link"
                        >
                            <Tooltip title="Delete type" placement="bottom">
                                <i className="alpha-icon md table-action-delete" />
                            </Tooltip>
                        </Button>
                    </Popconfirm>
                    <Switch
                        data-id-cells="is-active"
                        data-id-value={record.isEnabled}
                        style={{
                            visibility: "visible",
                            marginBottom: 18,
                            marginLeft: 14,
                        }}
                        checked={record.isEnabled}
                        onChange={() => {
                            store.toggleTaskType(record.id);
                        }}
                    />
                </div>
            ),
        },
    ];

    const handleEnterPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        store.setSearchString(event.currentTarget.value);
    };

    return (
        <Layout className="screen-size tasks-types-layout layout-with-table">
            <div className="header-wrapper">
                <div className="title-wrapper">
                    <div className="header-title">Task Types</div>
                </div>
                <Button
                    key={1}
                    disabled={!store.selectedProjectId}
                    data-id="button-add-type"
                    type="primary"
                    size="large"
                    onClick={() => {
                        store.setNewTypeDialogVisible(true);
                    }}
                >
                    Add type
                </Button>
            </div>
            <TaskTypeCreateDialog store={store} />
            {store.typeEditDialogVisible ? (
                <TaskTypeEditDialog store={store} />
            ) : null}
            <Layout>
                <Content>
                    <div className="projects-list-container">
                        <Select
                            style={{ width: 220 }}
                            options={
                                projectsStore.administrableProjects?.map(
                                    (p) => ({ label: p.name, value: p.id }),
                                ) || []
                            }
                            placeholder="Select project"
                            onChange={store.setSelectedProjectId}
                            showSearch
                            filterOption={Utils.filterOption}
                        />
                        <Input
                            data-id="search area"
                            placeholder="Search..."
                            onPressEnter={handleEnterPress}
                            onClear={() => store.setSearchString("")}
                            maxLength={150}
                            style={{ width: 200, marginLeft: "auto" }}
                            prefix={
                                <i
                                    className="alpha-icon xs table-search"
                                    style={{ marginRight: 10 }}
                                />
                            }
                            allowClear
                        />
                    </div>
                    <Table
                        data-id="table-tasks-types-list"
                        className="alpha-table task-types"
                        loading={store.taskTypesLoading}
                        columns={columns}
                        dataSource={
                            store.selectedProjectId
                                ? store.filteredTaskTypes
                                : []
                        }
                        rowKey={(r) => r!.id}
                        pagination={false}
                    />
                </Content>
            </Layout>
        </Layout>
    );
};

export default observer(TaskTypesList);

import { ActionEvent, PackagePreviewModel, TaskModel } from "../../tasks/types";
import {
    ActionConstraint,
    ActionDefinition,
} from "../../administration/types/Actions";
import { Utils } from "./Utils";
import { ProjectForCurrentUser } from "../services/types";

export default class ActionHelper {
    static canExecute(
        action: ActionDefinition,
        task: TaskModel,
        projectAssignment: ProjectForCurrentUser,
        pkg?: PackagePreviewModel,
        events?: ActionEvent[],
    ): { canExecute: boolean; reason?: string } {
        // Check action permissions:
        if (action.permissions) {
            // Can't trigger action if user is not assigned to project
            if (!projectAssignment) {
                return { canExecute: false, reason: "No project assignment" };
            }

            const requiresAdmin = action.permissions.admin ?? false;
            const requiresRoles = (action.permissions.roles ?? []).length > 0;
            const isAdmin = projectAssignment.isAdmin ?? false;
            const hasUserRolesDefined =
                (projectAssignment.userRoles ?? []).length > 0;
            const hasRequiredRole =
                action.permissions.roles?.some((r) =>
                    projectAssignment.userRoles?.includes(r),
                ) ?? false;

            // User has to be project admin (no extra roles defined)
            if (requiresAdmin && !requiresRoles && !isAdmin) {
                return {
                    canExecute: false,
                    reason: "User is not project admin",
                };
            }

            if (requiresRoles) {
                if (!hasUserRolesDefined && !isAdmin) {
                    return {
                        canExecute: false,
                        reason: "User has no roles assigned",
                    };
                }

                if (!hasRequiredRole && !isAdmin) {
                    return {
                        canExecute: false,
                        reason: "User doesn't have required role",
                    };
                }
            }
        }

        // Meets constraints
        if (
            action.constraints &&
            action.constraints.some((c) => !this.checkConstraint(c, task, pkg))
        ) {
            return { canExecute: false, reason: "Constraints not met" };
        }

        // No running actions of the same type:
        if (this.hasActionInProgress(action, task, pkg, events)) {
            return {
                canExecute: false,
                reason: "Action of the same type is in progress",
            };
        }

        return { canExecute: true, reason: undefined };
    }

    static hasActionInProgress(
        action: ActionDefinition,
        task: TaskModel,
        pkg?: PackagePreviewModel,
        events?: ActionEvent[],
    ) {
        return events
            ? events.some(
                  (e) =>
                      e.taskId === task.id &&
                      (pkg === undefined || e.packageId === pkg.id) &&
                      e.actionType === action.actionType &&
                      e.status === "Started",
              )
            : false;
    }

    static checkConstraint(
        constraint: ActionConstraint,
        task: TaskModel,
        pkg?: PackagePreviewModel,
    ) {
        let result: boolean = true;

        let sourceValue;

        switch (constraint.fieldType) {
            case "TaskProperty":
                sourceValue =
                    task &&
                    Object.keys(task).includes(
                        Utils.unCapitalizeWord(constraint.name),
                    )
                        ? task[Utils.unCapitalizeWord(constraint.name)]
                        : undefined;
                break;
            case "AttachmentProperty":
                sourceValue =
                    pkg &&
                    Object.keys(pkg).includes(
                        Utils.unCapitalizeWord(constraint.name),
                    )
                        ? pkg[Utils.unCapitalizeWord(constraint.name)]
                        : undefined;
                break;
            case "Metadata":
                sourceValue =
                    task &&
                    task.metadata &&
                    Object.keys(task.metadata).includes(constraint.name)
                        ? task.metadata[constraint.name]
                        : undefined;
                break;
            default:
                sourceValue = undefined;
                break;
        }

        switch (constraint.operator) {
            case "Equal":
                result = sourceValue == constraint.value;
                break;
            case "NotEqual":
                result = sourceValue != constraint.value;
                break;
            case "IsNull":
                result = sourceValue === undefined || sourceValue === null;
                break;
            case "NotNull":
                result = sourceValue !== undefined && sourceValue !== null;
                break;
            case "AnyOf":
                result = sourceValue == constraint.value;
                break;
            case "NoneOf":
                result = sourceValue == constraint.value;
                break;
            case "GreaterThan":
                result =
                    constraint.value !== undefined &&
                    sourceValue > constraint.value;
                break;
            case "LessThan":
                result =
                    constraint.value !== undefined &&
                    sourceValue < constraint.value;
                break;
            default:
                result = true;
                break;
        }

        // console.log('Constraint check [', constraint.fieldType, ', ', constraint.name, ', ',
        //     constraint.operator, ', ', constraint.value, '] => [', sourceValue, ', ', result, ']');

        return constraint.kind === "Requirement" ? result : !result;
    }
}

import { Route } from "react-router";
import * as React from "react";
import * as taskStatusesContext from "../stores/context";
import * as taskTypesContext from "../../task_types/stores/context";
import TaskTemplatesPage from "../screens/TaskTemplatesPage";

const taskTemplatesRoute = () => {
    return (
        <>
            <Route
                path={"/task/tasktemplates"}
                element={
                    <taskTypesContext.StoreProvider>
                        <taskStatusesContext.StoreProvider>
                            <TaskTemplatesPage />
                        </taskStatusesContext.StoreProvider>
                    </taskTypesContext.StoreProvider>
                }
            />
        </>
    );
};

export default taskTemplatesRoute;

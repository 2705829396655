import React from "react";
import TaskStatusesVisualStore from "../stores/TaskStatusesVisualStore";
import { observer } from "mobx-react-lite";
import {
    Flowchart,
    IFlowchartGraph,
    XFlowGraphCommands,
} from "@ant-design/flowchart/lib";

import {
    FlowEditorSelection,
    TaskStatus,
    TaskStatusTransition,
} from "../types";
import { Button, Form, FormInstance, Radio, Tooltip } from "antd";
import { Edge, Node } from "@antv/x6";
import FlowNodeEditor from "./FlowNodeEditor";
import FlowEdgeEditor from "./FlowEdgeEditor";
import FlowCanvasEditor from "./FlowCanvasEditor";

import {
    CompressOutlined,
    OneToOneOutlined,
    ZoomInOutlined,
    ZoomOutOutlined,
} from "@ant-design/icons";
type Props = {
    store: TaskStatusesVisualStore;
    form: FormInstance;
};

export type FlowMode = "parent" | "child";

const TaskStatusFlowEditor: React.FC<Props> = ({ store, form }) => {
    const [graph, setGraph] = React.useState<IFlowchartGraph>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [flowchartApp, setFlowchartApp] = React.useState<any>();
    const [selectedItem, setSelectedItem] = React.useState<FlowEditorSelection>(
        { type: "none" },
    );
    const [flowMode, setFlowMode] = React.useState<FlowMode>("parent");

    React.useEffect(() => {
        reloadGraph();
    }, [graph, store.taskStatuses, flowMode]);

    React.useEffect(() => {
        if (graph && flowchartApp) {
            graph.toggleMultipleSelection(false);
            graph.on("selection:changed", (args) =>
                handleSelectionChanged(args.selected),
            );
            graph.on("edge:added", (args) => {
                args.edge.setAttrs({ line: { strokeDasharray: "" } });
            });
            graph.on("edge:connected", (args) => {
                const sourceStatus = store.taskStatuses.find(
                    (s) => s.id === args.edge.getSourceNode()!.id,
                );
                if (sourceStatus) {
                    if (!sourceStatus.transitions) {
                        sourceStatus.transitions = [];
                    }

                    sourceStatus.transitions.push({
                        sourcePort: args.edge.getSourcePortId()!,
                        targetPort: args.currentPort!,
                        targetStatusId: args.edge.getTargetNode()!.id,
                    });
                }
            });
            graph.on("node:change:position", (args) => {
                const statusDesign = store.taskStatuses.find(
                    (s) => s.id === args.node.id,
                )?.design;
                if (statusDesign) {
                    Object.assign(statusDesign, args.current);
                }
            });
            graph.on("node:change:size", (args) => {
                const statusDesign = store.taskStatuses.find(
                    (s) => s.id === args.node.id,
                )?.design;
                if (statusDesign) {
                    Object.assign(statusDesign, args.current);
                }
            });
        }
    }, [flowchartApp, graph]);

    React.useEffect(() => {
        resetForm();
    }, [form, selectedItem, flowMode]);

    React.useEffect(() => {
        setFlowMode("parent");
    }, [store.selectedTaskType]);

    const reloadGraph = () => {
        if (graph?.loadData) {
            graph.removeCells(graph.getCells());
            resetForm();

            const data = {
                nodes:
                    store.taskStatuses
                        .filter(
                            (x) => x.isSubtaskStatus === (flowMode === "child"),
                        )
                        .map((s, i) => convertToNode(s, i)) || [],
                edges:
                    store.taskStatuses
                        .filter(
                            (s) =>
                                !!s.transitions &&
                                s.isSubtaskStatus === (flowMode === "child"),
                        )
                        .reduce(
                            (trs, tr) => [
                                ...trs,
                                ...tr.transitions!.map((t) => ({
                                    sourceStatusId: tr.id,
                                    ...t,
                                })),
                            ],
                            [],
                        )
                        .map((e) => convertToEdge(e)) || [],
            };
            graph.loadData(data);
        }
    };

    const resetForm = () => {
        form.resetFields();

        if (selectedItem.item) {
            if (selectedItem.item.isEdge()) {
                form.setFieldsValue({
                    source: store.taskStatuses.find(
                        (x) => x.id === selectedItem.item.source.cell,
                    )?.name,
                    target: store.taskStatuses.find(
                        (x) => x.id === selectedItem.item.target.cell,
                    )?.name,
                });
            } else if (selectedItem.item.isNode()) {
                form.setFieldsValue(
                    store.taskStatuses.find(
                        (x) => x.id === selectedItem.item!.id,
                    ),
                );
            }
        } else if (selectedItem) {
            form.setFieldsValue({
                initialStatus:
                    store.taskStatuses.find(
                        (x) =>
                            x.isDefault === true &&
                            x.isSubtaskStatus === (flowMode === "child"),
                    )?.id ?? undefined,
                finalStatus:
                    store.taskStatuses.find(
                        (x) =>
                            x.isFinal === true &&
                            x.isSubtaskStatus === (flowMode === "child"),
                    )?.id ?? undefined,
            });
        }
    };

    const getPortGroup = (position: string) => {
        return {
            attrs: {
                circle: {
                    fill: "#fff",
                    magnet: true,
                    r: 5,
                    stroke: "#31d0c6",
                    strokeWidth: 2,
                    style: {
                        visibility: "hidden",
                    },
                },
            },
            position: {
                name: position,
            },
            zIndex: 10,
        };
    };

    const convertToNode = (status: TaskStatus, i: number) => {
        if (!status.design) {
            status.design = {
                width: 160,
                height: 50,
                x: 100,
                y: 100 + 90 * i,
            };
        }

        return {
            id: status.id,
            name: status.name,
            renderKey: status.isDefault
                ? "Terminal"
                : status.isFinal
                  ? "Predefined Process"
                  : "Process",
            label: status.name,
            fontSize: 14,
            ports: {
                groups: {
                    top: getPortGroup("top"),
                    right: getPortGroup("right"),
                    bottom: getPortGroup("bottom"),
                    left: getPortGroup("left"),
                },
                items: [
                    { group: "top", id: "Top" },
                    { group: "right", id: "Right" },
                    { group: "bottom", id: "Bottom" },
                    { group: "left", id: "Left" },
                ],
            },
            ...status.design,
            ...(status.isEnabled
                ? {}
                : {
                      fill: "#ececec",
                      fontFill: "#9b9b9b",
                      stroke: "#9b9b9b",
                  }),
        };
    };

    const convertToEdge = (
        transition: TaskStatusTransition & { sourceStatusId: string },
    ) => {
        return {
            id: `${transition.sourceStatusId}:${transition.sourcePort}-${transition.targetStatusId}:${transition.targetPort}`,
            attrs: {
                line: {
                    stroke: "#A2B1C3",
                    strokeWidth: 1,
                },
            },
            source: {
                cell: transition.sourceStatusId,
                port: transition.sourcePort,
            },
            target: {
                cell: transition.targetStatusId,
                port: transition.targetPort,
            },
        };
    };

    const saveGraph = (data: unknown) => {
        console.log("Graph:", data);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleSelectionChanged = (selection: any[]) => {
        if (selection?.length) {
            if (selection[0] instanceof Edge) {
                flowchartApp
                    ?.getEdgeById(selection[0].id)
                    .then((e: unknown) =>
                        setSelectedItem({ type: "edge", item: e }),
                    );
            } else if (selection[0] instanceof Node) {
                flowchartApp
                    ?.getNodeById(selection[0].id)
                    .then((n: unknown) =>
                        setSelectedItem({ type: "node", item: n }),
                    );
            }
        } else {
            setSelectedItem({ type: "none" });
        }
    };

    const handleZoom = (factor: number | string) => {
        flowchartApp?.executeCommand(XFlowGraphCommands.GRAPH_ZOOM.id, {
            factor: factor,
            zoomOptions:
                typeof factor === "string"
                    ? undefined
                    : {
                          absolute: false,
                          minScale: 0.1,
                          maxScale: 3.0,
                      },
        });
    };

    const propertiesPanel = (
        <div className="flow-properties-panel" style={{}}>
            <p
                style={{
                    borderBottom: "1px solid #d9d9d9",
                    textAlign: "center",
                    padding: "8px 0px",
                    flex: "none",
                    margin: 0,
                }}
            >
                Properties
            </p>
            <div style={{ flex: "auto", overflowX: "hidden", padding: "16px" }}>
                <Form form={form} layout="vertical" className="alpha-form">
                    {selectedItem?.type === "none" && (
                        <FlowCanvasEditor
                            store={store}
                            flowMode={flowMode}
                            onChangeCallback={reloadGraph}
                        />
                    )}
                    {selectedItem?.type === "edge" && (
                        <FlowEdgeEditor
                            store={store}
                            graph={graph!}
                            selection={selectedItem}
                        />
                    )}
                    {selectedItem?.type === "node" && (
                        <FlowNodeEditor
                            store={store}
                            selection={selectedItem}
                        />
                    )}
                </Form>
            </div>
            <div
                style={{
                    flex: "none",
                    borderTop: "1px solid #d9d9d9",
                    padding: "8px",
                }}
            >
                <Button
                    onClick={() =>
                        flowchartApp
                            ?.getGraphData()
                            .then((v: unknown) => console.log("Graph data:", v))
                    }
                >
                    Print graph
                </Button>
                <Button onClick={reloadGraph} style={{ marginLeft: "8px" }}>
                    Reload graph
                </Button>
            </div>
        </div>
    );

    const scalingToolbar = (
        <div className="flow-scale-toolbar">
            <Tooltip title="Zoom in" placement="left">
                <Button
                    className="x6-toolbar-item xflow-toolbar-item"
                    onClick={() => handleZoom(0.1)}
                >
                    <ZoomInOutlined />
                </Button>
            </Tooltip>
            <Tooltip title="Zoom out" placement="left">
                <Button
                    className="x6-toolbar-item xflow-toolbar-item"
                    onClick={() => handleZoom(-0.1)}
                >
                    <ZoomOutOutlined />
                </Button>
            </Tooltip>
            <Tooltip title="Zoom to 1:1" placement="left">
                <Button
                    className="x6-toolbar-item xflow-toolbar-item"
                    onClick={() => handleZoom("real")}
                >
                    <OneToOneOutlined />
                </Button>
            </Tooltip>
            <Tooltip title="Fit screen" placement="left">
                <Button
                    className="x6-toolbar-item xflow-toolbar-item"
                    onClick={() => handleZoom("fit")}
                >
                    <CompressOutlined />
                </Button>
            </Tooltip>
        </div>
    );

    return (
        <div className="flow-tab-content">
            {store.taskType?.distinctSubtaskStatuses && (
                <Radio.Group
                    className="flow-type-toggle"
                    buttonStyle="solid"
                    defaultValue={flowMode}
                    onChange={(v) => setFlowMode(v.target.value)}
                >
                    <Radio.Button value="parent">Main tasks</Radio.Button>
                    <Radio.Button value="child">Subtasks</Radio.Button>
                </Radio.Group>
            )}

            <div className="flow-editor-container">
                <Flowchart
                    isAutoCenter
                    toolbarPanelProps={{
                        position: { top: 0, left: 0, right: 0 },
                        show: false,
                    }}
                    scaleToolbarPanelProps={{
                        position: { bottom: 8, right: 8 },
                        show: false,
                    }}
                    nodePanelProps={{
                        position: { width: 250, top: 40, bottom: 0, left: 0 },
                        show: false,
                    }}
                    canvasProps={{
                        position: { top: 0, left: 0, right: 300, bottom: 0 },
                        config: {
                            connecting: {
                                allowMulti: false,
                            },
                        },
                    }}
                    detailPanelProps={{
                        position: { width: 250, top: 40, bottom: 0, right: 0 },
                        show: false,
                    }}
                    keyBindingProps={false}
                    contextMenuPanelProps={{ show: false }}
                    onSave={saveGraph}
                    onReady={(g, app) => {
                        setGraph(g);
                        setFlowchartApp(app);
                    }}
                />
                {propertiesPanel}
                {scalingToolbar}
            </div>
        </div>
    );
};

export default observer(TaskStatusFlowEditor);

import * as React from "react";
import { observer } from "mobx-react-lite";
import { Button, Form, Input, Modal, Select } from "antd";
import { useStore } from "src/modules/common/stores";
import { useForm } from "antd/lib/form/Form";
import { DashboardWidgetType } from "../types/DashboardWidget";
import { useAdminStore } from "../stores/context";
import { ALL_PROJECTS } from "../../tasks/screens/TasksPage";
import { Utils } from "src/modules/common/misc/Utils";

type Props = {
    isVisible: boolean;
    closeDialog: () => void;
    updateCallBack?: () => void;
};
const FormItem = Form.Item;

export const CreateGroupedWidgetDialog: React.FC<Props> = observer(
    ({ isVisible, closeDialog, updateCallBack }) => {
        const rootConext = useStore();
        const { projectsStore } = rootConext;

        const adminContext = useAdminStore();
        const store = adminContext.widgetConstructorStore;

        const { editableWidget } = store;
        const [form] = useForm();

        React.useEffect(() => {
            if (editableWidget && isVisible) {
                form.setFieldsValue(editableWidget);
            }
        }, [editableWidget?.id, isVisible]);

        const handleCloseDialog = () => {
            form.resetFields();
            store.unselectProject();
            store.setEditableWidget(null);
            closeDialog();
        };

        const handleSubmit = () => {
            form.validateFields().then(async (values) => {
                values.widgetType = DashboardWidgetType.Grouped;
                if (values.projectId === ALL_PROJECTS) {
                    values.projectId = null;
                }
                await store.createUpdateGroupedWidget(values);
                if (updateCallBack) {
                    updateCallBack();
                }
                handleCloseDialog();
            });
        };

        return (
            <Modal
                open={isVisible}
                onCancel={closeDialog}
                maskClosable={false}
                closable={false}
                title={editableWidget ? "Update Widget" : "Create Widget"}
                destroyOnClose
                width={678}
                centered
                className="alpha-modal widgets-constructor"
                footer={[
                    <Button
                        className="light"
                        data-id="add-user-dialog-cancel"
                        size="large"
                        key="back"
                        onClick={handleCloseDialog}
                    >
                        Cancel
                    </Button>,
                    <Button
                        data-id="add-user-dialog-submit"
                        size="large"
                        key="submit"
                        type="primary"
                        onClick={handleSubmit}
                    >
                        Save
                    </Button>,
                ]}
            >
                <Form layout="vertical" className="alpha-form" form={form}>
                    <div style={{ marginBottom: 20 }}>
                        <Form.Item
                            name="name"
                            label={
                                <span className="dialog-field-label">Name</span>
                            }
                            rules={[
                                { required: true, message: "Missing name" },
                            ]}
                            style={{ display: "inline-block" }}
                        >
                            <Input
                                style={{ width: 200 }}
                                placeholder="Enter widget name"
                            />
                        </Form.Item>
                        <FormItem
                            label={
                                <span className="dialog-field-label">
                                    Project
                                </span>
                            }
                            name="projectId"
                            style={{ display: "inline-block", marginLeft: 15 }}
                            rules={[
                                { required: true, message: "Missing project" },
                            ]}
                            initialValue={ALL_PROJECTS}
                        >
                            <Select
                                style={{ width: 200 }}
                                options={[
                                    {
                                        label: "All projects",
                                        value: ALL_PROJECTS,
                                    },
                                    ...(projectsStore.projects?.map((p) => ({
                                        label: p.name,
                                        value: p.id,
                                    })) || []),
                                ]}
                                placeholder="Select project"
                                onChange={store.setSelectedProjectId}
                                showSearch
                                filterOption={Utils.filterOption}
                            />
                        </FormItem>
                        <Form.Item
                            name="widgets"
                            label={
                                <span className="dialog-field-label">
                                    Widgets
                                </span>
                            }
                            style={{ display: "inline-block", marginLeft: 15 }}
                            rules={[
                                {
                                    required: true,
                                    message: "Missing output field",
                                },
                            ]}
                        >
                            <Select
                                mode={"multiple"}
                                style={{ width: 200 }}
                                options={store.widgets
                                    .filter(
                                        (w) =>
                                            w.projectId ===
                                            store.selectedProjectId,
                                    )
                                    .map((w) => ({
                                        label: w.name,
                                        value: w.id,
                                    }))}
                                placeholder="Select Output Field"
                            />
                        </Form.Item>
                    </div>
                </Form>
            </Modal>
        );
    },
);

export default CreateGroupedWidgetDialog;

import * as React from "react";
import { Form, Input, Button } from "antd";
import { observer } from "mobx-react-lite";
import UserProfileStore from "../../common/stores/UserProfileStore";
import UsersManagerStore from "../../administration/stores/UsersManagerStore";

type Props = {
    store: UserProfileStore | UsersManagerStore;
    handleCancel: () => void;
};

const formItemLayout = {
    labelCol: {
        xs: { span: 7 },
        sm: { span: 7 },
    },
    wrapperCol: {
        xs: { span: 12 },
        sm: { span: 12 },
    },
};

const ResetPasswordForm: React.FC<Props> = ({ store, handleCancel }) => {
    const [form] = Form.useForm();

    return (
        <Form
            form={form}
            onFinish={() => {
                const user = (store as UsersManagerStore).selectedUser;
                const userId = user ? user.id : null;
                store.changePassword(
                    form.getFieldValue("newPassword"),
                    form.getFieldValue("oldPassword"),
                    userId,
                );
            }}
        >
            <Form.Item
                {...formItemLayout}
                label="Old password"
                name="oldPassword"
                rules={[
                    {
                        required: true,
                        message: "Old password is required",
                    },
                ]}
            >
                <Input.Password />
            </Form.Item>
            <Form.Item
                {...formItemLayout}
                label="New password"
                name="newPassword"
                rules={[
                    {
                        required: true,
                        message: "New password is required",
                    },
                    {
                        pattern:
                            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,}$/gm,
                        message:
                            "Password should beat least 6 characters long and contain at least one number, upper case and lower case character.",
                    },
                    ({ getFieldValue }) => ({
                        validator(rule: unknown, value: string) {
                            if (
                                !value ||
                                getFieldValue("oldPassword") !== value
                            ) {
                                return Promise.resolve();
                            }
                            return Promise.reject(
                                "New password cannot be the same as old password",
                            );
                        },
                    }),
                ]}
            >
                <Input.Password />
            </Form.Item>
            <Form.Item
                {...formItemLayout}
                label="Confirm password"
                name="confirmPassword"
                rules={[
                    {
                        required: true,
                        message: "Please confirm new password",
                    },
                    ({ getFieldValue }) => ({
                        validator(rule: unknown, value: string) {
                            if (
                                !value ||
                                getFieldValue("newPassword") === value
                            ) {
                                return Promise.resolve();
                            }
                            return Promise.reject(
                                "The two passwords that you entered do not match",
                            );
                        },
                    }),
                ]}
            >
                <Input.Password />
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }}>
                <div
                    style={{
                        textAlign: "right",
                        borderTop: "1px solid #f0f0f0",
                        padding: "24px 16px 0 0",
                        margin: "0 -24px",
                    }}
                >
                    <Button
                        data-id="reset-password-submit"
                        key="submit"
                        type="primary"
                        htmlType="submit"
                        style={{ marginRight: 12 }}
                        disabled={
                            !!form.getFieldsError() &&
                            form.getFieldsError().length > 0
                        }
                        size="large"
                    >
                        Reset
                    </Button>
                    <Button
                        data-id="reset-password-cancel"
                        key="back"
                        htmlType="button"
                        onClick={handleCancel}
                        size="large"
                    >
                        Cancel
                    </Button>
                </div>
            </Form.Item>
        </Form>
    );
};
export default observer(ResetPasswordForm);

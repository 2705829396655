import * as React from "react";
import { observer } from "mobx-react-lite";
import FileViewer from "react-file-viewer";
import DocumentViewVisualStore from "../stores/DocumentViewVisualStore";

const DocxViewer: React.FC<{ store: DocumentViewVisualStore }> = ({
    store,
}) => {
    return <FileViewer fileType={"docx"} filePath={store.wordDocUrl} />;
};

export default observer(DocxViewer);

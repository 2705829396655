import { Spin } from "antd";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { DocumentService } from "../../common/services";
import XMLViewer from "react-xml-viewer";
import DocumentVisualStore from "../stores/DocumentsVisualStore";

type Props = {
    documentId: string;
    store?: DocumentVisualStore;
};

const XmlDocumentPreview: React.FC<Props> = ({ documentId, store }) => {
    const [xmlContent, setXmlContent] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(true);
    const DocumentLoadingSpinner = (
        <div className="create-contract-document-loading">
            <Spin size="large" />
        </div>
    );

    const calculateHighlightedNode = () => {
        if (!store) {
            return;
        }
        const { highlightedBlockProps } = store;

        if (!highlightedBlockProps || !highlightedBlockProps.nodeHierarchy) {
            return;
        }

        const rootXmlDomNode = document.getElementById(
            `${documentId}-xml-preview`,
        );
        if (!rootXmlDomNode) {
            return;
        }

        const highlightedElements = rootXmlDomNode.getElementsByClassName(
            "highlighted-xml-node",
        );

        if (highlightedElements) {
            for (let i = 0; i < highlightedElements.length; i++) {
                highlightedElements[i].className = "";
            }
        }

        const hierarchy = highlightedBlockProps.nodeHierarchy.split(".");
        const xpathRootString = `//div[@id='${documentId}-xml-preview']`;
        let xpathString = xpathRootString;
        for (const node of hierarchy) {
            xpathString += `//span[text()='${node}']/parent::div`;
        }

        const element = document.evaluate(
            xpathString,
            document,
            null,
            XPathResult.FIRST_ORDERED_NODE_TYPE,
            null,
        ).singleNodeValue;

        if (element) {
            const div = element as HTMLDivElement;
            div.className = "highlighted-xml-node";
            div.scrollIntoView({ block: "center", behavior: "smooth" });
        }
    };

    const service = new DocumentService();
    React.useEffect(() => {
        if (documentId) {
            store?.setPageWidth(100);
            service
                .getXmlDocumentContent(documentId)
                .then((xml) => {
                    setXmlContent(xml.unwrapOr(""));
                    setIsLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, []);

    React.useEffect(calculateHighlightedNode, [store?.highlightedBlockProps]);

    return (
        <>
            {isLoading ? (
                DocumentLoadingSpinner
            ) : (
                <div
                    id={`${documentId}-xml-preview`}
                    style={{ maxHeight: "100%", overflow: "auto" }}
                >
                    <XMLViewer xml={xmlContent} />
                </div>
            )}
        </>
    );
};

export default observer(XmlDocumentPreview);

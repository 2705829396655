import { Button, Input, Popconfirm, Tag, Tooltip } from "antd";
import Table from "antd/es/table";
import type { ColumnsType } from "antd/es/table";
import { Observer, observer } from "mobx-react-lite";
import React from "react";
import { ProjectsStore } from "../../common/stores";
import { ALL_PROJECTS } from "../../tasks/screens/TasksPage";
import WidgetConstructorStore from "../stores/WidgetConstructorStore";
import {
    DashboardWidgetMeta,
    WidgetFilter,
    WidgetOutputField,
} from "../types/DashboardWidgetMeta";
import CreateWidgetDialog from "./CreateWidgetDialog";
import { safeStringLocaleCompare } from "../../common/misc/Utils";

type Props = {
    store: WidgetConstructorStore;
    projectsStore: ProjectsStore;
    isCreateModalVisible: boolean;
    setIsCreateModalVisible: (val: boolean) => void;
};
export const WidgetsTable: React.FC<Props> = ({
    store,
    projectsStore,
    isCreateModalVisible,
    setIsCreateModalVisible,
}) => {
    React.useEffect(() => {
        return () => {
            store.setSearchString("");
        };
    }, []);
    const editWidget = (widget: DashboardWidgetMeta) => {
        store.setEditableWidget(widget.id);
        setIsCreateModalVisible(true);
    };

    const columns: ColumnsType<DashboardWidgetMeta> = [
        {
            key: "name",
            dataIndex: "name",
            title: "Name",
            sorter: (a: DashboardWidgetMeta, b: DashboardWidgetMeta) =>
                safeStringLocaleCompare(b.name, a.name),
        },
        {
            key: "widgetType",
            dataIndex: "widgetType",
            title: "Widget Type",
            sorter: (a: DashboardWidgetMeta, b: DashboardWidgetMeta) =>
                safeStringLocaleCompare(b.widgetType, a.widgetType),
        },
        {
            key: "projectId",
            dataIndex: "projectId",
            title: "Project",
            render: (projectId: string) => (
                <span>
                    {projectId === ALL_PROJECTS
                        ? "All projects"
                        : projectsStore.projects?.find(
                              (p) => p.id === projectId,
                          )?.name}
                </span>
            ),
            sorter: (a: DashboardWidgetMeta, b: DashboardWidgetMeta) =>
                safeStringLocaleCompare(
                    store.getProjectIdLabel(b.projectId),
                    store.getProjectIdLabel(a.projectId),
                ),
        },
        {
            key: "filters",
            dataIndex: "filters",
            title: "Filters",
            render: (filters: WidgetFilter[]) => filters.length,
        },
        {
            key: "outputField",
            dataIndex: "outputField",
            title: "Output FieldName",
            render: (fieldNames: WidgetOutputField[]) => {
                return (
                    <Observer>
                        {() => (
                            <>
                                {fieldNames.map((f) => {
                                    const name =
                                        f.type === "General"
                                            ? f.entityReference
                                            : store.metadata.find(
                                                  (m) =>
                                                      m.id ===
                                                      f.entityReference,
                                              )?.name;
                                    return (
                                        <Tag key={f.entityReference}>
                                            {name}
                                        </Tag>
                                    );
                                })}
                            </>
                        )}
                    </Observer>
                );
            },
        },
        {
            key: "actions",
            title: null,
            render: (_: unknown, record: DashboardWidgetMeta) => (
                <div
                    className="row-actions-wrapper"
                    style={{ textAlign: "right" }}
                >
                    <Tooltip title="Edit widget">
                        <Button
                            type="link"
                            onClick={() => editWidget(record)}
                            size="small"
                            data-id-cells="Edit widget"
                            data-id-name={record.name}
                        >
                            <i className="alpha-icon md table-action-edit" />
                        </Button>
                    </Tooltip>
                    <Popconfirm
                        id="data-id-popconfirm-box"
                        title="Are you sure you want to delete this widget?"
                        onConfirm={() => {
                            store.deleteWidget(record.id);
                        }}
                    >
                        <Button
                            data-id-cells="Delete widget"
                            title="Delete"
                            size="small"
                            type="link"
                        >
                            <Tooltip title="Delete widget" placement="bottom">
                                <i className="alpha-icon md table-action-delete" />
                            </Tooltip>
                        </Button>
                    </Popconfirm>
                </div>
            ),
        },
    ];

    const handleEnterPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        store.setSearchString(event.currentTarget.value);
    };

    return (
        <>
            <CreateWidgetDialog
                isVisible={isCreateModalVisible}
                closeDialog={() => setIsCreateModalVisible(false)}
            />
            <Input
                data-id="input-search"
                style={{ width: 200, marginBottom: 20 }}
                allowClear
                placeholder="Search..."
                onPressEnter={handleEnterPress}
                prefix={
                    <i
                        className="alpha-icon xs table-search"
                        style={{ marginRight: 10 }}
                    />
                }
                onClear={() => store.setSearchString("")}
            />
            <Table
                loading={store.isLoading}
                rowKey={(r) => r.id}
                className="alpha-table with-scroll"
                columns={columns}
                dataSource={store.filteredWidgets}
                pagination={{
                    showSizeChanger: false,
                    pageSize: 20,
                    hideOnSinglePage: true,
                }}
                scroll={{ y: "calc(100vh - 300px)" }}
            />
        </>
    );
};

export default observer(WidgetsTable);

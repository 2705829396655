import { FilterFilled } from "@ant-design/icons";
import { Button, DatePicker, InputNumber, Select, Tooltip } from "antd";
import * as React from "react";
import { Utils } from "src/modules/common/misc/Utils";
import { FilterOperator } from "../../administration/types/DashboardWidgetMeta";
import {
    MetadataDefinition,
    MetadataFieldType,
} from "../../administration/types/Metadata";
import { TasksFiltersVisualStore } from "../stores";
import { Observer } from "mobx-react-lite";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

const WITH_ATTACHMENTS = "Only with attachments";
const WIHOUT_ATTACHMENTS = "Without attachments";

const description = {
    Equal: "Is equal to",
    NotEqual: "Is not equal to",
    IsGreaterThen: "Is greater than",
    IsLessThen: "Is less than",
};

const getFilterOperators = () => {
    return Object.keys(FilterOperator).map((k) => ({
        label: (
            <>
                <span className="label">{FilterOperator[k]}</span>
                <span className="description">{description[k]}</span>
            </>
        ),
        value: k,
    }));
};

export const TaskColumnsFilters = (
    columnName: string,
    store: TasksFiltersVisualStore,
    applyButtonRef: React.MutableRefObject<Record<string, HTMLElement>>,
    fieldType?: MetadataFieldType,
    metadata?: MetadataDefinition,
) => {
    const handleFilterResetClick = (event: React.MouseEvent) => {
        event.stopPropagation();
        store.resetColumnFilter(columnName);
    };

    const handleAttachmentsChange = (val: string) => {
        if (val === WITH_ATTACHMENTS) {
            store.setCurrentFilterOperator("IsGreaterThen");
        } else {
            store.setCurrentFilterOperator("Equal");
        }
        store.setCurrentFilterValue(columnName, [0], false);
    };

    const getAttachmentsFilterValue = () => {
        const val = store.columnsFilterDict[columnName]?.value[0];
        if (val === undefined) {
            return null;
        }
        return store.currentFilterOperator === "IsGreaterThen"
            ? WITH_ATTACHMENTS
            : WIHOUT_ATTACHMENTS;
    };

    const isFilterApplied = store.columnsFilterDict[columnName]?.saved;

    const getFilterIconStyle = () => {
        return { color: isFilterApplied ? "#1677ff" : undefined };
    };

    const handleDropdownVisibChange = (open: boolean) => {
        if (!open) {
            setTimeout(() => applyButtonRef.current[columnName]?.focus(), 100);
        }
    };

    return {
        filterDropdown: ({
            confirm,
        }: {
            confirm: (params: { closeDropdown: boolean }) => void;
        }) => {
            let widget: React.ReactElement;
            if (!metadata) {
                switch (columnName) {
                    case "taskType":
                        widget = (
                            <Select
                                placeholder="Select type"
                                filterOption={Utils.filterOption}
                                mode="multiple"
                                onChange={(val) =>
                                    store.setCurrentFilterValue(
                                        columnName,
                                        val,
                                        false,
                                    )
                                }
                                value={
                                    store.columnsFilterDict[columnName]
                                        ?.value || []
                                }
                                options={store.taskTypesForCurrentProject.map(
                                    (t) => ({
                                        value: t.id,
                                        label: `${t.name}`,
                                    }),
                                )}
                                onDropdownVisibleChange={
                                    handleDropdownVisibChange
                                }
                            />
                        );
                        break;
                    case "status":
                        widget = (
                            <Select
                                placeholder="Select status"
                                mode="multiple"
                                filterOption={Utils.filterOption}
                                value={
                                    store.columnsFilterDict[columnName]
                                        ?.value || []
                                }
                                onChange={(val) =>
                                    store.setCurrentFilterValue(
                                        columnName,
                                        val,
                                        false,
                                    )
                                }
                                options={store.taskStatusesForCurrentProject.map(
                                    (t) => ({
                                        value: t.id,
                                        label: `${t.name} (${store.taskTypesForCurrentProject.find((type) => type.id === t.typeId)!.name})`,
                                    }),
                                )}
                                onDropdownVisibleChange={
                                    handleDropdownVisibChange
                                }
                            />
                        );
                        break;
                    case "assignedTo":
                    case "createdBy":
                        widget = (
                            <Select
                                placeholder={`Select ${columnName === "createdBy" ? "owner" : "assignee"}`}
                                mode="multiple"
                                filterOption={Utils.filterOption}
                                value={
                                    store.columnsFilterDict[columnName]
                                        ?.value || []
                                }
                                onChange={(val) =>
                                    store.setCurrentFilterValue(
                                        columnName,
                                        val,
                                        false,
                                    )
                                }
                                options={Object.entries(
                                    store.allUsersFullNameResolver,
                                )
                                    .map((u) => ({
                                        label: u[1],
                                        value: u[0],
                                    }))
                                    .sort((a, b) =>
                                        a.label.toUpperCase() <
                                        b.label.toUpperCase()
                                            ? -1
                                            : 1,
                                    )}
                                onDropdownVisibleChange={
                                    handleDropdownVisibChange
                                }
                            />
                        );
                        break;
                    case "dueDate":
                    case "createDate":
                        widget = (
                            <RangePicker
                                onChange={(val) =>
                                    store.setCurrentFilterValue(
                                        columnName,
                                        val?.map((v) =>
                                            v?.hour(0).minute(0).second(0),
                                        ) || [],
                                        false,
                                    )
                                }
                                value={
                                    store.columnsFilterDict[
                                        columnName
                                    ]?.value.map((m) =>
                                        Utils.formatDatePickerValue(m),
                                    ) as never
                                }
                                format={Utils.getDateFormat()}
                                onOpenChange={handleDropdownVisibChange}
                            />
                        );
                        break;
                    case "priority":
                        widget = (
                            <Select
                                mode="multiple"
                                placeholder="Select priority"
                                value={
                                    store.columnsFilterDict[columnName]
                                        ?.value || []
                                }
                                onChange={(val) =>
                                    store.setCurrentFilterValue(
                                        columnName,
                                        val,
                                        false,
                                    )
                                }
                                filterOption={Utils.filterOption}
                                options={[
                                    { value: "LOW", label: "Low" },
                                    { value: "MEDIUM", label: "Medium" },
                                    { value: "HIGH", label: "High" },
                                ]}
                                onDropdownVisibleChange={
                                    handleDropdownVisibChange
                                }
                            />
                        );
                        break;
                    case "attachmentsCount":
                        widget = (
                            <Select
                                options={[
                                    {
                                        label: WITH_ATTACHMENTS,
                                        value: WITH_ATTACHMENTS,
                                    },
                                    {
                                        label: WIHOUT_ATTACHMENTS,
                                        value: WIHOUT_ATTACHMENTS,
                                    },
                                ]}
                                onChange={handleAttachmentsChange}
                                placeholder="Select attachments"
                                value={getAttachmentsFilterValue()}
                                onDropdownVisibleChange={
                                    handleDropdownVisibChange
                                }
                            />
                        );
                        break;
                    default:
                        widget = <></>;
                }
            } else {
                switch (fieldType) {
                    case "Text":
                        widget = (
                            <Select
                                mode="tags"
                                value={
                                    store.columnsFilterDict[columnName]
                                        ?.value || []
                                }
                                onChange={(val) =>
                                    store.setCurrentFilterValue(
                                        columnName,
                                        val,
                                        true,
                                    )
                                }
                                placeholder="Enter value"
                                onDropdownVisibleChange={
                                    handleDropdownVisibChange
                                }
                            />
                        );
                        break;
                    case "Number":
                        widget = (
                            <div className="numeric-widget">
                                <Select
                                    className="filter-operator"
                                    options={getFilterOperators()}
                                    defaultValue={"Equal"}
                                    onChange={store.setCurrentFilterOperator.bind(
                                        store,
                                    )}
                                    popupClassName="filter-opartor-container"
                                />
                                <InputNumber
                                    onChange={(val) =>
                                        store.setCurrentFilterValue(
                                            columnName,
                                            [val],
                                            true,
                                        )
                                    }
                                    value={
                                        store.columnsFilterDict[columnName]
                                            ?.value[0]
                                    }
                                    placeholder="Enter value"
                                    onPressEnter={() => {
                                        confirm({ closeDropdown: true });
                                        store.applyColumnFilter(columnName);
                                    }}
                                />
                            </div>
                        );
                        break;
                    case "Boolean": {
                        const { trueVal, falseVal } = metadata.format
                            ? JSON.parse(metadata.format)
                            : { trueVal: "true", falseVal: "false" };
                        widget = (
                            <Select
                                options={[
                                    {
                                        label: trueVal || "true",
                                        value: true,
                                    },
                                    {
                                        label: falseVal || "false",
                                        value: false,
                                    },
                                ]}
                                onChange={(val) =>
                                    store.setCurrentFilterValue(
                                        columnName,
                                        [val],
                                        true,
                                    )
                                }
                                value={
                                    store.columnsFilterDict[columnName]
                                        ?.value || null
                                }
                                placeholder="Select value"
                                onDropdownVisibleChange={
                                    handleDropdownVisibChange
                                }
                            />
                        );
                        break;
                    }
                    case "DateTime":
                        widget = (
                            <RangePicker
                                onChange={(val) =>
                                    store.setCurrentFilterValue(
                                        columnName,
                                        val?.map((v) =>
                                            v?.hour(0).minute(0).second(0),
                                        ) || [],
                                        true,
                                    )
                                }
                                value={
                                    store.columnsFilterDict[
                                        columnName
                                    ]?.value.map((m) =>
                                        dayjs(new Date(m)),
                                    ) as never
                                }
                                format={Utils.getDateFormat()}
                                onOpenChange={handleDropdownVisibChange}
                            />
                        );
                        break;
                    default:
                        widget = <></>;
                }
            }
            return (
                <div className="table-filter-popup">
                    {widget}
                    <div className="controls-container">
                        <Button
                            disabled={store.isGridView}
                            onClick={() => {
                                confirm({ closeDropdown: true });
                                if (
                                    !store.columnsFilterDict[columnName]?.saved
                                ) {
                                    store.setCurrentFilterValue(
                                        columnName,
                                        [],
                                        !!metadata,
                                    );
                                }
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            ref={(element) =>
                                (applyButtonRef.current[columnName] = element!)
                            }
                            type="primary"
                            disabled={
                                !store.columnsFilterDict[columnName]?.value
                                    ?.length ||
                                store.columnsFilterDict[columnName]
                                    ?.isWidgetFilter
                            }
                            onClick={() => {
                                confirm({ closeDropdown: true });
                                store.applyColumnFilter(columnName);
                            }}
                            style={{ marginLeft: 10 }}
                        >
                            Apply
                        </Button>
                    </div>
                </div>
            );
        },
        filterIcon: (
            <Observer>
                {() => (
                    <span>
                        <FilterFilled style={getFilterIconStyle()} />
                        <Tooltip title="Reset filter">
                            <i
                                className={`alpha-icon xs filter-reset ${store.columnsFilterDict[columnName]?.isWidgetFilter ? "hidden" : ""}`}
                                onClick={handleFilterResetClick}
                            />
                        </Tooltip>
                    </span>
                )}
            </Observer>
        ),
    };
};

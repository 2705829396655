import * as React from "react";
import { observer } from "mobx-react-lite";
import TaskTabsContent from "./TaskTabsContent";

const TabsContentContainer: React.FC = () => {
    return (
        <div>
            <TaskTabsContent />
        </div>
    );
};

export default observer(TabsContentContainer);

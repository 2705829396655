import {
    action,
    computed,
    makeObservable,
    observable,
    runInAction,
} from "mobx";
import { ErrorStore, ProjectsStore } from "../../common/stores";
import ReportsService from "../services/ReportsService";
import { PublishedReport } from "../types";
import { message } from "antd";
import { ProjectForCurrentUser } from "src/modules/common/services/types";

export default class ReportsStore {
    reports: PublishedReport[] = [];
    reportsLoading: boolean = false;
    selectedReport: PublishedReport | null;
    editReportDialogVisible: boolean = false;
    editDialogMode: "create" | "edit" = "create";
    reportAccessOptions = {
        User: "All users",
        ProjectAdmin: "Project admin and system admin",
        SystemAdmin: "Only system admin",
    };

    availableProjects: ProjectForCurrentUser[] = [];
    searchString: string = "";

    constructor(
        private projectsStore: ProjectsStore,
        private errorStore: ErrorStore,
        private reportsService: ReportsService,
    ) {
        makeObservable<ReportsStore>(this, {
            reports: observable,
            reportsLoading: observable,
            selectedReport: observable,
            editReportDialogVisible: observable,
            editDialogMode: observable,
            searchString: observable,
            projects: computed,
            enabledReports: computed,
            getReport: action.bound,
            getProjectName: action.bound,
            refreshAvailableProjects: action.bound,
            setIsLoading: action.bound,
            setReports: action.bound,
            setSelectedReport: action.bound,
            setNewReportDialogVisibility: action.bound,
            publishReport: action.bound,
            updateReport: action.bound,
            deleteReport: action.bound,
            toggleReportAvailability: action.bound,
            setSearchString: action,
            filteredReports: computed,
        });

        this.loadReports();
    }

    get projects() {
        return this.projectsStore.projects;
    }

    get enabledReports() {
        return this.reports.filter((r) => r.enabled) || [];
    }

    get filteredReports() {
        if (this.searchString.trim() !== "") {
            const searchStringLower = this.searchString.toLowerCase();
            return this.reports.filter((m) =>
                m.title.toLowerCase().includes(searchStringLower),
            );
        }
        return this.reports;
    }

    getReport(reportId: string) {
        return this.reports.find((r) => r.id === reportId);
    }

    getProjectName(projectId: string) {
        return (
            this.projectsStore.projects?.find((p) => p.id === projectId)
                ?.name ?? ""
        );
    }

    refreshAvailableProjects(reportId: string) {
        const rep = this.reports.find((r) => r.id === reportId);
        const availableProjects =
            rep?.projects && rep.projects.length > 0
                ? this.projects.filter((p) => rep.projects.includes(p.id))
                : this.projects;

        console.log(
            "ReportId:",
            reportId,
            "Report projects:",
            rep?.projects,
            "Available projects:",
            availableProjects,
        );

        this.availableProjects = availableProjects;
    }

    setIsLoading(isLoading: boolean) {
        this.reportsLoading = isLoading;
    }

    setReports(data: PublishedReport[]) {
        this.reports = data;
    }

    setSelectedReport(report: PublishedReport | null) {
        this.selectedReport = report;
        if (!report) {
            this.editReportDialogVisible = false;
        } else {
            this.editDialogMode = "edit";
            this.editReportDialogVisible = true;
        }
    }

    setNewReportDialogVisibility(isVisible: boolean) {
        this.selectedReport = {
            id: "",
            title: "",
            source: "",
            description: undefined,
            projects: [],
            reportAccess: "User",
            enabled: true,
        };

        this.editDialogMode = "create";
        this.editReportDialogVisible = isVisible;
    }

    setSearchString(searchString: string) {
        this.searchString = searchString;
    }

    async loadReports() {
        this.setIsLoading(true);

        const resp = await this.reportsService.getReports();
        resp.map((r) => {
            this.setReports(r);
        }).mapErr((err) => this.errorStore.addBasicError(err));

        this.setIsLoading(false);
    }

    async publishReport(formValues: FormData) {
        try {
            const resp = await this.reportsService.publishReport(formValues);
            resp.map(() => {
                runInAction(() => {
                    this.loadReports();
                    this.setNewReportDialogVisibility(false);
                });
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            }).mapErr((err: any) => {
                this.errorStore.addError(err.data);
            });
        } catch (err) {
            this.errorStore.addBasicError(err);
        }
    }

    async updateReport(formValues: FormData) {
        const metadataDefinitionId = this.selectedReport!.id;
        const response = await this.reportsService.updateReport(
            metadataDefinitionId,
            formValues,
        );
        response
            .map(() => {
                runInAction(() => {
                    this.loadReports();
                    this.setNewReportDialogVisibility(false);
                });
            })
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .mapErr((err: any) => this.errorStore.addError(err.data));
    }

    async deleteReport(reportId: string) {
        const response = await this.reportsService.deleteReport(reportId);
        response
            .map(() => {
                runInAction(() => {
                    const newList = this.reports
                        .slice()
                        .filter((t) => t.id !== reportId);
                    this.reports = newList;
                });
            })
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .mapErr((err: any) => {
                if (err.status === 409) {
                    message.error(err.data!.title);
                } else {
                    this.errorStore.addError(err.data);
                }
            });
    }

    async toggleReportAvailability(reportId: string) {
        const response =
            await this.reportsService.toggleReportAvailability(reportId);
        response
            .map(() => {
                runInAction(() => {
                    const newReports = this.reports.slice();
                    const reportToToggle = newReports.find(
                        (s) => s.id === reportId,
                    );

                    if (reportToToggle) {
                        const index = newReports.indexOf(reportToToggle);
                        newReports[index].enabled = !newReports[index].enabled;
                    }

                    this.reports = newReports;
                });
            })
            .mapErr((err) => {
                this.errorStore.addBasicError(err);
            });
    }
}

import { PlusOutlined } from "@ant-design/icons";
import {
    Button,
    Checkbox,
    Col,
    Divider,
    Form,
    Input,
    Row,
    Select,
    Space,
} from "antd";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import ActionDefinitionStore from "../stores/ActionDefinitionStore";
import { ActionField, ActionType } from "../types/Actions";
import ActionFieldInputSetupDialog from "./ActionFieldInputSetupDialog";
import ActionFieldEditor from "./ActionFieldEditor";
import ConstraintEditor from "./ConstraintEditor";
import { Utils } from "../../common/misc/Utils";
import { ProjectsStore } from "../../common/stores";

const FormItem = Form.Item;

type Props = {
    store: ActionDefinitionStore;
    projectsStore: ProjectsStore;
};

const ActionDefinitionForm: React.FC<Props> = ({ store, projectsStore }) => {
    const [form] = Form.useForm();
    const mode = store.editDialogMode;
    const [isTypeSet, setIsTypeSet] = useState<boolean>(false);
    const [selectedActionType, setSelectedActionType] = useState<
        ActionType | undefined
    >(store.getActionType(store.selectedDefinition?.actionType));

    React.useEffect(() => {
        if (!store.editDialogVisible) {
            form.resetFields();
        }
    }, [store.editDialogVisible, form]);

    React.useEffect(() => {
        if (store.selectedDefinition) {
            form.setFieldsValue(store.selectedDefinition);
            setIsTypeSet(
                store.selectedDefinition.actionType !== undefined &&
                    store.selectedDefinition.actionType.length > 0,
            );
        }
    }, [store.selectedDefinition?.id, store.editDialogVisible]);

    const handleSubmit = () => {
        form.validateFields()
            .then(() => {
                const formData = form.getFieldsValue();

                if (mode === "create") {
                    store.createActionDefinition(formData);
                } else {
                    store.updateActionDefinition(
                        formData.actionType,
                        formData.title,
                        formData.fields,
                        formData.constraints,
                        formData.permissions,
                    );
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleActionTypeChange = (newValue: string) => {
        const actionType = store.actionTypes.find((x) => x.name === newValue);

        if (actionType) {
            setIsTypeSet(true);
            setSelectedActionType(actionType);
            let currentFields: ActionField[] =
                form.getFieldValue("fields") || [];
            const requiredFields = actionType?.fields;
            currentFields = currentFields.filter((f) => !f.isPredefined);

            if (requiredFields && requiredFields.length > 0) {
                const missing = requiredFields
                    ?.filter(
                        (x) =>
                            currentFields?.find(
                                (c: ActionField) => c.name === x.name,
                            ) === undefined,
                    )
                    .map((x) => ({ ...x, isPredefined: true }));

                if (missing && missing.length > 0) {
                    form.setFieldValue("fields", [
                        ...missing,
                        ...currentFields,
                    ]);
                }
            }
        } else {
            setIsTypeSet(false);
            setSelectedActionType(undefined);
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const actionNameValidator = (rule: any, value: any, callback: any) => {
        const actions = store!.actionDefinitions.map((a) =>
            a.name.toLowerCase(),
        );
        if (
            value &&
            actions.includes(value.toLowerCase()) &&
            store.selectedDefinition?.name !== value
        ) {
            callback("Action with the same name already exists");
        } else {
            callback();
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const actionTitleValidator = (rule: any, value: any, callback: any) => {
        const actions = store!.actionDefinitions.map((a) =>
            a.title?.toLowerCase(),
        );
        if (
            value &&
            actions.includes(value.toLowerCase()) &&
            store.selectedDefinition?.title !== value
        ) {
            callback("Action with the same title already exists");
        } else {
            callback();
        }
    };

    const actionTypes = Utils.groupBy(
        store.actionTypes
            .slice()
            .sort((a, b) => a.title.localeCompare(b.title)),
        (k) => (k.isAttachment ? "Attachment" : "Task"),
    );

    const actionTypeOptions = Object.keys(actionTypes).map((t) => ({
        label: t,
        options: actionTypes[t as "Attachment" | "Task"].map((a) => ({
            label: a.title,
            value: a.name,
        })),
    }));

    return (
        <div className="dialog-section">
            <Form
                form={form}
                onFinish={handleSubmit}
                className="alpha-form"
                id="edit-action-definition-form"
                layout="vertical"
            >
                <FormItem name="projectId" noStyle>
                    <Input type="hidden" />
                </FormItem>
                <Row gutter={24}>
                    <Col span={8}>
                        <FormItem
                            name="name"
                            colon={false}
                            className="dialog-field"
                            label={
                                <span className="dialog-field-label">Name</span>
                            }
                            rules={[
                                { required: true, message: "Name is required" },
                                { validator: actionNameValidator },
                            ]}
                        >
                            <Input disabled={mode === "edit"} />
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem
                            name="title"
                            colon={false}
                            className="dialog-field"
                            label={
                                <span className="dialog-field-label">
                                    Title
                                </span>
                            }
                            rules={[{ validator: actionTitleValidator }]}
                        >
                            <Input />
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem
                            name="actionType"
                            colon={false}
                            className="dialog-field"
                            label={
                                <span className="dialog-field-label">
                                    Action type
                                </span>
                            }
                            rules={[
                                {
                                    required: true,
                                    message: "Action type is required",
                                },
                            ]}
                        >
                            <Select
                                suffixIcon={
                                    <i
                                        className="alpha-icon xxs arrow-down-icon"
                                        style={{ margin: 0, width: 8 }}
                                    />
                                }
                                onChange={handleActionTypeChange}
                                options={actionTypeOptions}
                            />
                        </FormItem>
                    </Col>
                </Row>
                {isTypeSet ? (
                    <>
                        <Divider orientation="left">Action fields</Divider>
                        <Form.List name="fields">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name }) => (
                                        <ActionFieldEditor
                                            key={key}
                                            store={store}
                                            index={name}
                                            form={form}
                                            actionType={selectedActionType}
                                            remove={remove}
                                        />
                                    ))}
                                    <Form.Item>
                                        <Button
                                            type="dashed"
                                            onClick={() =>
                                                add({ isPredefined: false })
                                            }
                                            block
                                            icon={<PlusOutlined />}
                                        >
                                            Add field
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>

                        <Divider orientation="left">Constraints</Divider>
                        <Form.Item label="Execution limited to">
                            <Space>
                                <Form.Item
                                    name={["permissions", "admin"]}
                                    valuePropName="checked"
                                >
                                    <Checkbox>Admin</Checkbox>
                                </Form.Item>
                                <Form.Item name={["permissions", "roles"]}>
                                    <Select
                                        mode="multiple"
                                        style={{ width: 280 }}
                                        placeholder="Project roles"
                                        options={
                                            projectsStore.projects
                                                .find(
                                                    (p) =>
                                                        p.id ===
                                                        store.selectedProjectId,
                                                )
                                                ?.projectRoles.map((r) => ({
                                                    value: r,
                                                    label: r,
                                                })) ?? []
                                        }
                                    />
                                </Form.Item>
                            </Space>
                        </Form.Item>
                        <Form.List name="constraints">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name }) => (
                                        <ConstraintEditor
                                            key={key}
                                            store={store}
                                            index={name}
                                            form={form}
                                            remove={remove}
                                            isAttachment={
                                                selectedActionType?.isAttachment ??
                                                false
                                            }
                                        />
                                    ))}
                                    <Form.Item>
                                        <Button
                                            type="dashed"
                                            onClick={() =>
                                                add({ kind: "Requirement" })
                                            }
                                            block
                                            icon={<PlusOutlined />}
                                        >
                                            Add constraint
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </>
                ) : null}
            </Form>
            <ActionFieldInputSetupDialog store={store} />
        </div>
    );
};

export default observer(ActionDefinitionForm);

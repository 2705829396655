import * as React from "react";
import { observer } from "mobx-react-lite";
import { Col, Form, Input, Radio, Row, Select, Tooltip } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import UsersManagerStore, { LoginTypes } from "../stores/UsersManagerStore";
import permissions, { AppPermissions } from "../../authorization/Permissions";
import { EMAIL_REGEX_PATTERN, Utils } from "../../common/misc/Utils";

type Props = {
    form: FormInstance;
    store: UsersManagerStore;
};

const Option = Select.Option;
const FormItem = Form.Item;

export const UserForm: React.FC<Props> = observer(({ store }) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const similarEmailsValidator = (rule: any, value: any, callback: any) => {
        const emailsToValidate = store!.users
            .filter(
                (u) =>
                    u.email &&
                    (!store!.selectedUser || store!.selectedUser.id !== u.id),
            )
            .map((u) => u.email.toLowerCase());
        if (value && emailsToValidate.includes(value.toLowerCase())) {
            callback("User with the same email is already registered!");
        } else {
            callback();
        }
    };

    const isFieldDisabled =
        store!.selectedUser! && store!.selectedUser!.isDomainUser
            ? true
            : false;

    const getPermissionsString = (role: string) => {
        if (permissions[role]) {
            const rolePermissions = permissions[role] as AppPermissions[];
            return rolePermissions.map((x) => (
                <>
                    {x}
                    <br />
                </>
            ));
        }
        return null;
    };

    return (
        <>
            <Row gutter={20}>
                <Col span="12">
                    <FormItem
                        label={
                            <span className="dialog-field-label">
                                Login type
                            </span>
                        }
                        name="loginType"
                        rules={[
                            {
                                required: true,
                                message: "Please select type",
                            },
                        ]}
                    >
                        <Select style={{ width: "100%" }} disabled>
                            {[
                                LoginTypes.internalUser,
                                LoginTypes.domainUser,
                            ].map((x, i) => (
                                <Option key={i} value={x}>
                                    {x}
                                </Option>
                            ))}
                        </Select>
                    </FormItem>
                </Col>
                <Col span="12">
                    <FormItem
                        label={
                            <span className="dialog-field-label">Username</span>
                        }
                        name="userName"
                        rules={[
                            {
                                required: true,
                                message: "Username is required",
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input disabled />
                    </FormItem>
                </Col>
                <Col span="12">
                    <FormItem
                        label={
                            <span className="dialog-field-label">
                                First name
                            </span>
                        }
                        name="firstName"
                        rules={[
                            {
                                required: true,
                                message: "First name is required",
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input disabled={isFieldDisabled} />
                    </FormItem>
                </Col>
                <Col span="12">
                    <FormItem
                        label={
                            <span className="dialog-field-label">
                                Last name
                            </span>
                        }
                        name="lastName"
                        rules={[
                            {
                                required: true,
                                message: "Last name is required",
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input disabled={isFieldDisabled} />
                    </FormItem>
                </Col>
                <Col span="12">
                    <FormItem
                        label={
                            <span className="dialog-field-label">Email</span>
                        }
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: "Email is required",
                                whitespace: true,
                            },
                            {
                                pattern: EMAIL_REGEX_PATTERN,
                                message: "Input valid email address please.",
                            },
                            {
                                validator: similarEmailsValidator,
                            },
                        ]}
                    >
                        <Input disabled={isFieldDisabled} />
                    </FormItem>
                </Col>
            </Row>
            <div className="dialog-administation-role-form-item">
                <FormItem
                    label={<span className="dialog-field-label">Roles</span>}
                    name="role"
                    rules={[
                        {
                            required: true,
                            message: "Please select role",
                        },
                    ]}
                >
                    <Radio.Group>
                        {store!.sortedRoles.map((r) => (
                            <Radio
                                className="checkbox-with-subtitle"
                                key={r.id}
                                value={r.name}
                                onChange={() =>
                                    store.setCurrentEditableRole(r.name)
                                }
                            >
                                <div>
                                    <div style={{ display: "inline" }}>
                                        {Utils.getAppRoleDisplayName(r.name)}
                                    </div>
                                    <div style={{ display: "inline-block" }}>
                                        <Tooltip
                                            title={getPermissionsString(r.name)}
                                        >
                                            <i
                                                className="alpha-icon lg question-icon"
                                                style={{
                                                    verticalAlign: "middle",
                                                }}
                                            />
                                        </Tooltip>
                                    </div>
                                </div>
                            </Radio>
                        ))}
                    </Radio.Group>
                </FormItem>
            </div>
        </>
    );
});

import { DashboardWidgetType } from "src/modules/administration/types/DashboardWidget";
import WidgetBase from "./WidgetBase";

export default class NumericWidget extends WidgetBase {
    totalTasksCount: number;

    constructor(
        id: string,
        name: string,
        type: DashboardWidgetType,
        projectId: string,
        isDefaultWidget: boolean,
        totalTasksCount: number,
    ) {
        super(id, type, name, projectId, isDefaultWidget);
        this.totalTasksCount = totalTasksCount;
    }
}

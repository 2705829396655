import * as React from "react";
import { useLocalObservable } from "mobx-react-lite";
import DashboardStore from "./DashboardStore";
import {
    ErrorStore,
    ProjectsStore,
    UserProfileStore,
    useStore,
} from "../../common/stores";
import DashboardService from "../services/DashboardService";

function createStore(
    userProfileStore: UserProfileStore,
    projectsStore: ProjectsStore,
    errorStore: ErrorStore,
) {
    const dashboardService = new DashboardService(errorStore);
    const dashboardStore = new DashboardStore(
        dashboardService,
        userProfileStore,
        projectsStore,
    );

    return {
        dashboardStore,
    };
}

const storeContext = React.createContext<ReturnType<typeof createStore> | null>(
    null,
);
type Props = {
    children: React.ReactNode;
};

export const DashboardStoreProvider: React.FC<Props> = ({ children }) => {
    const root = useStore();
    const store = useLocalObservable(() =>
        createStore(root.userProfileStore, root.projectsStore, root.errorStore),
    );
    return (
        <storeContext.Provider value={store}>{children}</storeContext.Provider>
    );
};

export const useDashboardStore = () => {
    const store = React.useContext(storeContext);
    if (!store) {
        // this is especially useful in TypeScript so you don't need to be checking for null all the time
        throw new Error("You have forgot to use StoreProvider, shame on you.");
    }

    return store;
};

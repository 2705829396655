import { Route } from "react-router";
import * as React from "react";
import DocumentViewerPage from "../screens/DocumentViewerPage";

const documentRoute = () => {
    return (
        <>
            <Route
                path="/preview/:documentId/:documentName"
                element={<DocumentViewerPage />}
            />
        </>
    );
};

export default documentRoute;

import * as React from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../stores";
import TaskFullViewRenderer from "./TaskFullViewRenderer";
import { useParams } from "react-router";

const TaskTabsContent: React.FC = () => {
    const { tabsStore, projectsStore, errorStore, userProfileStore } =
        useStore();
    const key = useParams().taskId!;
    return (
        <div key={`tab-content-${key}`}>
            <TaskFullViewRenderer
                errorStore={errorStore}
                projectsStore={projectsStore}
                tabsStore={tabsStore}
                userProfileStore={userProfileStore}
                key={key}
            />
        </div>
    );
};

export default observer(TaskTabsContent);

import * as React from "react";
import LocalStorageWorker from "../misc/StorageHelper";
import { useLocalObservable } from "mobx-react-lite";
import * as services from "../services";
import * as stores from ".";
import NotificationsService from "../services/NotificationsService";
import UserProfileService from "../services/UserProfileService";
import TaskTemplateService from "../../task_templates/services/TaskTemplateService";
import TaskTemplateVisualStore from "../../task_templates/stores/TaskTemplatesVisualStore";
import TaskTypesService from "src/modules/task_types/services/TaskTypesService";
function createStore() {
    const errorStore = new stores.ErrorStore();
    const projectsService = new services.ProjectsService();
    const localStorageHelper = new LocalStorageWorker();
    const projectsStore = new stores.ProjectsStore(
        projectsService,
        errorStore,
        localStorageHelper,
    );
    const userProfleService = new UserProfileService();
    const userProfileStore = new stores.UserProfileStore(
        userProfleService,
        errorStore,
    );
    const tabsStore = new stores.TabsStore(errorStore);
    const notificationsService = new NotificationsService();
    const notificationStore = new stores.NotificationStore(
        errorStore,
        notificationsService,
        userProfileStore,
    );
    const templateService = new TaskTemplateService();
    const taskTypesService = new TaskTypesService();
    const templateStore = new TaskTemplateVisualStore(
        projectsStore,
        templateService,
        taskTypesService,
        errorStore,
    );
    return {
        projectsStore,
        errorStore,
        tabsStore,
        notificationStore,
        userProfileStore,
        templateStore,
    };
}

const storeContext = React.createContext<ReturnType<typeof createStore> | null>(
    null,
);
type Props = {
    children: React.ReactNode;
};

export const StoreProvider: React.FC<Props> = ({ children }) => {
    const store = useLocalObservable(createStore);
    return (
        <storeContext.Provider value={store}>{children}</storeContext.Provider>
    );
};

export const useStore = () => {
    const store = React.useContext(storeContext);
    if (!store) {
        // this is especially useful in TypeScript so you don't need to be checking for null all the time
        throw new Error("You have forgot to use StoreProvider, shame on you.");
    }

    return store;
};

import * as React from "react";
import { observer } from "mobx-react-lite";
import { TaskViewVisualStore } from "../stores";
import { Button, Form, Modal, Select, Skeleton } from "antd";
import { AccessType } from "../stores/TaskViewVisualStore";
import { AccessDropdownItems } from "./AccessDropdownItems";
import TasksGridVisualStore from "../stores/TasksGridVisualStore";
import { TaskListModel } from "../types";
import { UserProfile } from "src/modules/common/services/types";

type Props = {
    store: TaskViewVisualStore | TasksGridVisualStore;
    isDialogVisible: boolean;
    closeDialog: () => void;
    task: TaskListModel;
};

const FormItem = Form.Item;

const ChangePrivacyDialog: React.FC<Props> = ({
    store,
    isDialogVisible,
    closeDialog,
    task,
}) => {
    const [form] = Form.useForm();
    const [isSharedWithVisible, setIsSharedWithVisible] = React.useState(false);

    React.useEffect(() => {
        form.setFieldsValue({ accessType: task.accessType });
        if (AccessType[task?.accessType] === AccessType.LimitedAccess) {
            form.setFieldsValue({
                sharedWith: task?.sharedWith!.filter(
                    (x) => x !== store.currentUserId,
                ),
            });
            setIsSharedWithVisible(true);
        } else {
            setIsSharedWithVisible(false);
        }
    }, [form, store]);

    const handleSaveClick = () => {
        form.validateFields()
            .then((values) => {
                store.handlePrivacyChange(
                    task,
                    values.accessType,
                    values.sharedWith ?? [],
                );
                handleDialogClose();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleDialogClose = () => {
        closeDialog();
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handlePrivacyChange = (value: string, option: any) => {
        if (option.label === AccessType.OnlyMe) {
            setIsSharedWithVisible(false);
        } else {
            form.setFieldsValue({ sharedWith: [] });
            if (isSharedWithVisible) {
                setIsSharedWithVisible(false);
            }
            if (option.label === AccessType.LimitedAccess) {
                setIsSharedWithVisible(true);
            }
        }
    };

    const getUserLabel = (user: UserProfile) => {
        return (
            <>
                <span>{`${user?.firstName} ${user?.lastName}`}</span>
                <span style={{ color: "red" }}>
                    {user?.isDeleted ? " (Inactive)" : ""}
                </span>
            </>
        );
    };

    const getfilteredUsers = () => {
        const hiddenUsers = store.usersInProject
            .filter((u) => u.isDeleted && !task?.sharedWith?.includes(u.userId))
            .map((h) => h.userId);
        return store.usersInProject.filter(
            (f) =>
                !hiddenUsers.includes(f.userId) &&
                f.userId !== store.currentUserId,
        );
    };

    return (
        <Modal
            title="Change access"
            open={isDialogVisible}
            className="alpha-modal comment-in-modal"
            closable={false}
            footer={[
                <Button
                    data-id="claim-cancel-button"
                    key="claim-cancel-button"
                    className="light"
                    onClick={handleDialogClose}
                >
                    Cancel
                </Button>,
                <Button
                    data-id="claim-save-button"
                    key="claim-save-button"
                    type="primary"
                    onClick={handleSaveClick}
                >
                    Change access
                </Button>,
            ]}
        >
            <Form form={form} className="alpha-form" layout="vertical">
                <FormItem colon={false} label="Access" name="accessType">
                    <Select
                        // TODO: Do we need this?
                        // loading={store.taskStatusesLoading}
                        optionLabelProp="label"
                        onChange={handlePrivacyChange}
                    >
                        {Object.keys(AccessType).map((p) => (
                            <Select.Option
                                key={p}
                                value={p}
                                label={AccessType[p as keyof typeof AccessType]}
                            >
                                {
                                    AccessDropdownItems[
                                        p as keyof typeof AccessDropdownItems
                                    ]
                                }
                            </Select.Option>
                        ))}
                    </Select>
                </FormItem>
                {isSharedWithVisible && (
                    <FormItem
                        name="sharedWith"
                        colon={false}
                        className="dialog-field"
                        label={
                            <span className="dialog-field-label">
                                Share with
                            </span>
                        }
                        rules={[
                            {
                                required: true,
                                message: "Share with is required",
                            },
                        ]}
                    >
                        {store.loadingUsers ? (
                            <div
                                style={{
                                    display: "inline-block",
                                    marginLeft: 12,
                                    height: 26,
                                    width: "100%",
                                    verticalAlign: "middle",
                                }}
                            >
                                <Skeleton
                                    title={false}
                                    avatar={false}
                                    paragraph={{ rows: 1 }}
                                    active
                                />
                            </div>
                        ) : (
                            <Select mode="multiple" optionLabelProp="label">
                                {store.usersInProject &&
                                    getfilteredUsers().map((u) => (
                                        <Select.Option
                                            key={u.userId}
                                            value={u.userId}
                                            label={getUserLabel(u)}
                                        >
                                            <div
                                                style={{
                                                    marginLeft: 7,
                                                    display: "inline-block",
                                                }}
                                            >
                                                {getUserLabel(u)}
                                            </div>
                                        </Select.Option>
                                    ))}
                            </Select>
                        )}
                    </FormItem>
                )}
            </Form>
        </Modal>
    );
};

export default observer(ChangePrivacyDialog);

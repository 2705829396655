import * as React from "react";
import { observer, Observer } from "mobx-react-lite";
import { Button, Col, Dropdown, MenuProps, message, Modal, Row } from "antd";
import WrappedResetPasswordForm from "../../user_profile/components/ResetPasswordDialog";
import Form, { useForm } from "antd/lib/form/Form";
import { reaction } from "mobx";
import { UserModel } from "../types/UserModel";
import UsersManagerStore, { LoginTypes } from "../stores/UsersManagerStore";
import { UserForm } from "./UserForm";
import { ProjectsAssignmentTable } from "./ProjectsAssignmentTable";

type Props = {
    store: UsersManagerStore;
};

export const EditUserDialog: React.FC<Props> = observer(({ store }) => {
    const [form] = useForm();
    const [deleteConfirmVisible, setDeleteConfirmVisible] =
        React.useState(false);
    const [currentStep, setCurrentStep] = React.useState(0);

    const mapUserRoles = (user: UserModel) => {
        const defaultRoles = store!.sortedRoles.map((r) => r.name);
        const defaultUserRoles = user.roles
            .filter((r) => defaultRoles.includes(r.name))
            .map((r) => r.name);
        const customRoles = user.roles
            .filter((r) => !defaultRoles.includes(r.name))
            .map((r) => r.id);

        return [...defaultUserRoles, ...customRoles];
    };

    React.useEffect(() => {
        const disposer = reaction(
            () => store!.selectedUser,
            (user: UserModel) => {
                form.setFieldsValue({
                    userName: user!.userName,
                    email: user!.email,
                    firstName: user!.firstName,
                    lastName: user!.lastName,
                    role: mapUserRoles(user!)[0],
                    loginType:
                        (user!.isDomainUser && LoginTypes.domainUser) ||
                        LoginTypes.internalUser,
                    projects: user!.projects,
                });
            },
        );
        return () => {
            disposer();
        };
    }, [form, store]);

    const validateFields = () => {
        return form.validateFields().then(async (data) => {
            if (data.loginType === LoginTypes.domainUser) {
                data.userName = store!.selectedUser!.id;
                data.isDomainUser = true;
            } else {
                data.email = data.email.toLowerCase();
            }
            return data;
        });
    };

    const handleNextClick = async () => {
        if (
            currentStep === 0 &&
            store.selectedUser!.roles.map((r) => r.name).includes("admin") &&
            store.currentEditableRole === "user"
        ) {
            validateFields()
                .then((data) => {
                    store.setNewUserFormValues(data);
                    store.setEditableProjectsForEditableUser();
                    setCurrentStep(1);
                })
                .catch((err) => {
                    console.log(err);
                });
        } else if (currentStep !== 1) {
            validateFields()
                .then(async (data) => {
                    store.setEditableProjectsForUserId(store.selectedUser!.id);
                    await store!
                        .createUpdateUser(data as UserModel, true)
                        .then(() => {
                            store.setIsEditUserDialogVisible(false);
                            store.loadProjects();
                        });
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            if (store.editableProjectsForUser.length === 0) {
                message.error("Please select at least one project");
                return;
            }
            store.createUpdateUser(undefined, true).then(() => {
                store.setIsEditUserDialogVisible(false);
                store.setCurrentEditableRole(null);
                store.clearEditableProjects();
            });
        }
    };

    const handleCancel = () => {
        store!.setIsEditUserDialogVisible(false);
        store.setCurrentEditableRole(null);
        form.resetFields();
    };

    const deleteConfirm = () => {
        setDeleteConfirmVisible(true);
    };
    const isDomainUser = store!.selectedUser?.isDomainUser;

    const menu: MenuProps = {
        items: [
            {
                key: 1,
                label: (
                    <div
                        data-id="edit-user-dialog-reset-pwd"
                        data-testid="edit-user-dialog-reset-pwd"
                        onClick={() =>
                            store!.setResetPasswordDialogVisible(true)
                        }
                        style={isDomainUser ? { display: "none" } : {}}
                    >
                        Reset password
                    </div>
                ),
            },
            {
                key: 2,
                label: (
                    <div
                        data-id="edit-user-dialog-remove"
                        data-testid="edit-user-dialog-remove"
                        style={{ color: "red" }}
                        onClick={deleteConfirm}
                    >
                        Remove user
                    </div>
                ),
                disabled: store.currentUserId === store.selectedUser!.id,
            },
        ],
    };

    const deleteUser = () => {
        store!.deleteUser();
        setDeleteConfirmVisible(false);
    };

    const getFooter = () => {
        return currentStep === 0
            ? [
                  <Row key="edit-user-footer-actions">
                      <Col span={8} style={{ textAlign: "left" }}>
                          <Dropdown
                              menu={menu}
                              trigger={["click"]}
                              key="more"
                              overlayClassName="alpha-dropdown-menu"
                          >
                              <Button
                                  className="light"
                                  data-id="edit-user-dialog-more"
                                  data-testid="edit-user-dialog-more"
                                  size="large"
                                  disabled={store.tableIsLoading}
                              >
                                  More...
                              </Button>
                          </Dropdown>
                      </Col>
                      <Col span={16} style={{ textAlign: "right" }}>
                          <Button
                              data-id="edit-user-dialog-cancel"
                              size="large"
                              key="cancel"
                              onClick={handleCancel}
                              disabled={store.tableIsLoading}
                          >
                              Cancel
                          </Button>
                          <Button
                              data-id="edit-user-dialog-submit"
                              data-testid="edit-user-dialog-submit"
                              size="large"
                              key="submit"
                              type="primary"
                              onClick={handleNextClick}
                              loading={store.tableIsLoading}
                          >
                              {store.currentEditableRole === "user" &&
                              store
                                  .selectedUser!.roles.map((r) => r.name)
                                  .includes("admin")
                                  ? "Next"
                                  : "Save changes"}
                          </Button>
                      </Col>
                  </Row>,
              ]
            : [
                  <Button
                      className="light"
                      data-id="add-user-dialog-cancel"
                      size="large"
                      key="cancel"
                      onClick={handleCancel}
                      disabled={store.tableIsLoading}
                  >
                      Cancel
                  </Button>,
                  <Button
                      className="light"
                      data-id="add-user-dialog-back"
                      size="large"
                      key="back"
                      onClick={() => setCurrentStep(0)}
                      disabled={store.tableIsLoading}
                  >
                      Back
                  </Button>,
                  <Button
                      data-id="add-user-dialog-submit"
                      size="large"
                      key="submit"
                      type="primary"
                      onClick={handleNextClick}
                      loading={store.tableIsLoading}
                  >
                      Save changes
                  </Button>,
              ];
    };

    const { selectedUser } = store!;
    return (
        <Modal
            open={store!.isEditUserDialogVisible}
            onCancel={handleCancel}
            maskClosable={false}
            closable={false}
            title={currentStep === 0 ? "Edit user" : "Project assignments"}
            destroyOnClose
            width={660}
            centered
            className="alpha-modal"
            footer={getFooter()}
        >
            <Observer>
                {() => (
                    <>
                        <Modal
                            open={deleteConfirmVisible}
                            closable={false}
                            maskClosable={false}
                            title="Please confirm"
                            onOk={deleteUser}
                            okButtonProps={{
                                size: "large",
                            }}
                            okText="Remove user"
                            onCancel={() => setDeleteConfirmVisible(false)}
                            cancelButtonProps={{
                                size: "large",
                                className: "light",
                            }}
                            cancelText="Cancel"
                            centered
                            className="alpha-portal-dialog two-columns confirm"
                        >
                            Are you sure that you want to delete user{" "}
                            {store!.selectedUser
                                ? `${store!.selectedUser.firstName} ${store!.selectedUser.lastName} (${store!.selectedUser.userName})`
                                : ""}
                            ? This action cannot be undone.
                        </Modal>
                        <WrappedResetPasswordForm store={store} />
                        {currentStep === 0 ? (
                            <Form
                                data-id="edit-user-content-form"
                                form={form}
                                className="alpha-form"
                                layout="vertical"
                                initialValues={
                                    (selectedUser && {
                                        userName: selectedUser!.userName,
                                        email: selectedUser!.email!,
                                        firstName: selectedUser!.firstName,
                                        lastName: selectedUser!.lastName,
                                        role:
                                            selectedUser!.roles &&
                                            selectedUser!.roles.length
                                                ? selectedUser!.roles.map(
                                                      (x) => x.name,
                                                  )[0]
                                                : "",
                                        loginType: LoginTypes.internalUser,
                                        projects: selectedUser.projects ?? [],
                                    }) ||
                                    {}
                                }
                            >
                                <UserForm store={store} form={form} />
                            </Form>
                        ) : (
                            <ProjectsAssignmentTable store={store} />
                        )}
                    </>
                )}
            </Observer>
        </Modal>
    );
});

export default EditUserDialog;

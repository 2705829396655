import { ErrorStore, ProjectsStore } from "../../common/stores";
import {
    action,
    runInAction,
    computed,
    makeObservable,
    observable,
} from "mobx";
import { message } from "antd";
import { SubtaskTemplate, TaskTemplate } from "../types";
import TaskTemplateService from "../services/TaskTemplateService";
import { UserModel } from "../../tasks/types";
import { TaskType } from "../../task_types/types";
import TaskTypesService from "../../task_types/services/TaskTypesService";

type SubtaskTemplateModel = {
    index: number | undefined;
    model: SubtaskTemplate;
};

export default class TaskTemplateVisualStore {
    usersInProject: UserModel[] = [];

    taskTemplates: TaskTemplate[] = [];

    taskTemplatesLoading: boolean = false;

    taskTypes: TaskType[] = [];

    selectedTaskTemplateId: string | undefined | null = undefined;

    isUsersLoading: boolean = false;

    taskCreatingInProgress: boolean = false;

    taskTypesLoading: boolean = false;

    selectedProjectId: string;

    selectedTaskTypeId: string;

    selectedSubtaskTemplate: SubtaskTemplateModel | undefined;

    searchString: string = "";

    constructor(
        private projectsStore: ProjectsStore,
        private taskTemplateService: TaskTemplateService,
        private taskTypesService: TaskTypesService,
        private errorStore: ErrorStore,
    ) {
        makeObservable<TaskTemplateVisualStore>(this, {
            taskTemplates: observable,
            taskTemplatesLoading: observable,
            taskTypes: observable,
            selectedTaskTemplateId: observable,
            selectedProjectId: observable,
            selectedTaskTypeId: observable,
            selectedSubtaskTemplate: observable,
            searchString: observable,
            setSelectedProjectId: action.bound,
            setSelectedTaskTypeId: action.bound,
            selectTemplate: action.bound,
            setTaskTemplates: action.bound,
            setSelectedSubtaskTemplate: action.bound,
            loadTaskTemplates: action.bound,
            createTaskTemplate: action.bound,
            updateTaskTemplate: action.bound,
            deleteTaskTemplate: action.bound,
            setSearchString: action,
            projects: computed,
            currentProjectId: computed,
            selectedTemplate: computed,
            templatTitleParameters: computed,
            templateDescriptionParameters: computed,
            filteredTemplates: computed,
        });
    }

    get projects() {
        return this.projectsStore.projectsForTasks;
    }

    get filteredTemplates() {
        if (this.searchString.trim() !== "") {
            const searchStringLower = this.searchString.toLowerCase();
            return this.taskTemplates.filter(
                (t) =>
                    t.name.toLowerCase().includes(searchStringLower) ||
                    t.templateCode?.toLowerCase().includes(searchStringLower),
            );
        }
        return this.taskTemplates;
    }

    get currentProjectId() {
        return this.projectsStore.currentProjectId;
    }

    get selectedTemplate() {
        return this.taskTemplates.find(
            (x) => x.id === this.selectedTaskTemplateId,
        );
    }

    get templatTitleParameters() {
        return this.selectedTemplate?.title?.match(/\${[^}]+}/g);
    }

    get templateDescriptionParameters() {
        return this.selectedTemplate?.description?.match(/\${[^}]+}/g);
    }

    get hasParameters() {
        return (
            !!this.templatTitleParameters ||
            !!this.templateDescriptionParameters ||
            this.selectedTemplate?.subTasks?.some(
                (s) =>
                    s.title.match(/\${[^}]+}/g) ||
                    s.description?.match(/\${[^}]+}/g),
            )
        );
    }

    setTaskTemplates(templates: TaskTemplate[]) {
        this.taskTemplates = templates;
    }

    selectTemplate(templateId: string | undefined | null) {
        this.selectedTaskTemplateId = templateId;
    }

    setSelectedProjectId(projectId: string) {
        this.selectedProjectId = projectId;
        this.loadTaskTypesForProject(projectId).then(() =>
            this.loadTaskTemplates(projectId),
        );
    }

    setSelectedTaskTypeId(taskTypeId: string) {
        this.selectedTaskTypeId = taskTypeId;
    }

    setSelectedSubtaskTemplate(
        subtaskTemplate: SubtaskTemplate | undefined,
        index: number | undefined = undefined,
    ) {
        if (subtaskTemplate) {
            this.selectedSubtaskTemplate = {
                index,
                model: subtaskTemplate,
            };
        } else {
            this.selectedSubtaskTemplate = undefined;
        }
    }

    setSearchString(searchString: string) {
        this.searchString = searchString;
    }

    async loadTaskTemplates(projectId?: string) {
        try {
            runInAction(() => {
                this.taskTemplates = [];
                this.taskTemplatesLoading = true;
            });

            const response = await (projectId
                ? this.taskTemplateService.getTaskTemplates(projectId)
                : this.taskTemplateService.getAccessibleTaskTemplates());

            response
                .map((templates: TaskTemplate[]) => {
                    runInAction(() => this.setTaskTemplates(templates || []));
                })
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .mapErr((err: any) => this.errorStore.addError(err.data));
        } catch (err) {
            this.errorStore.addBasicError(err);
        } finally {
            runInAction(() => {
                this.taskTemplatesLoading = false;
            });
        }
    }

    async createTaskTemplate(formData: FormData) {
        const response =
            await this.taskTemplateService.createTaskTemplate(formData);
        if (response.isOk()) {
            runInAction(() => {
                this.loadTaskTemplates(this.selectedProjectId);
            });
        } else {
            console.error(response.error);
        }
    }

    async updateTaskTemplate(formData: FormData) {
        const resp = await this.taskTemplateService.updateTaskTemplate(
            formData,
            this.selectedTaskTemplateId!,
        );
        if (resp.isOk()) {
            runInAction(() => {
                this.loadTaskTemplates(this.selectedProjectId);
            });
        } else {
            console.error(resp.error);
        }
    }

    async deleteTaskTemplate(templateId: string) {
        const response =
            await this.taskTemplateService.deleteTaskType(templateId);
        response
            .map(() => {
                runInAction(() => {
                    let newTaskTemplate = this.taskTemplates.slice();
                    newTaskTemplate = newTaskTemplate.filter(
                        (t) => t.id !== templateId,
                    );
                    this.taskTemplates = newTaskTemplate;
                });
            })
            .mapErr((err) => {
                if (err.status === 409) {
                    message.error(err.data!.title);
                } else {
                    this.errorStore.addError(err.data);
                }
            });
    }

    async loadTaskTypesForProject(projectId: string) {
        runInAction(() => {
            this.taskTypes = [];
            this.taskTypesLoading = true;
        });
        const resp =
            await this.taskTypesService.getTaskTypesForProject(projectId);
        resp.map((t) => {
            runInAction(() => {
                this.taskTypes = t;
            });
        }).mapErr((err) => this.errorStore.addError(err.data));

        runInAction(() => {
            this.taskTypesLoading = false;
        });
    }
    // TODO: This method is duplicated. Shoule be moved to rootStore
    async loadUsersForProject(projectId: string) {
        this.isUsersLoading = true;
        const resp =
            await this.taskTemplateService.getUsersInProject(projectId);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        resp.map((users: any) => {
            runInAction(() => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                this.usersInProject = users.map((u: any) => {
                    return {
                        id: u.id,
                        userName: u.firstName
                            ? `${u.firstName} ${u.lastName}`
                            : u.userName,
                    };
                });
            });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }).mapErr((err: any) => this.errorStore.addError(err.data));
        runInAction(() => {
            this.isUsersLoading = false;
        });
    }
}

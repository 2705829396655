import * as React from "react";
import { observer } from "mobx-react-lite";
import {
    Button,
    Table,
    Popconfirm,
    Layout,
    Tooltip,
    Select,
    Input,
} from "antd";
import { ColumnProps } from "antd/lib/table";
import { ProjectsStore } from "../../common/stores";
import TaskTemplateVisualStore from "../stores/TaskTemplatesVisualStore";
import { TaskTemplate } from "../types";
import TaskTemplateEditDialog from "./TaskTemplateEditDialog";
import { safeStringLocaleCompare, Utils } from "../../common/misc/Utils";

const { Content } = Layout;
type Props = {
    store: TaskTemplateVisualStore;
    projectsStore: ProjectsStore;
};

const TaskTemplatesList: React.FC<Props> = ({ store, projectsStore }) => {
    const columns: ColumnProps<TaskTemplate>[] = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            render: (name: string) => <span data-id-cells="name">{name}</span>,
            sorter: (a: TaskTemplate, b: TaskTemplate) =>
                safeStringLocaleCompare(b.name, a.name),
        },
        {
            title: "Code",
            dataIndex: "templateCode",
            key: "templateCode",
            render: (templateCode: string) => (
                <span data-id-cells="templateCode">{templateCode}</span>
            ),
            sorter: (a: TaskTemplate, b: TaskTemplate) =>
                safeStringLocaleCompare(b.templateCode, a.templateCode),
        },
        {
            title: "Task type",
            dataIndex: "taskType",
            key: "taskType",
            render: (taskType: string) => (
                <span data-id-cells="taskType">
                    {store.taskTypes.find((x) => x.id === taskType)?.name}
                </span>
            ),
            sorter: (a: TaskTemplate, b: TaskTemplate) => {
                const taskTypeA = store.taskTypes.find(
                    (x) => x.id === a.taskType,
                )?.name;
                const taskTypeB = store.taskTypes.find(
                    (x) => x.id === b.taskType,
                )?.name;
                return safeStringLocaleCompare(taskTypeB, taskTypeA);
            },
        },
        {
            title: "Task priority",
            dataIndex: "defaultPriority",
            key: "defaultPriority",
            render: (defaultPriority: string) => (
                <span data-id-cells="defaultPriority">
                    {defaultPriority
                        ? Utils.getTaskPrioritiesForDropdown().find(
                              (x) => x.value === defaultPriority,
                          )!.label
                        : ""}
                </span>
            ),
            sorter: (a: TaskTemplate, b: TaskTemplate) => {
                const defaultPriorityFirst = a.defaultPriority
                    ? Utils.getTaskPrioritiesForDropdown().find(
                          (x) => x.value === a.defaultPriority,
                      )!.label
                    : "";
                const defaultPrioritySecond = b.defaultPriority
                    ? Utils.getTaskPrioritiesForDropdown().find(
                          (x) => x.value === b.defaultPriority,
                      )!.label
                    : "";
                return safeStringLocaleCompare(
                    defaultPrioritySecond,
                    defaultPriorityFirst,
                );
            },
        },
        {
            title: "Reminder days",
            dataIndex: "reminderDays",
            key: "reminderDays",
            render: (reminderDays: number | null) => (
                <span data-id-cells="defaultPriority">{reminderDays}</span>
            ),
            sorter: (a: TaskTemplate, b: TaskTemplate) => {
                const daysA = a.reminderDays || 0;
                const daysB = b.reminderDays || 0;
                return daysA - daysB;
            },
        },
        {
            title: "Actions",
            width: 200,
            render: (text: string, record: TaskTemplate) => (
                <div className="row-actions-wrapper">
                    <Button
                        data-id-cells="Edit template"
                        title="Rename"
                        size="small"
                        type="link"
                        onClick={() => {
                            store.selectTemplate(record.id);
                        }}
                    >
                        <Tooltip title="Edit template" placement="bottom">
                            <i className="alpha-icon md table-action-edit" />
                        </Tooltip>
                    </Button>
                    <Popconfirm
                        id="data-id-popconfirm-box"
                        title="Are you sure you want to delete this template?"
                        onConfirm={() => {
                            store.deleteTaskTemplate(record.id);
                        }}
                        placement="topRight"
                    >
                        <Button
                            data-id-cells="Delete template"
                            title="Delete"
                            size="small"
                            type="link"
                        >
                            <Tooltip title="Delete template" placement="bottom">
                                <i className="alpha-icon md table-action-delete" />
                            </Tooltip>
                        </Button>
                    </Popconfirm>
                </div>
            ),
        },
    ];

    const handleEnterPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        store.setSearchString(event.currentTarget.value);
    };

    return (
        <Layout className="screen-size tasks-types-layout layout-with-table">
            <div className="header-wrapper">
                <div className="title-wrapper">
                    <div className="header-title">Task Templates</div>
                </div>
                <Button
                    key={1}
                    disabled={!store.selectedProjectId}
                    data-id="button-add-type"
                    type="primary"
                    size="large"
                    onClick={() => {
                        store.selectTemplate(null);
                    }}
                >
                    Add template
                </Button>
            </div>
            <TaskTemplateEditDialog store={store} />
            <Layout>
                <Content>
                    <div className="projects-list-container">
                        <Select
                            style={{ width: 220 }}
                            options={
                                projectsStore.administrableProjects?.map(
                                    (p) => ({ label: p.name, value: p.id }),
                                ) || []
                            }
                            placeholder="Select project"
                            onSelect={store.setSelectedProjectId}
                            showSearch
                            filterOption={Utils.filterOption}
                        />
                        <Input
                            data-id="search area"
                            placeholder="Search..."
                            onPressEnter={handleEnterPress}
                            onClear={() => store.setSearchString("")}
                            maxLength={150}
                            style={{ width: 200, marginLeft: "auto" }}
                            prefix={
                                <i
                                    className="alpha-icon xs table-search"
                                    style={{ marginRight: 10 }}
                                />
                            }
                            allowClear
                        />
                    </div>
                    <Table
                        data-id="table-tasks-templates-list"
                        className="alpha-table tasks-templates-list"
                        columns={columns}
                        dataSource={
                            store.selectedProjectId
                                ? store.filteredTemplates
                                : []
                        }
                        rowKey={(r) => r.id}
                        pagination={false}
                    />
                </Content>
            </Layout>
        </Layout>
    );
};

export default observer(TaskTemplatesList);

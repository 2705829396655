import { Checkbox, Form, Select } from "antd";
import React from "react";

export const SelectEditorConfigForm: React.FC = () => {
    return (
        <>
            <Form.Item
                name="items"
                label="Items"
                rules={[
                    { required: true, message: "Select items are required" },
                ]}
            >
                <Select mode="tags" />
            </Form.Item>
            <Form.Item
                name="allowMultiple"
                wrapperCol={{ offset: 8 }}
                valuePropName="checked"
            >
                <Checkbox>Allow multiple</Checkbox>
            </Form.Item>
        </>
    );
};

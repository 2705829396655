import { observer } from "mobx-react-lite";
import React from "react";
import TaskStatusesList from "../components/TaskStatusesList";
import { useTaskStatusesStore } from "../stores/context";

const TaskTypesPage: React.FC = () => {
    const context = useTaskStatusesStore();
    return <TaskStatusesList store={context.store} />;
};

export default observer(TaskTypesPage);

import { gql } from "apollo-boost";
import {
    ProjectForCurrentUser,
    PackageSetItemModel,
    PackageSetItemsResult,
    UserProfile,
    Project,
} from "./types";
import { execQuery } from "./DataService";
import security from "./SecurityService";
import appClient, { ResultApi } from "./AppClient";
import { Utils } from "../misc/Utils";

export default class ProjectsService {
    async getProjectsForCurrentUser(): Promise<
        ResultApi<ProjectForCurrentUser[]>
    > {
        const url = `${process.env.REACT_APP_TASKS_URL}project/current-user`;
        return appClient.get<ProjectForCurrentUser[]>(url);
    }

    async getAllProjects(): Promise<ResultApi<Project[]>> {
        const url = `${process.env.REACT_APP_TASKS_URL}project`;
        return appClient.get<Project[]>(url);
    }

    async createUpdateProject(
        id: string | null,
        name: string,
        owner: string,
        projectRoles: string[],
    ): Promise<ResultApi<string>> {
        const request = {
            id,
            name,
            owner,
            projectRoles,
        };
        const url = `${process.env.REACT_APP_TASKS_URL}project`;
        return appClient.post<string>(url, request);
    }

    handleDownload(id: string, fileType: "pdf" | "apkg") {
        const path = encodeURIComponent(id);
        security.invoke((token) => {
            const t = "?access_token=" + encodeURIComponent(token);
            let url = "";
            if (fileType === "pdf") {
                url = `${process.env.REACT_APP_MANAGE_URL}document/${path}${t}`;
            } else {
                url = `${process.env.REACT_APP_MANAGE_URL}file/${fileType}/${path}${t}`;
            }
            window.open(url);
            return Promise.resolve();
        });
    }

    getPackageAsync(packageId: string) {
        const url =
            process.env.REACT_APP_MANAGE_URL +
            `packages/${packageId}?includeFields=false`;
        return appClient.get(url);
    }

    uploadPackage(formData: FormData, projectId: string) {
        const url =
            process.env.REACT_APP_MANAGE_URL +
            `projects/${projectId}/client/upload`;
        return appClient.post(url, formData);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    downloadProject(data: any, fileName: string) {
        const blob = new Blob([data], { type: "application/json" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
    }

    // async exportProject(projectId: string) {
    //     const url = process.env.REACT_APP_TASKS_URL + `admin/project/export/${projectId}`;
    //     window.open(url);
    //     return Promise.resolve();
    // }

    async exportProject(projectId: string) {
        const mapForm = document.createElement("form");
        mapForm.setAttribute("id", "projectExportPostForm");
        mapForm.target = "_blank";
        mapForm.method = "POST";

        await security.invoke((token) => {
            const t = "?access_token=" + encodeURIComponent(token);
            const url =
                process.env.REACT_APP_TASKS_URL +
                `project/export/${projectId}${t}`;
            Utils.downloadFile(url, mapForm, "projectExportPostForm");
            return Promise.resolve();
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async importProject(project: any): Promise<ResultApi<string>> {
        const url = process.env.REACT_APP_TASKS_URL + "project/import";
        return appClient.post(url, project);
    }

    async deleteProject(projectId: string) {
        const url = process.env.REACT_APP_TASKS_URL + `project/${projectId}`;
        return appClient.delete(url);
    }

    uploadPackageSet(formData: FormData, projectId: string) {
        const url =
            process.env.REACT_APP_TASKS_URL +
            `projects/${projectId}/upload-set`;
        return appClient.post(url, formData);
    }

    async getAllUsers() {
        const url = `${process.env.REACT_APP_TASKS_URL}userprofile`;
        const resp = await appClient.get<UserProfile[]>(url);
        return resp.unwrapOr(Array<UserProfile>());
    }

    async getUsersInProject(projectId: string) {
        const url =
            process.env.REACT_APP_TASKS_URL +
            `users/project/${projectId}?includeRoles=false`;
        const resp = await appClient.get<UserProfile[]>(url);
        return resp.unwrapOr(Array<UserProfile>());
    }

    async sendPackageViaEmail(id: string, emails: string[], message?: string) {
        const request = {
            emails,
            message,
        };

        const url =
            process.env.REACT_APP_TASKS_URL + `packages/${id}/send-by-email`;
        return appClient.post(url, request);
    }

    async getPackageSet(packageSetId: string): Promise<PackageSetItemModel[]> {
        const result = await execQuery<PackageSetItemsResult>({
            query: gql`
                query getTasksData($packageSetId: String) {
                    getPackagesFromSet(packageSetId: $packageSetId) {
                        id
                        fileName
                        fileSizeBytes
                        contentType
                        indexDate
                    }
                }
            `,
            variables: {
                packageSetId,
            },
            fetchPolicy: "network-only",
        });

        if (result.errors) {
            return [];
        }

        return result.data.getPackagesFromSet;
    }
}

import * as React from "react";
import { observer } from "mobx-react-lite";
import {
    TabsStore,
    ProjectsStore,
    ErrorStore,
    UserProfileStore,
    useStore,
} from "../stores";
import { TaskViewVisualStore, TaskCommentsStore } from "../../tasks/stores";
import { TaskService } from "../../tasks/services";
import { Row, Col } from "antd";
import { TaskSendViaEmailDialog } from "../../tasks/components";
import { useTasksStore } from "../../tasks/stores/context";
import TaskPreviewHeader from "./TaskPreviewHeader";
import TaskCommentsSection from "./TaskFullViewSections/TaskCommentsSection";
import { useNavigate, useParams } from "react-router";
import ClientActionRunner from "../../administration/misc/ClientActionHandler";
import TaskPreview from "src/modules/tasks/components/TaskPreview";

type TabRendererProps = {
    tabsStore: TabsStore;
    projectsStore: ProjectsStore;
    errorStore: ErrorStore;
    userProfileStore: UserProfileStore;
};

const TaskFullViewRenderer: React.FC<TabRendererProps> = ({
    tabsStore,
    errorStore,
    userProfileStore,
}) => {
    const context = useTasksStore();
    const rootConext = useStore();
    const navigate = useNavigate();

    const taskId = useParams().taskId!;
    const taskPreviewStore = tabsStore.taskStores[taskId!];
    const globalTaskPreviewStore = context.taskPreviewVisualStore;
    const task = taskPreviewStore.taskPreview!;

    if (!tabsStore.taskStores[taskId]) {
        const taskService = new TaskService();
        const actionRunner = new ClientActionRunner();
        tabsStore.taskStores[taskId] = new TaskViewVisualStore(
            taskService,
            rootConext.tabsStore,
            errorStore,
            userProfileStore,
            rootConext.notificationStore,
            context.tasksRootStore,
            actionRunner,
        );
    }

    if (!tabsStore.taskCommentStores[taskId]) {
        const taskService = new TaskService();
        tabsStore.taskCommentStores[taskId] = new TaskCommentsStore(
            taskService,
            errorStore,
            taskPreviewStore,
            taskId,
        );
    }

    const store = tabsStore.taskStores[taskId];
    const commentsStore = tabsStore.taskCommentStores[taskId];

    React.useEffect(() => {
        store.setNewDescription(task.description);
    }, [commentsStore, store, taskId, task]);

    const handleCollapseTask = () => {
        globalTaskPreviewStore.togglePreview(task!);
        tabsStore.removeTaskStore(taskId);
        navigate("/tasks");
    };

    return (
        <>
            <TaskSendViaEmailDialog
                store={taskPreviewStore}
                visible={store.emailTaskToUserDialogVisible}
                setVisible={store.setEmailTaskToUserDialogVisible}
                isSending={store.sendingEmailToUsers}
                sendEmails={store.emailTaskToUsers}
                taskId={task.id}
            />
            <div className="task-properties-modal drawer full-screen">
                <Row style={{ height: "calc(100vh - 42px)" }}>
                    <Col span={15} className="task-col">
                        <div
                            className="expanded-task-container"
                            data-id="expanded-task-container"
                        >
                            <TaskPreviewHeader
                                store={store}
                                isFullView
                                handleCollapseExpandTask={handleCollapseTask}
                            />
                            <div
                                className="dialog-content-container"
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <TaskPreview
                                    store={store}
                                    commentsStore={
                                        tabsStore.taskCommentStores[taskId]
                                    }
                                    isFullView
                                />
                            </div>
                        </div>
                    </Col>
                    <Col span={9} className="large-comments-container">
                        <TaskCommentsSection
                            store={store}
                            commentsStore={tabsStore.taskCommentStores[taskId]}
                            isFullView
                        />
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default observer(TaskFullViewRenderer);

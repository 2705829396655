import * as React from "react";
import { observer } from "mobx-react-lite";
import {
    Button,
    Table,
    Layout,
    Tooltip,
    Popconfirm,
    Switch,
    Input,
} from "antd";
import { ColumnProps } from "antd/lib/table";
import { LoadingIndicator } from "../../common/components/LoadingIndicator";
import { PublishedReport, ReportAccess } from "../types";
import ReportsStore from "../stores/ReportsStore";
import ReportEditDialog from "./ReportEditDialog";
import { safeStringLocaleCompare } from "../../common/misc/Utils";

const { Content } = Layout;
type Props = {
    store: ReportsStore;
};

const ReportsList: React.FC<Props> = ({ store }) => {
    const columns: ColumnProps<PublishedReport>[] = [
        {
            title: "Title",
            dataIndex: "title",
            key: "title",
            render: (value: string) => (
                <span data-id-cells="name">{value}</span>
            ),
            sorter: (a: PublishedReport, b: PublishedReport) =>
                safeStringLocaleCompare(b.title, a.title),
        },
        {
            title: "Source",
            dataIndex: "source",
            key: "source",
            render: (value: string) => (
                <span data-id-cells="name">{value}</span>
            ),
            sorter: (a: PublishedReport, b: PublishedReport) =>
                safeStringLocaleCompare(b.source, a.source),
        },
        {
            title: "Projects",
            dataIndex: "projects",
            key: "projects",
            render: (value: string[]) => (
                <span data-id-cells="name">
                    {value.map((v) => store.getProjectName(v)).join(", ")}
                </span>
            ),
        },
        {
            title: "Access",
            dataIndex: "reportAccess",
            key: "reportAccess",
            render: (value: ReportAccess) => (
                <span data-id-cells="name">
                    {store.reportAccessOptions[value]}
                </span>
            ),
        },
        {
            title: "Actions",
            width: 200,
            render: (record: PublishedReport) => (
                <div className="row-actions-wrapper">
                    <Button
                        data-id-cells="Edit report"
                        title="Edit"
                        size="small"
                        type="link"
                        onClick={() => {
                            store.setSelectedReport(record);
                        }}
                    >
                        <Tooltip title="Edit report" placement="bottom">
                            <i className="alpha-icon md table-action-edit" />
                        </Tooltip>
                    </Button>
                    <Popconfirm
                        id="data-id-popconfirm-box"
                        title="Are you sure you want to delete this report?"
                        onConfirm={() => {
                            store.deleteReport(record.id);
                        }}
                        placement="topRight"
                    >
                        <Button
                            data-id-cells="Delete report"
                            title="Delete"
                            size="small"
                            type="link"
                        >
                            <Tooltip title="Delete report" placement="bottom">
                                <i className="alpha-icon md table-action-delete" />
                            </Tooltip>
                        </Button>
                    </Popconfirm>
                    <Switch
                        data-id-cells="is-active"
                        data-id-value={record.enabled}
                        style={{
                            visibility: "visible",
                            marginBottom: 18,
                            marginLeft: 14,
                        }}
                        checked={record.enabled}
                        onChange={() => {
                            store.toggleReportAvailability(record.id);
                        }}
                    />
                </div>
            ),
        },
    ];
    if (store.reportsLoading) {
        return <LoadingIndicator />;
    }

    const handleEnterPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        store.setSearchString(event.currentTarget.value);
    };

    return (
        <Layout className="screen-size tasks-types-layout layout-with-table">
            <div className="header-wrapper">
                <div className="title-wrapper">
                    <div className="header-title">Published Reports</div>
                </div>
                <Button
                    data-id="button-add-type"
                    type="primary"
                    size="large"
                    onClick={() => {
                        store.setNewReportDialogVisibility(true);
                    }}
                >
                    Publish report
                </Button>
            </div>
            <ReportEditDialog store={store} />
            <Layout>
                <Content>
                    <Input
                        data-id="input-search"
                        style={{ width: 200, marginBottom: 20 }}
                        allowClear
                        placeholder="Search..."
                        onPressEnter={handleEnterPress}
                        prefix={
                            <i
                                className="alpha-icon xs table-search"
                                style={{ marginRight: 10 }}
                            />
                        }
                        onClear={() => store.setSearchString("")}
                    />
                    <Table
                        data-id="table-tasks-reports-list"
                        className="alpha-table with-scroll"
                        columns={columns}
                        dataSource={store.filteredReports}
                        rowKey={(r) => r.id}
                        pagination={false}
                        scroll={{ y: "calc(100vh - 240px)" }}
                    />
                </Content>
            </Layout>
        </Layout>
    );
};

export default observer(ReportsList);

import * as React from "react";
import { ActionEvent } from "../types";
import { observer } from "mobx-react-lite";
import { TaskViewVisualStore } from "../stores";
import TaskUserProfilePicture from "./TaskUserProfilePicture";
import TasksRootStore from "../stores/TasksRootStore";
import { Utils } from "../../common/misc/Utils";
import { List, Popover, Space, Tooltip } from "antd";
import {
    CheckCircleTwoTone,
    CloseCircleTwoTone,
    FieldTimeOutlined,
    MessageOutlined,
    ProfileOutlined,
    SyncOutlined,
    UnorderedListOutlined,
} from "@ant-design/icons";

type Props = {
    store: TaskViewVisualStore;
    rootStore: TasksRootStore;
    event: ActionEvent;
};

const ActionEventView: React.FC<Props> = ({ store, rootStore, event }) => {
    const getStatusIcon = () => {
        switch (event.status) {
            case "Completed":
                return <CheckCircleTwoTone twoToneColor="green" />;
            case "Failed":
                return <CloseCircleTwoTone twoToneColor="red" />;
            default:
                return <SyncOutlined spin />;
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getObjectHtml = (obj: any) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const parseVal = (val: any) => {
            if (typeof val === "object" && val !== null) {
                return Object.keys(val as object).map((k) => (
                    <tr key={k}>
                        <td>{k}</td>
                        <td>{val[k]}</td>
                    </tr>
                ));
            }
            return val;
        };

        return (
            <table className="at-object-table">
                <tbody>
                    {Object.keys(obj).map((k) => (
                        <tr key={k}>
                            <td>{k}</td>
                            <td>{parseVal(obj[k])}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };

    return (
        <List.Item
            extra={
                <Space>
                    <Popover
                        title="Timings"
                        content={getObjectHtml({
                            Started: Utils.formatDateStringShort(
                                event.triggeredOn,
                            ),
                            Completed: event.completedOn
                                ? Utils.formatDateStringShort(event.completedOn)
                                : undefined,
                            Duration: Utils.getDuration(
                                event.triggeredOn,
                                event.completedOn,
                            ),
                        })}
                    >
                        <FieldTimeOutlined />
                    </Popover>
                    {event.fields && Object.keys(event.fields).length > 0 && (
                        <Popover
                            title="Fields"
                            content={getObjectHtml(event.fields)}
                            placement="bottom"
                        >
                            <UnorderedListOutlined />
                        </Popover>
                    )}
                    {event.result && Object.keys(event.result).length > 0 && (
                        <Popover
                            title="Result"
                            content={getObjectHtml(event.result)}
                            placement="bottom"
                        >
                            <ProfileOutlined />
                        </Popover>
                    )}
                    {event.message && (
                        <Popover
                            title="Message"
                            content={event.message}
                            placement="bottom"
                        >
                            <MessageOutlined />
                        </Popover>
                    )}
                    {getStatusIcon()}
                </Space>
            }
        >
            <List.Item.Meta
                avatar={
                    <TaskUserProfilePicture
                        store={store}
                        userId={event.userId}
                        userName={store.allUsersFullNameResolver[event.userId]}
                        className="author-avatar"
                        size={24}
                    />
                }
                title={
                    <>
                        {rootStore.getActionName(event.actionDefinitionId)}
                        {event.packageId && (
                            <Tooltip
                                title={store.getAttachmentName(event.packageId)}
                            >
                                <i className="alpha-icon attachment xxs" />
                            </Tooltip>
                        )}
                    </>
                }
                description={Utils.formatDateStringShort(event.triggeredOn)}
            />
        </List.Item>
    );
};

export default observer(ActionEventView);

import * as React from "react";
import { observer } from "mobx-react";
import {
    Modal,
    Input,
    Button,
    Form,
    Checkbox,
    Select,
    Space,
    InputNumber,
} from "antd";
import TaskTypesVisualStore from "../stores/TaskTypesVisualStore";
import { useForm } from "antd/lib/form/Form";

type Props = {
    store: TaskTypesVisualStore;
};

const FormItem = Form.Item;
export const periodOptions = ["Day(s)", "Week(s)", "Month(s)", "Year(s)"];

const TaskTypeCreateDialog: React.FC<Props> = ({ store }) => {
    const [form] = useForm();

    const handleSubmit = async () => {
        form.validateFields()
            .then((values) => {
                const dueDate =
                    values.count && values.period
                        ? { count: values.count, period: values.period }
                        : null;
                store.createTaskType(
                    values.name,
                    values.asignee,
                    values.code,
                    values.canChangeStatusManually,
                    values.distinctSubtaskStatuses,
                    dueDate,
                );
                form.resetFields();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const taskTypeNamesValidator = (rule: any, value: any, callback: any) => {
        const taskTypesToValidate = store!.taskTypes.map((t) =>
            t.name.toLowerCase(),
        );
        if (value && taskTypesToValidate.includes(value.toLowerCase())) {
            callback("Task type with the same name already exists");
        } else {
            callback();
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const taskTypesCodesValidator = (rule: any, value: any, callback: any) => {
        const taskTypesToValidate = store!.taskTypes.map(
            (t) => t.code?.toLowerCase() ?? "",
        );
        if (value && taskTypesToValidate.includes(value.toLowerCase())) {
            callback("Task type with the same code already exists");
        } else {
            callback();
        }
    };

    return (
        <Modal
            data-id-type="modal-window"
            data-id-name="Create new task type"
            className="alpha-modal"
            title="Create new task type"
            open={store.newTypeDialogVisible}
            closable={false}
            centered
            footer={[
                <Button
                    data-id="button-new-task-type-cancel"
                    key="task-type-cancel"
                    size="large"
                    className="light"
                    onClick={() => {
                        store.setNewTypeDialogVisible(false);
                        form.resetFields();
                    }}
                >
                    Cancel
                </Button>,
                <Button
                    data-id="button-new-task-type-Save"
                    type="primary"
                    key="task-type-save"
                    size="large"
                    className="dark"
                    onClick={handleSubmit}
                >
                    Save
                </Button>,
            ]}
        >
            <Form
                layout="vertical"
                data-id="create-task-type-dialog-form"
                form={form}
                className="alpha-form"
            >
                <FormItem
                    label={<span className="dialog-field-label">Name</span>}
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: "Name is required",
                            whitespace: true,
                        },
                        { validator: taskTypeNamesValidator },
                    ]}
                >
                    <Input data-id="input-Name" />
                </FormItem>
                <FormItem
                    label={
                        <span className="dialog-field-label">
                            Default assignee
                        </span>
                    }
                    name="asignee"
                    rules={[
                        {
                            required: true,
                            message: "Default assignee is required",
                            whitespace: true,
                        },
                    ]}
                >
                    <Select
                        options={store.usersForProject
                            .filter((u) => !u.isDeleted)
                            .map((u) => ({
                                value: u.userId,
                                label: `${u.firstName} ${u.lastName}`,
                            }))}
                    />
                </FormItem>
                <FormItem
                    label={<span className="dialog-field-label">Code</span>}
                    name="code"
                    rules={[{ validator: taskTypesCodesValidator }]}
                >
                    <Input data-id="input-code" />
                </FormItem>
                <FormItem label="Due period">
                    <Space>
                        <FormItem name="count">
                            <InputNumber data-id="input-count" min={1} />
                        </FormItem>
                        <FormItem name="period" style={{ width: 100 }}>
                            <Select
                                data-id="input-period"
                                options={periodOptions.map((p) => ({
                                    label: p,
                                    value: p.charAt(0),
                                }))}
                            />
                        </FormItem>
                    </Space>
                </FormItem>
                <FormItem
                    label={
                        <span className="dialog-field-label">
                            Manual status change allowed
                        </span>
                    }
                    name="canChangeStatusManually"
                    valuePropName="checked"
                    initialValue
                >
                    <Checkbox data-id="input-Name" />
                </FormItem>
                <FormItem
                    label={
                        <span className="dialog-field-label">
                            Distinct subtask statuses
                        </span>
                    }
                    name="distinctSubtaskStatuses"
                    valuePropName="checked"
                    initialValue={false}
                >
                    <Checkbox data-id="input-Name" defaultChecked={false} />
                </FormItem>
            </Form>
        </Modal>
    );
};

export default observer(TaskTypeCreateDialog);

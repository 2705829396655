import * as React from "react";
import { observer } from "mobx-react-lite";
import DashboardStore from "../stores/DashboardStore";
import { ActivitiesPeriodEnum, Activity } from "../types/Activity";
import { Divider, Select, Skeleton } from "antd";
import { TaskViewVisualStore } from "../../tasks/stores";
import { ALL_PROJECTS } from "../../tasks/screens/TasksPage";

type Props = {
    dashboardStore: DashboardStore;
    tasksStore: TaskViewVisualStore;
};

enum LastActivityType {
    Comments = "NewComments",
    FailedTasks = "FailedMessages",
    AssignedTasks = "AssignedTasks",
}
const ActivitiesCard: React.FC<Props> = ({ dashboardStore }) => {
    const { allProjectsActivities, selectedProjectActivities } = dashboardStore;

    const handleActivityClick = (
        activityType: LastActivityType,
        allProjects: boolean,
    ) => {
        const projectId = allProjects
            ? ALL_PROJECTS
            : dashboardStore.selectedActivitiesProjectId;
        const url = `${window.location.href}/../tasks/project/${projectId}/activity/${activityType}?period=${dashboardStore.activitiesPeriod}`;
        window.open(url, "_blank")!.focus();
    };

    const getCounter = (counter: number, showAllProjects: boolean) => {
        return dashboardStore.reloadingLastActivities && !showAllProjects ? (
            <Skeleton.Button active size="small" />
        ) : (
            counter
        );
    };

    const ActvityContainer = (
        activities: Activity[],
        showAllProjects: boolean = true,
    ) => {
        const assignedTasks = activities.find(
            (a) => a.activityType === "AssignedTasks",
        );
        const failedTasks = activities.find(
            (a) => a.activityType === "FailedMessages",
        );
        const comments = activities.find(
            (a) => a.activityType === "NewComments",
        );
        const assginedTasksCount = assignedTasks?.ids.length ?? 0;
        const failedTasksCount = failedTasks?.ids.length ?? 0;
        const commentsCount = comments?.ids.length ?? 0;

        return (
            <>
                <div className="activities-row">
                    <div
                        className={`activity-container ${assginedTasksCount === 0 ? "disabled" : ""} `}
                        key={1}
                        onClick={() =>
                            handleActivityClick(
                                LastActivityType.AssignedTasks,
                                showAllProjects,
                            )
                        }
                    >
                        <div
                            className={`count ${assginedTasksCount === 0 ? "nullable" : ""}`}
                        >
                            {getCounter(assginedTasksCount, showAllProjects)}
                        </div>
                        <div className="title">
                            Tasks <br /> assigned to you
                        </div>
                    </div>
                    <div
                        className={`activity-container ${failedTasksCount === 0 ? "disabled" : ""} `}
                        key={2}
                        onClick={() =>
                            handleActivityClick(
                                LastActivityType.FailedTasks,
                                showAllProjects,
                            )
                        }
                    >
                        <div
                            className={`count ${failedTasksCount === 0 ? "nullable" : ""}`}
                        >
                            {getCounter(failedTasksCount, showAllProjects)}
                        </div>
                        <div className="title">Errors</div>
                    </div>
                    <div
                        className={`activity-container ${commentsCount === 0 ? "disabled" : ""} `}
                        key={3}
                        onClick={() =>
                            handleActivityClick(
                                LastActivityType.Comments,
                                showAllProjects,
                            )
                        }
                    >
                        <div
                            className={`count ${commentsCount === 0 ? "nullable" : ""}`}
                        >
                            {getCounter(commentsCount, showAllProjects)}
                        </div>
                        <div className="title">Comments</div>
                    </div>
                </div>
            </>
        );
    };

    return (
        <div className="card">
            <div>
                <div className="section-title last-activities">
                    Last activities
                </div>
                <Select
                    className="period-selector"
                    options={[
                        {
                            label: "Last 24 hours",
                            value: ActivitiesPeriodEnum.Today,
                        },
                        {
                            label: "Last 7 days",
                            value: ActivitiesPeriodEnum.SevenDays,
                        },
                        {
                            label: "Last 30 days",
                            value: ActivitiesPeriodEnum.ThirtyDays,
                        },
                    ]}
                    defaultValue={dashboardStore.activitiesPeriod}
                    onChange={dashboardStore.handleActivitiesPeriodChange}
                />
            </div>
            <div className="activities-card-container">
                <div className="label">All projects</div>
                {ActvityContainer(allProjectsActivities)}
                <Divider />
                <Select
                    className="project-selector"
                    options={dashboardStore.projects.map((p) => ({
                        value: p.id,
                        label: p.name,
                    }))}
                    defaultValue={dashboardStore.selectedActivitiesProjectId}
                    onChange={dashboardStore.handleActivitiesProjectChange}
                />
                {ActvityContainer(selectedProjectActivities, false)}
            </div>
        </div>
    );
};

export default observer(ActivitiesCard);

import {
    ClearOutlined,
    ImportOutlined,
    MinusCircleOutlined,
    PlusOutlined,
} from "@ant-design/icons";
import {
    Button,
    Form,
    FormInstance,
    FormListFieldData,
    Input,
    List,
    Space,
} from "antd";
import { StoreValue } from "antd/es/form/interface";
import { observer } from "mobx-react-lite";
import React from "react";
import { useHotkeys } from "react-hotkeys-hook";

type Props = {
    form: FormInstance;
    items: FormListFieldData[];
    add: (defaultValue?: StoreValue) => void;
    remove: (index: number | number[]) => void;
};

const MetadataListItemsEditor: React.FC<Props> = ({
    form,
    items,
    add,
    remove,
}) => {
    useHotkeys(
        "shift+a",
        () => {
            add();
        },
        { preventDefault: true },
    );

    const pasteFromClipboard = () => {
        navigator.clipboard.readText().then((text) => {
            const lines = text.split("\n").map((line) => line.trim());
            lines.forEach((line) => {
                if (line.trim() === "") {
                    return;
                }

                const [value, label] = line
                    .split(/\t|,| - /)
                    .map((v) => v.trim());
                add({ value, label });
            });
        });
    };

    const clearItems = () => {
        form.setFieldValue("listValues", []);
    };

    return (
        <>
            <div style={{ marginBottom: 8 }}>
                <label>
                    <span className="dialog-field-label">List items</span>
                </label>
            </div>
            <Space style={{ marginBottom: 8 }}>
                <Button icon={<PlusOutlined />} onClick={() => add()}>
                    Add
                </Button>
                <Button
                    icon={<ImportOutlined />}
                    onClick={() => pasteFromClipboard()}
                >
                    Paste
                </Button>
                <Button icon={<ClearOutlined />} onClick={() => clearItems()}>
                    Clear
                </Button>
            </Space>
            <List
                style={{ maxHeight: 220, overflowY: "auto", marginBottom: 24 }}
                size="small"
                bordered
                dataSource={items}
                renderItem={(item) => (
                    <List.Item>
                        <Space align="center">
                            <Form.Item
                                name={[item.name, "value"]}
                                style={{ marginBottom: 0 }}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: "Item value is required",
                                    },
                                ]}
                            >
                                <Input
                                    placeholder="value"
                                    autoFocus
                                    onPressEnter={(e) => e.currentTarget.blur()}
                                />
                            </Form.Item>
                            <Form.Item
                                name={[item.name, "label"]}
                                style={{ marginBottom: 0 }}
                            >
                                <Input
                                    placeholder="label"
                                    onPressEnter={(e) => e.currentTarget.blur()}
                                />
                            </Form.Item>
                            <MinusCircleOutlined
                                className="alpha-antd-icon no-margin"
                                onClick={() => remove(item.name)}
                            />
                        </Space>
                    </List.Item>
                )}
            />
        </>
    );
};

export default observer(MetadataListItemsEditor);

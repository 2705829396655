import appClient from "../../common/services/AppClient";
import type { UserInfo } from "../types/UserInfo";

class UserInfoService {
    async getUserInfo() {
        const url = `${process.env.REACT_APP_TASKS_URL}users/userInfo`;
        const resp = await appClient.get<UserInfo>(url);
        const userInfo = resp.unwrapOr({} as UserInfo);
        return userInfo;
    }
}

const UserService = new UserInfoService();
export default UserService;
